<template>
  <div class="margin-bottom-15">
    <el-form-item class="margin-0" label="Комбіновані частини">
      <div class="line-height-1-5">
        Сума для розподілу: <CurrencyView :value="value" />. Вкажить частину
        готівкою, решта буде рахуватись як частина карткою.
      </div>
    </el-form-item>

    <el-form-item label="Готівка">
      <div class="margin-top-5">
        <NumberInput
          v-model="localCombiCashValue"
          :min="0"
          :max="value"
          :disabled="isDataSent"
          suffixDefault="money"
          size="small"
          class="margin-right-10"
        />

        <span v-if="!isDataSent">
          <el-button
            size="small"
            @click="sendData()"
            :disabled="
              localCombiCashValue === 0 || localCombiCashValue === value
            "
            >Встановити</el-button
          >
        </span>

        <el-button size="small" @click="cancelData()" v-if="isDataSent"
          >Змінити</el-button
        >
      </div>

      <div class="line-height-1-5">
        <div>
          Готівкою {{ isDataSent ? '' : '(ще не встановлено)' }}
          <CurrencyView :value="localCombiCashValue" />, тому карткою
          <CurrencyView :value="value - localCombiCashValue" />.
        </div>

        <div
          class="small color-danger margin-top-5"
          v-if="
            (localCombiCashValue === value || localCombiCashValue === 0) &&
            !isCombiWithCashback
          "
        >
          Для комбінованого методу оплати необхідно, щоб обидві частини (готівка
          та картка) не дорівнювали нулю. Або перемикніться на інший метод.
        </div>
      </div>
    </el-form-item>
  </div>
</template>

<script>
  import CurrencyView from '@/components/Common/CurrencyView'
  import NumberInput from '@/components/Common/Forms/NumberInput'

  export default {
    data() {
      return {
        localCombiCashValue: 0,
        isDataSent: false,
      }
    },
    props: {
      value: Number,
      cashPart: Number,
      isCombiWithCashback: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      cashPart: {
        handler(value) {
          if (value === undefined) return

          this.localCombiCashValue = value
          this.isDataSent = true
        },
        immediate: true,
      },
    },
    components: {
      CurrencyView,
      NumberInput,
    },
    methods: {
      sendData() {
        this.isDataSent = true
        this.$emit('change', this.localCombiCashValue)
      },
      cancelData() {
        this.isDataSent = false
        this.localCombiCashValue = 0
        this.$emit('change', 0)
      },
    },
    mounted() {
      if (this.cashPart) {
        this.$nextTick(() => {
          this.localCombiCashValue = this.cashPart
        })
      }
    },
  }
</script>

<style></style>
