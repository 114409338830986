const BASE_SOURCES = {
  CARD: 'card',
  CASH: 'cash',
}

const BASE_SOURCES_TRANSLATIONS = {
  [BASE_SOURCES.CARD]: 'Картка',
  [BASE_SOURCES.CASH]: 'Готівка',
}

export const MONEY_SOURCES = {
  ...BASE_SOURCES,
  FUND: 'fund',
}

export const MONEY_SOURCES_TRANSLATIONS = {
  ...BASE_SOURCES_TRANSLATIONS,
  [MONEY_SOURCES.FUND]: 'Інше',
}

export const PAYMENT_METHODS = {
  ...BASE_SOURCES,
  CERT: 'cert',
  CASHBACK: 'cashback',
  COMBI: 'combi',
}

export const PAYMENT_METHODS_TRANSLATIONS = {
  ...BASE_SOURCES_TRANSLATIONS,
  [PAYMENT_METHODS.CERT]: 'Сертіфікат',
  [PAYMENT_METHODS.CASHBACK]: 'Бали',
  [PAYMENT_METHODS.COMBI]: 'Комбінований',
}

export const BASE_PAYMENT_PARTS = {
  [PAYMENT_METHODS.CARD]: 0,
  [PAYMENT_METHODS.CASH]: 0,
}

export const DEFAULT_PAYMENT_PARTS = {
  ...BASE_PAYMENT_PARTS,
  [PAYMENT_METHODS.CERT]: 0,
  [PAYMENT_METHODS.CASHBACK]: 0,
}

export const DISCOUNT_FROM_TYPES = {
  NONE: 'none',
  SALON: 'salon',
  MASTER: 'master',
}

export const DISCOUNT_FROM_TYPES_TRANSLATIONS = {
  [DISCOUNT_FROM_TYPES.NONE]: 'Всіх',
  [DISCOUNT_FROM_TYPES.SALON]: 'Салону',
  [DISCOUNT_FROM_TYPES.MASTER]: 'Майстра',
}

export const MONEY_PARTS = {
  SALONPART: 'salonPart',
  MASTERPART: 'masterPart',
  ADMINPART: 'adminPart',
  CLIENTPART: 'clientPart',
}
export const MONEY_PARTS_TRANSLATIONS = {
  [MONEY_PARTS.SALONPART]: 'салону',
  [MONEY_PARTS.MASTERPART]: 'майстру',
  [MONEY_PARTS.ADMINPART]: 'адміністратору',
  [MONEY_PARTS.CLIENTPART]: 'клієнту',
}
