<template>
  <div id="app" v-loading="loading">
    <div class="content-wrapper" :class="this.$route.name">
      <Header />
      <Main />

      <EventsForm
        :dialogVisible="editEventPopupVisibility"
        :eventId="workingEventId"
        :newEvent="isNewEvent"
        :eventType="eventType"
        :eventData="eventData"
        @closeEventDialog="onCloseAddEditDialog"
        v-if="editEventPopupVisibility"
      />
    </div>

    <Footer />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { EventBus } from '@/bus.js'

  import Header from '@/layout/Header'
  import Main from '@/layout/Main'
  import Footer from '@/layout/footer/Footer'

  import EventsForm from '@/components/Calendar/EventsForms/EventsForm' // ver 2

  export default {
    name: 'default-layout',
    data() {
      return {
        workingEventId: null,
        eventData: null,
        isNewEvent: false,
        eventType: null,
        editEventPopupVisibility: false,
        root: null,
      }
    },
    components: {
      Header,
      Main,
      Footer,
      EventsForm,
    },
    computed: {
      ...mapGetters(['loading', 'statuses']),
    },
    methods: {
      addEvent({ date, timeStart, clientId, isNeedToShowClientMessage }) {
        this.updateDate = ''
        this.isNewEvent = true
        this.eventData = {
          dateStart: date,
          timeStart,
          clientId,
          isNeedToShowClientMessage,
        }

        this.editEventPopupVisibility = true
        this.addLookOnBackButtonEvent()
      },
      editEvent({ id, type }) {
        this.updateDate = ''
        this.workingEventId = id
        this.editEventPopupVisibility = true
        this.eventType = type
        this.isNewEvent = false

        this.addLookOnBackButtonEvent()
      },
      addLookOnBackButtonEvent() {
        let self = this

        history.pushState(null, null, null)
        window.addEventListener('popstate', self.lookOnBackButton)

        history.pushState(null, document.title, location.href)

        window.addEventListener('popstate', function () {
          if (this.editEventPopupVisibility) {
            this.onCloseAddEditDialog()
          }

          history.pushState(null, document.title, location.href)
        })
      },
      lookOnBackButton() {
        let self = this

        if (this.editEventPopupVisibility) {
          this.onCloseAddEditDialog()
        }

        history.pushState(null, null, null)
        window.removeEventListener('popstate', self.lookOnBackButton)
      },
      onCloseAddEditDialog() {
        this.workingEventId = null
        this.eventData = null
        this.isNewEvent = false
        this.eventType = null
        this.editEventPopupVisibility = false
      },
    },
    mounted() {
      EventBus.$on('addEvent', (data) => this.addEvent(data))
      EventBus.$on('editEvent', (eventId) => this.editEvent(eventId))

      this.root = document.documentElement

      this.statuses.forEach((status) => {
        this.root.style.setProperty(`--status-color-${status.id}`, status.color)
      })
    },
    beforeDestroy() {
      EventBus.$off('addEvent')
      EventBus.$off('editEvent')
    },
  }
</script>

<style lang="scss">
  #app {
    flex: 1;
    height: 100%;
    // height: calc(var(--vh, 1vh) * 100);
    height: 100dvh;
    display: grid;
    grid-template-rows: 1fr auto;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
      @supports (-moz-appearance: meterbar) {
        padding-bottom: 50px;
      }
    }

    @media screen and (max-width: 767px) {
      _::-webkit-full-page-media,
      _:future,
      :root .safari_only {
        padding-bottom: 20px;
      }
    }

    &.calendar {
      padding-bottom: 0;
    }
  }
</style>
