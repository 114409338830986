<template>
  <div class="line-height-1-5 margin-bottom-5 margin-top-5">
    <div>
      <strong class="margin-right-5">
        <i
          class="el-icon-shopping-cart-full color-warning"
          v-if="isSameGoodsExpenceCategory"
        />

        <i
          class="el-icon-watch color-warning"
          v-if="isSameMaterialExpenceCategory"
        />

        {{ category.name }}
      </strong>

      <el-link
        @click="onCategoryEditing(true)"
        :disabled="isSomeCategoryEditing || loading"
        icon="el-icon-edit"
        type="primary"
        class="margin-right-10"
        >Редагувати</el-link
      >

      <el-popconfirm
        confirm-button-text="Так"
        cancel-button-text="Ні, скасувати"
        icon="el-icon-info"
        icon-color="#f26419"
        title="Ви впевнені, що хочете видалити категорію?"
        :disabled="isSomeCategoryEditing || loading || isCategoryHaveChildren"
        @confirm="deleteCategory(category.id)"
      >
        <el-link
          :disabled="isSomeCategoryEditing || loading || isCategoryHaveChildren"
          icon="el-icon-delete"
          type="danger"
          size="small"
          slot="reference"
          >Видалити</el-link
        >
      </el-popconfirm>

      <el-tooltip
        content="Категорія має дочірні категорії, спочатку видаліть їх, або прив'яжіть до іншої категорії"
        placement="top"
        v-if="isCategoryHaveChildren"
      >
        <el-link type="text" :underline="false">
          &nbsp;<i class="el-icon-question color-danger"
        /></el-link>
      </el-tooltip>

      <div v-if="isSameGoodsExpenceCategory" class="small">
        Категорія використовується для вказання закупівлі товарів
      </div>

      <div v-if="isSameMaterialExpenceCategory" class="small">
        Категорія використовується для вказання закупівлі матеріалів
      </div>

      <ExpencesCategoryForm
        v-if="categoryEditing"
        :rawCategorizedCategories="rawCategorizedCategories"
        :category="category"
        @categoryEditing="onCategoryEditing"
      />
    </div>

    <ExpencesCategories
      :categorizedCategories="category.children"
      :isSomeCategoryEditing="isSomeCategoryEditing"
      :rawCategorizedCategories="rawCategorizedCategories"
      v-if="category.children"
      class="margin-top-5"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { db } from '@/plugins/firebase'
  import { EventBus } from '@/bus.js'

  import expenceService from '@/services/expence-service'

  import ExpencesCategoryForm from '@/components/Finances/FinancesFlow/FinancesExpence/ExpencesCategories/ExpencesCategoryForm'

  export default {
    props: {
      category: Object,
      rawCategorizedCategories: Array,
      isSomeCategoryEditing: Boolean,
    },
    components: {
      ExpencesCategoryForm,
      ExpencesCategories: () =>
        import(
          '@/components/Finances/FinancesFlow/FinancesExpence/ExpencesCategories/ExpencesCategories'
        ),
    },
    data() {
      return {
        categoryEditing: false,
      }
    },
    computed: {
      ...mapGetters(['loading', 'userSalon']),
      isCategoryHaveChildren() {
        return this.category.children && this.category.children.length > 0
      },
      materialExpenceCategory() {
        return this.userSalon.materialExpenceCategory
      },
      isSameMaterialExpenceCategory() {
        return this.category.id === this.materialExpenceCategory
      },
      goodsExpenceCategory() {
        return this.userSalon.goodsExpenceCategory
      },
      isSameGoodsExpenceCategory() {
        return this.category.id === this.goodsExpenceCategory
      },
    },
    methods: {
      onCategoryEditing(isEditing) {
        this.categoryEditing = isEditing

        EventBus.$emit('someCategoryEditing', isEditing)
      },
      async deleteCategory() {
        const batch = db.batch()

        this.$store.dispatch('setLoading', true)

        expenceService.updateCategory({
          batch,
          categoryId: this.category.id,
          categoryData: {
            active: false,
          },
        })

        batch
          .commit()
          .then(() => {
            this.onCategoryEditing(false)
          })
          .catch((error) => {
            this.$store.dispatch('setError', error.message)
          })
          .finally(() => {
            this.$store.dispatch('setLoading', false)
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .category-editing {
    align-items: flex-end;

    @include to('sm') {
      display: flex;

      > div,
      .el-button {
        margin-right: 10px;
        margin-left: 0;
      }
    }
  }
</style>
