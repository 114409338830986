import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

export default {
  state: {
    statistics: [],
  },
  mutations: {},
  actions: {
    bindStatistics: firestoreAction((context) => {
      context.dispatch('setLoading', true)

      return context
        .bindFirestoreRef('statistics', db.collection('statistics'))
        .then(function () {
          context.dispatch('setLoading', false)
        })
    }),

    unbindStatistics: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('statistics')
    }),

    addStatistic: firestoreAction((context, payload) => {
      payload = Object.assign(
        {
          salonId: context.getters.userSalonId,
        },
        payload
      )

      return db
        .collection('statistics')
        .add(payload)
        .then(function (docRef) {
          db.collection('statistics')
            .doc(docRef.id)
            .set({ id: docRef.id }, { merge: true })
        })
    }),

    updateStatistic: firestoreAction((context, payload) => {
      return db
        .collection('statistics')
        .doc(payload.id)
        .set(payload, { merge: true })
    }),

    removeStatistic: firestoreAction((context, statisticId) => {
      db.collection('statistics').doc(statisticId).delete()
    }),
  },
  getters: {
    statistics: (state) => state.statistics,
    statisticById: (state) => (id) => {
      return state.statistics.find((state) => state.id === id)
    },
  },
}
