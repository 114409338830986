import { constants } from '@/utils/constants'

export const validateString = (rule, value, callback) => {
  if (!value || value === '') {
    callback(new Error(constants.requiredFieldMessage))
  } else {
    callback()
  }
}

export const validateArray = (rule, value, callback) => {
  if (!value || value.length === 0) {
    callback(new Error(constants.requiredFieldMessage))
  } else {
    callback()
  }
}

export const validateObject = (rule, value, callback) => {
  if (!value || (value && Object.keys(value).length === 0)) {
    callback(new Error(constants.requiredFieldMessage))
  } else {
    callback()
  }
}

export const validateStringAndIfItNotEqualOtherString = (
  rule,
  value,
  callback,
  value2,
  equalErrorText
) => {
  if (value === '' || value === null) {
    callback(new Error(constants.requiredFieldMessage))
  } else if (value === value2) {
    callback(new Error(equalErrorText))
  } else {
    callback()
  }
}
