import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  state: {
    salons: [],
    salonCurrent: {},
  },
  mutations: {},
  actions: {
    updateSalonMasters: firestoreAction((context, payload) => {
      const updateObj =
        payload.role === 1
          ? {
              owners: firebase.firestore.FieldValue.arrayUnion(payload.master),
            }
          : {
              masters: firebase.firestore.FieldValue.arrayUnion(
                payload.master.id
              ),
            }

      db.collection('salons').doc(payload.salonId).update(updateObj)
    }),

    removeSalon: firestoreAction((context, salonId) => {
      return db
        .collection('salons')
        .doc(salonId)
        .set({ active: Boolean(false) }, { merge: true })
    }),
  },
  getters: {
    salons: (state) => state.salons,
  },
}
