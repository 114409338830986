<template>
  <div class="procedures">
    <el-form-item
      :prop="'mastersProcedures.' + masterKey + '.procedures'"
      :rules="{
        required: true,
        message: 'Виберіть процедуру',
        trigger: 'blur',
      }"
    >
      <div class="procedures-master-controls">
        <el-button
          @click="openChoosingProceduresDialog"
          :disabled="
            isClientAdding ||
            loading ||
            !masterId ||
            masterProcedures.length === 0 ||
            chooseProceduresDialogVisibility
          "
          type="primary"
          plain
        >
          {{
            isProceduresChoosed ? 'Редагувати процедури' : 'Виберіть процедури'
          }}
        </el-button>

        <el-button
          @click="clearChoosedProcedures"
          :disabled="
            isClientAdding ||
            loading ||
            masterProcedures.length === 0 ||
            chooseProceduresDialogVisibility ||
            !isProceduresChoosed
          "
          type="danger"
          plain
        >
          Очистити процедури
        </el-button>

        <el-button
          @click="removeMaster"
          :disabled="
            isClientAdding || loading || chooseProceduresDialogVisibility
          "
          type="danger"
          plain
        >
          Видалити майстра
        </el-button>
      </div>

      <div v-if="masterProcedures.length === 0" class="small color-danger">
        Процедур ще немає. Створіть їх у розділі "Процедури".
      </div>
    </el-form-item>

    <ChoosingMasterProceduresDialog
      :dialogVisibility="chooseProceduresDialogVisibility"
      :masterProcedures="masterProcedures"
      :eventFormProcedures="localEventFormProcedures"
      :masterName="master.name"
      @closeDialog="onCloseChooseProceduresDialog"
      @chooseProcedures="onChooseProcedures"
      v-if="chooseProceduresDialogVisibility"
    />

    <div class="procedures-list">
      <div
        v-for="(procedureItem, procedureKey) in localEventFormProcedures"
        :key="procedureKey"
        class="procedures-list--item"
      >
        <ProcedureItem
          :procedureItem="procedureItem"
          :procedureKey="procedureKey"
          :isClientAdding="isClientAdding"
          :proceduresCount="proceduresCount"
          @sendProcedureItem="onSendProcedureItem"
          @removeProcedure="onRemoveProcedure"
          @moveProcedure="onMoveProcedure"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { sortObjectByKey } from '@/utils/functions'

  import ProcedureItem from '@/components/Calendar/EventsForms/Default/ProcedureItem'
  import ChoosingMasterProceduresDialog from '@/components/Calendar/EventsForms/Default/ChoosingMasterProceduresDialog'

  export default {
    data() {
      return {
        localEventFormProcedures: {},
        isProceduresChoosed: false,
        chooseProceduresDialogVisibility: false,
      }
    },
    components: {
      ProcedureItem,
      ChoosingMasterProceduresDialog,
    },
    props: {
      isNewEvent: Boolean,
      eventFormProcedures: Object,
      masterId: String,
      isClientAdding: Boolean,
      eventTimeStart: String,
      masterKey: String,
    },
    watch: {
      eventFormProcedures: {
        immediate: true,
        deep: false,
        handler(procedures) {
          if (!procedures) return {}

          this.localEventFormProcedures = sortObjectByKey(
            JSON.parse(JSON.stringify(procedures))
          )

          this.isProceduresChoosed =
            procedures && Object.keys(procedures).length > 0
        },
      },
    },
    computed: {
      ...mapGetters([
        'masterById',
        'procedureById',
        'proceduresCategorized',
        'loading',
      ]),
      master() {
        return this.masterById(this.masterId)
      },
      proceduresCount() {
        return Object.keys(this.localEventFormProcedures).length
      },
      masterProcedures() {
        if (!this.masterId) return []

        return this.proceduresCategorized({
          masterId: this.masterId,
          returnId: false,
        })
      },
    },
    methods: {
      openChoosingProceduresDialog() {
        this.chooseProceduresDialogVisibility = true
      },
      clearChoosedProcedures() {
        this.localEventFormProcedures = {}
        this.isProceduresChoosed = false
        this.$emit('sendProcedures', null)
      },
      removeMaster() {
        this.$emit('removeMaster', this.masterKey)
      },
      onCloseChooseProceduresDialog() {
        this.chooseProceduresDialogVisibility = false
      },
      onChooseProcedures(choosedProcedures) {
        if (choosedProcedures && choosedProcedures.length > 0) {
          let tempProcedures = {}

          choosedProcedures.forEach((item, index) => {
            let obj = {}

            if (item.procedureId) {
              obj = item
            } else {
              let foundProcedureObject = null

              Object.keys(this.localEventFormProcedures).forEach(
                (procedureKey) => {
                  if (foundProcedureObject) return

                  const [, procedureId] = procedureKey.split('_')

                  if (procedureId === item.id) {
                    foundProcedureObject =
                      this.localEventFormProcedures[procedureKey]
                  }
                }
              )

              if (foundProcedureObject && foundProcedureObject.procedureId) {
                obj = foundProcedureObject
              } else {
                const { mastersData } = this.procedureById(item.id)

                obj = {
                  procedureId: item.id,
                  procedureName: item.name,
                  timeStart: null,
                  timeEnd: null,
                  price:
                    mastersData && mastersData[item.masterId]
                      ? mastersData[item.masterId].price
                      : 0,
                  duration:
                    mastersData && mastersData[item.masterId]
                      ? mastersData[item.masterId].duration
                      : 0,
                  masterId: item.masterId,
                  correction: false,
                }
              }
            }

            const procedureKey = `${index}_${obj.procedureId}`

            if (
              !Object.prototype.hasOwnProperty.call(
                tempProcedures,
                procedureKey
              )
            ) {
              tempProcedures[procedureKey] = obj
            }
          })

          this.localEventFormProcedures = tempProcedures

          this.chooseProceduresDialogVisibility = false
          this.isProceduresChoosed =
            Object.keys(this.localEventFormProcedures).length > 0 ? true : false

          this.$nextTick(() => {
            this.$emit(
              'sendProcedures',
              JSON.parse(JSON.stringify(this.localEventFormProcedures))
            )
          })
        }
      },
      onSendProcedureItem(data) {
        this.$emit('sendProcedureItem', data)
      },
      onRemoveProcedure(data) {
        this.$emit('removeProcedureItem', data)
      },
      onMoveProcedure(data) {
        this.$emit('moveProcedure', data)
      },
    },
    mounted() {},
  }
</script>

<style lang="scss" scoped>
  .procedures {
    > .el-form-item {
      margin-bottom: 0;
    }
  }

  .procedures-master-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    > .el-button {
      margin: 0;
    }
  }

  .procedures-list--item {
    &:first-child {
      margin-top: 15px;
    }
    &::v-deep {
      &:not(:last-child) {
        .procedure-border {
          border-top: 1px solid $border-color;
          margin-top: 10px;
          padding-top: 10px;
        }
      }

      .procedure-border {
        padding-top: 0;
      }
    }
  }
</style>
