import { render, staticRenderFns } from "./VerticalMenu.vue?vue&type=template&id=7c454263&scoped=true&"
import script from "./VerticalMenu.vue?vue&type=script&lang=js&"
export * from "./VerticalMenu.vue?vue&type=script&lang=js&"
import style0 from "./VerticalMenu.vue?vue&type=style&index=0&id=7c454263&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c454263",
  null
  
)

export default component.exports