<template>
  <el-form-item label="Курс" prop="cource">
    <el-select
      v-model="cource"
      filterable
      clearable
      placeholder="Оберіть курс"
      value-key="id"
      @change="selectCource"
      :disabled="loading || !newEvent"
    >
      <el-option
        v-for="item in cources"
        :key="item.id"
        :label="item.name"
        :value="item"
      >
        <div style="display: flex">
          <span style="flex: 1 0 auto">{{ item.name }}</span>
          <span style="flex: 0 0 auto; color: #8492a6; font-size: 13px">{{
            item.masterId
              ? masterById(item.masterId).name
              : item.externalMasterName
          }}</span>
        </div>
      </el-option>
    </el-select>

    <div v-if="eventForm.cource && eventForm.cource.id" class="cource-info">
      <div class="cource-info--item">
        Майстер{{ eventForm.cource.externalMasterName ? ' (запрошений)' : '' }}:
        <strong>{{
          eventForm.cource.externalMasterName
            ? eventForm.cource.externalMasterName
            : eventForm.cource.masterId
            ? masterById(eventForm.cource.masterId).name
            : ''
        }}</strong>
      </div>

      <div class="cource-info--item" v-if="eventForm.cource.duration">
        Тривалість:
        <strong>{{ getPluralLocal(eventForm.cource.duration) }}</strong>
      </div>

      <div class="cource-info--item">
        Вартість:
        <strong><CurrencyView :value="eventForm.cource.price" /></strong>
      </div>

      <div class="cource-info--item">
        Процедури: <strong>{{ eventForm.cource.procedures }}</strong>
      </div>
    </div>
  </el-form-item>
</template>

<script>
  import { db } from '@/plugins/firebase'

  import { mapGetters } from 'vuex'

  import { sortArrayByString, getPlural } from '@/utils/functions'

  import CurrencyView from '@/components/Common/CurrencyView'

  export default {
    data() {
      return {
        cource: {},
        cources: [],
      }
    },
    computed: {
      ...mapGetters(['loading', 'masterById']),
    },
    props: {
      isClientAdding: Boolean,
      newEvent: Boolean,
      eventForm: Object,
    },
    watch: {
      eventForm: {
        immediate: true,
        deep: false,
        handler(eventFormData) {
          if (!eventFormData && !eventFormData.cource) return

          this.cource = eventFormData.cource
        },
      },
    },
    components: {
      CurrencyView,
    },
    methods: {
      selectCource() {
        this.$emit('selectCource', this.cource)
      },
      getPluralLocal(howMuch) {
        return `${howMuch} ${getPlural(howMuch)}`
      },
    },
    mounted() {
      this.cources = []

      this.$store.dispatch('setLoading', true)

      db.collection('cources')
        .where('salonId', '==', this.$store.getters.userSalonId)
        .where('active', '==', true)
        .get()
        .then((querySnapshot) => {
          const items = []

          querySnapshot.forEach(function (doc) {
            items.push(doc.data())
          })

          return items
        })
        .then((items) => {
          this.cources = sortArrayByString(
            JSON.parse(JSON.stringify(items)),
            'name'
          )
        })
        .then(() => {
          this.$store.dispatch('setLoading', false)
        })
    },
  }
</script>

<style lang="scss" scoped>
  .cource-info {
    line-height: 1.5;
    margin: 10px 0 0;
  }
</style>
