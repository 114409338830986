<template>
  <div>
    <div v-if="currentEvent && currentEvent.id && !loading">
      <EventContentV1 :currentEvent="currentEvent" v-if="!isVer2" />

      <EventContentV2 :currentEvent="currentEvent" v-if="isVer2" />
    </div>

    <div v-if="loading">Завантаження</div>

    <div v-if="(!currentEvent || !currentEvent.id) && !loading">
      Щось пішло не так...
    </div>
  </div>
</template>

<script>
  import eventService from '@/services/event-service'
  import { mapGetters } from 'vuex'

  import EventContentV1 from '@/components/Event/EventContentV1'
  import EventContentV2 from '@/components/Event/EventContentV2'

  export default {
    data() {
      return {
        currentEvent: null,
      }
    },
    props: {
      eventId: String,
    },
    computed: {
      ...mapGetters(['loading']),
      isVer2() {
        return !!this.currentEvent.mastersProcedures
      },
    },
    components: {
      EventContentV1,
      EventContentV2,
    },
    methods: {},
    async mounted() {
      this.currentEvent = await eventService.getEvent({ eventId: this.eventId })
    },
  }
</script>

<style lang="scss" scoped>
  .event-item {
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
    }

    & + div {
      padding-top: 10px;
    }

    &__label {
      flex: 1 0 200px;

      @media screen and (min-width: 768px) {
        max-width: 200px;
        padding-right: 10px;
      }
    }

    &__data {
      flex: 1;
      color: $dark-gray;

      &-additional {
        & + div {
          margin-top: 10px;
        }

        &-title {
          color: $gray;
        }
      }
    }
  }

  .event-title {
    font-size: 20px;
    color: $dark-gray;

    span {
      margin-right: 10px;
    }

    .el-tag {
      vertical-align: middle;
    }
  }

  .event-title-container {
    padding-bottom: 10px;
  }
</style>
