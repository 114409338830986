import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

export default {
  state: {
    procedureFieldsTemplates: [],
  },
  mutations: {},
  actions: {
    bindProceduresFieldTemplates: firestoreAction((context) => {
      context.dispatch('setLoading', true)

      const salonId = context.getters.userSalonId

      return context
        .bindFirestoreRef(
          'procedureFieldsTemplates',
          db
            .collection('procedureFieldsTemplates')
            .where('salonId', '==', salonId)
        )
        .then(function () {
          context.dispatch('setLoading', false)
        })
    }),

    unbindProceduresFieldTemplates: firestoreAction(
      ({ unbindFirestoreRef }) => {
        unbindFirestoreRef('procedureFieldsTemplates')
      }
    ),

    removeProcedureFieldTemplate: firestoreAction((context, procedureId) => {
      return db.collection('procedureFieldsTemplates').doc(procedureId).delete()
    }),
  },
  getters: {
    procedureFieldsTemplates: (state) =>
      JSON.parse(JSON.stringify(state.procedureFieldsTemplates)),
    procedureFieldsTemplateById: (state) => (id) => {
      return state.procedureFieldsTemplates.find(
        (procedure) => procedure.id === id
      )
    },
    procedureFieldsTemplateByName: (state) => (name) => {
      return state.procedureFieldsTemplates.find(
        (procedure) => procedure.name === name
      )
    },
  },
}
