<template>
  <div><Icons icon="copyright" /> 2024 Bossy Manager</div>
</template>

<script>
  import Icons from '@/components/Common/Icons/Icons'

  export default {
    data() {
      return {}
    },
    components: {
      Icons,
    },
  }
</script>
