<template>
  <div class="event" :id="currentEvent.id">
    <div class="event-title-container">
      <div class="event-title">
        <span>Деталі візиту</span>

        <el-tag :type="statusStyle">
          {{ status.title }}
        </el-tag>
      </div>
    </div>

    <div
      class="event-item"
      v-if="currentEvent.client && currentEvent.client.id"
    >
      <div class="event-item__label">Клієнт</div>

      <div
        class="event-item__data flex flex--vertical-center"
        :id="currentEvent.client.id"
      >
        <ClientView :clientId="currentEvent.client.id" />
      </div>
    </div>

    <div class="event-item">
      <div class="event-item__label">Дата</div>

      <div class="event-item__data">
        {{ currentEvent.dateStart }} / {{ currentEvent.eventTimeStart }}-{{
          currentEvent.eventTimeStart
        }}
      </div>
    </div>

    <div class="event-item">
      <div class="event-item__label">Тривалість</div>

      <div class="event-item__data">
        <CurrencyView :value="currentEvent.eventDuration" sign="хв" />
      </div>
    </div>

    <div class="event-item">
      <div class="event-item__label">
        {{ status.id === 3 ? 'Сплачена сума' : 'Вартість' }}
      </div>

      <div class="event-item__data">
        <span>
          <CurrencyView
            :value="currentEvent.eventPrice || currentEvent.price"
          />

          <CurrencyView
            :value="currentEvent.originPrice"
            v-if="currentEvent.price !== currentEvent.originPrice"
            class="origin-price small"
          />
        </span>

        <span v-if="currentEvent.paymentMethod">
          / {{ PAYMENT_METHODS_TRANSLATIONS[currentEvent.paymentMethod] }}</span
        >
      </div>
    </div>

    <div class="event-item" v-if="currentEvent.note">
      <div class="event-item__label">Коментар під час створення</div>

      <div class="event-item__data">{{ currentEvent.note }}</div>
    </div>

    <div class="event-item" v-if="currentEvent.finalizeNote">
      <div class="event-item__label">Коментар при завершенні</div>

      <div class="event-item__data">{{ currentEvent.finalizeNote }}</div>
    </div>

    <div class="event-item">
      <div class="event-item__label">Створено</div>

      <div class="event-item__data">
        {{ createdObj.name }} / {{ getdate(createdObj.date) }}
      </div>
    </div>

    <div class="event-item" v-if="currentEvent.finalizeAt">
      <div class="event-item__label">Завершено</div>

      <div class="event-item__data">
        {{ getdate(currentEvent.finalizeAt) }}
      </div>
    </div>

    <div class="event-item">
      <div class="event-item__label">Процедури</div>

      <div class="event-item__data">
        <div
          v-for="(
            masterProcedures, masterKey
          ) in currentEvent.mastersProcedures"
          :key="masterKey"
          class="margin-bottom-5"
        >
          {{ masterById(masterProcedures.masterId).name }} /

          <div
            v-for="(procedure, procedureKey) in masterProcedures.procedures"
            :key="procedureKey"
          >
            - {{ procedure.procedureName }}

            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { firstNull } from '@/utils/functions'
  import { PAYMENT_METHODS_TRANSLATIONS } from '@/utils/moneySource'

  import CurrencyView from '@/components/Common/CurrencyView'
  import ClientView from '@/components/Common/Clients/ClientView'

  export default {
    data() {
      return {
        PAYMENT_METHODS_TRANSLATIONS,
      }
    },
    props: {
      currentEvent: Object,
    },
    computed: {
      ...mapGetters(['statusById', 'masterById']),
      createdObj() {
        return this.currentEvent.history[0]
      },
      status() {
        return this.statusById(this.currentEvent.status)
      },
      statusStyle() {
        let style = ''

        switch (this.status.id) {
          case 1: {
            style = 'warning'
            break
          }

          case 2: {
            style = 'danger'
            break
          }

          case 3: {
            style = 'success'
            break
          }

          case 4: {
            style = 'info'
            break
          }
        }

        return style
      },
    },
    components: {
      CurrencyView,
      ClientView,
    },
    methods: {
      getdate(date) {
        const dateObj = new Date(date)

        return (
          dateObj.getFullYear() +
          '-' +
          firstNull(dateObj.getMonth() + 1) +
          '-' +
          firstNull(dateObj.getDate())
        )
      },
    },
    created() {},
  }
</script>

<style lang="scss" scoped>
  .event-title {
    font-size: 20px;
    color: $dark-gray;

    span {
      margin-right: 10px;
    }

    .el-tag {
      vertical-align: middle;
    }
  }

  .event-title-container {
    padding-bottom: 10px;
  }
  .event-item {
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
    }

    & + div {
      padding-top: 10px;
    }

    &__label {
      flex: 1 0 200px;

      @media screen and (min-width: 768px) {
        max-width: 200px;
        padding-right: 10px;
      }
    }

    &__data {
      flex: 1;
      color: $dark-gray;

      &-additional {
        & + div {
          margin-top: 10px;
        }

        &-title {
          color: $gray;
        }
      }
    }
  }
  .origin-price {
    margin-left: 5px;
    text-decoration: line-through;
    color: $gray;
  }
</style>
