<template>
  <div
    v-if="client"
    :id="client.id"
    class="client-info"
    :class="{ 'small-info': !clientInfo }"
  >
    <div class="client-info__row margin-bottom-20">
      <div class="client-info__name-container">
        <div class="client-info__name-value">
          <div
            class=""
            :class="{ 'color-danger': client.tough && client.toughNote }"
          >
            {{ client.name }}
          </div>

          <div
            class="color-danger client-info__name-tough"
            v-if="client.tough && client.toughNote"
          >
            {{ client.toughNote }}
          </div>

          <div v-if="client.note" class="client-info__name-note">
            {{ client.note }}
          </div>
        </div>

        <el-link type="primary" @click="isClientAdding = true"
          >Редагувати</el-link
        >
      </div>
    </div>

    <h6 v-if="clientInfo">ІНФОРМАЦІЯ</h6>

    <div class="client-info__row" v-if="client.phone">
      <div class="client-info__label">Телефон:</div>
      <div class="client-info__value">
        <ClientPhone :clientPhone="client.phone" />
      </div>
    </div>

    <div
      v-if="client.birthday && client.birthday.length > 0"
      class="client-info__row"
    >
      <div class="client-info__label">
        {{ clientInfo ? 'День народження' : 'ДН' }}:
      </div>
      <div class="client-info__value">
        {{ getBirthday(client.birthday) }} / {{ getAge(client.birthday) }}
      </div>
    </div>

    <div
      v-if="client.originOfRegistration && clientInfo"
      class="client-info__row"
    >
      <div class="client-info__label">Перший контакт:</div>
      <div class="client-info__value">
        {{
          client.originOfRegistration.slug
            ? client.originOfRegistration.name
            : client.originOfRegistration
        }}
        <span v-if="client.originOfRegistrationLink"
          >/
          <a :href="client.originOfRegistrationLink" target="_blank">{{
            client.originOfRegistrationLink
          }}</a></span
        >
      </div>
    </div>

    <div v-if="client.botContactId && isSalonWithBot" class="client-info__row">
      <div class="client-info__label">Користується ботом в телеграмі:</div>
      <div class="client-info__value">
        <span v-if="client.telegramData">
          {{ client.telegramData.userName }}

          <el-link
            type="text"
            class="margin-right-10"
            target="_blank"
            :href="`https://login.sendpulse.com/messengers/chats/${client.telegramData.botId}/contacts/all/${client.botContactId}/`"
          >
            <i class="el-icon-chat-line-square" /> відкрити чат
          </el-link>
        </span>
      </div>
    </div>

    <div
      v-if="client.earnings && isUserIsAdminOrLowerLocal"
      class="client-info__row"
    >
      <div class="client-info__label">Дохід:</div>
      <div class="client-info__value">
        <CurrencyView :value="client.earnings" />
      </div>
    </div>

    <div
      v-if="client.latestFinishedDate && clientInfo"
      class="client-info__row"
    >
      <div class="client-info__label">Остання процедура:</div>
      <div class="client-info__value">
        {{ getDateFromDateNumber(client.latestFinishedDate) }}
      </div>
    </div>

    <div v-if="client.email" class="client-info__row">
      <div class="client-info__label">Email:</div>
      <div class="client-info__value">
        {{ client.email }}
      </div>
    </div>

    <div class="client-info__row">
      <div class="client-info__label">Зв'язуємось при акціях/дзвінках:</div>
      <div class="client-info__value">
        {{
          client.interestingInContact === true ||
          client.interestingInContact === undefined
            ? "Зв'язуємось"
            : "Не зв'язуємось"
        }}
      </div>
    </div>

    <div
      class="client-info__row"
      v-if="
        client.interestingInProcedures &&
        client.interestingInProcedures.length > 0
      "
    >
      <div class="client-info__label">Зацікавлений у процедурах:</div>
      <div class="client-info__value">
        <div
          v-for="(procCat, index) in client.interestingInProcedures"
          :key="index"
        >
          {{ procedureCategoryById(procCat).name }}
        </div>
      </div>
    </div>

    <div v-if="client.createdAt && clientInfo" class="client-info__row">
      <div class="client-info__label">Створений:</div>
      <div class="client-info__value">
        {{ getDateFromDateObj(client.createdAt) }}
      </div>
    </div>

    <div
      v-if="client.referral && clientInfo"
      class="client-info__row flex--vertical-center"
    >
      <div class="client-info__label">Дізнався про нас:</div>

      <div class="client-info__value">
        <span
          v-if="client.referral === 'client' || client.referral === 'master'"
          class="flex flex--vertical-center"
        >
          <span v-if="client.referral === 'client'">
            <ClientView
              v-if="client.referralClient"
              :clientId="client.referralClient"
            />

            <span v-else>
              {{ client.referralClient }}
            </span>
          </span>

          <span v-if="client.referral === 'master'">
            <span v-if="client.referralMaster">
              {{ masterById(client.referralMaster).name }}
            </span>

            <span v-else> <i class="el-icon-user" /> від майстра </span>
          </span>

          <span class="margin-left-5">
            <i
              class="el-icon-check color-success"
              v-if="client.isReferralClientReceivedCashback"
              title="Бали за запрошення отримані"
            />
            <i
              class="el-icon-close color-danger"
              v-else
              title="Бали за запрошення не отримані"
            />
          </span>
        </span>

        <span v-else>
          {{ client.referral }}

          <span v-if="client.advType"> / {{ client.advType }}</span>
        </span>
      </div>
    </div>

    <div v-if="client.creatorId" class="client-info__row">
      <div class="client-info__label">Зареєстрував:</div>
      <div class="client-info__value">{{ creator(client.creatorId) }}</div>
    </div>

    <div
      v-if="client.invitedClients && client.invitedClients.length > 0"
      class="client-info__row"
    >
      <div class="client-info__label">За порадою клієнта зареєструвалися:</div>
      <div class="client-info__value">
        <div
          v-for="invitedClient in client.invitedClients"
          :key="invitedClient"
          class="client-info__invided-client"
        >
          <ClientView :clientId="invitedClient" />
        </div>
      </div>
    </div>

    <el-tabs
      v-model="activeTab"
      v-if="clientInfo && client && client.id"
      class="client-info__events-goods"
    >
      <el-tab-pane label="Визіти" name="events"
        ><ClientEvents :clientId="client.id"
      /></el-tab-pane>

      <el-tab-pane label="Покупки" name="goods" lazy
        ><ClientGoods :clientId="client.id"
      /></el-tab-pane>

      <el-tab-pane label="Бали" name="cashback" lazy v-if="userSalon.loyality"
        ><ClientCashback :clientId="client.id"
      /></el-tab-pane>
    </el-tabs>

    <ClientForm
      @closeNewClient="onCloseNewClient"
      :dialogVisibility="isClientAdding"
      :clientId="client.id"
      :isEditing="true"
      :dialogWindow="true"
      ref="clientForm"
      v-if="isClientAdding"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { firstNull } from '@/utils/functions'
  import { isUserIsAdminOrLower } from '@/utils/users'

  import { getClientFromFirebaseById } from '@/utils/clients'

  import ClientPhone from '@/components/Common/Clients/ClientPhone'
  import ClientEvents from '@/components/Clients/ClientEvents/ClientEvents'
  import ClientCashback from '@/components/Clients/ClientCashback/ClientCashback'
  import ClientForm from '@/components/Clients/ClientForm'
  import ClientView from '@/components/Common/Clients/ClientView'
  import CurrencyView from '@/components/Common/CurrencyView'

  import ClientGoods from '@/components/Clients/ClientGoods/ClientGoods'

  export default {
    data() {
      return {
        isClientAdding: false,
        activeTab: 'events',
        client: {},
      }
    },
    props: {
      clientId: String,
      clientInfo: Boolean,
    },
    watch: {
      clientId: {
        immediate: true,
        deep: false,
        async handler(id) {
          if (!id) return

          this.client = await this.getClientFromFirebaseById(id).catch(() =>
            this.$message.error("Проблема зі з'єднанням, спробуйте ще раз")
          )
        },
      },
    },
    components: {
      ClientPhone,
      ClientEvents,
      ClientCashback,
      ClientForm,
      ClientView,
      CurrencyView,
      ClientGoods,
    },
    computed: {
      ...mapGetters([
        'discountById',
        'userById',
        'user',
        'userSalon',
        'masterById',
        'procedureCategoryById',
      ]),
      loyality() {
        return this.userSalon.loyality
      },
      currentUser() {
        return this.masterById(this.user.id)
      },
      isUserIsAdminOrLowerLocal() {
        return isUserIsAdminOrLower(this.currentUser)
      },
      isSalonWithBot() {
        return this.userSalon.services?.includes('bot')
      },
    },
    methods: {
      async getClientFromFirebaseById(clientId = this.clientId) {
        return await getClientFromFirebaseById(clientId)
      },
      getAge(dateArray) {
        const now = new Date()

        return now.getFullYear() - dateArray[0]
      },
      getDateFromDateObj(date) {
        if (!date) return
        const createdDate = new Date(date)

        return (
          firstNull(createdDate.getDate()) +
          '.' +
          firstNull(createdDate.getMonth() + 1) +
          '.' +
          createdDate.getFullYear()
        )
      },
      getDateFromDateNumber(dateNum) {
        return (
          String(dateNum).slice(6, 8) +
          '.' +
          String(dateNum).slice(4, 6) +
          '.' +
          String(dateNum).slice(0, 4)
        )
      },
      creator(id) {
        if (!id) return

        const user = this.userById(id)

        return user.name
      },
      clearClientObj() {
        this.isClientAdding = false
      },
      onCloseNewClient(client) {
        if (client && client.id) {
          this.client = client
        }

        this.$nextTick(() => (this.isClientAdding = false))
      },
      getBirthday(birthday) {
        return `${firstNull(birthday[2])}.${firstNull(birthday[1])}.${
          birthday[0]
        }`
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tough-icon {
    cursor: pointer;
  }

  .client-info {
    &__row {
      font-size: 14px;
      line-height: 1.3;

      @media screen and (min-width: 480px) {
        display: flex;
      }

      & + div {
        margin-top: 5px;

        &.client-info__events-goods {
          margin-top: 20px;
        }
      }
    }

    &__title {
      font-size: 15px;
      color: $gray;

      .client-info__row + & {
        margin-top: 20px;
      }
    }

    &__name-value {
      margin-right: 5px;
    }

    &__name-container {
      font-size: 18px;
      font-weight: 600;
      color: $dark-gray;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      width: 100%;
      padding-right: 40px;

      .client-info__name-tough,
      .client-info__name-note {
        font-size: 0.8rem;
        font-weight: normal;
        margin-top: 5px;
      }
    }

    &__label {
      flex: 0 0 165px;
      padding-right: 10px;
    }

    &__value {
      flex: 1 1 auto;
      color: $dark-gray;

      button {
        padding: 0;
      }
    }

    &__invided-client {
      margin-bottom: 10px;
    }

    &.small-info {
      .client-info__row {
        .client-info__label {
          flex: 0 0 80px;
        }
      }
    }
  }
</style>
