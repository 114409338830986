<template>
  <div class="empty-layout">
    <router-view></router-view>

    <div class="footer-wrapper">
      <div class="container">
        <FooterCopyright />
        <CheckSiteVersionLite @disableLogin="onDisableLogin" />
      </div>
    </div>
  </div>
</template>

<script>
  import CheckSiteVersionLite from '@/components/Common/CheckSiteVersionLite'
  import FooterCopyright from '@/layout/footer/FooterCopyright'

  export default {
    name: 'empty-layout',
    data() {
      return {}
    },
    components: {
      CheckSiteVersionLite,
      FooterCopyright,
    },
    methods: {
      onDisableLogin() {
        this.$store.dispatch('logoutUser')
      },
    },
    async mounted() {
      await this.$store.dispatch('bindSettings')
    },
    async beforeDestroy() {
      await this.$store.dispatch('unbindSettings')
    },
  }
</script>

<style lang="scss" scoped>
  .empty-layout {
    height: 100vh;
    height: 100dvh;
    display: flex;
    padding: 20px;
    flex-direction: column;

    @media screen and (max-width: 480px) {
      padding: 10px 0;
    }

    .login-form {
      flex: 1;
    }

    .footer-wrapper {
      font-size: 12px;
      margin-top: auto;
      padding: 5px 0;

      .container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        margin-right: 5px;
      }
    }
  }
</style>
