import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  state: {
    salesCategories: [],
  },
  mutations: {},
  actions: {
    bindSalesCategories: firestoreAction((context) => {
      const salonId = context.getters.userSalonId

      return context.bindFirestoreRef(
        'salesCategories',
        db.collection('salesCategories').where('salonId', '==', salonId)
      )
    }),

    unbindSalesCategories: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('salesCategories')
    }),

    addSalesCategory: firestoreAction((context, payload) => {
      payload = Object.assign(
        {
          salonId: context.getters.userSalonId,
        },
        payload
      )

      return db
        .collection('salesCategories')
        .add(payload)
        .then(function (docRef) {
          db.collection('salesCategories')
            .doc(docRef.id)
            .set({ id: docRef.id }, { merge: true })
        })
    }),

    updateSalesCategory: firestoreAction((context, payload) => {
      return db
        .collection('salesCategories')
        .doc(payload.id)
        .set(payload, { merge: true })
    }),

    updateSalesParentCategory: firestoreAction((context, payload) => {
      let updateObj = {
        childCategories: firebase.firestore.FieldValue.arrayUnion(payload.name),
      }

      return db.collection('salesCategories').doc(payload.id).update(updateObj)
    }),

    removeSalesCategory: firestoreAction((context, SalesId) => {
      return db
        .collection('salesCategories')
        .doc(SalesId)
        .set({ active: Boolean(false) }, { merge: true })
    }),
  },
  getters: {
    salesCategories: (state) => state.salesCategories,
    salesActiveCategories: (state) =>
      state.salesCategories.filter((event) => event.active === true),
    salesCategoryById: (state) => (id) => {
      return state.salesCategories.find((Sales) => Sales.id === id)
    },
    salesCategoryByName: (state) => (name) => {
      return state.salesCategories.find((Sales) => Sales.name === name)
    },
  },
}
