export default {
  state: {
    statuses: [
      {
        id: 1,
        title: 'Запланований',
        slug: 'pending',
        icon: 'el-icon-time',
        color: '#f6ae2d',
        class: 'color-warning',
      },
      {
        id: 2,
        title: 'Скасований',
        slug: 'cancel',
        icon: 'el-icon-close',
        color: '#f26419',
        class: 'color-danger',
      },
      {
        id: 3,
        title: 'Завершений',
        slug: 'success',
        icon: 'el-icon-check',
        color: '#5bc8af',
        class: 'color-success',
      },
      {
        id: 4,
        title: 'Навчання',
        slug: 'study',
        icon: 'el-icon-notebook-1',
        color: '#8d5a97',
      },
      {
        id: 5,
        title: 'Відсутність',
        slug: 'absence',
        icon: 'el-icon-cold-drink',
        color: '#B1AFBD',
      },
    ],
  },
  mutations: {},
  actions: {},
  getters: {
    statuses(state) {
      return state.statuses
    },
    statusById: (state) => (id) => {
      return state.statuses.find((state) => state.id === id)
    },
  },
}
