import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

export default {
  state: {
    settings: [],
  },
  mutations: {},
  actions: {
    bindSettings: firestoreAction((context) => {
      return context.bindFirestoreRef('settings', db.collection('settings'))
    }),

    unbindSettings: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('settings')
    }),

    updateSetting: firestoreAction((context, payload) => {
      const settingName = payload.settingName
      const setting = {
        [settingName]: payload.value,
      }

      return db
        .collection('salons')
        .doc(payload.salonId)
        .set(setting, { merge: true })
    }),

    removeSetting: firestoreAction((context, settingId) => {
      db.collection('settings').doc(settingId).delete()
    }),
  },
  getters: {
    settings: (state) => state.settings,
    settingById: (state) => (id) => {
      return state.settings.find((state) => state.id === id)
    },
    siteSettings: (state, getters) => {
      const siteSetting = getters.settingById('site')

      return siteSetting ? siteSetting : []
    },
  },
}
