<template>
  <div class="masters-item margin-bottom-10">
    <div class="masters-item-list margin-bottom-15">
      <div
        v-for="(mastersItem, masterKey) in localMastersProcedures"
        :key="masterKey"
        class="masters-item-list--item"
      >
        <MasterItem
          :isNewEvent="isNewEvent"
          :isClientAdding="isClientAdding"
          :eventTimeStart="eventTimeStart"
          :masterKey="masterKey"
          :mastersItem="mastersItem"
          :mastersCount="localMastersProcedures.length"
          :masterIds="masterIds"
          :date="date"
          :eventId="eventId"
          @sendMasterProcedures="onSendMasterProcedures"
          @removeMaster="onRemoveMaster"
        />
      </div>
    </div>

    <el-form-item
      label="Вибрати майстра"
      v-if="mastersWhoShowingInForms.length !== masterIds.length"
      class="add-masters__container"
      prop="masterIds"
    >
      <el-button
        icon="el-icon-plus"
        type="primary"
        @click="addMaster(item.userId)"
        :disabled="isClientAdding || loading"
        v-for="item in mastersByAlphabet"
        :key="item.userId"
        class="add-master__button"
        :class="{
          hidden: masterIds.length > 0 && masterIds.includes(item.userId),
        }"
        plain
      >
        {{ item.name }}
      </el-button>
    </el-form-item>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { constants } from '@/utils/constants'
  import {
    timeStringToFloat,
    durationToTimeFloat,
    floatToTimeString,
    floatToMinutesDuration,
    toTens,
    sortObjectByKey,
    sortArrayByString,
  } from '@/utils/functions'

  import MasterItem from '@/components/Calendar/EventsForms/Default/MasterItem'

  export default {
    data() {
      return {
        masterId: '',
        localMastersProcedures: {},
        TIMEEND: constants.timeEndHour,
      }
    },
    props: {
      isNewEvent: Boolean,
      mastersProcedures: Object,
      isClientAdding: Boolean,
      eventTimeStart: String,
      masterIds: Array,
      date: String,
      eventId: String,
    },
    components: {
      MasterItem,
    },
    computed: {
      ...mapGetters(['loading', 'mastersWhoShowingInForms']),
      mastersByAlphabet() {
        return sortArrayByString(this.mastersWhoShowingInForms, 'name')
      },
    },
    watch: {
      mastersProcedures: {
        immediate: true,
        deep: false,
        handler(mastersProcedures) {
          if (!mastersProcedures) return

          this.localMastersProcedures = sortObjectByKey(
            JSON.parse(JSON.stringify(mastersProcedures))
          )
        },
      },
    },
    methods: {
      onSendMasterProcedures({ masterProcedures, masterKey }) {
        this.$set(
          this.localMastersProcedures,
          [masterKey],
          sortObjectByKey(JSON.parse(JSON.stringify(masterProcedures)))
        )

        this.countStartsAndEndsForProcedures()
      },
      countStartsAndEndsForProcedures() {
        let procedureTimeStartString = this.eventTimeStart
        let eventEndTime = ''
        let eventDuration = 0
        let eventPrice = 0
        let procedureIds = []

        const mastersKeys = Object.keys(this.localMastersProcedures)

        mastersKeys.forEach((masterKey) => {
          let masterStartTime = procedureTimeStartString
          let masterEndTime = ''
          let masterDuration = 0
          let masterPrice = 0

          const { procedures } = this.localMastersProcedures[masterKey]

          if (procedures && Object.keys(procedures).length > 0) {
            const proceduresKeys = Object.keys(procedures)

            if (proceduresKeys && proceduresKeys.length > 0) {
              proceduresKeys.forEach((procedureKey) => {
                const [, procedureId] = procedureKey.split('_')
                const { duration, price } = procedures[procedureKey]

                procedureIds.push(procedureId)

                const timeStartFloat = timeStringToFloat(
                  procedureTimeStartString
                )
                const durationFloat = durationToTimeFloat(duration)
                let durationUpdated = duration

                const timeEndFloat =
                  timeStartFloat + durationFloat <= this.TIMEEND
                    ? timeStartFloat + durationFloat
                    : this.TIMEEND
                const timeEndString = floatToTimeString(timeEndFloat)

                if (timeEndFloat === this.TIMEEND) {
                  durationUpdated = floatToMinutesDuration(
                    timeEndFloat - timeStartFloat
                  )

                  this.localMastersProcedures[masterKey].procedures[
                    procedureKey
                  ].duration = toTens(durationUpdated)
                  this.localMastersProcedures[masterKey].procedures[
                    procedureKey
                  ].isOvertimed = true
                } else {
                  if (
                    this.localMastersProcedures[masterKey].procedures[
                      procedureKey
                    ].isOvertimed
                  ) {
                    delete this.localMastersProcedures[masterKey].procedures[
                      procedureKey
                    ].isOvertimed
                  }
                }

                this.localMastersProcedures[masterKey].procedures[
                  procedureKey
                ].timeStart = procedureTimeStartString
                this.localMastersProcedures[masterKey].procedures[
                  procedureKey
                ].timeEnd = timeEndString

                procedureTimeStartString = timeEndString
                masterEndTime = timeEndString
                eventDuration += toTens(durationUpdated)
                masterDuration += toTens(durationUpdated)
                eventPrice += price
                masterPrice += price
              })

              this.localMastersProcedures[masterKey].masterStartTime =
                masterStartTime
              this.localMastersProcedures[masterKey].masterEndTime =
                masterEndTime
              this.localMastersProcedures[masterKey].masterDuration =
                masterDuration
              this.localMastersProcedures[masterKey].masterPrice = masterPrice

              eventEndTime = procedureTimeStartString
            }
          }
        })

        this.$emit('sendEndTime', {
          eventEndTime,
          eventDuration,
          eventPrice,
          procedureIds: [...new Set(procedureIds)],
        })

        this.$nextTick(() => this.sendMastersProcedures())
      },
      sendMastersProcedures() {
        this.$emit('sendMastersProcedures', {
          mastersProcedures: sortObjectByKey(
            JSON.parse(JSON.stringify(this.localMastersProcedures))
          ),
        })
      },
      addMaster(masterId) {
        this.$emit('addMaster', masterId)
      },
      onRemoveMaster(masterKey) {
        this.$emit('removeMaster', masterKey)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .add-masters__container {
    &::v-deep {
      .el-form-item__content {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
    }

    &.is-error {
      .add-master__button {
        &:not(.is-disabled) {
          border-color: $red;
          color: $red;
        }
      }
    }

    .add-master__button {
      margin: 0;

      &.hidden {
        display: none;
      }
    }
  }

  .masters-item-list {
    &:not(:empty) {
      & + .add-masters__container {
        border-top: 1px solid $border-color;
        padding-top: 15px;
        margin-top: 15px;
      }
    }
  }

  .masters-item-list--item {
    & + .masters-item-list--item {
      border-top: 1px solid $border-color;
      padding-top: 10px;
      margin-top: 10px;
    }
  }
</style>
