<template>
  <div>
    <div
      v-for="(categorizedRootCategory, index) in categorizedCategories"
      :key="index"
      class="margin-bottom-5"
    >
      <strong>{{ categorizedRootCategory.name }}</strong>

      <div
        v-if="
          categorizedRootCategory.children &&
          categorizedRootCategory.children.length > 0
        "
        class="padding-left-10"
      >
        <ExpencesCategories
          :categorizedCategories="categorizedRootCategory.children"
          :isSomeCategoryEditing="isSomeCategoryEditing"
          :rawCategorizedCategories="rawCategorizedCategories"
        />
      </div>

      <div v-else>-</div>
    </div>
  </div>
</template>

<script>
  import ExpencesCategories from '@/components/Finances/FinancesFlow/FinancesExpence/ExpencesCategories/ExpencesCategories'

  export default {
    props: {
      categorizedCategories: Array,
      isSomeCategoryEditing: Boolean,
      rawCategorizedCategories: Array,
    },
    data() {
      return {}
    },
    components: {
      ExpencesCategories,
    },
    computed: {},
    methods: {},
    mounted() {},
  }
</script>

<style lang="scss" scoped></style>
