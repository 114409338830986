<template>
  <div
    class="footer-wrapper"
    :class="{ expanded: expandFooter, 'with-buttons': currentPageButtons }"
  >
    <div class="container">
      <div class="footer-desktop">
        <FooterCopyright /> <CheckSiteVersion v-if="firstDataIsLoaded" />
        <CheckSiteMaintenance v-if="firstDataIsLoaded" />

        <CheckActivity v-if="user && currentUser && currentUser.forceExit" />
      </div>

      <div class="footer-mobile">
        <component
          :is="currentPageButtons"
          :expandFooter="expandFooter"
          @expandFooter="onExpandFooter"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CheckSiteVersion from '@/components/Common/CheckSiteVersion'
  import CheckSiteMaintenance from '@/components/Common/CheckSiteMaintenance'
  import CheckActivity from '@/components/Common/CheckActivity'

  import FooterCopyright from '@/layout/footer/FooterCopyright'
  import CalendarButtons from '@/layout/footer/buttons/Calendar'
  import FinancesButtons from '@/layout/footer/buttons/Finances'

  const listOfSupportingButtons = ['calendar', 'finances']

  export default {
    data() {
      return {
        expandFooter: false,
        currentRoute: '',
      }
    },
    components: {
      CheckSiteVersion,
      CheckSiteMaintenance,
      CheckActivity,
      CalendarButtons,
      FinancesButtons,
      FooterCopyright,
    },
    computed: {
      ...mapGetters(['firstDataIsLoaded', 'user', 'masterById']),
      currentUser() {
        return this.masterById(this.user.id)
      },
      currentPageButtons() {
        this.checkExpand()

        return listOfSupportingButtons.includes(this.$route.name)
          ? this.$route.name + '-buttons'
          : ''
      },
    },
    methods: {
      checkExpand() {
        if (this.currentRoute !== this.$route.name) {
          this.expandFooter = false
        }

        this.currentRoute = this.$route.name
      },
      onExpandFooter(expand) {
        this.expandFooter = expand
      },
    },
    async mounted() {
      await this.$store.dispatch('bindSettings')
    },
  }
</script>

<style lang="scss" scoped>
  $vertical-padding: 5px;

  .footer-wrapper {
    border-top: 1px solid $border-color;
    font-size: 12px;
    overflow: hidden;
    height: $footer-height-desktop;
    padding: 3px 0;
    transition: height 200ms ease-in-out;
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 1;

    @media screen and (min-width: 768px) {
      position: relative;
    }

    &.with-buttons {
      height: $footer-height-mobile;
      padding: 0;
      z-index: 10;

      .footer-desktop {
        display: none;
      }

      @media screen and (min-width: 768px) {
        height: $footer-height-desktop;

        .footer-mobile {
          display: none;
        }

        .footer-desktop {
          display: flex;
          padding: 3px 0;
        }
      }
    }

    &.expanded {
      height: $footer-height-mobile + 40;

      @media screen and (min-width: 768px) {
        height: $footer-height-desktop;

        &.with-buttons {
          height: $footer-height-desktop;
        }
      }
    }

    .footer-desktop,
    .footer-mobile {
      display: flex;
      align-items: center;
    }

    .footer-mobile {
      align-items: flex-end;
      height: 100%;
    }

    .container {
      height: 100%;
    }

    svg {
      margin-right: 5px;
    }

    .check-activity {
      margin-left: auto;
    }

    &::v-deep {
      .footer-buttons {
        display: flex;
        align-items: center;
        width: 100%;
        height: $footer-height-mobile;
        position: relative;
      }

      .footer-button {
        flex: 1;
        text-align: center;

        &.active,
        &:active {
          .el-link {
            text-shadow: 0 0 10px $red;
          }
        }

        .el-link {
          font-size: 30px;
          display: block;
        }
      }

      .footer-buttons-expanded-area {
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: $footer-height-mobile-expand-part;
        padding: $vertical-padding 0;
        border-bottom: 1px solid $border-color;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
      }

      .footer-buttons-masters,
      .footer-buttons-settings,
      .footer-buttons-clients {
        width: 100%;
        transform: translateX(-100%);
        transition: 200ms all;
        position: absolute;
        top: $vertical-padding;
        left: 0;
        bottom: $vertical-padding;

        &.active {
          transform: translateY(0);
        }
      }

      .footer-buttons-clients {
        .el-select {
          top: -1px;
        }
      }
    }
  }
</style>
