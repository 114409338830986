import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

export default {
  state: {
    masters: [],
    partnerMasters: [],
    usedMasters: [],
    filteredMasters: [],
  },
  mutations: {
    setFilteredMasters(state, payload) {
      state.filteredMasters = payload
    },
  },
  actions: {
    bindMasters: firestoreAction((context) => {
      const salonId = context.getters.userSalonId

      return context.bindFirestoreRef(
        'masters',
        db.collection('masters').where('salonId', '==', salonId)
      )
    }),

    bindPartnerMasters: firestoreAction((context) => {
      const salonId = context.getters.userSalonId

      return context.bindFirestoreRef(
        'partnerMasters',
        db.collection('masters').where('partnerIds', 'array-contains', salonId)
      )
    }),

    bindUsedMasters: firestoreAction((context) => {
      const salonId = context.getters.userSalonId

      return context.bindFirestoreRef(
        'usedMasters',
        db
          .collection('masters')
          .where('usedInSalonIds', 'array-contains', salonId)
      )
    }),

    unbindMasters: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('masters')
      unbindFirestoreRef('partnerMasters')
    }),

    addMaster: firestoreAction((context, payload) => {
      return db.collection('masters').doc(payload.email).set(payload)
    }),

    updateMaster: firestoreAction((context, payload) => {
      return db
        .collection('masters')
        .doc(payload.email)
        .set(payload, { merge: true })
    }),

    removeMaster: firestoreAction((context, clientId) => {
      db.collection('masters').doc(clientId).delete()
    }),

    setFilteredMasters({ commit }, payload) {
      commit('setFilteredMasters', payload)
    },
  },
  getters: {
    masters: (state) => state.masters,
    partnerMasters: (state) => state.partnerMasters,
    usedMasters: (state) => state.usedMasters,
    allMasters: (state, getters) => {
      const partnerMastersIds = state.partnerMasters.map(({ userId }) => userId)
      const usedMastersThatInCurrentPartners = state.usedMasters.filter(
        ({ userId }) => !partnerMastersIds.includes(userId)
      )

      return [
        ...state.masters.filter((master) => {
          return !state.partnerMasters
            .map((item) => item.userId)
            .includes(master.userId)
        }),
        ...state.partnerMasters.map((item) => {
          const salonData =
            item.partnerSalons[getters.userSalonId]?.salonData ?? null

          return {
            partner: true,
            id: item.email,
            ...item,
            ...(salonData && {
              ...salonData,
            }),
          }
        }),
        ...usedMastersThatInCurrentPartners.map((item) => {
          return {
            usedMaster: true,
            ...item,
          }
        }),
      ]
    },
    masterById: (state, getters) => (id) => {
      return getters.allMasters.find((user) => user.userId === id)
    },
    mastersByRole: (state, getters) => (role) => {
      return getters.allMasters.find((user) => +user.role === +role)
    },
    mastersAdmins: (state, getters) => {
      return getters.allMasters.filter((user) => +user.role === 2)
    },
    mastersActive: (state, getters) => {
      return getters.allMasters.filter((user) => {
        return (
          +user.role <= 3 &&
          typeof user.usedMaster !== undefined &&
          !user.usedMaster
        )
      })
    },
    mastersActiveIds: (state, getters) => {
      return getters.mastersActive.map((user) => user.userId)
    },
    mastersWhoShowingInForms: (state, getters) => {
      return getters.mastersActive.filter((user) => user.showInForms !== false)
    },
    mastersWhoShowingInAbsenceForms: (state, getters) => {
      return getters.mastersActive.filter(
        (user) => user.showInAbsenceForms !== false
      )
    },
    mastersWhoShowingInCalendar: (state, getters) => {
      return getters.mastersActive.filter(
        (user) => user.showInCalendar !== false
      )
    },
    mastersForCalendar: (state, getters) => {
      return getters.mastersWhoShowingInCalendar.map(function (item) {
        return {
          id: item.userId,
          userId: item.userId,
          name: item.name,
          eventLetter: item.eventLetter,
          checked: true,
        }
      })
    },
    filteredMasters(state) {
      return state.filteredMasters
    },
  },
}
