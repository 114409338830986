<template>
  <el-dialog
    :visible.sync="localDialogVisibility"
    :title="isEditing ? 'Редагування клієнта' : 'Додавання нового клієнта'"
    width="60%"
    append-to-body
    :destroy-on-close="true"
    :before-close="cancelAddingClient"
    class="on-left"
    @closed="resetToFieldsDefault"
  >
    <div v-if="!!preClientObj?.phone" class="line-height-1-5 margin-bottom-15">
      <i>
        Ці дані були відправлені клієнтом через чат-бот. Телефон не може
        змінюватись.
      </i>
    </div>

    <div
      class="new-client"
      :class="{ 'new-client--dialog': dialogWindow }"
      v-loading="loading"
    >
      <h6 class="new-client__title" v-if="!dialogWindow">
        Додавання нового клієнта
      </h6>

      <el-form
        :model="newClientForm"
        :rules="rules"
        ref="newClientForm"
        label-position="top"
      >
        <PhoneInput
          v-model="newClientForm.phone"
          :isEditing="isEditing"
          :focus="false"
          :isShowing="localDialogVisibility"
          :clientId="clientId"
          :checkClientValidation="true"
          :isPreClient="!!preClientObj?.phone"
          @validatePhone="onValidatePhone"
          prop="phone"
        />

        <el-form-item label="Ім'я" prop="name">
          <el-input v-model="newClientForm.name"></el-input>
        </el-form-item>

        <el-form-item
          label="День народження"
          prop="birthday"
          class="birthday-select"
        >
          <el-radio-group
            v-model="day"
            class="vertical small"
            size="mini"
            ref="activeDay"
          >
            <el-radio-button v-for="day in 31" :key="day" :label="day">{{
              day
            }}</el-radio-button>
          </el-radio-group>

          <el-radio-group
            v-model="month"
            class="vertical"
            size="mini"
            ref="activeMonth"
          >
            <el-radio-button
              v-for="(month, index) in MONTH"
              :key="index"
              :label="index + 1"
              >{{ month }}</el-radio-button
            >
          </el-radio-group>

          <el-radio-group
            v-model="year"
            class="vertical small"
            size="mini"
            ref="activeYear"
          >
            <el-radio-button
              v-for="(year, index) in 51"
              :key="index"
              :label="year + 1959"
              >{{ year + 1959 }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>

        <el-form-item label="Звідки клієнт дізнався про нас" prop="referral">
          <el-radio-group v-model="newClientForm.referral" size="small">
            <el-radio-button
              v-for="item in clientReferrals"
              :key="item.slug"
              :label="item.slug"
              :value="item.name"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="Реклама у"
          prop="advType"
          v-if="newClientForm.referral === 'adv'"
        >
          <el-radio-group v-model="newClientForm.advType" size="small">
            <el-radio-button
              v-for="item in advType"
              :key="item.slug"
              :label="item.slug"
              :value="item.name"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="Майстер, який запросив"
          prop="referralMaster"
          v-if="newClientForm.referral && newClientForm.referral === 'master'"
        >
          <div
            v-if="preClientObj?.referralMaster"
            class="line-height-1-5 padding-bottom-10"
          >
            <i>Клієнт в боті вказав і'мя майстра</i>:
            <strong>{{ preClientObj.referralMaster }}</strong>

            <div class="small">
              <i
                >Вам необхідно спробувати знайти та обрати його. Якщо такого
                немає, змініть "Звідки дізнався" на "Інше".</i
              >
            </div>
          </div>

          <MasterSearch
            :masterId="newClientForm.referralMaster || ''"
            @choosingMaster="onChoosingReferralMaster"
            @clearChoosingMaster="onClearChoosingReferralMaster"
          />
        </el-form-item>

        <ClientFormItem
          :clientPhone="referralClient.phone || ''"
          :preClientReferralClient="preClientObj?.referralClient"
          title="Клієнт, який запросив"
          prop="referralClient"
          @choosedClient="onChoosingReferralClient"
          v-if="newClientForm.referral && newClientForm.referral === 'client'"
        />

        <el-form-item
          label="Клієнт вийшов на зв'язок через"
          prop="originOfRegistration"
        >
          <el-radio-group
            v-model="newClientForm.originOfRegistration"
            size="small"
            :disabled="!!preClientObj?.id"
          >
            <el-radio-button
              v-for="item in clientOrigins"
              :key="item.slug"
              :label="item.slug"
              :value="item.name"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="Посилання на профіль"
          prop="originOfRegistrationLink"
          v-if="
            newClientForm.originOfRegistration &&
            ['facebook', 'instagram'].includes(
              newClientForm.originOfRegistration
            )
          "
        >
          <el-input v-model="newClientForm.originOfRegistrationLink"></el-input>
        </el-form-item>

        <el-form-item label="E-mail" prop="email">
          <el-input v-model.trim="newClientForm.email" type="email"></el-input>
        </el-form-item>

        <el-form-item
          label="Зацікавлений у процедурах"
          prop="interestingInProcedures"
        >
          <el-select v-model="newClientForm.interestingInProcedures" multiple>
            <el-option
              v-for="item in proceduresActiveCategories"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item prop="interestingInContact">
          <el-checkbox v-model="newClientForm.interestingInContact">
            Зв'язуємося при акціях/дзвінках
          </el-checkbox>
        </el-form-item>

        <el-form-item prop="tough">
          <el-checkbox v-model="newClientForm.tough">Важкий клієнт</el-checkbox>
        </el-form-item>

        <el-form-item
          label="Чому важкий"
          prop="toughNote"
          v-if="newClientForm.tough"
        >
          <el-input
            v-model="newClientForm.toughNote"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>

        <el-form-item label="Додаткова інформація" prop="note">
          <el-input
            v-model="newClientForm.note"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="addClient" v-if="!isEditing">
        Створити
      </el-button>

      <el-button type="primary" @click="editClient" v-if="isEditing">
        Редагувати
      </el-button>

      <el-button @click="cancelAddingClient" type="danger" plain
        >Скасувати</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { db } from '@/plugins/firebase'

  import firebase from 'firebase/app'
  import 'firebase/firestore'

  import clientService from '@/services/client-service'
  import historyService from '@/services/history-service'

  import {
    getClientFromFirebaseById,
    getClientFromFirebaseByPhone,
    clientOrigins,
    clientReferrals,
    advType,
  } from '@/utils/clients'

  import { constants } from '@/utils/constants'

  import { HISTORY_TYPES } from '@/utils/history'

  import {
    getSortingValueNumberFromDateWithoutTime,
    cleanPhone,
  } from '@/utils/functions'

  import MasterSearch from '@/components/Masters/MasterSearch'
  import PhoneInput from '@/components/Common/Forms/PhoneInput'

  export default {
    data() {
      return {
        clientObj: {},
        localDialogVisibility: false,

        newClientForm: {
          phone: '',
          name: '',
          birthday: [],
          originOfRegistration: '',
          originOfRegistrationLink: '',
          referral: '',
          referralClient: '',
          referralMaster: '',
          advType: '',
          note: '',
          tough: false,
          toughNote: '',
          email: '',
          interestingInProcedures: [],
          interestingInContact: true,
        },

        referralClient: {},

        MONTH: constants.monthNames,
        clientOrigins: clientOrigins,
        clientReferrals: clientReferrals,
        advType: advType,

        day: '',
        month: '',
        year: '',

        rules: {
          name: [constants.requiredFieldMessageRuleObject],
          referral: [constants.requiredFieldMessageRuleObject],
          referralClient: [
            {
              required: !this.preClientObj?.referralClient,
              message: constants.requiredMessage,
              trigger: 'blur',
            },
          ],
          advType: [constants.requiredFieldMessageRuleObject],
          originOfRegistration: [constants.requiredFieldMessageRuleObject],
          email: [
            {
              type: 'email',
              message: 'Введіть коректну email адресу',
              trigger: ['blur', 'change'],
            },
          ],
          toughNote: [constants.requiredFieldMessageRuleObject],
        },
      }
    },
    props: {
      isEditing: Boolean,
      dialogVisibility: Boolean,
      dialogWindow: {
        default: false,
        type: Boolean,
      },
      clientId: {
        default: null,
        type: String,
      },
      preClientObj: Object,
    },
    components: {
      MasterSearch,
      PhoneInput,
      ClientFormItem: () => import('@/components/Common/Forms/ClientFormItem'),
    },
    watch: {
      dialogVisibility: {
        immediate: true,
        async handler(visibility) {
          this.localDialogVisibility = visibility

          if (visibility && this.clientId) {
            this.$store.dispatch('setLoading', true)

            this.clientObj = await getClientFromFirebaseById(this.clientId)

            if (!this.clientObj) {
              this.$store.dispatch('setLoading', false)

              this.$store.dispatch(
                'setError',
                'Виникла проблема при завантаженні даних клієнта. Спробуйте пізніше.'
              )

              return
            }

            this.newClientForm = {
              phone: this.clientObj.phone,
              name: this.clientObj.name,
              originOfRegistration: this.clientObj.originOfRegistration,
              originOfRegistrationLink: this.clientObj.originOfRegistrationLink,
              referral: this.clientObj.referral,
              referralClient: this.clientObj.referralClient,
              referralMaster: this.clientObj.referralMaster,
              advType: this.clientObj.advType || '',
              email: this.clientObj.email ? this.clientObj.email : '',
              note: this.clientObj.note,
              tough: this.clientObj.tough,
              toughNote: this.clientObj.toughNote,
              interestingInProcedures: this.clientObj.interestingInProcedures
                ? this.clientObj.interestingInProcedures
                : [],
              interestingInContact:
                this.clientObj.interestingInContact === true ||
                this.clientObj.interestingInContact === undefined
                  ? true
                  : false,
            }

            if (this.clientObj.birthday && this.clientObj.birthday.length > 0) {
              this.year = this.clientObj.birthday[0]
              this.month = this.clientObj.birthday[1]
              this.day = this.clientObj.birthday[2]

              this.$nextTick(function () {
                this.setBirthdayItemsScroll()
              })
            }

            if (this.newClientForm.referralClient) {
              this.referralClient = await getClientFromFirebaseById(
                this.newClientForm.referralClient
              )
            }

            this.$store.dispatch('setLoading', false)
          }
        },
      },
      preClientObj: {
        immediate: true,
        handler(obj) {
          if (!obj?.id) return

          if (obj.birthday) {
            const birthday = obj.birthday.split('.')

            this.year = birthday[2]
            this.month = Number(birthday[1])
            this.day = Number(birthday[0])

            this.$nextTick(function () {
              this.setBirthdayItemsScroll()
            })
          }

          this.newClientForm = {
            phone: obj.phone,
            name: obj.name,
            originOfRegistration: 'telegram-bot',
            originOfRegistrationLink: '',
            referral: obj.referral,
            referralClient: '',
            referralMaster: '',
            advType: obj.advType || '',
            email: obj.email ? obj.email : '',
            note: '',
            tough: false,
            toughNote: '',
            interestingInProcedures: [],
            interestingInContact: true,
            contactId: obj.contactId,
            botId: obj.botId,
            userName: obj.userName ?? '',
          }
        },
      },
    },
    computed: {
      ...mapGetters([
        'loading',
        'user',
        'userSalon',
        'masterById',
        'proceduresActiveCategories',
      ]),
      loyalityReferral() {
        return this.userSalon.loyalityReferral
      },
      currentUser() {
        return this.masterById(this.user.id)
      },
    },
    methods: {
      async getClientByPhone(phone) {
        return await getClientFromFirebaseByPhone(phone)
      },
      onValidatePhone() {
        this.$refs.newClientForm.validateField('phone')
      },
      onChoosingReferralClient(client) {
        if (client && client.id) {
          this.newClientForm.referralClient = client.id
          this.referralClient = client
        }
      },
      onClearChoosingReferralClient() {
        this.newClientForm.referralClient = ''
        this.referralClient = null
      },
      onChoosingReferralMaster(event) {
        if (event && event.userId) {
          this.newClientForm.referralMaster = event.userId
        }
      },
      onClearChoosingReferralMaster() {
        this.newClientForm.referralMaster = null
      },
      setBirthdayItemsScroll() {
        if (
          !this.$refs?.activeDay &&
          !this.$refs?.activeMonth &&
          !this.$refs?.activeYear
        )
          return

        const parentDay = this.$refs.activeDay.$el
        const parentMonth = this.$refs.activeMonth.$el
        const parentYear = this.$refs.activeYear.$el

        if (parentDay) {
          const activeDay = parentDay.querySelector(
            '.el-radio-button__orig-radio:checked'
          ).parentNode

          activeDay.parentElement.scrollTop = activeDay.offsetTop
        }

        if (parentMonth) {
          const activeMonth = parentMonth.querySelector(
            '.el-radio-button__orig-radio:checked'
          ).parentNode

          activeMonth.parentElement.scrollTop = activeMonth.offsetTop
        }

        if (parentYear) {
          const activeYear = parentYear.querySelector(
            '.el-radio-button__orig-radio:checked'
          ).parentNode

          activeYear.parentElement.scrollTop = activeYear.offsetTop
        }
      },
      addClient() {
        this.$refs.newClientForm.validate(async (valid) => {
          if (valid) {
            const batch = db.batch()

            const newClient = {
              phone: this.newClientForm.phone,
              rawPhone: cleanPhone(this.newClientForm.phone),
              name: this.newClientForm.name,
              isNewClient: true,
              birthday:
                this.year && this.month && this.day
                  ? [Number(this.year), Number(this.month), Number(this.day)]
                  : [],
              ...(this.newClientForm.originOfRegistration && {
                originOfRegistration: this.newClientForm.originOfRegistration,
              }),
              ...(this.newClientForm.originOfRegistrationLink &&
                this.newClientForm.originOfRegistration.link && {
                  originOfRegistrationLink:
                    this.newClientForm.originOfRegistrationLink,
                }),
              ...(this.newClientForm.advType && {
                advType: this.newClientForm.advType,
              }),
              ...(this.newClientForm.referral && {
                referral: this.newClientForm.referral,
              }),
              ...(this.newClientForm.referralClient &&
                this.loyalityReferral && {
                  referralClient: this.newClientForm.referralClient,
                  isReferralClientReceivedCashback: false,
                }),
              ...(this.newClientForm.referralMaster && {
                referralMaster: this.newClientForm.referralMaster,
                isReferralClientReceivedCashback: false,
              }),
              ...(this.newClientForm.tough && {
                tough: this.newClientForm.tough,
              }),
              ...(this.newClientForm.tough &&
                this.newClientForm.toughNote && {
                  toughNote: this.newClientForm.toughNote,
                }),
              ...(this.newClientForm.email && {
                email: this.newClientForm.email,
              }),
              ...(this.newClientForm.note && {
                note: this.newClientForm.note,
              }),
              ...(this.user && { createdBy: { id: this.user.id } }),
              interestingInProcedures:
                this.newClientForm.interestingInProcedures,
              interestingInContact: this.newClientForm.interestingInContact,
              createdAt: Date(),
              earnings: 0,
              salonId: this.userSalon.id,
            }

            this.$store.dispatch('setLoading', true)

            const clientId = clientService.addClient({
              batch,
              clientData: newClient,
            })

            const marketingRef = db.collection('marketings').doc()
            const marketingRefIf = marketingRef.id

            batch.set(marketingRef, {
              id: marketingRefIf,
              type: 'client',
              salonId: this.userSalon.id,
              sortingValue: getSortingValueNumberFromDateWithoutTime(),
              clientId,
              ...(this.newClientForm.referral && {
                clientReferral: this.newClientForm.referral,
              }),
              ...(this.newClientForm.advType && {
                advType: this.newClientForm.advType,
              }),
            })

            if (
              this.newClientForm.referral === 'client' &&
              this.newClientForm.referralClient
            ) {
              const refferalClientRef = db
                .collection('clients')
                .doc(this.newClientForm.referralClient)

              batch.set(
                refferalClientRef,
                {
                  invitedClients:
                    firebase.firestore.FieldValue.arrayUnion(clientId),
                },
                { merge: true }
              )
            }

            // Add history
            historyService.addHistory({
              batch,
              now: new Date(),
              currentUser: this.currentUser,
              type: HISTORY_TYPES.ADD_CLIENT,
              additionalData: {
                clientId,
              },
            })

            batch
              .commit()
              .then(async () => {
                this.$emit(
                  'closeNewClient',
                  await this.getClientByPhone(this.newClientForm.phone)
                )

                this.$store.dispatch('addToLocalClients', {
                  ...newClient,
                  id: clientId,
                  isFull: true,
                })

                this.resetToFieldsDefault()
                this.$message.success('Клієнта створено')
              })
              .catch((error) => this.$store.dispatch('setError', error.message))
              .finally(() => {
                this.$store.dispatch('setLoading', false)
              })
          }
        })
      },
      editClient() {
        this.$refs.newClientForm.validate(async (valid) => {
          if (valid) {
            const batch = db.batch()

            const editedClient = {
              id: this.clientObj.id,
              phone: this.newClientForm.phone,
              rawPhone: cleanPhone(this.newClientForm.phone),
              name: this.newClientForm.name,
              birthday:
                this.year && this.month && this.day
                  ? [Number(this.year), Number(this.month), Number(this.day)]
                  : [],
              originOfRegistration: this.newClientForm.originOfRegistration
                ? this.newClientForm.originOfRegistration
                : '',
              originOfRegistrationLink:
                this.newClientForm.originOfRegistrationLink &&
                this.newClientForm.originOfRegistration.link
                  ? this.newClientForm.originOfRegistrationLink
                  : '',
              referral: this.newClientForm.referral
                ? this.newClientForm.referral
                : '',
              referralClient: this.newClientForm.referralClient
                ? this.newClientForm.referralClient
                : '',
              referralMaster: this.newClientForm.referralMaster
                ? this.newClientForm.referralMaster
                : '',
              ...(this.newClientForm.advType && {
                advType: this.newClientForm.advType,
              }),
              note: this.newClientForm.note ? this.newClientForm.note : '',
              email: this.newClientForm.email ? this.newClientForm.email : '',
              tough: this.newClientForm.tough
                ? this.newClientForm.tough
                : false,
              toughNote:
                this.newClientForm.toughNote && this.newClientForm.tough
                  ? this.newClientForm.toughNote
                  : '',
              interestingInProcedures:
                this.newClientForm.interestingInProcedures,
              interestingInContact: this.newClientForm.interestingInContact,
              ...(this.clientObj.contactId && {
                contactId: this.clientObj.contactId,
              }),
              ...(this.clientObj.botId && {
                botId: this.clientObj.botId,
              }),
              ...(this.clientObj.userName && {
                userName: this.clientObj.userName,
              }),
            }

            const localClient = this.$store.getters.localClientById(
              this.clientObj.id
            )

            if (localClient) {
              this.$store.dispatch('addToLocalClients', {
                ...localClient,
                ...editedClient,
                isFull: true,
              })
            }

            const self = this

            this.$store.dispatch('setLoading', true)

            const clientRef = db.collection('clients').doc(this.clientObj.id)

            batch.set(clientRef, editedClient, { merge: true })

            // Add history
            historyService.addHistory({
              batch,
              now: new Date(),
              currentUser: this.currentUser,
              type: HISTORY_TYPES.UPDATE_CLIENT,
              additionalData: {
                clientId: this.clientObj.id,
              },
            })

            batch
              .commit()
              .then(async () => {
                self.$emit(
                  'closeNewClient',
                  await this.getClientByPhone(this.newClientForm.phone)
                )
                self.$message.success('Клієнта відредаговано')
              })
              .catch((error) => self.$store.dispatch('setError', error.message))
              .finally(() => {
                self.$store.dispatch('setLoading', false)
              })

            self.$store.dispatch('setLoading', false)
          }
        })
      },
      onPhoneError(event) {
        this.phoneError = event
      },
      cancelAddingClient() {
        this.$emit('closeNewClient', null)
      },
      resetToFieldsDefault() {
        this.newClientForm = {
          name: '',
          phone: '',
          originOfRegistration: '',
          originOfRegistrationLink: '',
          referral: '',
          referralClient: '',
          note: '',
          email: '',
          tough: false,
          toughNote: '',
          birthday: [],
          interestingInProcedures: [],
          interestingInContact: true,
        }

        this.year = ''
        this.month = ''
        this.day = ''
      },
    },
  }
</script>

<style lang="scss">
  .el-form-item {
    .new-client {
      .el-form-item {
        margin-bottom: 15px;
      }
    }
  }

  .client-error {
    margin-top: -15px;
    margin-bottom: 15px;
  }

  .new-client {
    margin: 15px 0;
    line-height: 1.4;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 15px;

    &--dialog {
      border: none;
      padding: 0;
      margin: 0;
    }

    .el-form-item__label {
      line-height: inherit;
      min-height: auto;
      padding: 0 0 10px;
    }

    &__title {
      margin: 0 0 20px;
    }

    &__actions {
      margin-top: 20px;
    }

    &__warning {
      font-size: 12px;
      margin: 0 0 20px;
    }

    .el-button.el-button--default[disabled] {
      opacity: 0.2;
    }
  }

  .birthday-select {
    .el-form-item__content {
      display: flex;
    }

    .el-radio-group.vertical {
      max-height: 150px;
      overflow-y: auto;
      width: 120px;

      &.small {
        width: 80px;
      }

      .el-radio-button {
        width: 100%;
        display: block;
      }

      .el-radio-button__inner {
        width: 100%;
      }
    }
  }
</style>
