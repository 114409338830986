<template>
  <el-form
    ref="eventForm"
    :rules="rules"
    :model="eventForm"
    label-width="140px"
  >
    <el-form-item label="Дата візиту" required>
      <div class="flex--md flex--vertical-center">
        <el-form-item prop="dateStart" class="margin-bottom-0 margin-right-10">
          <el-date-picker
            v-model="eventForm.dateStart"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            firstDayOfWeek="1"
            :picker-options="datePickerOptions"
            :disabled="isClientAdding || loading"
            :clearable="false"
          />
        </el-form-item>

        <div class="margin-right-10 show-up-md">-</div>

        <el-form-item>
          <el-checkbox
            v-model="eventForm.faster"
            :disabled="isClientAdding || loading"
          >
            По можливості перенести раніше
          </el-checkbox>
        </el-form-item>
      </div>
    </el-form-item>

    <el-form-item label="Час початку" prop="eventTimeStart" required>
      <el-time-select
        v-model="eventForm.eventTimeStart"
        :picker-options="{
          start: TIMESTART,
          step: '00:10',
          end: TIMEEND,
        }"
        format="HH:mm"
        placeholder="Час початку"
        :disabled="isClientAdding || loading"
        @change="countStartsAndEndsForProcedures"
      />

      <InfoPanel
        text="Візит, або його частина під час відключень/можливих відключень енергії."
        class="margin-top-15"
        v-if="isEventInTurnOffTime"
      />

      <InfoPanel
        text="Початок візиту під час відключень/можливих відключень енергії."
        class="margin-top-15"
        v-if="isEventStartInTurnOffTime && !this.eventForm.eventTimeEnd"
      />
    </el-form-item>

    <ClientFormItem
      prop="client"
      :rules="{
        required: true,
        message: 'Виберіть клієнта',
        trigger: 'blur',
      }"
      :client="eventForm.client"
      :isClientAdding="isClientAdding"
      @changeClientAdding="onChangeClientAdding"
      @choosedClient="onChoosedClient"
      v-if="dataLoaded"
    />

    <MastersItem
      ref="mastersProceduresItem"
      :isNewEvent="isNewEvent"
      :isClientAdding="isClientAdding"
      :eventTimeStart="eventForm.eventTimeStart"
      :masterIds="eventForm.masterIds"
      :mastersProcedures="eventForm.mastersProcedures"
      :date="eventForm.dateStart"
      :eventId="eventId"
      @addMaster="onAddMaster"
      @sendMastersProcedures="onSendMastersProcedures"
      @sendEndTime="onSendEndTime"
      @removeMaster="onRemoveMaster"
      v-if="dataLoaded"
    />

    <el-form-item label="Додаткова інформація про візит" prop="note">
      <el-input
        v-model="eventForm.note"
        :disabled="loading"
        type="textarea"
        :rows="3"
      />
    </el-form-item>

    <el-form-item
      label="Інформація про візит"
      v-if="
        eventForm.eventDuration ||
        (eventForm.eventTimeStart && eventForm.eventTimeEnd) ||
        eventForm.eventPrice
      "
    >
      <div class="line-height-1-5">
        <div v-if="eventForm.eventDuration">
          Тривалість:
          <strong>{{ getDuration(eventForm.eventDuration) }}</strong>
        </div>

        <div v-if="eventForm.eventTimeStart && eventForm.eventTimeEnd">
          Час:
          <strong
            >{{ eventForm.eventTimeStart }} -
            {{ eventForm.eventTimeEnd }}</strong
          >
        </div>

        <div v-if="eventForm.eventPrice">
          Загальна вартість: <CurrencyView :value="eventForm.eventPrice" />
        </div>
      </div>
    </el-form-item>

    <el-form-item
      label="Видалений візит"
      v-if="
        originEvent && originEvent.status && isCanceledEvent(originEvent.status)
      "
    >
      <el-switch
        v-model="eventForm.status"
        :disabled="loading"
        active-text="Повернути до запланованих"
        inactive-text="Залишити як є"
        active-value="1"
        inactive-value="2"
        active-color="#13ce66"
        inactive-color="#ff4949"
      />
    </el-form-item>

    <el-form-item v-if="originEvent?.editCounts" label="Кількість редагувань">
      <strong>{{ originEvent.editCounts }}</strong>
    </el-form-item>

    <el-form-item v-if="isClientWithBot" label="Повідомлення клієнту в бот">
      <el-checkbox v-model="isNeedToSendBotMessage">
        Після редагування ми
        {{ isNeedToSendBotMessage ? '' : 'не' }} відправляємо повідомлення в бот
        клієнту
      </el-checkbox>
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        @click="saveEvent"
        :loading="loading"
        :disabled="loading || isClientAdding"
      >
        {{ isNewEvent ? 'Додати' : 'Редагувати' }}
      </el-button>

      <el-button
        type="danger"
        @click="closeDialog"
        :loading="loading"
        :disabled="loading || isClientAdding"
      >
        Скасувати
      </el-button>
    </el-form-item>

    <EventNoteForClient
      ref="eventNoteForClient"
      :clientName="eventForm.client.name"
      :eventDate="eventForm.dateStart"
      :eventStartTime="eventForm.eventTimeStart"
      v-if="
        eventForm &&
        eventForm.eventTimeStart &&
        eventForm.dateStart &&
        eventForm.client &&
        eventForm.client.name
      "
    />
  </el-form>
</template>

<script>
  import { db } from '@/plugins/firebase'
  import { mapGetters } from 'vuex'
  import { customEventBus } from '@/utils/customEvent'

  import eventService from '@/services/event-service'
  import clientService from '@/services/client-service'
  import marketingService from '@/services/marketing-service'
  import masterService from '@/services/master-service'
  import historyService from '@/services/history-service'
  import financesReportsService from '@/services/finances-reports-service'

  import {
    diffDays,
    stringDateToDateObj,
    getSortingValueNumberFromDate,
    stringDateToArray,
    stringTimeToArray,
    durationToHoursAndMinutesArray,
    sanitizeString,
    getSortingValueNumberFromDateWithoutTimeAndDay,
    dateToHoursMinutesSecondsMillsecondsToNull,
  } from '@/utils/functions'

  import { constants } from '@/utils/constants'
  import { isCanceledEvent, statuses } from '@/utils/statuses'
  import { validateString, validateArray } from '@/utils/customValidations'
  import { getIdFromVer2Events } from '@/utils/events'
  import { MARKETINGS_TYPES } from '@/utils/marketings'
  import { HISTORY_TYPES } from '@/utils/history'
  import { getClientFromFirebaseById } from '@/utils/clients'

  import ClientFormItem from '@/components/Common/Forms/ClientFormItem'
  import MastersItem from '@/components/Calendar/EventsForms/Default/MastersItem'
  import CurrencyView from '@/components/Common/CurrencyView'
  import EventNoteForClient from '@/components/Events/EventNoteForClient'
  import InfoPanel from '@/components/Common/InfoPanel'

  const emptyEventForm = {
    dateStart: '',
    dateEnd: '',
    eventTimeStart: '',
    eventTimeEnd: '',
    eventDuration: 0,
    eventPrice: 0,
    faster: false,
    note: '',
    client: null,
    masterIds: [],
    mastersProcedures: {},
    procedureIds: [],
  }

  export default {
    data() {
      return {
        globalEventId: '',
        dataLoaded: false,
        isClientAdding: false,
        TIMESTART: constants.timeStartString,
        TIMEEND: constants.timeEndString,
        originEvent: {},
        isNeedToShowClientMessage: true,
        eventForm: JSON.parse(JSON.stringify(emptyEventForm)),
        isNeedToSendBotMessage: false,
        rules: {
          dateStart: [{ validator: validateString, trigger: 'change' }],
          eventTimeStart: [{ validator: validateString, trigger: 'change' }],
          masterIds: [{ validator: validateArray, trigger: 'blur' }],
        },
        datePickerOptions:
          constants.datePickerOptionsWithDisabledPastAndAfterOneYear,
      }
    },
    props: {
      isNewEvent: Boolean,
      eventId: String,
      eventData: Object,
      localDialogVisible: Boolean,
    },
    components: {
      ClientFormItem,
      MastersItem,
      CurrencyView,
      EventNoteForClient,
      InfoPanel,
    },
    computed: {
      ...mapGetters([
        'loading',
        'user',
        'userSalon',
        'userSalonId',
        'eventById',
        'localClientById',
        'procedureById',
        'masterById',
      ]),
      isSalonWithBot() {
        return this.userSalon.services?.includes('bot')
      },
      isClientWithBot() {
        if (!this.eventForm.client) return false

        return this.isSalonWithBot && this.eventForm.client.botContactId
      },
      isEventStartInTurnOffTime() {
        if (!this.userSalon.isTurnOff || !this.userSalon.turnOffGraph) {
          return false
        }

        const date = new Date(this.eventForm.dateStart)
        const day = date.getDay()
        const rightDay = day === 0 ? 7 : day

        const timeStart = this.eventForm.eventTimeStart.split(':')[0]

        const type =
          this.userSalon.turnOffGraph[`day_${rightDay}`][`hour_${timeStart}`]

        return ['off', 'possible'].includes(type)
      },
      isEventInTurnOffTime() {
        if (
          !this.userSalon.isTurnOff ||
          !this.userSalon.turnOffGraph ||
          !this.eventForm.eventTimeEnd
        ) {
          return false
        }

        const date = new Date(this.eventForm.dateStart)
        const day = date.getDay()
        const rightDay = day === 0 ? 7 : day

        const timeStart = this.eventForm.eventTimeStart.split(':')[0]
        const timeEnd = this.eventForm.eventTimeEnd.split(':')[0]

        const isTurnOffTime = Array.from({
          length: timeEnd - timeStart + 1,
        }).reduce((acc, _, index) => {
          if (acc) {
            return acc
          }

          const type =
            this.userSalon.turnOffGraph[`day_${rightDay}`][
              `hour_${+timeStart + index}`
            ]

          if (['off', 'possible'].includes(type)) {
            acc = true
          }

          return acc
        }, false)

        return isTurnOffTime
      },
    },
    watch: {
      eventId: {
        handler(eventId) {
          if (!eventId) return

          this.globalEventId = getIdFromVer2Events(eventId)

          this.loadData(this.globalEventId)
        },
        immediate: true,
      },
      eventData: {
        handler(data) {
          if (!data) return

          this.loadData()
        },
        immediate: true,
      },
      'eventForm.eventTimeStart': {
        handler(data) {
          if (
            (!data && !this.isClientWithBot) ||
            data === this.originEvent.eventTimeStart
          )
            return

          this.isNeedToSendBotMessage = true
        },
        immediate: true,
      },
      'eventForm.dateStart': {
        handler(data) {
          if (
            (!data && !this.isClientWithBot) ||
            data === this.originEvent.dateStart
          )
            return

          this.isNeedToSendBotMessage = true
        },
        immediate: true,
      },
      isNeedToSendBotMessage: {
        handler(isNeed) {
          this.isNeedToShowClientMessage = isNeed
        },
        immediate: true,
      },
    },
    methods: {
      getDuration(duration) {
        const [hours, minutes] = durationToHoursAndMinutesArray(duration)

        return `${hours}г ${minutes}хв`
      },
      isCanceledEvent(status) {
        return isCanceledEvent(status)
      },
      async loadData() {
        this.eventForm = JSON.parse(JSON.stringify(emptyEventForm))
        this.originEvent = {}
        this.dataLoaded = false

        if (this.isNewEvent) {
          this.eventForm.dateStart = this.eventData.dateStart
          this.eventForm.eventTimeStart = this.eventData.timeStart

          if (
            this.eventData.clientId &&
            this.localClientById(this.eventData.clientId)
          ) {
            this.eventForm.client = this.localClientById(
              this.eventData.clientId
            )
          }

          this.isNeedToShowClientMessage =
            this.eventData.isNeedToShowClientMessage || true
        } else {
          const event =
            this.eventById(this.globalEventId) ||
            (await eventService.getEvent({ eventId: this.globalEventId }))

          this.originEvent = JSON.parse(JSON.stringify(event))
          this.eventForm = JSON.parse(
            JSON.stringify({
              ...event,
              client: this.localClientById(event.client.id),
            })
          )
        }

        this.dataLoaded = true
      },
      onChangeClientAdding(clientAdding) {
        this.isClientAdding = clientAdding
      },
      onChoosedClient(client) {
        if (!client) return

        this.eventForm.client = client

        this.doEmptyValidation()
      },
      onAddMaster(masterId) {
        const index = Object.keys(this.eventForm.mastersProcedures).length
        const key = `${index}_${masterId}`

        if (
          !Object.prototype.hasOwnProperty.call(
            this.eventForm.mastersProcedures,
            key
          )
        ) {
          this.$set(
            this.eventForm.mastersProcedures,
            key,
            JSON.parse(
              JSON.stringify({
                masterId,
                masterIndex: index,
                masterStartTime: '',
                masterEndTime: '',
                masterDuration: 0,
                masterPrice: 0,
                procedures: null,
              })
            )
          )

          this.eventForm.masterIds.push(masterId)
        }

        this.doEmptyValidation()
      },
      onSendMastersProcedures({ mastersProcedures }) {
        this.$set(
          this.eventForm,
          'mastersProcedures',
          JSON.parse(JSON.stringify(mastersProcedures))
        )

        this.doEmptyValidation()
      },
      countStartsAndEndsForProcedures() {
        if (this.$refs.mastersProceduresItem.countStartsAndEndsForProcedures) {
          this.$refs.mastersProceduresItem.countStartsAndEndsForProcedures()
        }
      },
      onSendEndTime({ eventEndTime, eventDuration, eventPrice, procedureIds }) {
        this.eventForm.eventTimeEnd = eventEndTime
        this.eventForm.eventDuration = eventDuration
        this.eventForm.eventPrice = eventPrice
        this.eventForm.procedureIds = procedureIds
      },
      onRemoveMaster(masterKey) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.eventForm.mastersProcedures,
            masterKey
          )
        ) {
          const [, masterId] = masterKey.split('_')
          const indexOfMasterId = this.eventForm.masterIds.findIndex(
            (item) => item === masterId
          )

          this.eventForm.masterIds.splice(indexOfMasterId, 1)
          this.$delete(this.eventForm.mastersProcedures, masterKey)

          if (this.eventForm.masterIds.length > 0) {
            // We need to change indexes in rest items
            Object.keys(this.eventForm.mastersProcedures).forEach(
              (masterKey, index) => {
                const [, masterId] = masterKey.split('_')
                const newMasterKey = `${index}_${masterId}`

                // If masterKey is same then we don't need to change anything
                if (newMasterKey !== masterKey) {
                  // Make copy of previous masterKey data and after remove it
                  const previosMasterKeyDataObj = JSON.parse(
                    JSON.stringify(this.eventForm.mastersProcedures[masterKey])
                  )
                  this.$delete(this.eventForm.mastersProcedures, masterKey)

                  if (
                    !Object.prototype.hasOwnProperty.call(
                      this.eventForm.mastersProcedures,
                      newMasterKey
                    )
                  ) {
                    // Set same data on new masterKey
                    this.$set(
                      this.eventForm.mastersProcedures,
                      newMasterKey,
                      previosMasterKeyDataObj
                    )
                  }
                }
              }
            )
          }

          this.$nextTick(() => {
            if (
              this.$refs?.mastersProceduresItem?.countStartsAndEndsForProcedures
            ) {
              this.$refs.mastersProceduresItem.countStartsAndEndsForProcedures()
            }
          })
        }
      },
      doEmptyValidation() {
        if (this.$refs.eventForm) {
          this.$refs.eventForm.validate(() => null)
        }
      },
      saveEvent() {
        this.$refs.eventForm.validate(async (valid) => {
          if (valid) {
            const batch = db.batch()

            this.$store.dispatch('setLoading', true)

            const client = await getClientFromFirebaseById(
              this.eventForm.client.id
            )

            if (!client || !client.id) {
              this.$store.dispatch('setLoading', false)

              this.$message.error(
                'Проблема з отриманням інформації про клієнта, спробуйте ще раз'
              )

              return
            }

            const currentUser = this.masterById(this.user.id)

            const dateStartArray = stringDateToArray(this.eventForm.dateStart)
            const timeStartArray = stringTimeToArray(
              this.eventForm.eventTimeStart
            )

            const startTimeDateObj = new Date(
              Number(dateStartArray[0]),
              Number(dateStartArray[1]) - 1,
              Number(dateStartArray[2]),
              Number(timeStartArray[0]),
              Number(timeStartArray[1])
            )

            const timeEndArray = stringTimeToArray(this.eventForm.eventTimeEnd)

            const endTimeDateObj = new Date(
              Number(dateStartArray[0]),
              Number(dateStartArray[1]) - 1,
              Number(dateStartArray[2]),
              Number(timeEndArray[0]),
              Number(timeEndArray[1])
            )

            const sortingValue = getSortingValueNumberFromDate(startTimeDateObj)

            if (!startTimeDateObj || !sortingValue) {
              this.$store.dispatch('setLoading', false)

              this.$store.dispatch(
                'setError',
                'Виникла помилка з обробкою даних. Будь ласка зверніться до розробників.'
              )

              throw Error(`dateIssues: ${startTimeDateObj}`)
            }

            const now = Date()

            if (this.isNewEvent) {
              const newEventId = eventService.addEvent({
                batch,
                eventData: {
                  editCounts: 0,
                  eventDuration: this.eventForm.eventDuration,
                  eventTimeStart: this.eventForm.eventTimeStart,
                  eventTimeEnd: this.eventForm.eventTimeEnd,
                  eventPrice: this.eventForm.eventPrice,
                  dateStart: this.eventForm.dateStart,
                  dateEnd: this.eventForm.dateStart,
                  dateStartObj: String(startTimeDateObj),
                  dateEndObj: String(endTimeDateObj),
                  faster: this.eventForm.faster,
                  masterIds: this.eventForm.masterIds,
                  mastersProcedures: this.eventForm.mastersProcedures,
                  procedureIds: this.eventForm.procedureIds,
                  statusActive: true,
                  status: statuses.planned,
                  client: {
                    id: client.id,
                    phone: client.phone,
                    name: client.name,
                  },
                  ...(this.eventForm.note && {
                    note: sanitizeString(this.eventForm.note),
                  }),
                },
                currentUser,
                sortingValue,
                now,
              })

              masterService.addPartnerMasterAsUsed({
                batch,
                masterIds: this.eventForm.masterIds,
              })

              clientService.addClientEventWithMastersProcedures({
                batch,
                eventForm: this.eventForm,
                clientId: client.id,
                sortingValue,
                eventId: newEventId,
              })

              const lastEvent = await clientService.getClientLastFinishedEvent({
                clientId: client.id,
              })

              if (lastEvent) {
                const { date, dateStart } = lastEvent
                const clientLastEventDate = date || dateStart
                const lastEventMasterIds =
                  lastEvent.masterIds || [lastEvent.raw?.master?.userId] || []

                const today = new Date(now)

                const daysFromLastVisit = diffDays(
                  dateToHoursMinutesSecondsMillsecondsToNull(today),
                  stringDateToDateObj(clientLastEventDate)
                )

                marketingService.addMarketing({
                  batch,
                  marketingType: MARKETINGS_TYPES.EVENT,
                  additionalObj: {
                    newEventId,
                    price: this.eventForm.eventPrice,
                    masterIds: this.eventForm.masterIds,
                    ...(client.referral && {
                      clientReferral: client.referral,
                    }),
                    ...(daysFromLastVisit === 0 && {
                      rewrittenEventInSameDay: true,
                    }),
                    ...(daysFromLastVisit > 0 && {
                      daysFromLastVisit,
                    }),
                  },
                })

                if (daysFromLastVisit === 0) {
                  await financesReportsService.updateMonthReport({
                    batch,
                    nowSortingValue:
                      getSortingValueNumberFromDateWithoutTimeAndDay(
                        startTimeDateObj
                      ),
                    reportData: {
                      type: 'event',
                      value: 0,
                      event: {
                        isRewrittenClientsCount: true,
                        masterIds: this.eventForm.masterIds,
                        lastEventMasterIds,
                      },
                    },
                  })
                }
              }

              historyService.addHistory({
                batch,
                currentUser,
                now: new Date(),
                type: HISTORY_TYPES.ADD_EVENT,
                additionalData: {
                  eventId: newEventId,
                  clientId: client.id,
                  eventPrice: this.eventForm.eventPrice,
                },
              })
            } else {
              // We need to remove mastersProcedures before adding new edited obj because without it could be unneeded merging
              eventService.removeEventMasterProceduresBeforeEditing({
                batch,
                eventId: this.globalEventId,
              })

              eventService.editEvent({
                batch,
                eventId: this.globalEventId,
                eventUpdateObj: {
                  editCounts: this.originEvent.editCounts
                    ? this.originEvent.editCounts + 1
                    : 1,
                  eventDuration: this.eventForm.eventDuration,
                  eventTimeStart: this.eventForm.eventTimeStart,
                  eventTimeEnd: this.eventForm.eventTimeEnd,
                  eventPrice: this.eventForm.eventPrice,
                  dateStart: this.eventForm.dateStart,
                  dateEnd: this.eventForm.dateStart,
                  dateStartObj: String(startTimeDateObj),
                  dateEndObj: String(endTimeDateObj),
                  faster: this.eventForm.faster,
                  masterIds: this.eventForm.masterIds,
                  mastersProcedures: this.eventForm.mastersProcedures,
                  procedureIds: this.eventForm.procedureIds,
                  statusActive: true,
                  status: statuses.planned,
                  sortingValue,
                  client: {
                    id: client.id,
                    phone: client.phone,
                    name: client.name,
                  },
                  ...(this.originEvent.confirmed && {
                    confirmed: false,
                  }),
                  ...(this.eventForm.note && {
                    note: sanitizeString(this.eventForm.note),
                  }),
                },
                editType: 'edited',
                currentUser,
                now,
              })

              if (this.originEvent.client.id !== client.id) {
                clientService.removeClientEvent({
                  batch,
                  clientId: this.originEvent.client.id,
                  eventId: this.globalEventId,
                })
              }

              clientService.addClientEventWithMastersProcedures({
                batch,
                eventForm: this.eventForm,
                clientId: client.id,
                sortingValue,
                eventId: this.globalEventId,
              })

              historyService.addHistory({
                batch,
                currentUser,
                now: new Date(),
                type: HISTORY_TYPES.UPDATE_EVENT,
                additionalData: {
                  eventId: this.globalEventId,
                  clientId: client.id,
                  eventPrice: this.eventForm.eventPrice,
                },
              })
            }

            batch
              .commit()
              .then(() => {
                this.$message.success(
                  this.isNewEvent ? 'Візит доданий' : 'Візит відредаговано'
                )

                if (this.isNeedToShowClientMessage) {
                  this.showEventNoteForClient(client)
                }

                // Pass data to listener of Calling service (if event is creating due that service)
                if (this.isNewEvent) {
                  customEventBus.trigger('eventCreated', {
                    dateStart: this.eventForm.dateStart,
                    clientId: client.id,
                    eventTimeStart: this.eventForm.eventTimeStart,
                    procedureIds: this.eventForm.procedureIds,
                    masterIds: this.eventForm.masterIds,
                    eventPrice: this.eventForm.eventPrice,
                  })
                } else {
                  customEventBus.trigger('eventEdited', {
                    dateStart: this.eventForm.dateStart,
                    clientId: client.id,
                    eventTimeStart: this.eventForm.eventTimeStart,
                    procedureIds: this.eventForm.procedureIds,
                    masterIds: this.eventForm.masterIds,
                    eventPrice: this.eventForm.eventPrice,
                  })
                }

                this.closeDialog({ dateStart: this.eventForm.dateStart })
              })
              .catch((error) => {
                this.$store.dispatch('setError', error.message)
              })
              .finally(() => this.$store.dispatch('setLoading', false))
          }
        })
      },
      showEventNoteForClient(client) {
        if (
          this.userSalon &&
          this.userSalon.eventNote &&
          this.$refs.eventNoteForClient
        ) {
          this.$nextTick(async () => {
            await this.$refs.eventNoteForClient.showEventMessage({
              client,
              isNewEvent: this.isNewEvent,
            })
          })
        }
      },
      closeDialog(eventData) {
        this.$emit('closeDialog', eventData)
      },
    },
    beforeDestroy() {
      this.eventForm = null
      this.originEvent = null
      this.globalEventId = ''
      this.dataLoaded = false
      this.isClientAdding = false
      this.TIMESTART = constants.timeStartString
      this.TIMEEND = constants.timeEndString
      this.isNeedToShowClientMessage = true
    },
  }
</script>
