<template>
  <div class="event" :id="currentEvent.id">
    <div class="event-title-container">
      <div class="event-title">
        <span>{{ currentEvent.title }}</span>

        <el-tag
          :type="
            getStatus(
              statusById(currentEvent.status || currentEvent.raw.status).id
            )
          "
        >
          {{ statusById(currentEvent.status || currentEvent.raw.status).title }}
        </el-tag>
      </div>

      {{
        getStatus(statusById(currentEvent.status || currentEvent.raw.status))
      }}

      <span
        v-if="
          currentEvent.raw &&
          currentEvent.raw.correction &&
          currentEvent.raw.correction === 'correction'
        "
      >
        (корекція)
      </span>

      <span
        v-if="
          currentEvent.raw &&
          currentEvent.raw.correction &&
          currentEvent.raw.correction === 'refresh'
        "
      >
        (рефреш)
      </span>
    </div>

    <div
      class="event-item"
      v-if="
        currentEvent.raw &&
        currentEvent.raw.client &&
        currentEvent.raw.client.id
      "
    >
      <div class="event-item__label">Клієнт</div>

      <div
        class="event-item__data flex flex--vertical-center"
        :id="currentEvent.raw.client.id"
      >
        <ClientView :clientId="currentEvent.raw.client.id" />
      </div>
    </div>

    <div class="event-item" v-if="currentEvent.raw && currentEvent.raw.master">
      <div class="event-item__label">Майстер</div>

      <div class="event-item__data">
        {{ currentEvent.raw.master.name }} / {{ currentEvent.raw.master.phone }}
      </div>
    </div>

    <div class="event-item">
      <div class="event-item__label">Дата</div>

      <div class="event-item__data">
        {{ currentEvent.dateStart || currentEvent.raw.date }} /
        {{ currentEvent.timeStart || currentEvent.raw.timeStart }}-{{
          currentEvent.timeEnd || currentEvent.raw.timeEnd
        }}
      </div>
    </div>

    <div
      class="event-item"
      v-if="currentEvent.raw && currentEvent.raw.duration"
    >
      <div class="event-item__label">Тривалість</div>

      <div class="event-item__data">{{ currentEvent.raw.duration }}м</div>
    </div>

    <div class="event-item">
      <div class="event-item__label">Ціна</div>

      <div class="event-item__data">
        <CurrencyView :value="currentEvent.raw.price" /><span
          v-if="currentEvent.raw.paymentMethod"
        >
          / {{ currentEvent.raw.paymentMethod }}</span
        >
      </div>
    </div>

    <div class="event-item" v-if="currentEvent.raw.additionalFields">
      <div class="event-item__label">Додаткові поля</div>

      <div class="event-item__data">
        <div
          v-for="(field, index) in currentEvent.raw.additionalFields"
          :key="index"
          class="event-item__data-additional"
        >
          <div class="event-item__data-additional-title">{{ field.name }}:</div>

          <div
            class="event-item__data-additional-data"
            v-if="typeof field.value !== 'object'"
          >
            {{
              typeof field.value !== 'object'
                ? field.value
                : field.value.join(', ')
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="event-item" v-if="currentEvent.raw.discountFrom">
      <div class="event-item__label">Знижка від</div>

      <div class="event-item__data">
        {{ currentEvent.raw.discountFrom === 'master' ? 'Майстра' : 'Салону' }}
      </div>
    </div>

    <div class="event-item" v-if="currentEvent.raw.note">
      <div class="event-item__label">Коментар під час створення</div>

      <div class="event-item__data">{{ currentEvent.raw.note }}</div>
    </div>

    <div class="event-item" v-if="currentEvent.raw.finalizeNote">
      <div class="event-item__label">Коментар при завершенні</div>

      <div class="event-item__data">{{ currentEvent.raw.finalizeNote }}</div>
    </div>

    <div class="event-item" v-if="currentEvent.raw.finalizeAt">
      <div class="event-item__label">Завершено</div>

      <div class="event-item__data">
        {{ getdate(currentEvent.raw.finalizeAt) }}
      </div>
    </div>

    <div class="event-item" v-if="currentEvent.raw.creator">
      <div class="event-item__label">Створив</div>

      <div class="event-item__data">
        {{ currentEvent.raw.creator.name }} /
        {{ getdate(currentEvent.raw.createdAt) }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { firstNull } from '@/utils/functions'

  import CurrencyView from '@/components/Common/CurrencyView'
  import ClientView from '@/components/Common/Clients/ClientView'

  export default {
    data() {
      return {}
    },
    props: {
      currentEvent: Object,
    },
    computed: {
      ...mapGetters(['statusById']),
    },
    components: {
      CurrencyView,
      ClientView,
    },
    methods: {
      getdate(date) {
        const dateObj = new Date(date)

        return (
          dateObj.getFullYear() +
          '-' +
          firstNull(dateObj.getMonth() + 1) +
          '-' +
          firstNull(dateObj.getDate())
        )
      },
      getStatus(status) {
        if (status === 1) return 'warning'
        if (status === 2) return 'danger'
        if (status === 3) return 'success'
        if (status === 4) return 'info'
      },
    },
    created() {},
  }
</script>

<style lang="scss" scoped>
  .event-item {
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
    }

    & + div {
      padding-top: 10px;
    }

    &__label {
      flex: 1 0 200px;

      @media screen and (min-width: 768px) {
        max-width: 200px;
        padding-right: 10px;
      }
    }

    &__data {
      flex: 1;
      color: $dark-gray;

      &-additional {
        & + div {
          margin-top: 10px;
        }

        &-title {
          color: $gray;
        }
      }
    }
  }

  .event-title {
    font-size: 20px;
    color: $dark-gray;

    span {
      margin-right: 10px;
    }

    .el-tag {
      vertical-align: middle;
    }
  }

  .event-title-container {
    padding-bottom: 10px;
  }
</style>
