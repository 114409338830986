import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

export default {
  state: {
    proceduresCategories: [],
  },
  mutations: {},
  actions: {
    bindProceduresCategories: firestoreAction((context) => {
      const salonId = context.getters.userSalonId

      return context.bindFirestoreRef(
        'proceduresCategories',
        db.collection('proceduresCategories').where('salonId', '==', salonId)
      )
    }),

    unbindProceduresCategories: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('proceduresCategories')
    }),

    addProcedureCategory: firestoreAction((context, payload) => {
      payload = Object.assign(
        {
          salonId: context.getters.userSalonId,
        },
        payload
      )

      return db
        .collection('proceduresCategories')
        .add(payload)
        .then(function (docRef) {
          db.collection('proceduresCategories')
            .doc(docRef.id)
            .set({ id: docRef.id }, { merge: true })
        })
    }),

    updateProcedureCategory: firestoreAction((context, payload) => {
      return db
        .collection('proceduresCategories')
        .doc(payload.id)
        .set(payload, { merge: true })
    }),

    removeProcedureCategory: firestoreAction((context, procedureId) => {
      return db
        .collection('proceduresCategories')
        .doc(procedureId)
        .set({ active: Boolean(false) }, { merge: true })
    }),
  },
  getters: {
    proceduresCategories: (state) => state.proceduresCategories,
    proceduresActiveCategories: (state) =>
      state.proceduresCategories.filter((event) => event.active === true),
    procedureCategoryById: (state) => (id) => {
      return state.proceduresCategories.find((procedure) => procedure.id === id)
    },
    procedureCategoryByName: (state) => (name) => {
      return state.proceduresCategories.find(
        (procedure) => procedure.name === name
      )
    },
  },
}
