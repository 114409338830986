<template>
  <div class="footer-buttons">
    <div class="footer-buttons-expanded-area">
      <div
        class="footer-buttons-clients"
        :class="{ active: expandedMenu === 'clients' }"
      >
        <ClientSearch ref="clientSearch" :atBottom="true" />
      </div>
    </div>

    <div class="footer-button footer-button-1">
      <el-link
        icon="el-icon-date"
        :underline="false"
        @click="$router.push('/calendar')"
      ></el-link>
    </div>

    <div class="footer-button footer-button-2">
      <el-link icon="el-icon-plus" :underline="false" @click="addNew"></el-link>
    </div>

    <div
      class="footer-button footer-button-3"
      :class="{ active: expandedMenu === 'clients' }"
    >
      <el-link
        icon="el-icon-search"
        :underline="false"
        @click="expandFooterTrigger('clients')"
      />
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/bus.js'
  import { dateTimeFormat } from '@/utils/functions'

  import ClientSearch from '@/components/Clients/ClientSearch'

  export default {
    name: 'default-buttons',
    data() {
      return {
        expandedMenu: '',
      }
    },
    props: {
      expandFooter: Boolean,
    },
    components: {
      ClientSearch,
    },
    methods: {
      addNew() {
        EventBus.$emit('addEvent', {
          date: dateTimeFormat(new Date(), 'Y-Mo-D'),
          timeStart: '',
        })
      },
      expandFooterTrigger(menu) {
        let footerState = this.expandFooter

        if (menu === this.expandedMenu && footerState) {
          this.expandedMenu = ''
          footerState = false
        } else if (
          (menu !== this.expandedMenu && footerState) ||
          !footerState
        ) {
          this.expandedMenu = menu
          footerState = true
        }

        this.$emit('expandFooter', footerState)
      },
    },
    mounted() {
      this.expandedMenu = ''
    },
  }
</script>
