<template>
  <div
    :class="`info-panel ${styleClass || ''} ${
      isShowing ? 'showing' : 'hidden'
    }`"
  >
    {{ text }}

    <el-button
      @click="close"
      icon="el-icon-close"
      type="text"
      class="close-button"
      v-if="canBeClosed"
    />
  </div>
</template>

<script>
  const MAIN_STORAGE_KEY = 'infoPanels'

  export default {
    data() {
      return {
        isShowing: true,
      }
    },
    components: {},
    computed: {},
    props: {
      text: String,
      styleClass: String,
      canBeClosed: {
        type: Boolean,
        default: false,
      },
      name: {
        type: String,
        default: '',
      },
    },
    watch: {},
    methods: {
      close() {
        const dataToStore =
          JSON.parse(localStorage.getItem(MAIN_STORAGE_KEY)) || {}

        dataToStore[this.name] = false

        this.isShowing = false

        localStorage.setItem(MAIN_STORAGE_KEY, JSON.stringify(dataToStore))
      },
    },
    mounted() {
      const currentStorageValues =
        JSON.parse(localStorage.getItem(MAIN_STORAGE_KEY)) || {}

      this.isShowing =
        typeof currentStorageValues[this.name] !== 'undefined'
          ? currentStorageValues[this.name]
          : true
    },
  }
</script>

<style lang="scss" scoped>
  .info-panel {
    background-color: lighten($yellow, 30%);
    border: 1px solid lighten($yellow, 15%);
    color: darken($yellow, 15%);
    padding: 10px;
    min-height: inherit;
    line-height: 1.5;
    display: flex;
    align-items: flex-start;

    &.hidden {
      display: none;
    }
  }

  .close-button {
    padding: 3px;
    flex: 0 0 30px;
    text-align: right;
    margin-left: auto;
  }
</style>
