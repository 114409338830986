<template>
  <span
    :style="'display: ' + display"
    :class="`value-currency ${styleClass ? styleClass : ''}`"
    >{{ showingValue
    }}<span class="value-currency__sign">{{ showingSign }}</span>
  </span>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { constants } from '@/utils/constants'

  export default {
    data() {
      return {
        defaultSign: constants.moneySigns[0],
      }
    },
    computed: {
      ...mapGetters(['userSalon']),
      moneySign() {
        return this.userSalon.sign
      },
      showingSign() {
        if (this.sign === 'none') return ''

        return this.sign ? this.sign : this.moneySign || this.defaultSign
      },
      showingValue() {
        return typeof this.value === 'number'
          ? Number.isInteger(this.value)
            ? this.value
            : this.value.toFixed(2)
          : this.value
      },
    },
    props: {
      value: [String, Number],
      sign: [String],
      display: {
        default: 'inline',
        type: String,
      },
      styleClass: String,
    },
  }
</script>

<style lang="scss" scoped>
  .value-currency {
    font-weight: bold;

    &__sign {
      font-size: 70%;
      font-weight: normal;
      margin-left: 1px;
    }
  }
</style>
