var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model-date--block",class:{
    opened: _vm.opened,
  }},[_c('div',{staticClass:"model-date--header",on:{"click":_vm.toggleBlock}},[_c('div',{staticClass:"models-date--title"},[_vm._v(" на "),_c('strong',[_vm._v(_vm._s(_vm.date))]),(_vm.eventForm.models[_vm.date] && _vm.eventForm.models[_vm.date].length)?_c('span',[_vm._v(" ("+_vm._s(_vm.eventForm.models[_vm.date].length)+")")]):_c('span',[_vm._v(" (0)")])]),_c('div',{staticClass:"models-date--toggler"},[_c('Icons',{attrs:{"icon":_vm.opened ? 'chevron-up' : 'chevron-down'}})],1)]),_c('div',{staticClass:"model-date--content"},[(
        _vm.modelsOnThisDateAndNOTOnThisTime &&
        _vm.modelsOnThisDateAndNOTOnThisTime.length > 0
      )?_c('div',{staticClass:"not-in-a-time-row"},[_vm._v(" У вас є моделі в цей день, але в час, який не збігається з вибраним проміжком (можливо, ви змінили час проведення курсу, коли моделі були збережені зі старим часом). Ви можете відреагувати час цих моделей, або видалити їх. "),_c('div',{staticClass:"models-list--not-in-a-time-row"},_vm._l((_vm.modelsOnThisDateAndNOTOnThisTime),function(model){return _c('div',{key:model.id,staticClass:"models-list--item"},[_c('CourceModelItem',{attrs:{"model":model},on:{"editItem":_vm.onEditModel,"removeItem":_vm.onRemoveModel}})],1)}),0)]):_vm._e(),_c('CourceModelsDayAddButton',{attrs:{"date":_vm.date,"eventForm":_vm.eventForm},on:{"addModel":_vm.onAddModel}}),_c('div',{key:_vm.modelsOnThisDate && _vm.modelsOnThisDate.length,staticClass:"models",style:({
        height: `${_vm.timeArray.length * _vm.hourHeight}px`,
      })},[_c('div',{staticClass:"models-time"},_vm._l((_vm.timeArray),function(time){return _c('div',{key:time,staticClass:"models-time--label",style:({
            height: `${_vm.hourHeight}px`,
          })},[_vm._v(" "+_vm._s(time)+" ")])}),0),_c('div',{staticClass:"models-list",style:({
          gridTemplateRows: _vm.getTemplateRowStyle,
        })},_vm._l((_vm.modelsOnThisDateAndOnThisTime),function(model){return _c('div',{key:model.id,staticClass:"models-list--item",style:({
            gridRow: `col-${model.timeStartNumber || 1000} / span ${
              model.span || 1
            }`,
          })},[_c('CourceModelItem',{attrs:{"model":model},on:{"editItem":_vm.onEditModel,"removeItem":_vm.onRemoveModel}})],1)}),0)]),_c('CourceModelsDayAddButton',{attrs:{"date":_vm.date,"eventForm":_vm.eventForm},on:{"addModel":_vm.onAddModel}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }