var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"procedures"},[_c('el-form-item',{attrs:{"prop":'mastersProcedures.' + _vm.masterKey + '.procedures',"rules":{
      required: true,
      message: 'Виберіть процедуру',
      trigger: 'blur',
    }}},[_c('div',{staticClass:"procedures-master-controls"},[_c('el-button',{attrs:{"disabled":_vm.isClientAdding ||
          _vm.loading ||
          !_vm.masterId ||
          _vm.masterProcedures.length === 0 ||
          _vm.chooseProceduresDialogVisibility,"type":"primary","plain":""},on:{"click":_vm.openChoosingProceduresDialog}},[_vm._v(" "+_vm._s(_vm.isProceduresChoosed ? 'Редагувати процедури' : 'Виберіть процедури')+" ")]),_c('el-button',{attrs:{"disabled":_vm.isClientAdding ||
          _vm.loading ||
          _vm.masterProcedures.length === 0 ||
          _vm.chooseProceduresDialogVisibility ||
          !_vm.isProceduresChoosed,"type":"danger","plain":""},on:{"click":_vm.clearChoosedProcedures}},[_vm._v(" Очистити процедури ")]),_c('el-button',{attrs:{"disabled":_vm.isClientAdding || _vm.loading || _vm.chooseProceduresDialogVisibility,"type":"danger","plain":""},on:{"click":_vm.removeMaster}},[_vm._v(" Видалити майстра ")])],1),(_vm.masterProcedures.length === 0)?_c('div',{staticClass:"small color-danger"},[_vm._v(" Процедур ще немає. Створіть їх у розділі \"Процедури\". ")]):_vm._e()]),(_vm.chooseProceduresDialogVisibility)?_c('ChoosingMasterProceduresDialog',{attrs:{"dialogVisibility":_vm.chooseProceduresDialogVisibility,"masterProcedures":_vm.masterProcedures,"eventFormProcedures":_vm.localEventFormProcedures,"masterName":_vm.master.name},on:{"closeDialog":_vm.onCloseChooseProceduresDialog,"chooseProcedures":_vm.onChooseProcedures}}):_vm._e(),_c('div',{staticClass:"procedures-list"},_vm._l((_vm.localEventFormProcedures),function(procedureItem,procedureKey){return _c('div',{key:procedureKey,staticClass:"procedures-list--item"},[_c('ProcedureItem',{attrs:{"procedureItem":procedureItem,"procedureKey":procedureKey,"isClientAdding":_vm.isClientAdding,"proceduresCount":_vm.proceduresCount},on:{"sendProcedureItem":_vm.onSendProcedureItem,"removeProcedure":_vm.onRemoveProcedure,"moveProcedure":_vm.onMoveProcedure}})],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }