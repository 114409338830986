<template>
  <el-drawer
    title=""
    :visible.sync="localVisibility"
    size="50%"
    :with-header="true"
    append-to-body
    custom-class="client-view"
    :before-close="onCloseDrawer"
  >
    <ClientInfo
      :clientId="clientId"
      :clientInfo="true"
      v-if="localVisibility"
    />
  </el-drawer>
</template>

<script>
  import ClientInfo from '@/components/Clients/ClientInfo'

  export default {
    data() {
      return {
        localVisibility: false,
      }
    },
    components: {
      ClientInfo,
    },
    props: {
      clientId: String,
      clientViewVisible: Boolean,
    },
    watch: {
      clientViewVisible: {
        immediate: true,
        deep: false,
        handler(visible) {
          this.localVisibility = visible

          if (visible) {
            this.addLookOnBackButtonEvent()
          }
        },
      },
    },
    computed: {},
    methods: {
      onCloseDrawer() {
        this.$emit('closeClientView')
      },
      addLookOnBackButtonEvent() {
        let self = this

        history.pushState(null, null, null)
        window.addEventListener('popstate', self.lookOnBackButton)
      },
      lookOnBackButton() {
        let self = this

        history.pushState(null, null, null)
        window.removeEventListener('popstate', self.lookOnBackButton)

        this.onCloseDrawer()
      },
    },
    mounted() {},
  }
</script>

<style lang="scss" scoped>
  .el-drawer__wrapper {
    &::v-deep {
      .el-drawer.client-view {
        min-width: 100%;
        overflow-y: auto;

        @media screen and (min-width: 624px) {
          min-width: 600px;
        }

        .el-drawer__header {
          margin: 0;
          position: absolute;
          top: 0;
          right: 0;
        }

        .el-drawer__body {
          padding: 20px;
        }
      }
    }
  }
</style>
