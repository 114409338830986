import Vue from 'vue'
import Router from 'vue-router'
import AuthGuard from './auth-guard'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      name: 'home',
      meta: { layout: 'empty' },
      component: () => import('@/pages/Home'),
    },
    {
      path: '/forgotPassword',
      name: 'forgotPassword',
      meta: { layout: 'empty' },
      component: () => import('@/pages/ForgotPassword'),
    },
    {
      path: '/calendar',
      name: 'calendar',
      meta: { layout: 'default' },
      component: () => import('@/pages/Calendar'),
      beforeEnter: AuthGuard,
    },
    // {
    //   path: '/wizard',
    //   name: 'wizard',
    //   meta: { layout: 'default' },
    //   component: () => import('@/pages/Wizard'),
    //   beforeEnter: AuthGuard,
    // },
    {
      path: '/clients',
      name: 'clients',
      meta: { layout: 'default' },
      component: () => import('@/pages/Clients'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/finances',
      name: 'finances',
      meta: { layout: 'default' },
      component: () => import('@/pages/Finances'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/procedures',
      name: 'procedures',
      meta: { layout: 'default' },
      component: () => import('@/pages/Procedures'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/masters',
      name: 'masters',
      meta: { layout: 'default' },
      component: () => import('@/pages/Masters'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/notifications',
      name: 'notifications',
      meta: { layout: 'default' },
      component: () => import('@/pages/Notifications'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/certificates',
      name: 'certificates',
      meta: { layout: 'default' },
      component: () => import('@/pages/Certificates'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/statistics',
      name: 'statistics',
      meta: { layout: 'default' },
      component: () => import('@/pages/Statistics'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/warehouse',
      name: 'warehouse',
      meta: { layout: 'default' },
      component: () => import('@/pages/Warehouse'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/cources',
      name: 'сources',
      meta: { layout: 'default' },
      component: () => import('@/pages/Cources'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/userPanel',
      name: 'userPanel',
      meta: { layout: 'default' },
      component: () => import('@/pages/UserPanel'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/marketing',
      name: 'marketing',
      meta: { layout: 'default' },
      component: () => import('@/pages/Marketing'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/calling',
      name: 'calling',
      meta: { layout: 'default' },
      component: () => import('@/pages/Calling'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/salons',
      name: 'salons',
      meta: { layout: 'default' },
      component: () => import('@/pages/Salons'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/invite/:id',
      name: 'invite',
      meta: { layout: 'empty' },
      component: () => import('@/pages/Invite'),
    },
    {
      path: '/systemPanel',
      name: 'systemPanel',
      meta: { layout: 'default' },
      component: () => import('@/pages/SystemPanel'),
      beforeEnter: AuthGuard,
    },
    {
      path: '/versions',
      name: 'versions',
      meta: { layout: 'default' },
      component: () => import('@/pages/VersionsPanel'),
      beforeEnter: AuthGuard,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})
