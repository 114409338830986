import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

import 'firebase/firestore'

export default {
  state: {
    localClients: [],
  },
  mutations: {
    addToLocalClients(state, payload) {
      state.localClients.push(payload)
    },
    updateInLocalClients(state, payload) {
      const indexOfClient = state.localClients.findIndex(
        (item) => item.id === payload.id
      )

      if (indexOfClient !== '-1') {
        state.localClients[indexOfClient] = payload
      }
    },
  },
  actions: {
    updateClient: firestoreAction((context, payload) => {
      return db
        .collection('clients')
        .doc(payload.id)
        .set(payload, { merge: true })
    }),

    updateClientEvents: firestoreAction((context, payload) => {
      const eventId = payload.eventId
      const clientId = payload.clientId

      delete payload.eventId
      delete payload.clientId

      return db
        .collection('clients')
        .doc(clientId)
        .collection('clientEvents')
        .doc(eventId)
        .set(payload, { merge: true })
    }),

    addToLocalClients({ state, commit }, payload) {
      const isAlreadyInStore = state.localClients.some(
        (item) => item.id === payload.id
      )

      if (!isAlreadyInStore) {
        commit('addToLocalClients', payload)
      } else {
        const isNeedToUpdate = isAlreadyInStore && payload.isFull

        if (isNeedToUpdate) {
          commit('updateInLocalClients', payload)
        }
      }
    },
  },
  getters: {
    localClients: (state) => state.localClients,
    localClientById: (state) => (id) => {
      const foundItem = state.localClients?.length
        ? state.localClients.find((item) => item.id === id)
        : null

      return foundItem
    },
  },
}
