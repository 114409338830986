<template>
  <Default :expandFooter="expandFooter" @expandFooter="onExpandFooter" />
</template>

<script>
  import Default from '@/layout/footer/buttons/Default'

  export default {
    data() {
      return {}
    },
    props: {
      expandFooter: Boolean,
    },
    components: {
      Default,
    },
    methods: {
      onExpandFooter(expand) {
        this.$emit('expandFooter', expand)
      },
    },
  }
</script>
