var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form-item',{attrs:{"label":`Майстер ${_vm.masterName}`,"prop":'mastersProcedures.' + _vm.masterKey + '.procedures',"rules":{
      required: true,
      message: 'Виберіть процедури',
      trigger: 'change',
    }}},[_c('ProceduresItem',{attrs:{"isNewEvent":_vm.isNewEvent,"eventFormProcedures":_vm.localMasterProcedures.procedures,"eventTimeStart":_vm.eventTimeStart,"isClientAdding":_vm.isClientAdding,"masterId":_vm.localMasterProcedures.masterId,"masterKey":_vm.masterKey},on:{"sendProcedureItem":_vm.onSendProcedureItem,"sendProcedures":_vm.onSendProcedures,"removeProcedureItem":_vm.onRemoveProcedureItem,"moveProcedure":_vm.onMoveProcedure,"removeMaster":_vm.onRemoveMaster}})],1),(
      _vm.localMasterProcedures.procedures &&
      (_vm.localMasterProcedures.masterDuration ||
        (_vm.localMasterProcedures.masterStartTime &&
          _vm.localMasterProcedures.masterEndTime) ||
        _vm.localMasterProcedures.masterPrice)
    )?_c('el-form-item',{attrs:{"label":"Інформація про процедури майстра"}},[_c('div',{staticClass:"line-height-1-5"},[(_vm.localMasterProcedures.masterDuration)?_c('div',[_vm._v(" Тривалість: "),_c('strong',[_vm._v(_vm._s(_vm.getDuration(_vm.localMasterProcedures.masterDuration)))])]):_vm._e(),(
          _vm.localMasterProcedures.masterStartTime &&
          _vm.localMasterProcedures.masterEndTime
        )?_c('div',[_vm._v(" Час: "),_c('strong',[_vm._v(_vm._s(_vm.localMasterProcedures.masterStartTime)+" - "+_vm._s(_vm.localMasterProcedures.masterEndTime))])]):_vm._e(),(_vm.localMasterProcedures.masterPrice)?_c('div',[_vm._v(" Загальна вартість: "),_c('CurrencyView',{attrs:{"value":_vm.localMasterProcedures.masterPrice}})],1):_vm._e()])]):_vm._e(),_c('MasterItemEvents',{attrs:{"masterId":_vm.mastersItem.masterId,"date":_vm.date,"eventId":_vm.correctEventId,"currentItemTimeStart":_vm.mastersItem.masterStartTime,"currentItemTimeEnd":_vm.mastersItem.masterEndTime,"newEvent":_vm.isNewEvent}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }