import { db } from '@/plugins/firebase'
import { getSortingValueNumberFromDateWithoutTime } from '@/utils/functions'
import store from '@/store/index.js'

const MARKETINGS_COLLECTION_KEY = 'marketings'

const marketingService = {
  addMarketing: ({ batch, marketingType, additionalObj }) => {
    const marketingRef = db.collection(MARKETINGS_COLLECTION_KEY).doc()
    const marketingRefId = marketingRef.id

    batch.set(marketingRef, {
      id: marketingRefId,
      type: marketingType,
      sortingValue: getSortingValueNumberFromDateWithoutTime(),
      salonId: store.getters.userSalonId,
      ...additionalObj,
    })
  },
}

export default marketingService
