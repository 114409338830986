<template>
  <span v-if="eventId">
    <el-link @click="openEvent"><small>докладніше про візит</small></el-link>

    <el-drawer
      :visible.sync="eventVisible"
      :with-header="false"
      size="49%"
      append-to-body
      destroy-on-close
      custom-class="event-view"
      @closeDrawer="closeEvent"
    >
      <Event :eventId="eventId" />
    </el-drawer>
  </span>
</template>

<script>
  import Event from '@/components/Event/Event'

  export default {
    data() {
      return {
        eventVisible: false,
      }
    },
    props: {
      eventId: String,
    },
    components: {
      Event,
    },
    methods: {
      openEvent() {
        this.eventVisible = true

        this.addLookOnBackButtonEvent()
      },
      closeEvent() {
        this.eventVisible = false
      },
      addLookOnBackButtonEvent() {
        let self = this

        history.pushState(null, null, null)
        window.addEventListener('popstate', self.lookOnBackButton)

        history.pushState(null, document.title, location.href)

        window.addEventListener('popstate', function () {
          if (this.eventVisible) {
            this.closeEvent()
          }

          history.pushState(null, document.title, location.href)
        })
      },
      lookOnBackButton() {
        let self = this

        if (this.eventVisible) {
          this.closeEvent()
        }

        history.pushState(null, null, null)
        window.removeEventListener('popstate', self.lookOnBackButton)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-drawer__wrapper {
    &::v-deep {
      .event-view {
        min-width: 100%;
        overflow-y: auto;

        @media screen and (min-width: 624px) {
          min-width: 600px;
        }

        .el-drawer__header {
          margin: 0;
          position: absolute;
          top: 0;
          right: 0;
        }

        .el-drawer__body {
          padding: 20px;
        }
      }
    }
  }
</style>
