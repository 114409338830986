<template>
  <div class="main-wrapper">
    <div class="main-wrapper__menu">
      <VerticalMenu />
    </div>

    <div class="container flex flex--direction-column flex-1" v-if="user?.id">
      <router-view></router-view>
    </div>

    <el-dialog
      title="Встановлення"
      :visible.sync="installDialogVisible"
      width="30%"
      :before-close="onCloseDialogVisible"
      class="on-left no-responsive"
    >
      <span
        >Ви можете встановити додаток собі на робочий стіл, це буде набагато
        зручніше для користування</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="install">Встановити</el-button>
        <el-button @click="onCloseDialogVisible">Скасувати</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import VerticalMenu from '@/components/Common/VerticalMenu'

  export default {
    data() {
      return {
        installDialogVisible: false,
        deferredPrompt: null,
      }
    },
    computed: {
      ...mapGetters(['user']),
    },
    components: {
      VerticalMenu,
    },
    methods: {
      async install() {
        this.deferredPrompt = window.deferredPrompt

        if (!this.deferredPrompt) {
          // The deferred prompt isn't available.
          return
        }

        // Show the install prompt
        this.deferredPrompt.prompt()

        await this.deferredPrompt.userChoice

        // We've used the prompt, and can't use it again, throw it away
        this.deferredPrompt = null

        this.onCloseDialogVisible()
      },
      onCloseDialogVisible() {
        this.installDialogVisible = false
      },
    },
    mounted() {
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault()
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e
        // Update UI notify the user they can install the PWA
        this.installDialogVisible = true
      })

      window.addEventListener('appinstalled', () => {
        // Hide the app-provided install promotion
        this.onCloseDialogVisible()
        // Clear the deferredPrompt so it can be garbage collected
        this.deferredPrompt = null
      })
    },
  }
</script>

<style lang="scss" scoped>
  .main-wrapper {
    display: grid;
    grid-template-columns: auto;
    height: 100%;

    @media screen and (min-width: 980px) {
      grid-template-columns: 200px auto;
    }

    > .container {
      min-width: 1px;
      padding: 5px 10px 20px;

      @media screen and (min-width: 980px) {
        padding: 10px;
      }
    }

    &__menu {
      display: none;

      @media screen and (min-width: 980px) {
        display: block;
        position: relative;
        z-index: 1;
      }
    }
  }
</style>
