import { db } from '@/plugins/firebase'
import store from '@/store/index.js'

const SETTINGS_COLLECTION_KEY = 'settings'
const SETTINGS_COLLECTION_KEY_DOC_ID = 'site'
const VERSION_HISTORY_COLLECTION_KEY = 'versionHistory'

const siteService = {
  updateSettings: ({ batch }) => {
    const settingRef = db
      .collection(SETTINGS_COLLECTION_KEY)
      .doc(SETTINGS_COLLECTION_KEY_DOC_ID)

    batch.set(
      settingRef,
      {
        salonId: store.getters.userSalonId,
      },
      { merge: true }
    )
  },

  addVersionHistory: ({ batch, versionNumber, versionData }) => {
    const versionHistoryRef = db
      .collection(SETTINGS_COLLECTION_KEY)
      .doc(SETTINGS_COLLECTION_KEY_DOC_ID)
      .collection(VERSION_HISTORY_COLLECTION_KEY)
      .doc(versionNumber)

    batch.set(versionHistoryRef, {
      id: versionNumber,
      versionData,
    })
  },

  deleteVersionHistory: ({ batch, versionNumber }) => {
    const versionHistoryRef = db
      .collection(SETTINGS_COLLECTION_KEY)
      .doc(SETTINGS_COLLECTION_KEY_DOC_ID)
      .collection(VERSION_HISTORY_COLLECTION_KEY)
      .doc(versionNumber)

    batch.delete(versionHistoryRef)
  },

  getVersionHistory: async ({ versionNumber }) => {
    await store.dispatch('setLoading', true)

    let event = {}

    await db
      .collection(SETTINGS_COLLECTION_KEY)
      .doc(SETTINGS_COLLECTION_KEY_DOC_ID)
      .collection(VERSION_HISTORY_COLLECTION_KEY)
      .doc(versionNumber)
      .get()
      .then((doc) => {
        if (doc.exists) {
          event = doc.data()
        }
      })
      .catch((error) => store.dispatch('setError', error.message))
      .finally(() => {
        store.dispatch('setLoading', false)
      })

    return event
  },

  getVersionHistories: async () => {
    let versions = []

    await db
      .collection(SETTINGS_COLLECTION_KEY)
      .doc(SETTINGS_COLLECTION_KEY_DOC_ID)
      .collection(VERSION_HISTORY_COLLECTION_KEY)
      .orderBy('id', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const item = doc.data()

          versions.push(item)
        })
      })

    return versions
  },
}

export default siteService
