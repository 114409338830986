import store from '@/store/index.js'
import firebase from 'firebase/app'
import 'firebase/firestore'

import { db } from '@/plugins/firebase'

import financesReportsService from '@/services/finances-reports-service'

const INCOME_COLLECTION_KEY = 'income'

const incomeService = {
  addIncome: async ({ batch, incomeData, reportData, now, currentUser }) => {
    const incomeRef = db.collection(INCOME_COLLECTION_KEY).doc()
    const incomeRefId = incomeRef.id

    if (reportData) {
      await financesReportsService.updateFinanceReport({
        batch,
        reportData,
        doMonthReport: reportData.doMonthReport || false,
      })
    }

    batch.set(incomeRef, {
      ...incomeData,
      id: incomeRefId,
      salonId: store.getters.userSalonId,
      history: [
        {
          type: 'created',
          userId: currentUser.userId,
          at: now,
        },
      ],
    })

    return { incomeId: incomeRefId }
  },

  getIncomes: async (date) => {
    let incomes = []

    await db
      .collection(INCOME_COLLECTION_KEY)
      .where('sortingValue', '>=', date[0])
      .where('sortingValue', '<', date[1])
      .where('salonId', '==', store.getters.userSalonId)
      .orderBy('sortingValue', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const item = doc.data()

          incomes.push(item)
        })
      })
      .catch((error) => {
        this.$store.dispatch('setError', error.message)
        this.$store.dispatch('setLoading', false)
      })

    return incomes
  },

  updateIncome: ({
    batch,
    incomeId,
    whatChanged = {},
    incomeData = {},
    originData,
    now,
    currentUser,
  }) => {
    let whatChangedObj = {}

    const incomeRef = db.collection(INCOME_COLLECTION_KEY).doc(incomeId)

    if (Object.keys(whatChanged).length) {
      const historyObj = {}

      for (let objKey in whatChanged) {
        historyObj[objKey] = [originData[objKey] || '', whatChanged[objKey]]
      }

      whatChangedObj = {
        ...whatChanged,
        history: firebase.firestore.FieldValue.arrayUnion({
          userId: currentUser.userId,
          userName: currentUser.name,
          date: now,
          type: 'updated',
          historyData: historyObj,
        }),
      }
    }

    batch.set(
      incomeRef,
      {
        salonId: store.getters.userSalonId,
        ...whatChangedObj,
        ...(Object.keys(incomeData).length && incomeData),
      },
      { merge: true }
    )
  },

  removeEventMasterProceduresBeforeEditing: ({ batch, incomeId }) => {
    const incomeRef = db.collection(INCOME_COLLECTION_KEY).doc(incomeId)

    batch.set(
      incomeRef,
      {
        data: {
          event: {
            mastersProcedures: firebase.firestore.FieldValue.delete(),
          },
        },
      },
      { merge: true }
    )
  },

  updateEventIncome: ({
    batch,
    incomeId,
    changedParts = {},
    incomeData = {},
    now,
    currentUser,
  }) => {
    const incomeRef = db.collection(INCOME_COLLECTION_KEY).doc(incomeId)

    batch.set(
      incomeRef,
      {
        salonId: store.getters.userSalonId,
        ...incomeData,
        history: firebase.firestore.FieldValue.arrayUnion({
          userId: currentUser.userId,
          userName: currentUser.name,
          at: now,
          type: 'updated',
          changedParts,
        }),
      },
      { merge: true }
    )
  },
}

export default incomeService
