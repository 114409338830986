<template>
  <div
    class="model-date--block"
    :class="{
      opened: opened,
    }"
  >
    <div class="model-date--header" @click="toggleBlock">
      <div class="models-date--title">
        на <strong>{{ date }}</strong>
        <span v-if="eventForm.models[date] && eventForm.models[date].length">
          ({{ eventForm.models[date].length }})</span
        >
        <span v-else> (0)</span>
      </div>

      <div class="models-date--toggler">
        <Icons :icon="opened ? 'chevron-up' : 'chevron-down'" />
      </div>
    </div>

    <div class="model-date--content">
      <div
        v-if="
          modelsOnThisDateAndNOTOnThisTime &&
          modelsOnThisDateAndNOTOnThisTime.length > 0
        "
        class="not-in-a-time-row"
      >
        У вас є моделі в цей день, але в час, який не збігається з вибраним
        проміжком (можливо, ви змінили час проведення курсу, коли моделі були
        збережені зі старим часом). Ви можете відреагувати час цих моделей, або
        видалити їх.

        <div class="models-list--not-in-a-time-row">
          <div
            class="models-list--item"
            v-for="model in modelsOnThisDateAndNOTOnThisTime"
            :key="model.id"
          >
            <CourceModelItem
              :model="model"
              @editItem="onEditModel"
              @removeItem="onRemoveModel"
            />
          </div>
        </div>
      </div>

      <CourceModelsDayAddButton
        :date="date"
        :eventForm="eventForm"
        @addModel="onAddModel"
      />

      <div
        class="models"
        :key="modelsOnThisDate && modelsOnThisDate.length"
        :style="{
          height: `${timeArray.length * hourHeight}px`,
        }"
      >
        <div class="models-time">
          <div
            v-for="time in timeArray"
            :key="time"
            :style="{
              height: `${hourHeight}px`,
            }"
            class="models-time--label"
          >
            {{ time }}
          </div>
        </div>

        <div
          class="models-list"
          :style="{
            gridTemplateRows: getTemplateRowStyle,
          }"
        >
          <div
            class="models-list--item"
            v-for="model in modelsOnThisDateAndOnThisTime"
            :key="model.id"
            :style="{
              gridRow: `col-${model.timeStartNumber || 1000} / span ${
                model.span || 1
              }`,
            }"
          >
            <CourceModelItem
              :model="model"
              @editItem="onEditModel"
              @removeItem="onRemoveModel"
            />
          </div>
        </div>
      </div>

      <CourceModelsDayAddButton
        :date="date"
        :eventForm="eventForm"
        @addModel="onAddModel"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import Icons from '@/components/Common/Icons/Icons'
  import CourceModelItem from '@/components/Calendar/EventsForms/Cource/CourceModelItem'
  import CourceModelsDayAddButton from '@/components/Calendar/EventsForms/Cource/CourceModelsDayAddButton'

  export default {
    data() {
      return {
        hourHeight: 50,
        opened: false,
      }
    },
    props: {
      date: String,
      eventForm: Object,
      timeArray: Array,
      activeDay: String,
    },
    watch: {
      activeDay: {
        immediate: true,
        deep: false,
        handler(date) {
          if (!date) return

          this.opened = this.date === date ? true : false
        },
      },
    },
    computed: {
      ...mapGetters(['loading']),
      procedures() {
        return this.eventForm.cource.procedures.split(',')
      },
      modelsOnThisDate() {
        return this.eventForm.models && this.eventForm.models[this.date]
          ? this.eventForm.models[this.date]
          : []
      },
      timeArrayStartNumber() {
        return Number(this.timeArray[0].replace(/\D/g, ''))
      },
      timeArrayEndNumber() {
        return Number(
          this.timeArray[this.timeArray.length - 1].replace(/\D/g, '')
        )
      },
      modelsOnThisDateAndOnThisTime() {
        return this.modelsOnThisDate.filter((event) => {
          return (
            event.timeStartNumber >= this.timeArrayStartNumber &&
            event.timeEndNumber <= this.timeArrayEndNumber
          )
        })
      },
      modelsOnThisDateAndNOTOnThisTime() {
        return this.modelsOnThisDate.filter((event) => {
          return (
            event.timeStartNumber < this.timeArrayStartNumber ||
            event.timeEndNumber > this.timeArrayEndNumber
          )
        })
      },
      getTemplateRowStyle() {
        const rowsNames = this.timeArray
          .map((time) => `[col-${time.replace(':', '')}] ${this.hourHeight}px`)
          .join(' ')

        return `repeat(${this.timeArray.length}, ${rowsNames})`
      },
    },
    components: {
      CourceModelItem,
      CourceModelsDayAddButton,
      Icons,
    },
    methods: {
      toggleBlock() {
        if (Object.keys(this.eventForm.cource).length === 0) return

        this.opened = !this.opened
        this.$emit('toggleActive', this.date)
      },
      onAddModel(date) {
        this.$emit('addModel', date)
      },
      onEditModel(model) {
        this.$emit('editModel', { ...model, date: this.date }) // TEMP with a date
      },
      onRemoveModel(model) {
        this.$emit('removeModel', { ...model, date: this.date }) // TEMP with a date
      },
    },
  }
</script>

<style lang="scss" scoped>
  .model-date--header {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .models-date--title {
    margin-right: 10px;
  }

  .models-date--toggler {
    color: $red;
  }

  .model-date--content {
    max-height: 0;
    overflow: hidden;
    transition: 200ms max-height ease;

    .opened & {
      max-height: 1000px;
    }
  }

  .models {
    display: flex;
    position: relative;
    margin-bottom: 10px;
  }

  .models-time {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    font-size: 12px;
    z-index: 1;

    &--label {
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        background: $border-color;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:last-child {
        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background: $border-color;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  .models-list {
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    flex: 1;
    padding-left: 40px;
    position: relative;
    z-index: 1;
  }

  .not-in-a-time-row {
    line-height: 1.5;
  }

  .models-list--not-in-a-time-row {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    gap: 10px;

    .models-list--item {
      flex: 1 0 25%;
    }
  }

  .models-list--item {
    overflow: hidden;
  }
</style>
