<template>
  <div class="margin-bottom-15">
    <el-form-item
      :label="
        eventForm.students.length === 0 || eventForm.students.length === 1
          ? 'Учень'
          : 'Учні'
      "
      prop="students"
      :rules="{
        required: true,
        message: 'Учень обов\'язковий',
        trigger: 'blur',
      }"
    >
      <div class="student-list">
        <div v-for="student in eventForm.students" :key="student.id">
          <CourceStudentItem
            :student="student"
            :isClientAdding="isClientAdding"
            :courcePrice="eventForm.cource?.price || 10"
            @changePrice="onChangePrice"
            @removeStudent="onRemoveStudent"
          />
        </div>
      </div>

      <div v-if="isAlreadyHere">Такий учень уже є. Виберіть іншого.</div>

      <el-button
        @click="addStudent"
        :disabled="
          addingItem ||
          isClientAdding ||
          loading ||
          Object.keys(eventForm.cource).length === 0
        "
        class="student-add"
        type="primary"
      >
        Обрати {{ eventForm.students.length > 0 ? 'ще одного' : '' }} учня
      </el-button>

      <el-button @click="onCancelChoosingClient" v-if="addingItem">
        Скасувати
      </el-button>
    </el-form-item>

    <div v-if="addingItem">
      <ClientFormItem
        :isClientAdding="isClientAdding"
        title="Вибір учня"
        prop="student"
        @changeClientAdding="onChangeClientAdding"
        @choosedClient="onClientsChoose"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ClientFormItem from '@/components/Common/Forms/ClientFormItem'
  import CourceStudentItem from '@/components/Calendar/EventsForms/Cource/CourceStudentItem'

  export default {
    data() {
      return {
        addingItem: false,
        isAlreadyHere: false,
        students: [],
      }
    },
    computed: {
      ...mapGetters(['loading']),
    },
    props: {
      isClientAdding: Boolean,
      newEvent: Boolean,
      eventForm: Object,
    },
    watch: {
      eventForm: {
        async handler(eventFormData) {
          if (!eventFormData) return

          this.students = JSON.parse(JSON.stringify(eventFormData.students))
        },
        immediate: true,
      },
    },
    components: {
      ClientFormItem,
      CourceStudentItem,
    },
    methods: {
      addStudent() {
        this.addingItem = true
        this.isAlreadyHere = false
      },
      onRemoveStudent(student) {
        this.students.splice(this.students.indexOf(student), 1)

        this.sendStudentsData()
      },
      onChangePrice(data) {
        const studentIndex = this.students.findIndex(
          (student) => student.id === data.id
        )

        this.students[studentIndex] = data

        this.sendStudentsData()
      },
      onChangeClientAdding(isAdding) {
        this.$emit('isClientAdding', isAdding)
      },
      onClientsChoose(clientData) {
        if (!clientData || !clientData.id) return

        this.isAlreadyHere = false

        if (!this.students.some((item) => item.id === clientData.id)) {
          this.students.push({
            id: clientData.id,
            name: clientData.name,
            phone: clientData.phone,
            price:
              this.eventForm.cource && this.eventForm.cource.price
                ? this.eventForm.cource.price
                : 10,
          })

          this.addingItem = false
        } else {
          this.isAlreadyHere = true
        }

        this.sendStudentsData()
      },
      onCancelChoosingClient() {
        this.isAlreadyHere = false
        this.addingItem = false
      },
      sendStudentsData() {
        this.$emit('sendStudentsData', this.students)
      },
    },
    mounted() {},
  }
</script>

<style lang="scss" scoped></style>
