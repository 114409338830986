<template>
  <el-form
    ref="expenceForm"
    :model="expenceForm"
    :label-width="labelWidth"
    class="expence-form"
  >
    <div
      v-for="(expence, index) in expenceForm.expences"
      :key="expence.key"
      class="expence-form-item"
    >
      <el-form-item
        label="Категорія"
        size="medium"
        :prop="'expences.' + index + '.category'"
        :rules="REQUIRED_FIELD_MESSAGE_RULE_OBJECT"
      >
        <el-cascader-panel
          v-model="expence.category"
          :options="categorizedCategories"
          :show-all-levels="false"
          :props="{ checkStrictly: false, emitPath: false }"
        />

        <el-tooltip
          content="Виберіть категорію або якщо немає відповідної, додайте її натиснувши на меню 'Категорії' в нижній частині форми"
          placement="top"
        >
          <el-link type="text" :underline="false">
            &nbsp;<i class="el-icon-question color-danger" />
          </el-link>
        </el-tooltip>

        <div v-if="numerOfActiveCategories === 0">
          <InfoPanel
            text="Схоже, ви ще не встигли створити категорії. Ви можете створити категорії натиснувши на меню 'Категорії' в нижній частині форми або використовувати основні."
            class="margin-top-15"
          />
        </div>

        <div
          class="line-height-1-5 margin-top-10"
          v-if="mostUsedTenCategories.length"
        >
          <div class="margin-bottom-5">Найчастіше використовуються</div>

          <el-link
            type="primary"
            v-for="cat in mostUsedTenCategories"
            :key="cat.id"
            @click="expence.category = cat.id"
            class="margin-right-5"
          >
            {{ cat.name }}
          </el-link>
        </div>

        <div
          class="line-height-1-5"
          v-if="materialExpenceCategoryId || goodsExpenceCategoryId"
        >
          <el-link
            type="primary"
            @click="expence.category = materialExpenceCategoryId"
            class="margin-right-5"
            v-if="materialExpenceCategoryName"
          >
            {{ materialExpenceCategoryName }}
          </el-link>

          <el-link
            type="primary"
            @click="expence.category = goodsExpenceCategoryId"
            class="margin-right-5"
            v-if="goodsExpenceCategoryName"
          >
            {{ goodsExpenceCategoryName }}
          </el-link>
        </div>
      </el-form-item>

      <el-form-item label="Гроші з" :rules="REQUIRED_FIELD_MESSAGE_RULE_OBJECT">
        <el-radio-group v-model="expence.moneySource">
          <el-radio label="cash">Каси</el-radio>
          <el-radio label="card">Картки</el-radio>
          <el-radio label="fund">Запасів</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        label="Ціна"
        size="medium"
        :prop="'expences.' + index + '.price'"
        :rules="REQUIRED_FIELD_MESSAGE_RULE_OBJECT"
      >
        <NumberInput v-model="expence.price" :min="10" suffixDefault="money" />
      </el-form-item>

      <el-form-item
        label="Коментар"
        size="medium"
        :prop="'expences.' + index + '.comment'"
        :rules="REQUIRED_FIELD_MESSAGE_RULE_OBJECT"
        class="width-100"
      >
        <el-input v-model="expence.comment" placeholder="Коментар"></el-input>
      </el-form-item>

      <el-form-item size="medium">
        <el-button
          type="danger"
          @click.prevent="removeItem(index)"
          :disabled="
            expenceForm.expences.length === 1 || isClientCategoriesEditing
          "
        >
          Видалити
        </el-button>
      </el-form-item>
    </div>

    <el-form-item size="medium">
      <el-button
        @click="addItem"
        :disabled="loading || isClientCategoriesEditing"
      >
        Ще
      </el-button>
    </el-form-item>

    <el-form-item size="medium" v-if="isUserIsOwnerOrLower">
      <el-checkbox
        v-model="expenceForm.isCustomDate"
        label="Внести іншою датою"
      />
    </el-form-item>

    <el-form-item v-if="expenceForm.isCustomDate">
      <InfoPanel
        text="На той випадок якщо вам треба внести гроші іншою датою та часом. Але майте на увазі
      що якщо в вас була здача грошей до моменту внесення, то це також може вплинути на фінансовий
      результат."
      />
    </el-form-item>

    <el-form-item
      label="Дата"
      v-if="expenceForm.isCustomDate"
      prop="customDate"
      :rules="REQUIRED_FIELD_MESSAGE_RULE_OBJECT"
    >
      <el-date-picker
        v-model="expenceForm.customDate"
        :clearable="false"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        :picker-options="datePickerOptions"
        class="margin-right-10"
      />
    </el-form-item>

    <el-form-item
      label="Час"
      v-if="expenceForm.isCustomDate"
      prop="customTime"
      :rules="REQUIRED_FIELD_MESSAGE_RULE_OBJECT"
    >
      <el-time-select
        v-model="expenceForm.customTime"
        :clearable="false"
        :picker-options="{
          start: '00:00',
          step: '00:30',
          end: '23:30',
        }"
        format="HH:mm"
      />
    </el-form-item>

    <ExpencesCategoriesContainer
      @closeForm="onCloseCategoriesForm"
      v-if="isClientCategoriesEditing"
    />

    <el-form-item>
      <el-button
        type="primary"
        :disabled="loading || isClientCategoriesEditing"
        @click="submitForm()"
      >
        Зберегти
      </el-button>

      <el-button @click="resetForm()" :disabled="isClientCategoriesEditing"
        >Скасувати</el-button
      >

      <el-link
        type="primary"
        :icon="!isClientCategoriesEditing ? 'el-icon-setting' : 'el-icon-close'"
        @click="isClientCategoriesEditing = !isClientCategoriesEditing"
        class="el-form-item__content--right"
      >
        Категорії
      </el-link>
    </el-form-item>
  </el-form>
</template>

<script>
  import { db } from '@/plugins/firebase'
  import { mapGetters } from 'vuex'
  import firebase from 'firebase/app'
  import 'firebase/firestore'

  import { constants } from '@/utils/constants'

  import expenceService from '@/services/expence-service'
  import historyService from '@/services/history-service'
  import financesReportService from '@/services/finances-reports-service'

  import {
    getSortingValueNumberFromDate,
    sortArrayByNumber,
    stringTimeToArray,
    dateTimeFormat,
    dateToHoursMinutesSecondsMillsecondsToNull,
  } from '@/utils/functions'

  import {
    LEGACY_EXPENCES_CATEGORIES,
    EXPENCES_TYPES_KEYS,
  } from '@/utils/expences'

  import { isUserIsOwnerOrLower } from '@/utils/users'

  import { HISTORY_TYPES } from '@/utils/history'

  import { expencesCategoriesBindMixin } from '@/components/Finances/FinancesFlow/FinancesExpence/expences-categories-bind-mixin'

  import ExpencesCategoriesContainer from '@/components/Finances/FinancesFlow/FinancesExpence/ExpencesCategories/ExpencesCategoriesContainer'
  import NumberInput from '@/components/Common/Forms/NumberInput'
  import InfoPanel from '@/components/Common/InfoPanel'

  export default {
    data() {
      return {
        labelWidth: '140px',
        expenceForm: {
          isCustomDate: false,
          customDate: '',
          customTime: '',
          expences: [
            {
              key: 1,
              moneySource: 'cash',
              category: '',
              price: 10,
              comment: '',
            },
          ],
        },
        countExpenceItems: 0,
        isClientCategoriesEditing: false,
        datePickerOptions: {
          firstDayOfWeek: 1,
          disabledDate(date) {
            const today = dateToHoursMinutesSecondsMillsecondsToNull(new Date())
            const yesterday = new Date(today)

            yesterday.setDate(yesterday.getDate() - 2)

            return date < yesterday || date > today
          },
        },
        REQUIRED_FIELD_MESSAGE_RULE_OBJECT:
          constants.requiredFieldMessageRuleObject,
        LEGACY_EXPENCES_CATEGORIES,
      }
    },
    mixins: [expencesCategoriesBindMixin],
    components: {
      NumberInput,
      ExpencesCategoriesContainer,
      InfoPanel,
    },
    computed: {
      ...mapGetters([
        'loading',
        'user',
        'userSalon',
        'masterById',
        'expencesCategoryById',
        'expencesActiveCategories',
        'isExpencesCategoriesBinded',
        'expencesCategoriesActiveChildrenArray',
      ]),
      currentUser() {
        return this.masterById(this.user.id)
      },
      isUserIsOwnerOrLower() {
        return isUserIsOwnerOrLower(this.currentUser)
      },
      rootCategories() {
        return Object.keys(EXPENCES_TYPES_KEYS).map((key) =>
          key.toLocaleLowerCase()
        )
      },
      materialExpenceCategoryId() {
        return this.userSalon.materialExpenceCategory
      },
      goodsExpenceCategoryId() {
        return this.userSalon.goodsExpenceCategory
      },
      numerOfActiveCategories() {
        return this.expencesActiveCategories.length
      },
      mostUsedTenCategories() {
        return sortArrayByNumber(
          this.expencesActiveCategories
            .filter(
              (category) =>
                category.usingCount > 0 &&
                ![
                  this.materialExpenceCategoryId,
                  this.goodsExpenceCategoryId,
                ].includes(category.id)
            )
            .splice(0, 10),
          'usingCount',
          'desc'
        )
      },
      categorizedCategories() {
        return this.expencesCategoriesActiveChildrenArray(false)
      },
      materialExpenceCategoryName() {
        if (!this.isExpencesCategoriesBinded) return null

        return (
          this.expencesCategoryById(this.materialExpenceCategoryId)?.name ||
          null
        )
      },
      goodsExpenceCategoryName() {
        if (!this.isExpencesCategoriesBinded) return null

        return (
          this.expencesCategoryById(this.goodsExpenceCategoryId)?.name || null
        )
      },
    },
    mounted() {
      const now = new Date()
      this.expenceForm.customDate = dateTimeFormat(now, 'Y-Mo-D')
      this.expenceForm.customTime = dateTimeFormat(now, 'H:Mi')
    },
    methods: {
      addItem() {
        const expencesArr = this.expenceForm.expences
        const lastKey = expencesArr[expencesArr.length - 1].key

        const dialog = document.querySelector(
          'body > .el-dialog__wrapper .el-dialog'
        )
        const scrolledHeight = this.$refs.expenceForm.$el.clientHeight

        this.expenceForm.expences.push({
          key: lastKey + 1,
          category: '',
          moneySource: 'cash',
          price: 10,
          comment: '',
        })

        this.$nextTick(() => {
          dialog.scrollTo({
            behaviour: 'smooth',
            top: scrolledHeight,
          })
        })
      },
      removeItem(index) {
        if (index === 0 && this.expenceForm.expences.length === 1) {
          return
        }

        this.expenceForm.expences.splice(index, 1)
      },
      async submitForm() {
        this.$refs.expenceForm.validate(async (valid) => {
          if (valid) {
            this.$store.dispatch('setLoading', true)

            const batch = db.batch()
            const user = this.masterById(this.user.id)

            let sortingValue = getSortingValueNumberFromDate()

            if (this.expenceForm.isCustomDate) {
              const customDate = new Date(this.expenceForm.customDate)
              const customTimeArray = stringTimeToArray(
                this.expenceForm.customTime
              )
              customDate.setHours(customTimeArray[0])
              customDate.setMinutes(customTimeArray[1])

              sortingValue = getSortingValueNumberFromDate(customDate)
            }

            for (const expence of this.expenceForm.expences) {
              const expenceData = {
                category: expence.category,
                value: expence.price,
                createdBy: user.userId,
                createdAt: Date(),
                sortingValue,
                moneySource: expence.moneySource,
                ...(expence.comment && { comment: expence.comment }),
              }

              expenceService.addExpence({
                batch,
                expenceData: {
                  ...expenceData,
                  value: expence.price,
                },
              })

              if (
                expence.category &&
                !this.rootCategories.includes(expence.category)
              ) {
                expenceService.updateCategory({
                  batch,
                  categoryId: expence.category,
                  categoryData: {
                    usingCount: firebase.firestore.FieldValue.increment(1),
                  },
                })
              }

              historyService.addHistory({
                batch,
                currentUser: user,
                now: new Date(),
                type: HISTORY_TYPES.ADD_EXPENCE,
                additionalData: {
                  category: expence.category,
                  value: expence.price,
                },
              })
            }

            const expenceReportCategories = this.expenceForm.expences.map(
              (item) => {
                return { categoryId: item.category, value: item.price }
              }
            )

            await financesReportService.updateFinanceReport({
              batch,
              sortingValue: Number(
                String(sortingValue).slice(0, String(sortingValue).length - 6)
              ),
              reportData: {
                type: 'expence',
                expenceCategories: expenceReportCategories,
              },
            })

            batch
              .commit()
              .then(() => this.resetForm())
              .catch((error) => this.$store.dispatch('setError', error.message))
              .finally(() => this.$store.dispatch('setLoading', false))
          }
        })
      },
      resetForm() {
        this.expenceForm = {
          isCustomDate: false,
          customDate: '',
          customTime: '',
          expences: [
            {
              key: 1,
              category: '',
              moneySource: 'cash',
              price: 10,
              comment: '',
            },
          ],
        }

        this.isClientCategoriesEditing = false

        this.$emit('closeAddingExpence')
      },
      onCloseCategoriesForm() {
        this.isClientCategoriesEditing = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .expence-form-item {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $border-color;

    &__label {
      text-align: right;
      padding-top: 7px;
      padding-right: 15px;
    }
  }
</style>
