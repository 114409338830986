import Vue from 'vue'
import fb from 'firebase/app'

import App from './App.vue'
import router from './router/index'
import store from './store/index'

import * as Sentry from '@sentry/vue'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://f25fb67e56d8440685cbd83e1b365268@o244059.ingest.sentry.io/5222001',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          // 'localhost',
          /^https:\/\/app\.bossy\.pro/,
          /^https:\/\/api\.bossy\.pro/,
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })
}

/* Plugins */
import './plugins/firebase'
import './plugins/element-ui'
import './plugins/font-awesome'

import './global.scss'
import './registerServiceWorker'

Vue.config.productionTip = false

window.appVersion = '3.4.7'

let app

fb.auth().onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
      async beforeMount() {
        // Clear reload listener and reload timer on main page
        clearTimeout(window.reloadTimer)
        window.reloadLink.removeEventListener('click', window.reloadFunction)

        delete window.reloadLink
        delete window.reloadSection
        delete window.reloadTimer
        delete window.reloadFunction

        if (user && user.uid) {
          this.$store.dispatch('setLoading', true)

          this.$store
            .dispatch('autoLoginUser', user)
            .catch(() => {
              this.$store.dispatch('setLoading', false)
            })
            .finally(() => this.$store.dispatch('setLoading', false))
        }
      },
    }).$mount('#app')
  }
})
