import { db } from '@/plugins/firebase'
import store from '@/store/index.js'

import { sortObjectByKey } from '@/utils/functions'

const STUDIES_COLLECTION_KEY = 'studies'

const studiesService = {
  getStudy: async ({ studyId }) => {
    await store.dispatch('setLoading', true)

    let event = {}

    await db
      .collection(STUDIES_COLLECTION_KEY)
      .doc(studyId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          event = doc.data()
        }
      })
      .catch((error) => store.dispatch('setError', error.message))
      .finally(() => store.dispatch('setLoading', false))

    return event
  },

  getStudies: async () => {
    await store.dispatch('setLoading', true)

    let studies = []

    await db
      .collection(STUDIES_COLLECTION_KEY)
      .where('salonId', '==', store.getters.userSalonId)
      .orderBy('sortingValue', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          const study = doc.data()

          let models

          if (study.models) {
            models = sortObjectByKey(study.models)
          }

          studies.push({
            ...study,
            models,
          })
        })
      })
      .catch((error) => store.dispatch('setError', error.message))
      .finally(() => store.dispatch('setLoading', false))

    return studies
  },

  addStudy: ({ batch, studyData }) => {
    const studyRef = db.collection(STUDIES_COLLECTION_KEY).doc()
    const studyRefId = studyRef.id

    batch.set(studyRef, {
      id: studyRefId,
      salonId: store.getters.userSalonId,
      ...(studyData && { ...studyData }),
    })

    return studyRefId
  },

  updateStudy: ({ batch, studyId, studyData }) => {
    const studyRef = db.collection(STUDIES_COLLECTION_KEY).doc(studyId)

    batch.set(
      studyRef,
      {
        salonId: store.getters.userSalonId,
        ...(studyData && { ...studyData }),
      },
      { merge: true }
    )
  },

  deleteStudy: ({ batch, studyId }) => {
    const studyRef = db.collection(STUDIES_COLLECTION_KEY).doc(studyId)

    batch.delete(studyRef)
  },
}

export default studiesService
