<template>
  <span class="check-site-version" v-show="withVersion">
    /
    <el-link @click="openVersionHistoryMessage" v-if="versionData">
      {{ appVersion }}
    </el-link>

    <span v-if="!versionData">&nbsp;{{ appVersion }}</span>

    <el-link @click="openUpdateMessage" v-if="waitAfterCancel">
      <Icons icon="sync-alt" />
    </el-link>
  </span>
</template>

<script>
  import { db } from '@/plugins/firebase'
  import { mapGetters } from 'vuex'
  import { parseTextarea } from '@/utils/functions'

  import siteService from '@/services/site-service'
  import masterService from '@/services/master-service'

  import Icons from '@/components/Common/Icons/Icons'

  export default {
    data() {
      return {
        waitAfterCancel: false,
        timer: null,
        versionInfo: {},
      }
    },
    components: {
      Icons,
    },
    computed: {
      ...mapGetters(['siteSettings', 'user', 'masterById']),
      dBversion() {
        return this.siteSettings.version
      },
      appVersion() {
        return window.appVersion
      },
      currentUser() {
        return this.masterById(this.user.id)
      },
      versionData() {
        return this.versionInfo.versionData
          ? parseTextarea(this.versionInfo.versionData)
          : ''
      },
    },
    props: {
      withVersion: {
        default: true,
        type: Boolean,
      },
    },
    watch: {
      dBversion: {
        handler(newVersion) {
          if (!newVersion && this.waitAfterCancel) return

          this.checkUserVersion(newVersion)
        },
        immediate: true,
      },
    },
    methods: {
      openVersionHistoryMessage(isNeedToUpdateUser = false) {
        if (!this.versionData) return

        this.$alert(this.versionData, `Оновлення ${this.appVersion}`, {
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'Ок',
          lockScroll: true,
          closeOnClickModal: true,
          type: 'info',
          customClass: 'version-history',
        })
          .then(async () => {
            if (isNeedToUpdateUser) {
              const batch = db.batch()

              masterService.updateMaster({
                batch,
                masterId: this.currentUser.email,
                masterData: {
                  sawVersionInfo: this.appVersion,
                },
              })

              await batch.commit()
            }
          })
          .catch(() => {})
      },
      openUpdateMessage() {
        this.waitAfterCancel = false

        this.$confirm(
          'Версія, яку ви використовуєте застаріла, вам необхідно оновитися. Для цього натисніть кнопку Оновити. Або відкладіть оновлення на 5 хвилин.',
          'Оновлення',
          {
            confirmButtonText: 'Оновити',
            cancelButtonText: 'Відкласти',
            type: 'info',
          }
        )
          .then(() => {
            if ('serviceWorker' in navigator) {
              navigator.serviceWorker
                .getRegistrations()
                .then(function (registrations) {
                  for (let registration of registrations) {
                    registration.update()
                  }
                })
            }
          })
          .then(() =>
            this.$message({
              type: 'success',
              message: 'Оновлено',
            })
          )
          .then(() => {
            window.setTimeout(() => {
              document.location.reload()
            }, 3000)
          })
          .catch(() => {
            this.waitAfterCancel = true
            clearTimeout(this.timer)

            this.timer = window.setTimeout(() => {
              this.openUpdateMessage()
            }, 300000)
          })
      },
      isVersionAreGreater(versionGreater, versionSmaller) {
        const versionGreaterArray = versionGreater.split('.')
        const versionSmallerArray = versionSmaller.split('.')

        let ifSomeItemAreGreater = false

        if (versionGreaterArray.length > versionSmallerArray.length) {
          const arrayDiff =
            versionGreaterArray.length - versionSmallerArray.length

          for (let i = 0; i < arrayDiff; i++) {
            versionSmallerArray.push(0)
          }
        }

        versionGreaterArray.map((number, index) => {
          if (+number > +versionSmallerArray[index] && !ifSomeItemAreGreater) {
            ifSomeItemAreGreater = true
          }
        })

        return ifSomeItemAreGreater
      },
      checkUserVersion(dBVersion) {
        if (!dBVersion) return

        const isVersionAreGreater = this.isVersionAreGreater(
          dBVersion,
          this.appVersion
        )

        if (isVersionAreGreater) {
          this.openUpdateMessage()
        }
      },
    },
    async mounted() {
      this.checkUserVersion(this.dBversion)

      this.versionInfo = await siteService.getVersionHistory({
        versionNumber: this.appVersion,
      })

      let isNeedToShowVersionHistory = false

      if (this.currentUser?.sawVersionInfo) {
        isNeedToShowVersionHistory = this.isVersionAreGreater(
          this.appVersion,
          this.currentUser.sawVersionInfo
        )
      } else {
        isNeedToShowVersionHistory = true
      }

      if (isNeedToShowVersionHistory) {
        this.openVersionHistoryMessage(true)
      }
    },
    beforeDestroy() {
      clearTimeout(this.timer)
    },
  }
</script>

<style lang="scss" scoped>
  .check-site-version {
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    margin-left: 5px;

    .el-link {
      margin-left: 5px;
      font-size: inherit;
      line-height: 1;
    }
  }
</style>

<style lang="scss">
  .version-history {
    width: 80%;
  }
</style>
