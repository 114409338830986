<template>
  <div class="event-procedure">
    <div class="event-procedure--primary">
      - {{ procedure.procedureName }} /
      <span class="small">{{ masterName }}</span>
    </div>

    <div
      class="event-procedure--secondary"
      v-if="procedure.additionalFields"
      :class="{
        'one-line': Object.keys(procedure.additionalFields).length < 5,
      }"
    >
      <div
        v-for="(field, index) in getAdditionalFields(
          procedure.additionalFields
        )"
        :key="index"
        class="event-procedure--secondary-item"
      >
        <span class="small">{{ field.name }}: </span>
        <span v-if="typeof field.value === 'object'">{{
          field.value.join(', ')
        }}</span>
        <span v-if="typeof field.value !== 'object'">{{ field.value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {}
    },
    props: {
      procedure: Object,
    },
    computed: {
      ...mapGetters(['masterById']),
      masterName() {
        return this.masterById(this.procedure.masterId).name
      },
    },
    components: {},
    methods: {
      getAdditionalFields(fields) {
        return Object.fromEntries(Object.entries(fields).sort())
      },
    },
  }
</script>

<style lang="scss" scoped>
  .event-procedure {
    padding-top: 5px;
  }
  .event-procedure--secondary {
    &:not(:empty) {
      padding: 2px 0 5px 9px;
      border-bottom: 1px solid $border-color;
    }

    &.one-line {
      display: flex;
      justify-content: stretch;
    }
  }

  .event-procedure--secondary-item {
    color: $gray;
    margin-top: 3px;

    & + div {
      margin-top: 1px;
    }

    span {
      color: $text-color;
    }

    .one-line & {
      margin: 0;
      flex: 1;

      & + div {
        padding-left: 10px;
      }

      span {
        display: block;
      }
    }
  }
</style>
