<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
  import * as Sentry from '@sentry/browser'

  import DefaultLayout from './layout/Default.vue'
  import EmptyLayout from './layout/Empty.vue'

  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {}
    },
    components: {
      DefaultLayout,
      EmptyLayout,
    },
    methods: {
      closeError() {
        this.$store.dispatch('clearError')
      },
      closeSuccess() {
        this.$store.dispatch('clearSuccess')
      },
    },
    computed: {
      layout() {
        return (this.$route.meta.layout || 'empty') + '-layout'
      },
      ...mapGetters(['error', 'success']),
    },
    mounted() {
      if (Sentry) {
        Sentry.configureScope(function (scope) {
          scope.setTag('appVersion', window.appVersion)
        })
      }
    },
  }
</script>
