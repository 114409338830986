<template>
  <div>
    <el-form-item label="До сплати" class="salon-master-admin-parts">
      <CurrencyView
        :value="price + productsPrice"
        class="salon-master-admin-parts__main-price"
      />

      <template slot="productsPart">
        <span v-if="productsPrice" class="salon-master-admin-parts__item">
          З цьго за товари:

          <CurrencyView :value="productsPrice" />
        </span>
      </template>

      <span class="salon-master-admin-parts__item">
        Салону:

        <CurrencyView :value="salonPart" />
      </span>

      <span class="salon-master-admin-parts__item" v-if="masterPart">
        Майстрам:

        <CurrencyView :value="masterPart" />
      </span>

      <span class="salon-master-admin-parts__item" v-if="isAdminPartPossible">
        <el-checkbox
          v-model="isAdminPartEnabledLocal"
          @change="countPrices('isAdminPartEnabled')"
        >
          Адміністратору:

          <CurrencyView
            :value="adminPart"
            :class="{ crossed: !isAdminPartEnabledLocal }"
          />&nbsp;
          <CurrencyView value="0" v-if="!isAdminPartEnabledLocal" />
        </el-checkbox>
      </span>

      <span class="salon-master-admin-parts__item" v-if="isClientPartPossible">
        <el-checkbox
          v-model="isClientPartEnabledLocal"
          @change="countPrices('isClientPartEnabled')"
        >
          Клієнту через 2 тижні

          <CurrencyView
            :value="clientPart"
            :class="{ crossed: !isClientPartEnabledLocal }"
            sign="B"
          />&nbsp;
          <CurrencyView value="0" sign="B" v-if="!isClientPartEnabledLocal" />
        </el-checkbox>
      </span>
    </el-form-item>

    <el-form-item class="margin-0">
      <InfoPanel
        text="Частина майстра більше ніж частина салону."
        class="margin-bottom-15"
        v-if="masterPart > salonPart"
      />
    </el-form-item>

    <el-form-item>
      <div class="small">
        <HorizontalGraph
          :height="15"
          :salonPart="salonPart"
          :masterPart="masterPart"
          :adminPart="isAdminPartEnabledLocal ? adminPart : 0"
          :clientPart="isClientPartEnabledLocal ? clientPart : 0"
        />
      </div>
    </el-form-item>
  </div>
</template>

<script>
  import CurrencyView from '@/components/Common/CurrencyView'
  import HorizontalGraph from '@/components/Common/HorizontalGraph'
  import InfoPanel from '@/components/Common/InfoPanel'

  export default {
    data() {
      return {
        isAdminPartEnabledLocal: false,
        isClientPartEnabledLocal: false,
      }
    },
    components: {
      CurrencyView,
      HorizontalGraph,
      InfoPanel,
    },
    props: {
      price: Number,
      productsPrice: Number,
      salonPart: Number,
      masterPart: Number,
      adminPart: Number,
      clientPart: Number,
      isAdminPartPossible: Boolean,
      isAdminPartEnabled: Boolean,
      isClientPartPossible: Boolean,
      isClientPartEnabled: Boolean,
    },
    watch: {
      isAdminPartEnabled: {
        immediate: true,
        async handler(value) {
          this.isAdminPartEnabledLocal = value
        },
      },
      isClientPartEnabled: {
        immediate: true,
        async handler(value) {
          this.isClientPartEnabledLocal = value
        },
      },
    },
    methods: {
      countPrices(typeOfChanges) {
        const localProp = `${typeOfChanges}Local`

        this.$emit('triggerParts', {
          value: this[localProp],
          type: typeOfChanges,
        })

        this.$nextTick(() => this.$emit('countPrices'))
      },
    },
  }
</script>

<style lang="scss" scoped>
  .salon-master-admin-parts {
    &::v-deep {
      .el-form-item__content {
        line-height: 1.4em;
        padding-top: 16px;
      }
    }
  }

  .salon-master-admin-parts__main-price {
    font-size: 2rem;
  }

  .salon-master-admin-parts__item {
    display: block;

    .crossed {
      text-decoration: line-through;
    }
  }
</style>
