<template>
  <li class="client-cashback-item">
    <div class="client-cashback-item__description">
      <span v-if="cashbackItem.reasonKey && reason">{{ reason }}</span>

      <span v-else-if="cashbackItem.reason">
        + бали за {{ cashbackItem.reason }}
      </span>

      <span v-if="cashbackItem.eventName">
        <span v-if="cashbackItem.referralEarn">
          Бали за запрошення клієнта / процедура
        </span>

        {{ cashbackItem.eventName }}
      </span>

      <span class="italic" v-if="cashbackItem.note">
        / {{ cashbackItem.note }}</span
      >

      <span> / {{ validFrom }} - {{ validTill }} </span>
    </div>

    <span v-if="cashbackItem.eventId">
      &nbsp;/ <EventContainer :eventId="cashbackItem.eventId"
    /></span>

    <div class="client-cashback-item__value">
      <CurrencyView :value="cashbackItem.value" sign="B" />

      <span
        v-if="
          cashbackItem.originValue !== cashbackItem.value &&
          cashbackItem.value !== 0
        "
      >
        / <CurrencyView :value="cashbackItem.originValue" sign="B"
      /></span>

      <div
        v-if="
          cashbackItem.notValidYet &&
          isUserIsAdminOrLower &&
          loyalityDoubleIsEnabled &&
          (isDayIsNotMoreThanFiveDaysAgo || isUserIsOwnerOrLower) &&
          cashbackItem.value !== 0
        "
        class="cashback-double"
      >
        <el-button
          size="extra-mini"
          type="primary"
          title="Увеличить баллы в 2 раза"
          @click="doubleCashback(cashbackItem)"
          :disabled="cashbackItem.isReward || cashbackItem.referralEarn"
        >
          <small>x</small>2
        </el-button>
      </div>
    </div>
  </li>
</template>

<script>
  import { mapGetters } from 'vuex'

  import {
    diffDays,
    getDateStringFromSortingValueNumber,
  } from '@/utils/functions'

  import { CASHBACK_REASONS } from '@/utils/expences'

  import { isUserIsAdminOrLower, isUserIsOwnerOrLower } from '@/utils/users'

  import CurrencyView from '@/components/Common/CurrencyView'
  import EventContainer from '@/components/Event/EventContainer'

  export default {
    data() {
      return {}
    },
    props: {
      cashbackItem: Object,
    },
    computed: {
      ...mapGetters(['loading', 'user', 'masterById', 'userSalon']),
      loyalityDoubleIsEnabled() {
        return this.userSalon.loyalityDouble
      },
      currentUser() {
        return this.masterById(this.user.id)
      },
      isUserIsAdminOrLower() {
        return isUserIsAdminOrLower(this.currentUser)
      },
      isUserIsOwnerOrLower() {
        return isUserIsOwnerOrLower(this.currentUser)
      },
      validFrom() {
        return getDateStringFromSortingValueNumber(this.cashbackItem.validFrom)
      },
      validTill() {
        return getDateStringFromSortingValueNumber(this.cashbackItem.validTill)
      },
      reason() {
        return CASHBACK_REASONS[this.cashbackItem.reasonKey]
      },
      isDayIsNotMoreThanFiveDaysAgo() {
        return (
          Math.abs(
            diffDays(new Date(this.cashbackItem.sortingValue), new Date())
          ) > 4
        )
      },
    },
    components: {
      CurrencyView,
      EventContainer,
    },
    methods: {
      doubleCashback(cashItem) {
        this.$emit('doubleCashback', cashItem)
      },
    },
    mounted() {},
  }
</script>

<style lang="scss" scoped>
  .client-cashback-item {
    &__value {
      margin-left: auto;
      flex-shrink: 0;
      display: flex;

      .cashback-double {
        margin-left: 3px;
        color: $green;

        small {
          font-size: 70%;
        }
      }
    }
  }
</style>
