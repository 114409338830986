<template>
  <div class="cource-model">
    <div class="cource-model--content">
      <div class="cource-model--model margin-bottom-5">
        <ClientView :client="model" />
      </div>

      <strong>
        {{ model.procedure }}
      </strong>

      <CurrencyView :value="model.price" />

      <div v-if="model.note" class="margin-top-5">{{ model.note }}</div>
    </div>

    <div class="cource-model--additional">
      <div class="cource-model--actions">
        <el-dropdown trigger="click" @command="handleCommand">
          <el-button><Icons icon="ellipsis-v" /></el-button>

          <el-dropdown-menu slot="dropdown" class="dropdown-menu">
            <el-dropdown-item command="edit" :disabled="model.finished"
              ><Icons icon="pen" />Редагувати</el-dropdown-item
            >

            <el-dropdown-item command="remove" :disabled="model.finished"
              ><Icons icon="trash" />Видалити</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
  import Icons from '@/components/Common/Icons/Icons'
  import CurrencyView from '@/components/Common/CurrencyView'
  import ClientView from '@/components/Common/Clients/ClientView'

  export default {
    data() {
      return {
        addingDay: '',
        courceModelDialogVisible: false,
      }
    },
    props: {
      model: Object,
      eventForm: Object,
    },
    computed: {
      procedures() {
        return this.eventForm.cource.procedures.split(',')
      },
    },
    components: {
      Icons,
      ClientView,
      CurrencyView,
    },
    methods: {
      handleCommand(command) {
        if (command === 'edit') {
          this.editItem()
        }

        if (command === 'remove') {
          this.removeItem()
        }
      },
      editItem() {
        this.$emit('editItem', this.model)
      },
      removeItem() {
        this.$emit('removeItem', this.model)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .cource-model {
    height: 100%;
    border-radius: $border-radius;
    background: $yellow;
    font-size: 12px;
    line-height: 1.2;
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
    color: $white;
    display: flex;
    align-items: flex-start;
  }

  .cource-model--content {
    margin-right: auto;
    padding: 7px;
    min-width: 1px;
    flex: 1 1 auto;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;

    strong {
      display: block;
    }
  }

  .cource-model--additional {
    display: flex;
    align-items: center;
    padding: 7px 4px 7px 0;
    flex: 0 0 20px;
  }

  .cource-model--actions {
    .el-button {
      background: none;
      border: none !important;
      padding: 5px;
    }
  }

  .dropdown-menu {
    padding: 10px;

    .el-dropdown-menu__item {
      padding: 5px 2px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &:hover {
        background: none;
      }

      svg {
        margin-right: 5px;
        min-width: 16px;
      }
    }
  }
</style>
