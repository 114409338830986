<template>
  <div
    class="client-info"
    :id="clientObj.id"
    :class="{ 'only-icon': onlyIcon }"
    v-if="clientObj && clientObj.id"
  >
    <el-button
      @click="clientView(clientObj.clientId || clientObj.id)"
      :disabled="loading || loadingLocal"
      type="primary"
      :icon="loading || loadingLocal ? 'el-icon-loading' : 'el-icon-user-solid'"
      size="mini"
      circle
    />

    <span v-if="!onlyIcon" class="client-info--client">
      <el-skeleton
        :rows="1"
        animated
        v-if="(loading || loadingLocal) && !(clientObj && clientObj.phone)"
        class="client-info--loading"
      />

      <span v-if="!(loading || loadingLocal) && clientObj && clientObj.phone">
        {{ clientObj.name }} /
        <span class="client-info--name"
          ><ClientPhone :clientPhone="clientObj.phone"
        /></span>
      </span>
    </span>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { EventBus } from '@/bus.js'

  import { getClientFromSqlById } from '@/utils/clients'

  import ClientPhone from '@/components/Common/Clients/ClientPhone'

  export default {
    data() {
      return {
        clientObj: {},
        loadingLocal: true,
      }
    },
    computed: {
      ...mapGetters([
        'loading',
        'user',
        'masterById',
        'userSalon',
        'localClientById',
      ]),
      currentUser() {
        return this.masterById(this.user.id)
      },
    },
    watch: {
      client: {
        immediate: true,
        deep: false,
        handler(clientObj) {
          if (!clientObj) return

          this.loadingLocal = true

          this.clientObj = clientObj

          this.loadingLocal = false
        },
      },
      clientId: {
        immediate: true,
        deep: false,
        async handler(id) {
          if (!id) return

          this.loadingLocal = true

          const localClient = this.localClientById(id)

          if (localClient?.id && localClient?.name) {
            this.clientObj = localClient
          } else {
            try {
              this.clientObj = await getClientFromSqlById(id)
            } catch (error) {
              this.$store.dispatch('setError', error.message)
            }
          }

          this.loadingLocal = false

          this.$emit('clientData', this.clientObj)
        },
      },
    },
    props: {
      client: Object,
      clientId: String,
      onlyIcon: Boolean,
    },
    components: {
      ClientPhone,
    },
    methods: {
      clientView(clientId) {
        EventBus.$emit('viewClient', clientId)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .client-info {
    display: flex;
    align-items: center;
    min-width: 150px;

    &.only-icon {
      min-width: inherit;
    }
    .el-button {
      margin-right: 5px;
    }
  }

  .client-info--client {
    text-align: left;
    flex: 1;
  }

  .client-info--name {
    display: inline-block;
  }

  .client-info--loading {
    &::v-deep {
      .el-skeleton {
        width: 100%;
        height: 16px;
      }
    }
  }
</style>
