var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"margin-bottom-15"},[_c('el-form-item',{attrs:{"label":_vm.eventForm.students.length === 0 || _vm.eventForm.students.length === 1
        ? 'Учень'
        : 'Учні',"prop":"students","rules":{
      required: true,
      message: 'Учень обов\'язковий',
      trigger: 'blur',
    }}},[_c('div',{staticClass:"student-list"},_vm._l((_vm.eventForm.students),function(student){return _c('div',{key:student.id},[_c('CourceStudentItem',{attrs:{"student":student,"isClientAdding":_vm.isClientAdding,"courcePrice":_vm.eventForm.cource?.price || 10},on:{"changePrice":_vm.onChangePrice,"removeStudent":_vm.onRemoveStudent}})],1)}),0),(_vm.isAlreadyHere)?_c('div',[_vm._v("Такий учень уже є. Виберіть іншого.")]):_vm._e(),_c('el-button',{staticClass:"student-add",attrs:{"disabled":_vm.addingItem ||
        _vm.isClientAdding ||
        _vm.loading ||
        Object.keys(_vm.eventForm.cource).length === 0,"type":"primary"},on:{"click":_vm.addStudent}},[_vm._v(" Обрати "+_vm._s(_vm.eventForm.students.length > 0 ? 'ще одного' : '')+" учня ")]),(_vm.addingItem)?_c('el-button',{on:{"click":_vm.onCancelChoosingClient}},[_vm._v(" Скасувати ")]):_vm._e()],1),(_vm.addingItem)?_c('div',[_c('ClientFormItem',{attrs:{"isClientAdding":_vm.isClientAdding,"title":"Вибір учня","prop":"student"},on:{"changeClientAdding":_vm.onChangeClientAdding,"choosedClient":_vm.onClientsChoose}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }