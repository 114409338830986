var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"el-input",class:{
    'el-input--suffix': _vm.suffix !== '',
    'el-input--prefix': _vm.prefix !== '',
    'el-input--small': _vm.size === 'small',
    'el-input--mini': _vm.size === 'mini',
    'is-disabled': _vm.disabled,
  }},[(_vm.prefix !== '')?_c('span',{staticClass:"el-input__prefix"},[_c('span',{staticClass:"el-input__prefix-inner"},[(typeof _vm.prefix !== 'string' && _vm.prefix.icon)?_c('span',[_c('Icons',{attrs:{"icon":_vm.prefix.icon}})],1):_c('span',[_vm._v(_vm._s(_vm.prefix))])])]):_vm._e(),_c('input',{staticClass:"el-input__inner",class:{
      'is-error': _vm.localError || _vm.error,
      controls: _vm.controls,
    },attrs:{"type":"text","autocomplete":"off","inputmode":"numeric","pattern":"[0-9]+([\\.,][0-9]+)?","disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"focus":function($event){return _vm.focusHandler($event)},"keypress":function($event){return _vm.isNumber($event)},"input":function($event){return _vm.inputHandler($event)},"change":function($event){return _vm.changeHandler($event)},"blur":function($event){return _vm.blurHandler($event)}}}),(_vm.controls)?_c('span',{staticClass:"el-input__controls"},[_c('el-button',{staticClass:"minus",attrs:{"icon":"el-icon-minus","disabled":_vm.value <= _vm.min || _vm.disabled},on:{"click":_vm.minus}}),_c('el-button',{staticClass:"plus",attrs:{"icon":"el-icon-plus","disabled":_vm.value >= _vm.max || _vm.disabled},on:{"click":_vm.plus}})],1):_vm._e(),(_vm.suffix !== '' || _vm.getSuffix !== '')?_c('span',{staticClass:"el-input__suffix",class:_vm.controls ? 'with-controls' : ''},[_c('span',{staticClass:"el-input__suffix-inner"},[_vm._v(" "+_vm._s(_vm.suffix || _vm.getSuffix)+" ")])]):_vm._e(),(_vm.description !== '' && !_vm.localError && !_vm.error)?_c('span',{staticClass:"el-input__description"},[_c('span',{staticClass:"el-input__description-inner"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]):_vm._e(),(!_vm.error)?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.localError)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }