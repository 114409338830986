<template>
  <el-form
    ref="salesForm"
    :model="salesForm"
    :label-width="labelWidth"
    class="income-form"
  >
    <el-form-item label="Метод" size="small">
      <el-radio-group
        v-model="salesForm.paymentMethod"
        :disabled="isCategoryAdding"
      >
        <el-radio-button label="cash">Наличными</el-radio-button>
        <el-radio-button label="card">Картой</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="Процент мастеру" prop="masters" size="small">
      <el-switch v-model="salesForm.masterIncome"></el-switch>
    </el-form-item>

    <el-form-item
      label="Мастер"
      prop="masters"
      size="small"
      v-if="salesForm.masterIncome"
    >
      <el-radio-group
        v-model="salesForm.master"
        v-if="mastersActive"
        :disabled="isCategoryAdding"
      >
        <el-radio-button
          :label="master.userId"
          v-for="master in mastersActive"
          :key="master.userId"
          >{{ master.name }}</el-radio-button
        >
      </el-radio-group>
    </el-form-item>

    <div
      v-for="(sale, index) in salesForm.sales"
      :key="sale.key"
      class="income-form-item"
    >
      <el-form-item
        label="Категория"
        size="small"
        :prop="'sales.' + index + '.category'"
        :rules="REQUIRED_FIELD_MESSAGE_RULE_OBJECT"
      >
        <el-radio-group
          v-model="sale.category"
          :disabled="isCategoryAdding"
          v-if="salesActiveCategories && salesActiveCategories.length > 0"
        >
          <el-radio-button
            v-for="category in salesActiveCategories"
            :key="category.id"
            :label="category.id"
            >{{ category.name }}</el-radio-button
          >
        </el-radio-group>

        <div v-else>Добавьте первую категорию</div>
      </el-form-item>

      <el-form-item
        label="Цена"
        size="small"
        :prop="'sales.' + index + '.price'"
        :rules="REQUIRED_FIELD_MESSAGE_RULE_OBJECT"
      >
        <NumberInput
          v-model="sale.price"
          :min="10"
          suffixDefault="money"
          :disabled="isCategoryAdding"
        />
      </el-form-item>

      <el-form-item
        label="Мастеру"
        size="small"
        :prop="'sales.' + index + '.masterPercents'"
        v-if="salesForm.masterIncome"
      >
        <el-radio-group
          v-model="sale.masterPercents"
          :disabled="isCategoryAdding"
        >
          <el-radio-button
            v-for="(percent, index) in masterPercentsOptions"
            :key="index"
            :label="percent"
            >{{ percent }}%</el-radio-button
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item
        label="Коментарий"
        size="small"
        :prop="'sales.' + index + '.comment'"
        :rules="REQUIRED_FIELD_MESSAGE_RULE_OBJECT"
        class="width-100"
      >
        <el-input
          v-model="sale.comment"
          placeholder="Коментарий *"
          :disabled="isCategoryAdding"
        ></el-input>
      </el-form-item>

      <el-form-item label="Итого" size="small" class="width-100 total">
        <div v-if="salesForm.masterIncome">
          Мастеру
          <CurrencyView
            :value="getMasterPart(sale) - getAdminPartFromMaster(sale)"
          />
        </div>
        <div>
          Салону
          <CurrencyView
            :value="getSalonPart(sale) - getAdminPartFromSalon(sale)"
          />
        </div>

        <div v-if="adminId">
          <el-checkbox v-model="giveToAdminHisPart">
            Администратору
            <span v-if="giveToAdminHisPart"> ({{ DEFAULTPERCENT * 100 }}%)</span
            >:
            <CurrencyView
              :value="
                getAdminPartFromSalon(sale) + getAdminPartFromMaster(sale)
              "
            />
          </el-checkbox>
        </div>
      </el-form-item>

      <el-form-item size="small">
        <el-button
          type="danger"
          @click.prevent="removeIncomeItem(index)"
          :disabled="salesForm.sales.length === 1 || isCategoryAdding"
          >Удалить</el-button
        >
      </el-form-item>
    </div>

    <el-form-item v-show="isCategoryAdding">
      <FinancesIncomeCategoriesForm
        @closeIncomeCategoriesForm="isCategoryAdding = false"
      />
    </el-form-item>

    <el-form-item size="small">
      <el-button @click="addIncomeItem" :disabled="isCategoryAdding"
        >Еще</el-button
      >
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        :disabled="isCategoryAdding || loading"
        @click="submitForm()"
        >Сохранить</el-button
      >
      <el-button :disabled="isCategoryAdding" @click="resetForm()"
        >Закрыть</el-button
      >

      <el-link
        type="primary"
        :disabled="isCategoryAdding"
        icon="el-icon-plus"
        @click="isCategoryAdding = true"
        class="el-form-item__content--right"
        >Категорию</el-link
      >
    </el-form-item>
  </el-form>
</template>

<script>
  import { db } from '@/plugins/firebase'
  import { mapGetters } from 'vuex'

  import { getSortingValueNumberFromDate } from '@/utils/functions'
  import { constants } from '@/utils/constants'

  import { HISTORY_TYPES } from '@/utils/history'
  import { PAYMENT_METHODS } from '@/utils/moneySource'

  import incomeService from '@/services/income-service'
  import historyService from '@/services/history-service'
  import masterService from '@/services/master-service'

  import CurrencyView from '@/components/Common/CurrencyView'
  import NumberInput from '@/components/Common/Forms/NumberInput'
  import FinancesIncomeCategoriesForm from '@/components/Finances/FinancesFlow/FinancesIncome/FinancesIncomeCategoriesForm'

  const DEFAULTPERCENT = 0.01

  const DEFAULT_PAYMENT_PARTS = {
    [PAYMENT_METHODS.CASH]: 0,
    [PAYMENT_METHODS.CARD]: 0,
  }

  export default {
    data() {
      return {
        labelWidth: '140px',
        salesForm: {
          name: '',
          masterIncome: true,
          master: '',
          paymentMethod: 'cash',
          sales: [
            {
              key: 1,
              category: '',
              price: 10,
              masterPercents: 10,
              comment: '',
            },
          ],
        },
        masterPercentsOptions: [0, 10, 15, 20, 25, 30],
        isCategoryAdding: false,
        giveToAdminHisPart: true,
        REQUIRED_FIELD_MESSAGE_RULE_OBJECT:
          constants.requiredFieldMessageRuleObject,
        DEFAULTPERCENT,
      }
    },
    components: {
      NumberInput,
      CurrencyView,
      FinancesIncomeCategoriesForm,
    },
    props: {
      adminId: String,
    },
    computed: {
      ...mapGetters([
        'loading',
        'user',
        'mastersActive',
        'masterById',
        'salesActiveCategories',
      ]),
    },
    methods: {
      getAdminPart(fromWhom) {
        return this.giveToAdminHisPart
          ? Number((fromWhom * DEFAULTPERCENT).toFixed(0))
          : 0
      },
      getAdminPartFromSalon(value) {
        return this.getAdminPart(this.getSalonPart(value))
      },
      getAdminPartFromMaster(value) {
        return this.getAdminPart(this.getMasterPart(value))
      },
      getSalonPart(incomeItem) {
        return incomeItem.price - this.getMasterPart(incomeItem)
      },
      getMasterPart(incomeItem) {
        return this.salesForm.masterIncome
          ? ((incomeItem.price * incomeItem.masterPercents) / 100).toFixed(0)
          : 0
      },
      async submitForm() {
        this.$refs.salesForm.validate(async (valid) => {
          if (valid) {
            this.$store.dispatch('setLoading', true)

            const batch = db.batch()
            const currentUser = this.masterById(this.user.id)
            const sortingValue = getSortingValueNumberFromDate()
            const now = Date()

            for (let sale of this.salesForm.sales) {
              let masterPart = 0

              if (this.salesForm.masterIncome && sale.masterPercents) {
                masterPart =
                  this.getMasterPart(sale) -
                  this.getAdminPart(this.getMasterPart(sale))
              }

              const salonPart =
                this.getSalonPart(sale) -
                this.getAdminPart(this.getSalonPart(sale))
              const adminPart =
                this.getAdminPart(this.getMasterPart(sale)) +
                this.getAdminPart(this.getSalonPart(sale))

              // Add income entry
              await incomeService.addIncome({
                batch,
                now,
                currentUser,
                incomeData: {
                  type: 'sale',
                  sortingValue,
                  value: Number(sale.price),
                  paymentMethod: this.salesForm.paymentMethod,
                  paymentParts: {
                    ...JSON.parse(JSON.stringify(DEFAULT_PAYMENT_PARTS)),
                    [this.salesForm.paymentMethod]: Number(sale.price),
                  },
                  moneyParts: {
                    masterPart,
                    salonPart,
                    adminPart,
                    clientPart: 0, // No client part for product sell
                  },
                  ...(sale.comment && {
                    note: sale.comment,
                  }),
                  masterId: this.salesForm.master,
                  data: {
                    categoryId: sale.category,

                    ...(adminPart &&
                      this.adminId && {
                        adminId: this.adminId,
                      }),
                  },
                },
                reportData: {
                  type: 'sale',
                  value: Number(sale.price),
                  sale: {
                    categoryId: sale.category,
                  },
                  ...(this.salesForm.master && {
                    masterId: this.salesForm.master,
                    masterPart,
                  }),
                },
              })

              if (this.salesForm.master) {
                masterService.addPartnerMasterAsUsed({
                  batch,
                  masterIds: [this.salesForm.master],
                })
              }

              // Add history
              historyService.addHistory({
                batch,
                currentUser,
                now: new Date(),
                type: HISTORY_TYPES.SELL,
              })
            }

            batch
              .commit()
              .then(() => {
                this.$message.success('Готово')
                this.resetForm(true)
              })
              .catch((error) => this.$store.dispatch('setError', error.message))
              .finally(() => this.$store.dispatch('setLoading', false))
          }
        })
      },
      resetForm(isNeedToUpdate = false) {
        this.$refs.salesForm.resetFields()
        this.salesForm.sales = [
          {
            key: 1,
            category: '',
            price: 10,
            masterPercents: 10,
            comment: '',
          },
        ]

        this.giveToAdminHisPart = true

        this.$emit('closeAddingIncome', isNeedToUpdate)
      },
      addIncomeItem() {
        let saleArr = this.salesForm.sales
        let lastKey = saleArr[saleArr.length - 1].key

        this.salesForm.sales.push({
          key: lastKey + 1,
          category: '',
          price: 10,
          masterPercents: 10,
          comment: '',
        })
      },
      removeIncomeItem(index) {
        if (index === 0 && this.salesForm.sales.length === 1) {
          return
        }

        this.salesForm.sales.splice(index, 1)
      },
    },
    mounted() {
      this.salesForm.master = this.masterById(this.user.id).userId
      this.giveToAdminHisPart = this.adminId ? true : false
    },
  }
</script>

<style lang="scss" scoped>
  .income-form-item {
    margin-bottom: 15px;

    &__label {
      text-align: right;
      padding-top: 7px;
      padding-right: 15px;
    }
  }

  .el-form-item.total {
    &::v-deep .el-form-item__content {
      line-height: 1.5;
      margin-top: 6px;
    }
  }
</style>
