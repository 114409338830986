<template>
  <div />
</template>

<script>
  import { mapGetters } from 'vuex'
  import { runFlow } from '@/utils/sendpulse'

  import { parseTextarea } from '@/utils/functions'

  export default {
    computed: {
      ...mapGetters(['userSalon']),
      message() {
        return this.parseMessage(this.userSalon.eventNote)
      },
      messageForShow() {
        return parseTextarea(this.message)
      },
      isSalonWithBot() {
        return this.userSalon.services?.includes('bot')
      },
      formattedDate() {
        const dateArr = this.eventDate.split('-')

        return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`
      },
    },
    props: {
      clientName: String,
      eventDate: String,
      eventStartTime: String,
    },
    methods: {
      parseMessage(message) {
        return message
          .replace('%%NAME%%', this.clientName)
          .replace('%%DATE%%', this.formattedDate)
          .replace('%%TIMESTART%%', this.eventStartTime)
      },
      async showEventMessage({ client, isNewEvent }) {
        let isNeedToShowNativeMessage = true

        if (
          this.isSalonWithBot &&
          client.botContactId &&
          this.userSalon.botData.eventCreationOrUpdatingId
        ) {
          const startDateTime = new Date(
            `${this.eventDate} ${this.eventStartTime}`
          )

          const startDateTimeIso = startDateTime
            .toISOString()
            .split('.')[0]
            .replace(/-/g, '')
            .replace(/:/g, '')

          const result = await runFlow({
            flowId: this.userSalon.botData.eventCreationOrUpdatingId,
            contactId: client.botContactId,
            salonId: this.userSalon.id,
            data: {
              eventDate: this.formattedDate,
              eventTime: this.eventStartTime,
              eventDateTimeCalendar: `${startDateTimeIso}Z`
                .replace(/-/g, '')
                .replace(/:/g, ''),
              ...(isNewEvent && { isNewEvent: 'true' }),
            },
          })

          if (result.success) {
            isNeedToShowNativeMessage = false

            this.$message.info('Повідомлення клієнту в бот надіслано')
          }
        }

        if (isNeedToShowNativeMessage) {
          this.$alert(this.messageForShow, 'Повідомлення клієнту', {
            confirmButtonText: 'Копіювати та закрити',
            dangerouslyUseHTMLString: true,
            customClass: 'event-message',
            callback: () => {
              window.navigator.clipboard.writeText(this.message)

              this.$message({
                type: 'success',
                message: 'Повідомлення скопійовано',
              })
            },
          })
        }
      },
    },
  }
</script>
