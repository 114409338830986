import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

export default {
  state: {
    schedules: [],
  },
  mutations: {},
  actions: {
    bindSchedules: firestoreAction((context, payload) => {
      context.unbindFirestoreRef('schedules')

      return context.bindFirestoreRef(
        'schedules',
        db
          .collection('schedules')
          .where('salonId', '==', context.getters.userSalonId)
          .where('sortingValue', '>=', payload.sortingValue)
          .orderBy('sortingValue', 'asc')
      )
    }),

    unbindSchedules: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('schedules')
    }),

    addSchedule: firestoreAction((context, payload) => {
      payload = Object.assign(
        {
          salonId: context.getters.userSalonId,
        },
        payload
      )

      return db
        .collection('schedules')
        .add(payload)
        .then(function (docRef) {
          db.collection('schedules')
            .doc(docRef.id)
            .set({ id: docRef.id }, { merge: true })
        })
    }),

    updateSchedule: firestoreAction((context, payload) => {
      return db
        .collection('schedules')
        .doc(payload.id)
        .set(payload, { merge: true })
    }),

    deleteSchedule: firestoreAction((context, payload) => {
      return db.collection('schedules').doc(payload.id).delete()
    }),
  },
  getters: {
    schedules: (state) => state.schedules,
    scheduleById: (state) => (id) =>
      state.schedules.find((event) => event.id === id),
    schedulesByMaster: (state) => (masterId) => {
      return state.schedules.filter(
        (event) => event.raw.master.userId === masterId
      )
    },
    scheduleByDate: (state) => (date) => {
      const scheduleOnThisDate = state.schedules.filter(
        (event) => event.date === date
      )

      return scheduleOnThisDate[0] || null
    },
  },
}
