<template>
  <div>
    <el-form-item :label="title || 'Клієнт'" :prop="prop" :rules="rules">
      <div
        v-if="preClientReferralClient"
        class="line-height-1-5 padding-bottom-10"
      >
        <i>Клієнт в боті вказав номер клєінта</i>:
        <strong>{{ preClientReferralClient }}</strong>
        <div class="small">
          <i
            >Вам необхідно спробувати знайти та обрати його. Якщо такого немає,
            змініть "Звідки дізнався" на "Інше".</i
          >
        </div>
      </div>

      <div
        v-if="oldClient && oldClient.id"
        class="client-info margin-bottom-10"
      >
        Попередній обраний клієнт:

        <div class="client-info__main">
          <ClientView :clientId="oldClient.id" class="margin-right-10" />
        </div>
      </div>

      <div class="client-choose flex" v-if="!choosedClient.id">
        <el-input
          placeholder="Введіть ім'я чи номер"
          v-model="searchClientString"
          ref="clientChoose"
          class="width-100 margin-right-10"
          @keyup.enter.native="searchClients"
          @blur="isBlured = true"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            type="primary"
            class="icon-button"
            @click="searchClients"
            :loading="localLoading || loading"
            :disabled="
              searchClientString.length < minimalCharsForSearch ||
              searchClientString.length > maximalCharsForSearch
            "
          />
        </el-input>

        <el-button
          icon="el-icon-plus"
          @click="addClient"
          :loading="localLoading || loading"
          type="success"
          title="Додати нового клієнта"
          class="icon-button"
        />
      </div>

      <InfoPanel
        :text="`Мінімальна довжина запроса - ${minimalCharsForSearch} символів`"
        class="margin-top-10"
        v-if="
          isBlured &&
          searchClientString.length &&
          searchClientString.length < minimalCharsForSearch
        "
      />

      <InfoPanel
        :text="`Максимальна довжина запроса - ${maximalCharsForSearch} символів`"
        class="margin-top-10"
        v-if="
          searchClientString.length &&
          searchClientString.length > maximalCharsForSearch
        "
      />

      <el-scrollbar
        wrap-style="max-height: 165px;"
        v-loading="localLoading"
        class="client-suggestions-list"
        v-if="!choosedClient.id"
      >
        <div v-if="results && results.length > 0">
          <div
            v-for="client in results"
            :key="client.id"
            class="client-suggestions-item"
          >
            <el-button type="text" @click="chooseClient(client)">
              {{ client.phone }} / {{ client.name }}
            </el-button>
          </div>
        </div>

        <div v-if="!isSearched" class="client-suggestions-not-found">
          Введіть номер або ім'я та натисніть пошук
        </div>

        <div
          v-if="isSearched && localLoading"
          class="client-suggestions-not-found"
        >
          Поиск
        </div>

        <div
          v-if="results.length === 0 && isSearched && !localLoading"
          class="client-suggestions-not-found"
        >
          Клієнтів не знайдено
        </div>
      </el-scrollbar>

      <div v-if="choosedClient && choosedClient.id" class="client-info">
        <div class="client-info__main flex flex--vertical-center">
          <ClientView
            :clientId="choosedClient.id"
            class="margin-right-10"
            v-if="!isJustCreatedClient"
          />

          <ClientView
            :client="choosedClient"
            class="margin-right-10"
            v-if="isJustCreatedClient"
          />

          <el-popconfirm
            confirm-button-text="Так"
            cancel-button-text="Ні, скасувати"
            icon="el-icon-info"
            title="Шукати іншого клієнта?"
            @confirm="searchOtherClient"
          >
            <el-button
              icon="el-icon-refresh"
              size="mini"
              type="warning"
              slot="reference"
              :loading="localLoading || loading"
            />
          </el-popconfirm>
        </div>

        <div class="client-info__additional">
          <div v-if="choosedClient.note">
            Позначка: <i>{{ choosedClient.note }}</i>
          </div>

          <div v-if="choosedClient.tough" class="color-warning">
            Важкий клієнт: <i>{{ choosedClient.toughNote }}</i>
          </div>
        </div>
      </div>
    </el-form-item>

    <el-form-item v-show="isClientAdding">
      <ClientForm
        @closeNewClient="onCloseNewClient"
        :dialogVisibility="isClientAdding"
        :dialogWindow="true"
        ref="clientForm"
      />
    </el-form-item>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import {
    getClientFromFirebaseByPhone,
    searchClientsFromSQLNotFull,
  } from '@/utils/clients'

  import { sanitizeString } from '@/utils/functions'

  import { isUserIsAdminOrLower } from '@/utils/users'

  import { constants } from '@/utils/constants'

  import ClientView from '@/components/Common/Clients/ClientView'
  import ClientForm from '@/components/Clients/ClientForm'
  import InfoPanel from '@/components/Common/InfoPanel'

  export default {
    data() {
      return {
        searchClientString: '',
        results: [],
        isSearched: false,
        oldClient: {},
        choosedClient: {},
        choosedClientPhone: '',
        localLoading: false,
        isJustCreatedClient: false,
        isBlured: false,
        maximalCharsForSearch: constants.maximalCharsForSearch,
      }
    },
    watch: {
      clientPhone: {
        async handler(phone) {
          if (phone) {
            this.choosedClientPhone = phone

            try {
              this.choosedClient = await getClientFromFirebaseByPhone(phone)
            } catch (error) {
              this.$store.dispatch('setError', error.message)
            }
          }
        },
        immediate: true,
      },
      client: {
        async handler(clientObj) {
          if (clientObj && clientObj.id) {
            this.choosedClient = clientObj
          }
        },
        immediate: true,
      },
    },
    computed: {
      ...mapGetters(['loading', 'user', 'masterById']),
      currentUser() {
        return this.masterById(this.user.id)
      },
      isUserIsAdminOrLower() {
        return isUserIsAdminOrLower(this.currentUser)
      },
      minimalCharsForSearch() {
        return this.isUserIsAdminOrLower
          ? constants.minimalCharsForSearch
          : constants.minimalCharsForMaster
      },
    },
    props: {
      clientPhone: {
        default: '',
        type: String,
      },
      client: Object,
      title: String,
      isClientAdding: Boolean,
      prop: {
        default: 'client',
        type: String,
      },
      rules: {
        default: null,
        type: Object,
      },
      preClientReferralClient: [String, Number],
    },
    components: {
      ClientForm,
      ClientView,
      InfoPanel,
    },
    methods: {
      searchOtherClient() {
        this.oldClient = this.choosedClient
        this.choosedClient = {}
        this.choosedClientPhone = ''
        this.searchClientString = ''
        this.isSearched = false
        this.isJustCreatedClient = false

        this.$nextTick(function () {
          if (this.$refs.clientChoose && this.$refs.clientChoose.focus) {
            this.$refs.clientChoose.focus()
          }
        })
      },
      async searchClients() {
        if (
          !this.searchClientString.length ||
          this.searchClientString.length < this.minimalCharsForSearch ||
          this.searchClientString.length > this.maximalCharsForSearch
        ) {
          return
        }

        this.results = []
        this.isSearched = true

        this.localLoading = true

        try {
          const result = await searchClientsFromSQLNotFull({
            queryString: sanitizeString(this.searchClientString),
          })

          this.results = result || []
        } catch (error) {
          if (error.code === 'auth/network-request-failed') {
            this.$store.dispatch(
              'setError',
              "Проблема зі з'єднанням. Спробуйте ще раз."
            )
            this.results = []
          }
        } finally {
          this.localLoading = false
        }
      },
      chooseClient(client) {
        this.choosedClient = client
        this.oldClient = {}

        this.$emit('choosedClient', client)
      },
      clearClientSelect() {
        this.searchOtherClient()
      },
      addClient() {
        this.isJustCreatedClient = false
        this.$emit('changeClientAdding', true)

        this.choosedClient = {}

        this.$emit('choosedClient', {})
      },
      onCloseNewClient(client) {
        if (client) {
          this.isJustCreatedClient = true
          this.choosedClient = client
          this.choosedClientPhone = client.phone

          this.$emit('choosedClient', client)
        }

        this.$emit('changeClientAdding', false)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .client-suggestions-list {
    margin-top: 10px;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    line-height: 1.5;
    min-height: 30px;
  }

  .client-suggestions-item {
    margin-left: 10px;
    margin-right: 10px;

    & + & {
      border-top: 1px solid $border-color;
    }

    .el-button {
      padding: 10px 0;
      width: 100%;
      text-align: left;
      color: $label-color;

      &:hover {
        color: $dark-gray;
      }
    }
  }

  .client-suggestions-not-found {
    padding: 10px;
    background: $light-gray;
  }

  .client-info__main {
    &::v-deep {
      .el-popover__reference-wrapper {
        display: flex;
      }
    }
  }

  .client-info__additional {
    line-height: 1.5;

    > div:first-child {
      padding-top: 10px;
    }
  }

  .color-warning {
    color: $red;
  }
</style>
