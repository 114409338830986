<template>
  <div class="procedure">
    <el-form-item
      class="procedure-form-item margin-bottom-0"
      :label="'Процедура ' + (index + 1)"
    >
      <div class="procedure-header line-height-1-5">
        <div class="margin-right-10 procedure-name">
          {{ localProcedureItem.procedureName }} /
          <CurrencyView :value="localProcedureItem.price" />
        </div>

        <div class="procedure-actions margin-left-auto">
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="small"
            @click="moveUpProcedure"
            :disabled="
              isClientAdding || loading || proceduresCount === 1 || index === 0
            "
            class="margin-left-auto"
          />

          <el-button
            type="primary"
            icon="el-icon-arrow-down"
            size="small"
            @click="moveDownProcedure"
            :disabled="
              isClientAdding ||
              loading ||
              proceduresCount === 1 ||
              index === proceduresCount - 1
            "
          />

          <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="removeProcedure"
            :disabled="isClientAdding || loading || proceduresCount === 1"
          />
        </div>
      </div>
    </el-form-item>

    <el-form-item
      v-if="procedure && (procedure.withCorrection || procedure.withRefresh)"
      class="margin-bottom-0"
    >
      <el-radio-group
        v-model="localProcedureItem.correction"
        :disabled="isClientAdding || loading"
        @change="changeCorrection"
      >
        <el-radio :label="Boolean(false)">Перша</el-radio>
        <el-radio label="correction" v-if="procedure.withCorrection"
          >Корекція</el-radio
        >
        <el-radio label="refresh" v-if="procedure.withRefresh">Рефреш</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item class="margin-bottom-0">
      <div class="line-height-1-5 margin-top-5">
        <div>
          <i class="el-icon-time" />

          {{ getRightDuration }} ({{ localProcedureItem.timeStart }} -
          {{ localProcedureItem.timeEnd }})

          <el-link
            @click="editDuration = true"
            type="primary"
            class="edit-duration-link"
            v-if="!editDuration"
            >Змінити тривалість</el-link
          >
        </div>

        <div
          v-if="
            localProcedureItem.duration !== procedureDuration && !editDuration
          "
          class="margin-top-5"
        >
          Базова тривалість: {{ getDuration(procedureDuration) }}<br />
          Змінена тривалість: {{ getDuration(localProcedureItem.duration) }}
          <span v-if="localProcedureItem.isOvertimed" class="color-warning"
            ><i class="el-icon-warning" /> Процедура з овертаймом
          </span>

          <el-link
            @click="cancelDuration"
            type="danger"
            class="edit-duration-link"
            :disabled="localProcedureItem.isOvertimed"
            >Скасувати зміни</el-link
          >
        </div>

        <div v-if="editDuration" class="margin-top-5">
          <NumberInput
            v-model="localProcedureItem.duration"
            :disabled="isClientAdding"
            :max="360"
            :min="10"
            suffix="хв"
            description="Тривалість у хвилинах"
            class="margin-right-10"
            toTens
            controls
          />

          <el-button
            type="success"
            icon="el-icon-check"
            @click="changeDuration"
            :disabled="isClientAdding || loading"
          />

          <el-button
            type="danger"
            icon="el-icon-close"
            @click="editDuration = false"
            :disabled="isClientAdding || loading"
          />
        </div>
      </div>
    </el-form-item>

    <el-form-item>
      <div class="procedure-border" />
    </el-form-item>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { toTens, durationToHoursAndMinutesArray } from '@/utils/functions'

  import NumberInput from '@/components/Common/Forms/NumberInput'
  import CurrencyView from '@/components/Common/CurrencyView'

  export default {
    data() {
      return {
        editDuration: false,
        localProcedureItem: {},
        priceKey: 'price',
        durationKey: 'duration',
      }
    },
    watch: {
      procedureItem: {
        immediate: true,
        deep: false,
        handler(procedure) {
          if (procedure) {
            if (!this.procedureById(procedure.procedureId)) {
              this.removeProcedure()

              return
            }

            this.localProcedureItem = JSON.parse(JSON.stringify(procedure))
          }
        },
      },
    },
    props: {
      procedureItem: Object,
      procedureKey: String,
      isClientAdding: Boolean,
      proceduresCount: Number,
    },
    components: {
      NumberInput,
      CurrencyView,
    },
    computed: {
      ...mapGetters(['loading', 'procedureById']),
      index() {
        const [procedureIndex] = this.procedureKey.split('_')

        return +procedureIndex
      },
      procedure() {
        if (!this.localProcedureItem && !this.localProcedureItem.procedureId)
          return

        return this.procedureById(this.localProcedureItem.procedureId)
      },
      procedureMasterData() {
        if (!this.procedure && !this.procedure.mastersData) return

        return this.procedure.mastersData[this.localProcedureItem.masterId]
      },
      procedureDuration() {
        if (
          !this.procedureMasterData &&
          !this.procedureMasterData[this.durationKey]
        )
          return

        return this.procedureMasterData[this.durationKey]
      },
      getRightDuration() {
        if (!this.localProcedureItem.duration) return '0хв'

        const value = this.localProcedureItem.duration

        const [hours, minutes] = durationToHoursAndMinutesArray(value)

        return `${hours}г ${minutes}хв`
      },
    },
    methods: {
      toTens(value) {
        return toTens(value)
      },
      getDuration(duration) {
        const [hours, minutes] = durationToHoursAndMinutesArray(duration)

        return `${hours}г ${minutes}хв`
      },
      removeProcedure() {
        this.$emit('removeProcedure', {
          procedureKey: this.procedureKey,
        })
      },
      moveUpProcedure() {
        this.$emit('moveProcedure', {
          newIndex: this.index - 1,
          procedureKey: this.procedureKey,
        })
      },
      moveDownProcedure() {
        this.$emit('moveProcedure', {
          newIndex: this.index + 1,
          procedureKey: this.procedureKey,
        })
      },
      changeCorrection() {
        switch (this.localProcedureItem.correction) {
          case 'correction':
            this.priceKey = 'priceCorrection'
            this.durationKey = 'durationCorrection'
            break

          case 'refresh':
            this.priceKey = 'priceRefresh'
            this.durationKey = 'durationRefresh'
            break

          default:
            this.priceKey = 'price'
            this.durationKey = 'duration'
        }

        this.localProcedureItem.price = this.procedureMasterData[this.priceKey]
        this.localProcedureItem.duration =
          this.procedureMasterData[this.durationKey]

        this.sendProcedureItem()
      },
      sendProcedureItem() {
        this.$emit('sendProcedureItem', {
          index: this.index,
          procedureKey: this.procedureKey,
          procedureItem: JSON.parse(JSON.stringify(this.localProcedureItem)),
        })
      },
      changeDuration() {
        this.editDuration = false

        this.sendProcedureItem()
      },
      cancelDuration() {
        this.editDuration = false

        this.localProcedureItem.duration =
          this.procedureMasterData[this.durationKey]

        this.sendProcedureItem()
      },
    },
    beforeDestroy() {
      this.localProcedureItem = null
      this.priceKey = 'price'
      this.durationKey = 'duration'
    },
  }
</script>

<style lang="scss" scoped>
  .procedure-form-item {
    @include to('md') {
      margin-left: -140px;
    }

    &.el-form-item {
      &::v-deep {
        .el-form-item__label {
          min-height: inherit;
          line-height: 32px;
        }
      }
    }
  }

  .procedure-header {
    display: flex;
    align-items: center;
  }
</style>
