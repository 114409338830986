<template>
  <el-select
    v-model="master"
    filterable
    clearable
    placeholder="Выбрать мастера"
    value-key="id"
    @change="chooseMaster"
  >
    <el-option
      v-for="item in mastersActive"
      :key="item.id"
      :label="item.name"
      :value="item"
      @click="chooseMaster(data.item)"
    >
      <div style="display: flex">
        <span style="flex: 1 0 auto">{{ item.name }}</span>
        <span style="flex: 0 0 auto; color: #8492a6; font-size: 13px">{{
          item.id
        }}</span>
      </div>
    </el-option>

    <template slot="empty">
      <div class="not-found">
        <div class="not-found__label">Ничего не найдено</div>
      </div>
    </template>
  </el-select>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {
        master: null,
      }
    },
    props: {
      label: String,
      masterId: String,
    },
    computed: {
      ...mapGetters(['loading', 'mastersActive', 'masterById']),
    },
    watch: {
      masterId: {
        immediate: true,
        deep: false,
        handler(id) {
          if (!id) return

          this.master = this.masterById(id) || null
        },
      },
    },
    methods: {
      chooseMaster(master) {
        if (master) {
          this.$emit('choosingMaster', master)
        } else {
          this.$emit('clearChoosingMaster')
        }
      },
    },
  }
</script>
