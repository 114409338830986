<template>
  <el-form-item label="Час" class="time-in-row" required>
    <div class="time-in-row__time">
      <el-form-item prop="timeStart">
        <el-time-select
          v-model="timeStart"
          :picker-options="{
            start: TIMESTART_FALLBACK,
            step: '00:30',
            end: TIMEEND_FALLBACK,
          }"
          format="HH:mm"
          :disabled="timeStartDisabled"
          placeholder="Час початку"
          @change="sendData"
        />
      </el-form-item>

      <div style="margin: 0 10px">-</div>

      <el-form-item prop="timeEnd">
        <el-time-select
          v-model="timeEnd"
          :picker-options="{
            start: timeStart ? timeStart : TIMESTART_FALLBACK,
            step: '00:30',
            end: TIMEEND_FALLBACK,
            minTime: timeStart ? timeStart : TIMESTART_FALLBACK,
          }"
          format="HH:mm"
          :disabled="timeEndDisabled"
          placeholder="Час закінчення"
          @change="sendData"
        />
      </el-form-item>
    </div>
  </el-form-item>
</template>

<script>
  import { constants } from '@/utils/constants'

  export default {
    data() {
      return {
        timeStart: '',
        timeEnd: '',
        TIMESTART_FALLBACK: constants.timeStartString,
        TIMEEND_FALLBACK: constants.timeEndString,
        TIMESTEP: constants.courcesTimeStep,
      }
    },
    props: {
      timeStartValue: [String, undefined],
      timeEndValue: [String, undefined],
      timeStartDisabled: Boolean,
      timeEndDisabled: Boolean,
    },
    watch: {
      timeStartValue: {
        immediate: true,
        handler(value) {
          if (!value) return

          this.timeStart = value
        },
      },
      timeEndValue: {
        immediate: true,
        handler(value) {
          if (!value) return

          this.timeEnd = value
        },
      },
    },
    methods: {
      sendData() {
        this.$emit('sendData', {
          timeStart: this.timeStart,
          timeEnd: this.timeEnd,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .time-in-row {
    &::v-deep .el-form-item__content {
      display: flex;
      flex-wrap: wrap;
      line-height: 1;

      @media screen and (min-width: 480px) {
        flex-wrap: nowrap;
      }

      .el-form-item {
        margin: 0;
        flex: 1 1 50%;

        &:last-child .el-input {
          margin-right: 0;

          @media screen and (min-width: 480px) {
            margin-right: 10px;
          }
        }

        .el-input {
          max-width: inherit;
        }
      }
    }

    .el-form-item {
      margin-bottom: 0;
    }

    .el-button {
      flex: 1;
      margin-top: 10px;
    }

    &__time {
      display: flex;
      align-items: center;

      .el-form-item {
        margin-bottom: 0 !important;
      }
    }
  }
</style>
