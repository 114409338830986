<template>
  <div class="horizontal-graph">
    <div
      class="horizontal-graph__graphs"
      :style="{ height: height + 'px' }"
      v-if="values && values.length && !isNull"
    >
      <div
        v-for="(value, index) in values"
        :key="index"
        :style="{
          width: getProgressWidth(
            typeof value === 'object' ? value.value : value ? value : 0
          ),
        }"
        class="horizontal-graph__graph horizontal-graph__graph--object"
        :class="[
          {
            'color-danger': value < 0,
          },
        ]"
      >
        {{ typeof value === 'object' ? value.value : value ? value : 0 }}
      </div>
    </div>

    <div
      class="horizontal-graph__graphs"
      :style="{ height: height + 'px' }"
      v-if="!values && !isNull"
    >
      <div
        v-for="(value, index) in valuesData"
        :key="index"
        :style="{
          width: getProgressWidth(value),
        }"
        :class="[
          `${index}-graph`,
          {
            'color-danger': value < 0,
          },
        ]"
        class="horizontal-graph__graph"
      >
        {{ value }}
      </div>
    </div>

    <div
      class="horizontal-graph__graphs empty"
      v-if="isNull"
      :style="{ height: height + 'px' }"
    >
      0
    </div>
  </div>
</template>

<script>
  const valuesDataArray = ['salonPart', 'masterPart', 'adminPart', 'clientPart']

  export default {
    data() {
      return {}
    },
    props: {
      values: Array,
      salonPart: Number,
      masterPart: Number,
      adminPart: Number,
      clientPart: Number,
      height: {
        type: Number,
        default: 20,
      },
      some: String,
    },
    computed: {
      valuesData() {
        if (this.values && this.values.length) return

        const valuesDataObject = {}

        valuesDataArray.forEach((propName) => {
          if (typeof this[propName] !== 'undefined') {
            valuesDataObject[propName] = this[propName]
          }
        })

        return valuesDataObject
      },
      total() {
        if (!this.values || !this.values.length) {
          return Object.keys(this.valuesData).reduce((sum, currentValue) => {
            return sum + Math.abs(this.valuesData[currentValue])
          }, 0)
        } else {
          return this.values.reduce((sum, currentValue) => {
            return (
              sum +
              (typeof currentValue === 'object'
                ? currentValue.value
                : currentValue
                ? Math.abs(currentValue)
                : 0)
            )
          }, 0)
        }
      },
      isNull() {
        return this.total === 0
      },
    },
    methods: {
      getProgressWidth(value) {
        let percent = 50

        if (value || this.total) {
          percent = (Math.abs(value) * 100) / this.total
        }

        return percent + '%'
      },
    },
  }
</script>

<style lang="scss" scoped>
  .horizontal-graph {
    &__graphs {
      height: 20px;
      display: flex;
      color: $white;
      border-radius: $border-radius;
      overflow: hidden;
      background: $gray;

      &.empty {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__graph {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      white-space: nowrap;
      min-width: max-content;
      padding: 5px;
      transition: 250ms width ease-in;

      &[style*=': 0%'] {
        min-width: inherit;
        padding: 0 1px;
        color: transparent;
      }

      &.salonPart-graph,
      &--object:nth-child(1) {
        background: var(--status-color-3);
      }

      &.masterPart-graph,
      &--object:nth-child(2) {
        background: var(--status-color-1);
      }

      &.adminPart-graph &--object:nth-child(3) {
        background: var(--status-color-4);
      }

      &.clientPart-graph,
      &--object:nth-child(4) {
        background: var(--status-color-2);
      }
    }
  }
</style>
