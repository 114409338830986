import { db } from '@/plugins/firebase'
import store from '@/store/index.js'

const WAREHOUSE_HISTORY_COLLECTION_KEY = 'warehouseHistory'

const warehouseService = {
  addHistory: ({ batch, historyData }) => {
    const warehouseRef = db.collection(WAREHOUSE_HISTORY_COLLECTION_KEY).doc()
    const warehouseRefId = warehouseRef.id

    batch.set(warehouseRef, {
      id: warehouseRefId,
      salonId: store.getters.userSalonId,
      ...(historyData && { ...historyData }),
    })

    return warehouseRefId
  },
}

export default warehouseService
