<template>
  <el-form
    ref="orderForm"
    :model="orderForm"
    label-width="140px"
    class="income-form"
    v-if="isSaleAdding"
  >
    <ClientFormItem
      ref="clientFormItem"
      :isClientAdding="isClientAdding"
      @changeClientAdding="onChangeClientAdding"
      @choosedClient="onChoosedClient"
    />

    <WarehouseFormItem
      ref="warehouseFormItem"
      class="margin-bottom-15"
      :isGlobalMasterPercent="orderForm.isGlobalMasterPercent"
      :globalMasterPercent="
        !isMasterPartInCustomValue ? globalMasterPercent : 0
      "
      :masterId="orderForm.master"
      :adminPercent="isAdminPartEnabled ? adminPercent : 0"
      @sendProductsData="onSendProductsData"
    />

    <el-form-item label="Ініціатор продажі" prop="masters" size="small">
      <el-radio-group v-model="orderForm.master" v-if="mastersActive">
        <el-radio-button
          :label="master.userId"
          v-for="master in mastersActive"
          :key="master.userId"
        >
          {{ master.name }}
        </el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-form-item
      label="Відсоток майстру"
      size="small"
      prop="globalMasterPercent"
      v-if="sellsMasterPart && orderForm.master"
    >
      <el-switch
        v-model="orderForm.isGlobalMasterPercent"
        :disabled="loading || finalPrice === 0"
        active-text="Індивідуальний для кожного товару"
        inactive-text="Глобальний"
        :active-value="false"
        :inactive-value="true"
        active-color="#13ce66"
        inactive-color="#13ce66"
        @change="onChangeTypeOfGlobalMasterPercent"
      />

      <FinancesIncomeWarehouseFormPercents
        @change="onPercentsChange"
        :finalPrice="finalPrice"
        class="margin-top-10"
        v-if="orderForm.isGlobalMasterPercent && finalPrice !== 0"
      />
    </el-form-item>

    <SalonMasterAdminPartsItem
      :price="finalPrice"
      :productsPrice="0"
      :salonPart="finalSalonPart"
      :masterPart="finalMasterPart"
      :adminPart="adminPart"
      :isAdminPartPossible="!!adminPercent"
      :isAdminPartEnabled="isAdminPartEnabled"
      :isClientPartPossible="false"
      @triggerParts="onTriggerParts"
    />

    <el-form-item label="Метод" size="small" class="margin-top-15">
      <el-radio-group
        v-model="orderForm.paymentMethod"
        @change="getPaymentParts"
      >
        <el-radio-button :label="PAYMENT_METHODS.CASH">
          <i class="el-icon-money" /> Готівка
        </el-radio-button>

        <el-radio-button :label="PAYMENT_METHODS.CARD">
          <i class="el-icon-bank-card" /> Картка
        </el-radio-button>

        <el-radio-button :label="PAYMENT_METHODS.COMBI">
          <i class="el-icon-coin" /> Комбінований
        </el-radio-button>
      </el-radio-group>
    </el-form-item>

    <CombiCashFormItem
      :value="finalPrice"
      @change="onCombiCashChange"
      v-if="orderForm.paymentMethod === PAYMENT_METHODS.COMBI && finalPrice"
    />

    <el-form-item
      label="Коментар"
      size="small"
      class="width-100"
      prop="note"
      :rules="[
        {
          message:
            'Обов\'язково вкажіть коментар через те, що загальна вартість дорівнює 0',
          required: finalPrice === 0,
          trigger: 'blur',
        },
      ]"
    >
      <el-input
        v-model="orderForm.note"
        placeholder="Коментар"
        type="textarea"
      />
    </el-form-item>

    <el-form-item
      v-if="
        finalPrice === 0 ||
        orderForm.products.length === 0 ||
        (orderForm.paymentMethod === PAYMENT_METHODS.COMBI &&
          !isCombiPartsIsPassed)
      "
    >
      <div class="info-items">
        <InfoPanel
          v-if="orderForm.products.length === 0"
          class="info-items--item"
          text="Додайте продукти до продажу."
        />

        <InfoPanel
          v-if="
            orderForm.paymentMethod === PAYMENT_METHODS.COMBI &&
            !isCombiPartsIsPassed
          "
          class="info-items--item"
          text="Не встановлено частини комбінованого продажу."
        />
      </div>
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        :disabled="
          loading ||
          // orderForm.products.length === 0 ||
          (orderForm.paymentMethod === PAYMENT_METHODS.COMBI &&
            !isCombiPartsIsPassed)
        "
        @click="submitForm"
      >
        Зберегти
      </el-button>

      <el-button :disabled="loading" @click="resetForm">Скасувати</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { db } from '@/plugins/firebase'

  import { mapGetters } from 'vuex'

  import { MASTER_PERCENT_OPTIONS } from './finances-income-config'
  import { PAYMENT_METHODS } from '@/utils/moneySource'

  import productService from '@/services/product-service'
  import masterService from '@/services/master-service'

  import InfoPanel from '@/components/Common/InfoPanel'
  import CombiCashFormItem from '@/components/Common/Forms/CombiCashFormItem'
  import FinancesIncomeWarehouseFormPercents from './FinancesIncomeWarehouseFormPercents'
  import ClientFormItem from '@/components/Common/Forms/ClientFormItem'
  import WarehouseFormItem from '@/components/Common/Forms/WarehouseFormItem'
  import SalonMasterAdminPartsItem from '@/components/Common/Forms/SalonMasterAdminPartsItem'

  const DEFAULT_PAYMENT_PARTS = {
    [PAYMENT_METHODS.CASH]: 0,
    [PAYMENT_METHODS.CARD]: 0,
  }

  export default {
    data() {
      return {
        orderForm: {
          name: '',
          master: '',
          note: '',
          paymentMethod: 'cash',
          paymentParts: JSON.parse(JSON.stringify(DEFAULT_PAYMENT_PARTS)),
          products: [],
          isGlobalMasterPercent: true,
          globalMasterPercentValue: 0,
          globalMasterPercentCustomValue: 1,
          globalMasterCustomValue: 0,
          individualMasterPart: 0,
          clientId: '',
          combiCashValue: 0,
        },
        finalPrice: 0,
        isClientAdding: false,
        isAdminPartEnabled: true,
        isCombiPartsIsPassed: false,
        MASTER_PERCENT_OPTIONS,
        PAYMENT_METHODS,
      }
    },
    components: {
      InfoPanel,
      FinancesIncomeWarehouseFormPercents,
      ClientFormItem,
      WarehouseFormItem,
      SalonMasterAdminPartsItem,
      CombiCashFormItem,
    },
    props: {
      adminId: String,
      isSaleAdding: Boolean,
    },
    computed: {
      ...mapGetters([
        'loading',
        'user',
        'mastersActive',
        'masterById',
        'userSalon',
      ]),
      currentUser() {
        return this.masterById(this.user.id)
      },
      sellsMasterPart() {
        return this.userSalon.sellsMasterPart
      },
      adminUser() {
        if (!this.adminId) return

        return this.masterById(this.adminId)
      },
      adminPercent() {
        if (
          !this.adminUser ||
          !this.adminUser.payType ||
          this.adminUser.payType !== 'income' ||
          !this.adminUser?.percent
        )
          return 0

        return this.adminUser.percent
      },
      adminPart() {
        if (!this.finalPrice) return 0

        return Number(((this.finalPrice * this.adminPercent) / 100).toFixed(0))
      },
      finalPriceWithAdminPart() {
        if (!this.adminPart || !this.isAdminPartEnabled) return this.finalPrice

        return this.finalPrice - this.adminPart
      },
      isMasterPartInPercents() {
        return this.orderForm.globalMasterPercentValue <= 100
      },
      isMasterPartInCustomPercents() {
        return this.orderForm.globalMasterPercentValue === 999
      },
      isMasterPartInCustomValue() {
        return this.orderForm.globalMasterPercentValue === 9999
      },
      globalMasterPercent() {
        if (!this.sellsMasterPart) return 0

        let value = 0

        if (this.orderForm.isGlobalMasterPercent) {
          if (this.isMasterPartInPercents) {
            value = this.orderForm.globalMasterPercentValue
          } else if (this.isMasterPartInCustomPercents) {
            value = this.orderForm.globalMasterPercentCustomValue
          }
        }

        return value
      },
      finalMasterPart() {
        if (!this.sellsMasterPart) return 0

        let value = 0

        if (!this.isMasterPartInCustomValue) {
          value = this.orderForm.isGlobalMasterPercent
            ? Number(
                (
                  (this.finalPriceWithAdminPart * this.globalMasterPercent) /
                  100
                ).toFixed(0)
              )
            : this.orderForm.individualMasterPart
        } else {
          value = this.orderForm.globalMasterCustomValue
        }

        return value
      },
      finalSalonPart() {
        return Number(this.finalPriceWithAdminPart - this.finalMasterPart)
      },
    },
    watch: {
      isSaleAdding: {
        immediate: true,
        deep: false,
        handler(saleAdding) {
          if (saleAdding) {
            this.orderForm.master = this.currentUser.userId
          }
        },
      },
    },
    methods: {
      getPaymentParts() {
        if (this.orderForm.paymentMethod === PAYMENT_METHODS.COMBI) {
          this.orderForm.paymentParts = {
            [PAYMENT_METHODS.CASH]: this.orderForm.combiCashValue,
            [PAYMENT_METHODS.CARD]:
              this.finalPrice - this.orderForm.combiCashValue,
          }
        } else {
          this.orderForm.paymentParts = {
            ...JSON.parse(JSON.stringify(DEFAULT_PAYMENT_PARTS)),
            [this.orderForm.paymentMethod]: this.finalPrice,
          }
        }
      },
      onTriggerParts({ value, type }) {
        this[type] = value
      },
      onChangeTypeOfGlobalMasterPercent() {
        if (this.finalMasterPart) {
          this.orderForm.individualMasterPart = 0

          if (this.orderForm.isGlobalMasterPercent) {
            this.orderForm.globalMasterPercentValue = 0
          }
        }
      },
      onCombiCashChange(value) {
        if (value) {
          this.isCombiPartsIsPassed = true

          this.orderForm.combiCashValue = value

          this.getPaymentParts()
        } else {
          this.isCombiPartsIsPassed = false
        }
      },
      onPercentsChange({ percentValue, percentCustomValue, customValue }) {
        this.orderForm.globalMasterPercentValue = percentValue
        this.orderForm.globalMasterPercentCustomValue = percentCustomValue
        this.orderForm.globalMasterCustomValue = customValue
      },
      onChangeClientAdding(event) {
        this.isClientAdding = event
      },
      onChoosedClient(client) {
        if (!client) return

        this.orderForm.clientId = client.id
        client = {}
      },
      onSendProductsData(productData) {
        this.orderForm.products = productData

        this.orderForm.individualMasterPart = productData.reduce(
          (sum, current) => {
            return sum + current.masterPart
          },
          0
        )

        this.finalPrice = productData.reduce(
          (sum, current) => sum + current.price * current.quantity,
          0
        )

        this.getPaymentParts()
      },
      submitForm() {
        this.$refs.orderForm.validate(async (valid) => {
          if (valid) {
            const batch = db.batch()

            this.$store.dispatch('setLoading', true)

            await productService.addSell({
              batch,
              products: this.orderForm.products,
              paymentMethod: this.orderForm.paymentMethod,
              paymentParts: this.orderForm.paymentParts,
              currentUser: this.currentUser,
              finalPrice: this.finalPrice,
              adminPart: this.adminPart,
              salonPart: this.finalSalonPart,
              masterPart: this.finalMasterPart,
              ...(this.orderForm.master && {
                masterId: this.orderForm.master,
              }),
              ...(this.orderForm.note && {
                note: this.orderForm.note,
              }),
              ...(this.orderForm.clientId && {
                clientId: this.orderForm.clientId,
              }),
              ...(this.adminPart && {
                adminId: this.adminId,
              }),
            })

            if (this.orderForm.master) {
              masterService.addPartnerMasterAsUsed({
                batch,
                masterIds: [this.orderForm.master],
              })
            }

            batch
              .commit()
              .then(() => {
                this.resetForm(true)
                this.$message.success('Готово')
              })
              .catch((error) => this.$store.dispatch('setError', error.message))
              .finally(() => this.$store.dispatch('setLoading', false))
          }
        })
      },
      resetForm(isNeedToUpdate = false) {
        if (this.$refs.clientFormItem) {
          this.$refs.clientFormItem.clearClientSelect()
        }

        if (this.$refs.warehouseFormItem) {
          this.$refs.warehouseFormItem.clearForm()
        }

        this.finalPrice = 0

        this.orderForm = {
          name: '',
          master: '',
          note: '',
          clientId: '',
          paymentMethod: 'cash',
          products: [],
          isGlobalMasterPercent: true,
          globalMasterPercentValue: 0,
          globalMasterPercentCustomValue: 1,
          globalMasterCustomValue: 0,
          individualMasterPart: 0,
        }

        this.$emit('closeAddingIncome', isNeedToUpdate)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .info-items {
    > .info-items--item {
      & + .info-items--item {
        margin-top: 15px;
      }
    }
  }
</style>
