import { Message } from 'element-ui'

export default {
  state: {
    loading: false,
    siteLoading: false,
    firstDataIsLoaded: false,
    success: null,
    error: null,
    bindDate: null,
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload
    },
    setFirstDataIsLoaded(state, payload) {
      state.firstDataIsLoaded = payload
    },
    setSuccess(state, payload) {
      Message.success(payload)

      state.success = payload
    },
    clearSuccess(state) {
      state.success = null
    },
    setError(state, payload) {
      Message.error(payload)

      state.error = payload
    },
    clearError(state) {
      state.error = null
    },
    setBindDate(state, payload) {
      state.bindDate = payload
    },
  },
  actions: {
    setLoading({ commit }, payload) {
      commit('setLoading', payload)
    },
    setFirstDataIsLoaded({ commit }, payload) {
      commit('setFirstDataIsLoaded', payload)
    },
    setSuccess({ commit }, payload) {
      commit('setSuccess', payload)
    },
    clearSuccess({ commit }, payload) {
      commit('clearSuccess', payload)
    },
    setError({ commit, dispatch }, payload) {
      dispatch('clearError')

      commit('setError', payload)

      commit('setLoading', false)
    },
    clearError({ commit }, payload) {
      commit('clearError', payload)
    },
    setBindDate({ commit }, payload) {
      commit('setBindDate', payload)
    },
  },
  getters: {
    loading(state) {
      return state.loading
    },
    firstDataIsLoaded(state) {
      return state.firstDataIsLoaded
    },
    success(state) {
      return state.success
    },
    error(state) {
      return state.error
    },
    bindDate(state) {
      return state.bindDate
    },
  },
}
