<template>
  <el-collapse accordion>
    <el-collapse-item
      v-for="catIdArray in catsSortedArray"
      :key="catIdArray[0]"
      :title="`${catIdArray[1]} (${catsObj[catIdArray[0]].length})`"
      :name="catIdArray[0]"
    >
      <ul>
        <li v-for="eventItem in catsObj[catIdArray[0]]" :key="eventItem.id">
          <ClientEventProcedureItem :event="eventItem" />
        </li>
      </ul>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ClientEventProcedureItem from '@/components/Clients/ClientEvents/ClientEventProcedureItem'

  export default {
    data() {
      return {
        catsObj: {
          other: [],
        },
        catsSortedArray: [],
      }
    },
    props: {
      events: Array,
    },
    computed: {
      ...mapGetters([
        'procedureById',
        'statusById',
        'masterById',
        'procedureCategoryById',
      ]),
    },
    components: {
      ClientEventProcedureItem,
    },
    methods: {
      getProcedureAndCategory(procedureId, event, sortableCats) {
        const procedure = this.procedureById(procedureId)
        const procedureCategory = procedure?.categoryId
          ? this.procedureCategoryById(procedure.categoryId)
          : null

        let whereNeedToPushEvent = 'other'
        let isNoNeedToPushEvent = true

        if (procedure && procedureCategory) {
          if (
            !Object.prototype.hasOwnProperty.call(
              this.catsObj,
              procedure.categoryId
            )
          ) {
            this.catsObj[procedure.categoryId] = []
            sortableCats.push([procedure.categoryId, procedureCategory.name])
          }

          whereNeedToPushEvent = procedure.categoryId
        }

        isNoNeedToPushEvent = this.catsObj[whereNeedToPushEvent].some(
          ({ id }) => id === event.id
        )

        if (!isNoNeedToPushEvent) {
          this.catsObj[whereNeedToPushEvent].push(event)
        }
      },
      getCategoriesFromEvents() {
        let sortableCats = []
        this.catsObj = {
          other: [],
        }

        this.events.map((event) => {
          if (event.procedures) {
            for (let procedureKey in event.procedures) {
              this.getProcedureAndCategory(
                event.procedures[procedureKey].procedureId,
                event,
                sortableCats
              )
            }
          } else {
            this.getProcedureAndCategory(event.procedure, event, sortableCats)
          }
        })

        sortableCats = sortableCats.sort(function (a, b) {
          return a[1].localeCompare(b[1])
        })

        if (this.catsObj.other && this.catsObj.other.length > 0) {
          sortableCats.push(['other', 'Без категорії'])
        }

        this.catsSortedArray = sortableCats
      },
    },
    created() {
      this.getCategoriesFromEvents()
    },
  }
</script>

<style lang="scss" scoped>
  .el-collapse {
    margin-top: 20px;

    &::v-deep {
      .el-collapse-item__content {
        font-size: inherit;
        color: inherit;
        line-height: inherit;
      }
    }
  }

  .client-events__category {
    & + .client-events__category {
      margin-top: 20px;
    }

    &-name {
      font-size: 16px;
    }
  }
</style>
