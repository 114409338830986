import { mapGetters } from 'vuex'

export const productBindMixin = {
  computed: {
    ...mapGetters(['isProductsBinded', 'isProductsLoading']),
  },
  async mounted() {
    if (!this.isProductsBinded && !this.isProductsLoading) {
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('setIsProductsLoading', true)

      await this.$store.dispatch('bindProducts')
      await this.$store.dispatch('bindProductsCategories')

      this.$store.dispatch('setLoading', false)
      this.$store.dispatch('setIsProductsLoading', false)

      this.$store.dispatch('setIsProductsBinded', true)
    }
  },
}
