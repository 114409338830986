<template>
  <div class="sells-list">
    <ClientGoodsListItem
      :sell="sell"
      v-for="sell in sells"
      :key="sell.id"
      :id="sell.id"
      class="sells-item"
    />
  </div>
</template>

<script>
  import { productBindMixin } from '@/components/Warehouse/product-bind-mixin'

  import ClientGoodsListItem from './ClientGoodsListItem.vue'

  export default {
    data() {
      return {}
    },
    props: {
      sells: Array,
    },
    mixins: [productBindMixin],
    components: { ClientGoodsListItem },
    watch: {},
    computed: {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .sells-item {
    & + .sells-item {
      margin-top: 5px;
    }
  }
</style>
