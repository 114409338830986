<template>
  <div>
    <el-radio-group v-model="percentValue" @change="changeHandler">
      <el-radio-button
        v-for="percent in MASTER_PERCENT_OPTIONS"
        :key="percent.id"
        :label="percent.id"
      >
        {{ percent.label }}
      </el-radio-button>
    </el-radio-group>

    <div v-if="percentValue === 999" class="margin-top-10">
      <NumberInput
        v-model="percentCustomValue"
        :min="1"
        :max="100"
        suffix="%"
        @change="changeHandler"
      />
    </div>

    <div v-if="percentValue === 9999" class="margin-top-10">
      <NumberInput
        v-model="customValue"
        :min="1"
        :max="finalPrice"
        suffixDefault="money"
        @change="changeHandler"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { MASTER_PERCENT_OPTIONS } from './finances-income-config'

  import NumberInput from '@/components/Common/Forms/NumberInput'

  export default {
    data() {
      return {
        percentValue: 0,
        percentCustomValue: 0,
        customValue: 0,
        MASTER_PERCENT_OPTIONS,
      }
    },
    components: {
      NumberInput,
    },
    props: {
      finalPrice: Number,
    },
    computed: {
      ...mapGetters(['loading', 'userSalon']),
      sellsMasterPart() {
        return this.userSalon.sellsMasterPart
      },
    },
    watch: {
      isSaleAdding: {
        immediate: true,
        deep: false,
        handler(saleAdding) {
          if (saleAdding) {
            this.orderForm.master = this.currentUser.userId
          }
        },
      },
    },
    methods: {
      changeHandler() {
        this.$emit('change', {
          percentValue: this.percentValue,
          percentCustomValue: this.percentCustomValue,
          customValue: this.customValue,
        })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
