<template>
  <div class="expence-categories margin-bottom-15">
    <el-form-item class="expence-form-categories" label="Редагування категорій">
      <div class="expence-categories--list line-height-1-5">
        <ExpencesCategoriesRoot
          :categorizedCategories="categorizedCategories"
          :isSomeCategoryEditing="isSomeCategoryEditing"
          :rawCategorizedCategories="categorizedCategories"
        />
      </div>

      <ExpencesCategoryForm
        v-if="isCategoryAdding"
        :rawCategorizedCategories="categorizedCategories"
        :category="emptyCategory"
        :isNew="true"
        @categoryEditing="onCategoryEditing"
      />

      <div
        v-if="expencesActiveCategoriesLength === 0"
        class="margin-top-15 line-height-1-5"
      >
        <InfoPanel
          text='Схоже, ви ще не встигли створити категорії, тому
        ви можете створити категорії за замовчуванням, натиснувши кнопку "Додати
        категорії за умовчанням", які можуть підійти під ваші цілі. Будь-коли ви
        зможете видалити їх або додати нові.'
          class="margin-bottom-15"
        />

        <el-button size="small" type="success" @click="fillWithDefaultData"
          >Додати категорії за умовчанням</el-button
        >
      </div>

      <div
        v-if="!materialExpenceCategory && expencesActiveCategoriesLength"
        class="margin-top-15 line-height-1-5"
      >
        <InfoPanel
          text="Схоже, ви ще не встигли вказати категорію, яка буде використовуватися для вказання матеріальних витрат (витрати, які необхідні для виконання послуг). Це можна зробити під час редагування категорій. Якщо не вказати, то буде використовуватись категорія операційних витрат."
          class="margin-bottom-15"
        />
      </div>

      <div
        v-if="!goodsExpenceCategory && expencesActiveCategoriesLength"
        class="margin-top-15 line-height-1-5"
      >
        <InfoPanel
          text="Схоже, ви ще не встигли вказати категорію, яка буде використовуватися для вказання витрат, пов'язаних з закупкою товарів для продажу. Це можна зробити під час редагування категорій. Якщо не вказати, то буде використовуватись категорія операційних витрат."
          class="margin-bottom-15"
        />
      </div>

      <div class="expence-categories--footer margin-top-15">
        <el-button
          type="primary"
          :disabled="loading || isSomeCategoryEditing"
          @click="addNewCategory()"
          size="small"
        >
          Додати нову категорію
        </el-button>

        <el-button
          :disabled="loading || isSomeCategoryEditing"
          size="small"
          @click="closeForm()"
        >
          Закрити форму
        </el-button>
      </div>
    </el-form-item>
  </div>
</template>

<script>
  import { db } from '@/plugins/firebase'

  import { mapGetters } from 'vuex'

  import expenceService from '@/services/expence-service'

  import { EventBus } from '@/bus.js'

  import {
    LEGACY_EXPENCES_CATEGORIES,
    // EXPENCES_TYPES_KEYS,
  } from '@/utils/expences'

  import InfoPanel from '@/components/Common/InfoPanel'

  import ExpencesCategoriesRoot from '@/components/Finances/FinancesFlow/FinancesExpence/ExpencesCategories/ExpencesCategoriesRoot'
  import ExpencesCategoryForm from '@/components/Finances/FinancesFlow/FinancesExpence/ExpencesCategories/ExpencesCategoryForm'

  export default {
    data() {
      return {
        isSomeCategoryEditing: false,
        isCategoryAdding: false,
        emptyCategory: {
          name: '',
          parentCategory: 'other',
        },
      }
    },
    components: {
      InfoPanel,
      ExpencesCategoriesRoot,
      ExpencesCategoryForm,
    },
    computed: {
      ...mapGetters([
        'loading',
        'userSalon',
        'expencesActiveCategories',
        'expencesCategoriesActiveChildrenArray',
      ]),
      expencesActiveCategoriesLength() {
        return this.expencesActiveCategories.length
      },
      categorizedCategories() {
        return this.expencesCategoriesActiveChildrenArray(true)
      },
      materialExpenceCategory() {
        return this.userSalon.materialExpenceCategory
      },
      goodsExpenceCategory() {
        return this.userSalon.goodsExpenceCategory
      },
    },
    watch: {
      categorizedCategories: {
        handler() {
          if (this.isSomeCategoryEditing) {
            this.isSomeCategoryEditing = false
          }
        },
        immediate: true,
      },
    },
    methods: {
      createItem(categories, batch, parentCategory) {
        categories.forEach((category) => {
          const parentCategoryForSet =
            parentCategory === 'type' ? category.type : parentCategory

          const parentCategoryId = expenceService.addCategory({
            batch,
            categoryData: {
              ...{
                name: category.label,
                parentCategory: parentCategoryForSet,
                oldId: category.value,
              },
              active: true,
            },
          })

          if (category.children) {
            this.createItem(category.children, batch, parentCategoryId)
          }
        })
      },
      fillWithDefaultData() {
        const batch = db.batch()

        this.$store.dispatch('setLoading', true)

        this.createItem(LEGACY_EXPENCES_CATEGORIES, batch, 'type')

        batch.commit().then(() => {
          this.$store.dispatch('setLoading', false)
        })
      },
      onCategoryEditing(isEditing) {
        this.isSomeCategoryEditing = isEditing
        this.isCategoryAdding = isEditing
      },
      addNewCategory() {
        this.isSomeCategoryEditing = true
        this.isCategoryAdding = true
      },
      closeForm() {
        this.isSomeCategoryEditing = false
        this.$emit('closeForm')
      },
    },
    mounted() {
      EventBus.$on('someCategoryEditing', (isEditing) => {
        this.isSomeCategoryEditing = isEditing
      })
    },
    beforeDestroy() {
      EventBus.$off('someCategoryEditing')
    },
  }
</script>

<style lang="scss" scoped>
  .expence-form-categories {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    padding: 15px 0 10px;
  }
</style>
