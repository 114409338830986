<template>
  <div class="finances-transaction-add-income">
    <el-button
      type="success"
      icon="el-icon-plus"
      @click="addIncome"
      :size="size"
    >
      Дохід
    </el-button>

    <el-dialog
      :visible.sync="isSaleAdding"
      title="Додати продаж"
      width="60%"
      append-to-body
      @open="getAdmin"
    >
      <div class="el-form margin-bottom-20">
        <div class="el-form-item el-form-item--small">
          <label class="el-form-item__label" style="width: 140px"
            >Тип продажу</label
          >

          <el-radio-group v-model="sellType" size="small">
            <el-radio-button label="warehouse">Зі складу</el-radio-button>
            <el-radio-button label="default">Звичайний</el-radio-button>
          </el-radio-group>
        </div>
      </div>

      <FinancesIncomeDefaultForm
        v-if="sellType === 'default'"
        :adminId="adminId"
        @closeAddingIncome="onCloseAddingIncome"
      />

      <FinancesIncomeWarehouseForm
        v-if="sellType === 'warehouse'"
        :adminId="adminId"
        :isSaleAdding="isSaleAdding"
        @closeAddingIncome="onCloseAddingIncome"
      />
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { dateTimeFormat } from '@/utils/functions'

  import FinancesIncomeDefaultForm from '@/components/Finances/FinancesFlow/FinancesIncome/FinancesIncomeDefaultForm'
  import FinancesIncomeWarehouseForm from '@/components/Finances/FinancesFlow/FinancesIncome/FinancesIncomeWarehouseForm'

  export default {
    data() {
      return {
        sellType: 'warehouse',
        isSaleAdding: false,
        adminId: '',
      }
    },
    props: {
      size: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters(['loading', 'scheduleByDate']),
    },
    components: {
      FinancesIncomeDefaultForm,
      FinancesIncomeWarehouseForm,
    },
    methods: {
      getAdmin() {
        const adminSchedule = this.scheduleByDate(
          dateTimeFormat(new Date(), 'Y-Mo-D')
        )

        if (adminSchedule && adminSchedule.admin.payType !== 'nope') {
          this.adminId = adminSchedule.admin.userId
        }
      },
      addIncome() {
        this.isSaleAdding = true
        this.addLookOnBackButtonEvent()
      },
      onCloseAddingIncome() {
        this.isSaleAdding = false

        this.sellType = 'warehouse'
      },
      addLookOnBackButtonEvent() {
        let self = this

        history.pushState(null, null, null)
        window.addEventListener('popstate', self.lookOnBackButton)
      },
      lookOnBackButton() {
        let self = this

        if (this.isSaleAdding) {
          this.onCloseAddingIncome()
        }

        history.pushState(null, null, null)
        window.removeEventListener('popstate', self.lookOnBackButton)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .finances-transaction-add-income {
    &::v-deep {
      .el-button {
        > span {
          display: none;

          @media screen and (min-width: 480px) {
            display: inline;
          }
        }
      }
    }
  }
</style>
