import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

import { sortArrayByString } from '@/utils/functions'

import { getQuantity } from '@/utils/warehouse'

export default {
  state: {
    products: [],
    isProductsBinded: false,
    isProductsLoading: false,
  },
  mutations: {
    setIsProductsBinded(state, payload) {
      state.isProductsBinded = payload
    },
    setIsProductsLoading(state, payload) {
      state.isProductsLoading = payload
    },
  },
  actions: {
    setIsProductsBinded({ commit }, payload) {
      commit('setIsProductsBinded', payload)
    },

    setIsProductsLoading({ commit }, payload) {
      commit('setIsProductsLoading', payload)
    },

    bindProducts: firestoreAction((context) => {
      const salonId = context.getters.userSalonId

      return context.bindFirestoreRef(
        'products',
        db.collection('products').where('salonId', '==', salonId)
      )
    }),

    unbindProducts: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('products')
    }),

    hideShowProduct: firestoreAction((context, { productId, toHide }) => {
      return db
        .collection('products')
        .doc(productId)
        .set({ hidden: Boolean(toHide) }, { merge: true })
    }),

    removeProduct: firestoreAction((context, productId) => {
      return db
        .collection('products')
        .doc(productId)
        .set({ active: Boolean(false) }, { merge: true })
    }),
  },
  getters: {
    isProductsBinded(state) {
      return state.isProductsBinded
    },
    isProductsLoading(state) {
      return state.isProductsLoading
    },
    products: (state) => state.products,
    productsActive: (state) =>
      state.products.filter((product) => product.active === true),
    productsCategorized:
      (state, getters) =>
      ({
        chosenProductsIds = [],
        withQuantity = true,
        returnId = false,
      } = {}) => {
        let activeProducts = JSON.parse(JSON.stringify(getters.productsActive))

        let products = []

        if (activeProducts && activeProducts.length > 0) {
          activeProducts = sortArrayByString(activeProducts, 'name')

          let categorizedProductsArr = []
          let categorizedProductsObj = {
            other: [],
          }

          activeProducts.map((item) => {
            const quantity = withQuantity
              ? getQuantity(
                  item.id,
                  item.quantity,
                  getters.userSalonId,
                  getters.user.id
                )
              : null

            if (item.categoryId) {
              if (
                !Object.prototype.hasOwnProperty.call(
                  categorizedProductsObj,
                  item.categoryId
                )
              ) {
                categorizedProductsObj[item.categoryId] = []
              }

              categorizedProductsObj[item.categoryId].push({
                value: returnId ? item.id : item,
                id: item.id,
                label: `${item.name} ${withQuantity ? `(${quantity})` : ''}`,
                ...(withQuantity && {
                  disabled: !quantity || chosenProductsIds.includes(item.id),
                }),
              })
            } else {
              if (item) {
                categorizedProductsObj.other.push({
                  value: returnId ? item.id : item,
                  id: item.id,
                  label: `${item.name} ${withQuantity ? `(${quantity})` : ''}`,
                  ...(withQuantity && {
                    disabled: !quantity || chosenProductsIds.includes(item.id),
                  }),
                })
              }
            }
          })

          let tempCategorizedProceduresObj = JSON.parse(
            JSON.stringify(categorizedProductsObj)
          ) // Get rid from other key (it's for other was last array elem)

          delete tempCategorizedProceduresObj.other

          for (let categoryId in tempCategorizedProceduresObj) {
            categorizedProductsArr.push({
              value: categoryId,
              id: categoryId,
              label: getters.productsCategoryById(categoryId).name,
              children: categorizedProductsObj[categoryId],
            })
          }

          if (
            categorizedProductsObj.other &&
            categorizedProductsObj.other.length > 0
          ) {
            categorizedProductsArr.push({
              value: 'other',
              id: 'other',
              label: 'Інші',
              children: categorizedProductsObj.other,
            })
          }

          products = categorizedProductsArr
        }

        return products
      },
    productById: (state) => (id) => {
      if (!state.isProductsBinded) {
        return {}
      }

      return state.products.find((product) => product.id === id)
    },
  },
}
