import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

export default {
  state: {
    notifications: [],
  },
  mutations: {},
  actions: {
    bindNotifications: firestoreAction((context) => {
      context.dispatch('setLoading', true)

      const salonId = context.getters.userSalonId

      return context
        .bindFirestoreRef(
          'notifications',
          db.collection('notifications').where('salonId', '==', salonId)
        )
        .then(function () {
          context.dispatch('setLoading', false)
        })
    }),

    unbindNotifications: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('notifications')
    }),
  },
  getters: {
    notifications: (state) => state.notifications,
    notificationsById: (state) => (id) => {
      return state.notifications.find(
        (notifications) => notifications.id === id
      )
    },
    notificationsByUserId: (state) => (userId) => {
      return state.notifications.filter(
        (notifications) => notifications.userId === userId
      )
    },
  },
}
