<template>
  <span class="check-site-version"> / App: {{ appVersion }}</span>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {}
    },
    components: {},
    computed: {
      ...mapGetters(['siteSettings']),
      dBversion() {
        return this.siteSettings.version
      },
      appVersion() {
        return window.appVersion
      },
    },
    watch: {
      dBversion: {
        handler(newVersion) {
          if (!newVersion || process.env.NODE_ENV === 'development') return

          this.checkVersions(newVersion)
        },
        immediate: true,
      },
    },
    methods: {
      openMessage() {
        this.$alert(
          'Версія, яку ви використовуєте застаріла, вам необхідно оновитися. Для цього натисніть кнопку Оновити.',
          'Версія застаріла',
          {
            confirmButtonText: 'Оновити',
            type: 'info',
            showClose: false,
          }
        )
          .then(() => {
            if ('serviceWorker' in navigator) {
              navigator.serviceWorker
                .getRegistrations()
                .then(function (registrations) {
                  for (let registration of registrations) {
                    registration.update()
                  }
                })
            }
          })
          .then(() =>
            this.$message({
              type: 'success',
              message: 'Оновлено',
            })
          )
          .then(() => {
            window.setTimeout(() => {
              document.location.reload(true)
            }, 3000)
          })
      },
      checkVersions(dBVersion) {
        if (!dBVersion) return

        const dBVersionArray = dBVersion.split('.')
        const appVersionArray = this.appVersion.split('.')
        let ifSomeItemAreGreater = false

        if (dBVersionArray.length > appVersionArray.length) {
          const arrayDiff = dBVersionArray.length - appVersionArray.length

          for (let i = 0; i < arrayDiff; i++) {
            appVersionArray.push(0)
          }
        }

        dBVersionArray.map((number, index) => {
          if (+number > +appVersionArray[index] && !ifSomeItemAreGreater) {
            ifSomeItemAreGreater = true
          }
        })

        if (ifSomeItemAreGreater) {
          this.openMessage()
          this.$emit('disableLogin')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .check-site-version {
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    margin-left: 5px;

    .el-link {
      margin-left: 5px;
      font-size: inherit;
      line-height: 1;
    }
  }
</style>
