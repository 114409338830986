<template>
  <div
    class="el-input"
    :class="{
      'el-input--suffix': suffix !== '',
      'el-input--prefix': prefix !== '',
      'el-input--small': size === 'small',
      'el-input--mini': size === 'mini',
      'is-disabled': disabled,
    }"
  >
    <span class="el-input__prefix" v-if="prefix !== ''">
      <span class="el-input__prefix-inner">
        <span v-if="typeof prefix !== 'string' && prefix.icon"
          ><Icons :icon="prefix.icon"
        /></span>
        <span v-else>{{ prefix }}</span>
      </span>
    </span>

    <input
      type="text"
      autocomplete="off"
      inputmode="numeric"
      pattern="[0-9]+([\.,][0-9]+)?"
      :value="value"
      @focus="focusHandler($event)"
      @keypress="isNumber($event)"
      @input="inputHandler($event)"
      @change="changeHandler($event)"
      @blur="blurHandler($event)"
      class="el-input__inner"
      :class="{
        'is-error': localError || error,
        controls: controls,
      }"
      :disabled="disabled"
    />

    <span class="el-input__controls" v-if="controls">
      <el-button
        @click="minus"
        icon="el-icon-minus"
        class="minus"
        :disabled="value <= min || disabled"
      />
      <el-button
        @click="plus"
        icon="el-icon-plus"
        class="plus"
        :disabled="value >= max || disabled"
      />
    </span>

    <span
      class="el-input__suffix"
      :class="controls ? 'with-controls' : ''"
      v-if="suffix !== '' || getSuffix !== ''"
    >
      <span class="el-input__suffix-inner">
        {{ suffix || getSuffix }}
      </span>
    </span>

    <span
      class="el-input__description"
      v-if="description !== '' && !localError && !error"
    >
      <span class="el-input__description-inner">
        {{ description }}
      </span>
    </span>

    <div class="el-form-item__error" v-if="!error">
      {{ localError }}
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { constants } from '@/utils/constants'
  import { toTens, toFifth } from '@/utils/functions'

  import Icons from '@/components/Common/Icons/Icons'

  export default {
    data() {
      return {
        localError: '',
        touched: false,
        defaultSign: constants.moneySigns[0],
      }
    },
    props: {
      value: Number,
      min: Number,
      max: Number,
      description: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: 'default',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      toTens: {
        type: Boolean,
        default: false,
      },
      toFifths: {
        type: Boolean,
        default: false,
      },
      prefix: {
        type: [String, Object],
        default: '',
      },
      suffix: {
        type: String,
        default: '',
      },
      suffixDefault: {
        type: String,
        default: '',
      },
      validate: {
        type: Boolean,
        default: false,
      },
      controls: {
        type: Boolean,
        default: false,
      },
      error: String,
    },
    components: {
      Icons,
    },
    computed: {
      ...mapGetters(['userSalon']),
      getSuffix() {
        return this.suffixDefault === 'money'
          ? this.userSalon.sign || this.defaultSign
          : ''
      },
    },
    watch: {
      min: {
        immediate: true,
        deep: false,
        handler(minValue) {
          if (minValue) {
            if (+this.value < minValue) {
              this.$emit('input', minValue)
            }
          }
        },
      },
    },
    methods: {
      isNumber(event) {
        event = event ? event : window.event

        const charCode = event.which ? event.which : event.keyCode

        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 44 &&
          charCode !== 46
        ) {
          event.preventDefault()
        } else if (charCode === 13) {
          event.preventDefault()

          this.$emit('input', +event.target.value)
          this.$emit('enterKey')
        } else if (
          // Do not allow to pass decimal again
          (charCode === 44 || charCode === 46) &&
          !Number.isInteger(+event.target.value)
        ) {
          event.preventDefault()
        } else {
          if (this.localError) this.localError = ''

          return true
        }
      },
      plus() {
        let value = +this.value + (this.toTens ? 10 : 1)

        if (this.max && value >= this.max) value = +this.max

        this.$emit('input', value)
        this.$emit('change', value)
      },
      minus() {
        let value = +this.value - (this.toTens ? 10 : 1)

        if (this.min && value <= this.min) value = +this.min

        this.$emit('input', value)
        this.$emit('change', value)
      },
      focusHandler(event) {
        event.target.select()
        this.touched = true

        this.$emit('focus', +event.target.value)
        this.$emit('isInFocus', true)
      },
      inputHandler(event) {
        if (this.localError) this.localError = ''

        if (
          (event.target.value && +event.target.value.replace(',', '.')) ||
          event.target.value === 0
        ) {
          this.$emit('input', +event.target.value.replace(',', '.'))
        }
      },
      changeHandler(event) {
        if (this.localError) this.localError = ''

        let value =
          (event.target.value === 0 ||
            event.target.value === '0' ||
            isNaN(event.target.value.replace(',', '.'))) &&
          !this.min
            ? 0
            : event.target.value
            ? +event.target.value.replace(',', '.')
            : +this.min

        if (this.max !== undefined && value >= this.max) {
          value = +this.max

          event.target.value = value
        }

        if (this.min !== undefined && value <= this.min) {
          value = +this.min

          event.target.value = value
        }

        if (event.target.value === '') {
          event.target.value = +this.min
        }

        if (this.toTens) {
          value = toTens(value)
        }

        if (this.toFifths) {
          value = toFifth(value)
        }

        this.$emit('input', value)
        this.$emit('change', value)
      },
      blurHandler(event) {
        this.touched = false

        this.$emit('isInFocus', false)

        if (this.validate && event.target.value === '') {
          this.localError = constants.requiredFieldMessage
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-input {
    vertical-align: top;

    // .el-form & {
    //   width: 100%;
    // }
  }
  .el-input__inner {
    vertical-align: top;

    &.is-error {
      border-color: $red;
    }

    &.controls {
      padding-right: 90px;
      padding-left: 65px;

      @media screen and (max-width: 480px) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    .el-form-item--small & {
      height: 32px;
      font-size: 13px;
      vertical-align: middle;
    }
  }

  .el-input__prefix {
    width: 25px;
    height: $input-height;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-input__suffix {
    width: 25px;
    height: $input-height;
    display: flex;
    align-items: center;
    justify-content: center;

    &.with-controls {
      right: 60px;

      @media screen and (max-width: 480px) {
        right: 30px;
      }
    }

    .el-input--small & {
      height: 32px;
    }
  }

  .el-input__controls {
    .plus,
    .minus {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
    }

    .plus {
      left: auto;
      right: 0;
    }
  }

  .el-input__description {
    position: absolute;
    width: 100%;
  }
</style>
