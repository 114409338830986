import { db } from '@/plugins/firebase'

const WARNINGS_COLLECTION_KEY = '!warnings!'

const warningService = {
  addWarning: async ({ warningData }) => {
    const warningRef = db.collection(WARNINGS_COLLECTION_KEY).doc()
    const warningRefId = warningRef.id

    await warningRef.set({
      id: warningRefId,
      ...(warningData && { ...warningData }),
    })
  },
}

export default warningService
