<template>
  <div class="client-event" :id="event.id">
    <div
      class="client-event__status"
      :style="{ backgroundColor: statusById(event.status).color }"
    />

    <div class="client-event__info">
      <div class="client-event__info-primary">
        <span>{{ date }}</span>

        <span class="separator">/</span>

        <CurrencyView :value="event.eventPrice" />

        <span class="separator">/</span>

        <div class="client-event__info-time">
          {{ event.eventTimeStart }} - {{ event.eventTimeEnd }}
        </div>

        <div class="client-event__info-link" v-if="event.id">
          <EventContainer :eventId="event.id" />
        </div>
      </div>

      <div v-show="!hideTitle" class="client-event__info-procedures">
        <div
          v-for="(procedure, masterProcedureKey) in event.procedures"
          :key="masterProcedureKey"
        >
          <ClientEventProcedureItemV2ProcedureItem
            :procedure="procedure"
            :masterProcedureKey="masterProcedureKey"
          />
        </div>
      </div>

      <div v-if="event.note" class="client-event__info-note">
        {{ event.note }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CurrencyView from '@/components/Common/CurrencyView'
  import EventContainer from '@/components/Event/EventContainer'

  import ClientEventProcedureItemV2ProcedureItem from '@/components/Clients/ClientEvents/ClientEventProcedureItemV2ProcedureItem'

  export default {
    data() {
      return {}
    },
    props: {
      event: Object,
      hideTitle: Boolean,
    },
    computed: {
      ...mapGetters(['statusById']),
      date() {
        const dateArray = this.event.date.split('-')

        return dateArray[2] + '.' + dateArray[1] + '.' + dateArray[0]
      },
    },
    components: {
      CurrencyView,
      EventContainer,
      ClientEventProcedureItemV2ProcedureItem,
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .client-event {
    display: flex;

    &__status {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-top: 5px;
      margin-right: 7px;
    }
  }

  .client-event__info {
    width: 100%;

    &-link {
      margin-left: auto;
      font-size: 85%;
      padding-left: 5px;
      flex: 0 0 auto;
    }

    &-time,
    &-note {
      font-size: 85%;
    }

    &-time {
      color: $gray;
      line-height: 1.5;
    }

    &-note {
      margin-top: 3px;
    }
  }

  .client-event__info-primary {
    align-items: baseline;

    @media screen and (min-width: 480px) {
      display: flex;
    }

    .title {
      margin: 0 0 0 5px;
      flex: 0 1 auto;
    }

    .separator {
      margin: 0 5px;
    }

    span {
      flex: 0 0 auto;
    }
  }
</style>
