<template>
  <div>
    <div v-for="day in dateArray" :key="day" class="models-date">
      <CourceModelsDay
        :date="day"
        :eventForm="eventForm"
        :timeArray="timeArray"
        :activeDay="activeDay"
        @addModel="onAddModel"
        @editModel="onEditModel"
        @removeModel="onRemoveModel"
        @toggleActive="onToggleActive"
      />
    </div>

    <CourceModelsForm
      :courceModelDialogVisible="courceModelDialogVisible"
      :eventForm="eventForm"
      :date="addingDate"
      :model="model"
      @sendModelData="onSendModelData"
      @closeDialog="courceModelDialogVisible = false"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { sortArrayByNumber } from '@/utils/functions'

  import CourceModelsDay from '@/components/Calendar/EventsForms/Cource/CourceModelsDay'
  import CourceModelsForm from '@/components/Calendar/EventsForms/Cource/CourceModelsForm'

  export default {
    data() {
      return {
        addingDate: '',
        courceModelDialogVisible: false,
        model: {},
        activeDay: '',
      }
    },
    props: {
      dateArray: Array,
      eventForm: Object,
    },
    watch: {
      eventForm: {
        immediate: true,
        deep: false,
        handler(event) {
          if (!event) return

          this.activeDay = event.activeDate || event.dateArray[0]
        },
      },
    },
    computed: {
      ...mapGetters(['loading']),
      timeArray() {
        const start = +this.eventForm.timeStart.split(':')[0]
        const end = +this.eventForm.timeEnd.split(':')[0]

        const timeArray = []

        for (let i = start; i <= end; i++) {
          timeArray.push(`${i}:00`)

          if (i !== end) {
            timeArray.push(`${i}:30`)
          }
        }

        return timeArray
      },
    },
    components: {
      CourceModelsDay,
      CourceModelsForm,
    },
    methods: {
      onAddModel(date) {
        this.addingDate = date
        this.courceModelDialogVisible = true
      },
      getIndexOfModel(date, modelId) {
        const modelItem = this.eventForm.models[date].find(
          (model) => model.id === modelId
        )
        return this.eventForm.models[date].indexOf(modelItem)
      },
      onSendModelData(eventData) {
        if (eventData.edit) {
          const indexOf = this.getIndexOfModel(
            eventData.model.date,
            eventData.model.id
          )

          // eslint-disable-next-line vue/no-mutating-props
          this.$set(this.eventForm.models[eventData.model.date], indexOf, {
            ...this.eventForm.models[eventData.model.date][indexOf],
            ...eventData.model,
          })

          this.sortModels(eventData.model.date)
        } else {
          if (
            !Object.prototype.hasOwnProperty.call(
              this.eventForm.models,
              eventData.model.date
            )
          ) {
            this.$set(this.eventForm.models, eventData.model.date, [])
          }

          // eslint-disable-next-line vue/no-mutating-props
          this.eventForm.models[eventData.model.date].push(eventData.model)

          this.sortModels(eventData.model.date)
        }
      },
      sortModels(date) {
        // eslint-disable-next-line vue/no-mutating-props
        this.eventForm.models[date] = sortArrayByNumber(
          this.eventForm.models[date],
          'timeStartNumber'
        )
      },
      onEditModel(model) {
        this.model = model
        this.addingDate = model.date
        this.courceModelDialogVisible = true
      },
      onRemoveModel(model) {
        const indexOf = this.getIndexOfModel(model.date, model.id)

        // eslint-disable-next-line vue/no-mutating-props
        this.eventForm.models[model.date].splice(indexOf, 1)
      },
      onToggleActive(activeDate) {
        this.activeDay = activeDate
      },
    },
  }
</script>

<style lang="scss" scoped>
  .models-add {
    margin-left: 23px;
  }

  .models-date--model {
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;

    & + .models-date--model {
      border-top: 1px solid $border-color;
      padding-top: 15px;
    }
  }

  .models-date--time {
    width: 100%;
  }

  .models-date {
    .client-choose {
      padding-left: 23px;
    }
  }
</style>
