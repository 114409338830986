export const STATUSES_KEYS = {
  PLANNED: 'planned',
  CANCELED: 'canceled',
  FINISHED: 'finished',
  STUDY: 'study',
  ABSENCE: 'absence',
}

export const statuses = {
  [STATUSES_KEYS.PLANNED]: 1,
  [STATUSES_KEYS.CANCELED]: 2,
  [STATUSES_KEYS.FINISHED]: 3,
  [STATUSES_KEYS.STUDY]: 4,
  [STATUSES_KEYS.ABSENCE]: 5,
}

export function isEvent(status) {
  return [statuses.planned, statuses.canceled, statuses.finished].includes(
    +status
  )
}

export function isPlannedEvent(status) {
  return +status === statuses.planned
}

export function isCanceledEvent(status) {
  return +status === statuses.canceled
}

export function isFinishedEvent(status) {
  return +status === statuses.finished
}

export function isStudy(status) {
  return +status === statuses.study
}

export function isAbsense(status) {
  return +status === statuses.absence
}
