<template>
  <el-form
    ref="eventForm"
    :rules="rules"
    :model="eventForm"
    label-width="140px"
  >
    <el-form-item label="Дата отсутствия" prop="dateStart" required>
      <el-date-picker
        v-model="eventForm.dateStart"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        firstDayOfWeek="1"
        :picker-options="datePickerOptions"
      />
    </el-form-item>

    <TimeInRowFormItem
      :timeStartValue="eventForm.timeStart"
      :timeEndValue="eventForm.timeEnd"
      :timeStartDisabled="timeInputDisabled"
      :timeEndDisabled="!eventForm.timeStart || timeInputDisabled"
      @sendData="onSendTimeData"
    />

    <el-form-item label="Майстер" prop="master">
      <el-select
        v-model="eventForm.master"
        placeholder="Вибрати працівника"
        value-key="userId"
      >
        <el-option
          v-for="item in mastersByAlphabet"
          :key="item.userId"
          :label="item.name"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Коментар" prop="note">
      <el-input v-model="eventForm.note" type="textarea" :rows="2" />
    </el-form-item>

    <MasterItemEvents
      :masterId="eventForm.master.userId"
      :date="eventForm.dateStart"
      :eventId="event.id"
      :currentItemTimeStart="eventForm.timeStart"
      :currentItemTimeEnd="eventForm.timeEnd"
      :newEvent="newEvent"
      v-if="eventForm.master?.userId"
    />

    <el-form-item>
      <el-button
        type="primary"
        @click="saveAbsence"
        :loading="loading"
        :disabled="loading"
      >
        Зберегти
      </el-button>

      <el-button
        type="danger"
        @click="$emit('closeDialog')"
        :loading="loading"
        :disabled="loading"
      >
        Скасувати
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { db } from '@/plugins/firebase'
  import { mapGetters } from 'vuex'
  import { HISTORY_TYPES } from '@/utils/history'

  import { constants } from '@/utils/constants'
  import {
    getSortingValueNumberFromDate,
    diffMinutes,
    sanitizeString,
    dateTimeStringToDateObj,
    sortArrayByString,
  } from '@/utils/functions'

  import absenceService from '@/services/absence-service'
  import historyService from '@/services/history-service'

  import MasterItemEvents from '@/components/Calendar/EventsForms/Default/MasterItemEvents'
  import TimeInRowFormItem from '@/components/Common/Forms/TimeInRowFormItem'

  export default {
    data() {
      return {
        eventForm: {
          master: '',
          dateStart: '',
          timeStart: '',
          timeEnd: '',
          note: '',
        },
        event: {},
        TIMESTART: constants.timeStartString,
        TIMEEND: constants.timeEndString,
        TIMESTEP: constants.courcesTimeStep,
        DAYTIMESTART: constants.timeStartString,
        DAYTIMEEND: constants.timeEndString,
        datePickerOptions: {
          firstDayOfWeek: 1,
          disabledDate(date) {
            let today = new Date()
            today.setDate(today.getDate() - 1)

            return date < today
          },
        },
        rules: {
          dateStart: [constants.requiredFieldMessageRuleObject],
          master: [constants.requiredFieldMessageRuleObject],
          timeStart: [constants.requiredFieldMessageRuleObject],
          timeEnd: [constants.requiredFieldMessageRuleObject],
        },
      }
    },
    props: {
      newEvent: Boolean,
      eventData: Object,
      eventId: String,
    },
    components: {
      MasterItemEvents,
      TimeInRowFormItem,
    },
    computed: {
      ...mapGetters([
        'loading',
        'absenceById',
        'mastersWhoShowingInAbsenceForms',
        'user',
        'masterById',
      ]),
      currentUser() {
        return this.masterById(this.user.id)
      },
      mastersByAlphabet() {
        return sortArrayByString(this.mastersWhoShowingInAbsenceForms, 'name')
      },
      timeInputDisabled() {
        return this.loading
      },
    },
    watch: {
      eventId: {
        async handler(id) {
          if (!id) return

          this.event =
            this.absenceById(id) ||
            (await absenceService.getAbsence({ absenceId: id }))

          this.eventForm = {
            master: this.event.master,
            dateStart: this.event.dateStart,
            timeStart: this.event.timeStart,
            timeEnd: this.event.timeEnd,
            note: this.event.note,
          }
        },
        immediate: true,
      },
      eventData: {
        handler(data) {
          if (!data && !this.newEvent) return

          this.eventForm.dateStart = data.dateStart
          this.eventForm.timeStart = data.timeStart
            ? data.timeStart
            : this.DAYTIMESTART
        },
        immediate: true,
      },
    },
    methods: {
      onSendTimeData(data) {
        this.eventForm.timeStart = data.timeStart
        this.eventForm.timeEnd = data.timeEnd
      },
      saveAbsence() {
        this.$refs.eventForm.validate(async (valid) => {
          if (valid) {
            this.$store.dispatch('setLoading', true)

            const batch = db.batch()

            const timeStartDateObj = dateTimeStringToDateObj(
              this.eventForm.dateStart,
              this.eventForm.timeStart
            )
            const timeEndDateObj = dateTimeStringToDateObj(
              this.eventForm.dateStart,
              this.eventForm.timeEnd
            )
            const sortingValue = getSortingValueNumberFromDate(timeStartDateObj)

            const absenceData = {
              sortingValue,
              dateStart: this.eventForm.dateStart,
              timeStart: this.eventForm.timeStart,
              timeEnd: this.eventForm.timeEnd,
              dateStartObj: timeStartDateObj.toString(),
              dateEndObj: timeEndDateObj.toString(),
              duration: diffMinutes(timeStartDateObj, timeEndDateObj),
              master: {
                userId: this.eventForm.master.userId,
                name: this.eventForm.master.name,
              },
              ...(this.eventForm.note && {
                note: sanitizeString(this.eventForm.note),
              }),
              statusActive: Boolean(true),
              status: 5,
            }

            if (this.newEvent) {
              absenceService.addAbsence({
                batch,
                absenceData: {
                  ...absenceData,
                  createdAt: Date(),
                  creator: {
                    userId: this.currentUser.userId,
                    name: this.currentUser.name,
                  },
                },
              })
            } else {
              absenceService.updateAbsence({
                batch,
                absenceId: this.event.id,
                absenceData: {
                  ...absenceData,
                  updated: Date(),
                  updater: {
                    userId: this.currentUser.userId,
                    name: this.currentUser.name,
                  },
                },
              })
            }

            historyService.addHistory({
              batch,
              currentUser: this.currentUser,
              now: new Date(),
              type: this.newEvent
                ? HISTORY_TYPES.ADD_ABSENCE
                : HISTORY_TYPES.UPDATE_ABSENCE,
              additionalData: {
                absenceId: this.newEvent ? '' : this.event.id,
                dateStart: this.eventForm.dateStart,
                timeStart: this.eventForm.timeStart,
                timeEnd: this.eventForm.timeEnd,
                masterId: this.eventForm.master.userId,
              },
            })

            batch
              .commit()
              .then(() => {
                this.$emit('closeDialog')
                this.$message.success('Відсутність збережено')
              })
              .catch((error) => this.$store.dispatch('setError', error.message))
              .finally(() => this.$store.dispatch('setLoading', false))
          }
        })
      },
    },
    beforeDestroy() {
      this.eventForm = {}
    },
  }
</script>
