<template>
  <el-form-item
    label="Телефон"
    :prop="prop"
    :rules="{
      validator: (rule, value, callback) =>
        validateClient(value, callback, {
          checkClientValidation,
          choosedFormat,
          getClient,
          isEditing,
          clientId,
        }),
      trigger: 'change',
      required: true,
    }"
  >
    <div
      class="el-input el-input-group"
      :class="{ 'is-disabled': isPreClient }"
    >
      <div class="phone-input" :class="{ 'is-editing': isEditing }">
        <div class="phone-input__wrapper">
          <input
            type="text"
            ref="phone"
            autocomplete="off"
            inputmode="numeric"
            :value="value"
            :placeholder="choosedFormat.mask"
            class="el-input__inner"
            :class="{ 'is-error': error && error !== '' }"
            :data-maska="choosedFormat.mask"
            v-maska
            v-if="focus"
            :disabled="isPreClient"
            @blur="validateForm"
            @focus="$event.target.select()"
            @input="inputHandler($event)"
          />

          <input
            type="text"
            :value="value"
            class="el-input__inner"
            :class="{ 'is-error': error && error !== '' }"
            :placeholder="choosedFormat.mask"
            ref="phone"
            autocomplete="off"
            inputmode="numeric"
            :data-maska="choosedFormat.mask"
            v-maska
            :disabled="isPreClient"
            v-if="!focus"
            @blur="validateForm"
            @input="inputHandler($event)"
          />

          <div class="phone-input__icon">
            <el-dropdown
              class="phone-input__icon-toggler"
              trigger="click"
              :disabled="isPreClient"
            >
              <span class="el-dropdown-link">
                <img :src="choosedFormat.flag" class="phone-input__icon-img" />
                <i class="el-icon-caret-bottom" />
              </span>

              <el-dropdown-menu
                slot="dropdown"
                class="phone-input__icon-dropdown"
              >
                <el-dropdown-item
                  v-for="(format, index) in phoneFormats"
                  :key="index"
                  class="phone-input__icon-select"
                >
                  <el-link @click="changeFormat(format)" :underline="false"
                    ><img :src="format.flag" class="phone-input__icon-img"
                  /></el-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div class="phone-input__error el-form-item__error" v-if="error">
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </el-form-item>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { vMaska, Mask } from 'maska'

  import { cleanPhone } from '@/utils/functions'
  import { constants } from '@/utils/constants'

  import { getClientFromFirebaseByPhone } from '@/utils/clients'

  const validateClient = async (
    value,
    callback,
    { choosedFormat, getClient, isEditing, clientId, checkClientValidation }
  ) => {
    const client =
      value !== '' &&
      value.length >= choosedFormat.minNumbersWithSymbols &&
      value.length <= choosedFormat.maxNumbersWithSymbols &&
      checkClientValidation
        ? await getClient(value)
        : null

    if (value === '') {
      callback(new Error(constants.requiredFieldMessage))
    } else if (
      value.length < choosedFormat.minNumbersWithSymbols ||
      value.length > choosedFormat.maxNumbersWithSymbols
    ) {
      const errorMessage =
        choosedFormat.minNumbersWithSymbols ===
        choosedFormat.maxNumbersWithSymbols
          ? `Номер має мати ${choosedFormat.countNumbers} символів`
          : `Номер має мати від ${choosedFormat.minNumbersWithSymbols} до ${choosedFormat.maxNumbersWithSymbols} символів`

      callback(new Error(errorMessage))
    } else if (
      checkClientValidation &&
      ((!isEditing && client) ||
        (isEditing && client && client?.id !== clientId))
    ) {
      callback(new Error('Клієнт із таким номером вже існує'))
    } else {
      callback()
    }
  }

  export default {
    data() {
      return {
        error: '',
        phoneFormats: constants.phoneFormats,
        choosedFormat: constants.phoneFormats.ua,
        maskIsReaded: false,
        validateClient,
      }
    },
    directives: { maska: vMaska },
    props: {
      value: String,
      isEditing: Boolean,
      focus: {
        type: Boolean,
        default: true,
      },
      isShowing: Boolean,
      prop: {
        type: String,
        default: 'phone',
      },
      clientId: String,
      checkClientValidation: {
        type: Boolean,
        default: false,
      },
      formRef: Object,
      isPreClient: Boolean,
    },
    computed: {
      ...mapGetters(['userSalon']),
    },
    watch: {
      isShowing: {
        immediate: true,
        handler(showing) {
          if (!showing || this.maskIsReaded) return

          this.choosedFormat = this.userSalon.defaultPhoneFormat
            ? constants.phoneFormats[this.userSalon.defaultPhoneFormat]
            : constants.phoneFormats.ua

          if (!this.isEditing && !this.value) return

          if (this.value && !this.maskIsReaded) {
            const rawPhone = cleanPhone(this.value)

            if (rawPhone.startsWith('+380')) {
              this.choosedFormat = constants.phoneFormats.ua
            } else if (rawPhone.startsWith('+48')) {
              this.choosedFormat = constants.phoneFormats.pl
            } else if (rawPhone.startsWith('+')) {
              this.choosedFormat = constants.phoneFormats.int
            }

            const mask = new Mask({
              mask: this.choosedFormat.mask,
            })

            this.$emit('input', mask.masked(rawPhone))
          }

          this.maskIsReaded = true
        },
      },
    },
    methods: {
      inputHandler(event) {
        this.$emit('input', event.target.value)
      },
      changeFormat(format) {
        const oldFormat = this.choosedFormat
        this.choosedFormat = format

        let newPhone = ''

        if (oldFormat.countNumbers === format.countNumbers) {
          newPhone = this.value.replace(oldFormat.code, format.code)
        } else {
          newPhone = this.value.replace(oldFormat.code, format.code)
        }

        this.$emit('input', newPhone)
      },
      validateForm() {
        if (this.checkClientValidation) {
          this.$emit('validatePhone')
        }
      },
      async getClient(phone) {
        this.$store.dispatch('setLoading', true)

        try {
          const value = await getClientFromFirebaseByPhone(phone)

          this.$store.dispatch('setLoading', false)

          return value
        } catch (error) {
          this.$store.dispatch('setError', error.message)

          this.$store.dispatch('setLoading', false)
        }
      },
    },
  }
</script>

<style lang="scss">
  .phone-input__wrapper {
    position: relative;
  }

  .phone-input {
    position: relative;
    line-height: 1.2;

    &.is-editing {
      padding-top: 0;
    }

    span {
      display: block;
      line-height: inherit;
    }

    input {
      padding-left: 45px;
      padding-right: 15px;

      &.is-error {
        border-color: #ff300a;
      }
    }

    &__format {
      padding-bottom: 3px;
    }

    &__icon {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      left: 10px;
      height: 100%;
      cursor: pointer;

      div {
        display: flex;
        align-items: center;

        img {
          flex-shrink: 0;
          width: 16px;
          margin-right: 3px;
          height: 16px;
          margin-top: 1px;
        }
      }

      &-toggler {
        height: 100%;

        .el-dropdown-link {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }

      &-dropdown {
        max-width: 200px;

        .phone-input__icon-select {
          padding: 5px;
        }
      }

      &-img {
        max-width: 100%;
        display: inline-block;
        vertical-align: top;
        width: 20px;
        height: 20px;
      }
    }

    &__trigger {
      right: 10px;
    }

    &__format {
      font-size: 10px;
      top: 0;
      left: 0;

      .el-button {
        padding: 0;
        font-weight: normal;
        font-family: inherit;
        text-transform: none;
        font-size: 10px;
      }
    }

    img {
      max-width: 100%;
      vertical-align: top;
    }
  }
</style>
