export const HISTORY_TYPES = {
  ADD_EVENT: 'add-event',
  UPDATE_EVENT: 'update-event',
  CANCEL_EVENT: 'cancel-event',
  FINALIZE_EVENT: 'finalize-event',
  ADD_CLIENT: 'add-client',
  ADD_CLIENT_CASHBACK_MANUAL: 'add-client-cashback',
  UPDATE_CLIENT: 'update-client',
  UPDATE_VALUE: 'update-value',
  UPDATE_PRICE: 'update-price',
  UPDATE_METHOD: 'update-method',
  ADD_EXPENCE: 'add-expence',
  UPDATE_INCOME: 'update-income',
  UPDATE_MASTER_INCOME: 'update-master-income',
  UPDATE_MASTER_ID: 'update-master-id',
  UPDATE_MASTER_PERCENTS: 'update-master-percents',
  UPDATE_COMMENT: 'update-comment',
  UPDATE_QUANTITY: 'update-quantity',
  UPDATE_MONEY_SOURCE: 'update-money-source',
  UPDATE_COMBI_CARD_VALUE: 'update-combi-card-value',
  UPDATE_COMBI_CASH_VALUE: 'update-combi-cash-value',
  ADD_ABSENCE: 'add-absence',
  UPDATE_ABSENCE: 'update-absence',
  DELETE_ABSENCE: 'delete-absence',
  ADD_STUDY: 'add-study',
  UPDATE_STUDY: 'update-study',
  DELETE_STUDY: 'delete-study',
  SELL_PRODUCT: 'sell-product',
  SELL: 'sell',
  ADD_CERTIFICATE: 'add-certificate',
}
