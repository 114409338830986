import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

import { sortArrayByString } from '@/utils/functions'

import {
  PARENT_CATEGORIES_FOR_NESTED_ARRAY,
  PARENT_CATEGORIES_MAX_LEVEL,
} from '@/utils/expences'

function cleanup(categories) {
  for (const category of categories) {
    if (category.children) {
      category.children = cleanup(category.children)

      if (category.children.length === 0) {
        delete category.children
      }
    }
  }

  return categories
}

function addLevels(array, level = 1, isNeedToLimitMaxBranch) {
  return array.map((obj) => {
    const newObj = {
      ...obj,
      level,
      ...(PARENT_CATEGORIES_MAX_LEVEL <= level &&
        isNeedToLimitMaxBranch && { disabled: true }),
    }

    if (obj.children) {
      newObj.children = addLevels(
        obj.children,
        level + 1,
        isNeedToLimitMaxBranch
      )
    }

    return newObj
  })
}

function buildCategoryTree(
  categories,
  parentId = undefined,
  isNeedToLimitMaxBranch = false
) {
  const copyOfCategories = JSON.parse(JSON.stringify(categories))
  const result = []

  for (const category of copyOfCategories.filter(
    (c) => c.parentCategory === parentId
  )) {
    const children = buildCategoryTree(copyOfCategories, category.id)

    if (children.length > 0) {
      category.children = children
    }

    result.push(category)
  }

  return addLevels(
    cleanup(sortArrayByString(result, 'name')),
    1,
    isNeedToLimitMaxBranch
  )
}

export default {
  state: {
    expencesCategories: [],
    isExpencesCategoriesBinded: false,
    isExpencesCategoriesLoading: false,
  },
  mutations: {
    setIsExpencesCategoriesBinded(state, payload) {
      state.isExpencesCategoriesBinded = payload
    },
    setIsExpencesCategoriesLoading(state, payload) {
      state.isExpencesCategoriesLoading = payload
    },
  },
  actions: {
    setIsExpencesCategoriesBinded({ commit }, payload) {
      commit('setIsExpencesCategoriesBinded', payload)
    },
    setIsExpencesCategoriesLoading({ commit }, payload) {
      commit('setIsExpencesCategoriesLoading', payload)
    },
    bindExpencesCategories: firestoreAction((context) => {
      const salonId = context.getters.userSalonId

      return context.bindFirestoreRef(
        'expencesCategories',
        db.collection('expencesCategories').where('salonId', '==', salonId)
      )
    }),

    unbindExpencesCategories: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('expencesCategories')
    }),
  },
  getters: {
    isExpencesCategoriesBinded(state) {
      return state.isExpencesCategoriesBinded
    },
    isExpencesCategoriesLoading(state) {
      return state.isExpencesCategoriesLoading
    },
    expencesCategories: (state) => state.expencesCategories,
    expencesActiveCategories: (state) =>
      state.expencesCategories.filter((event) => event.active === true),
    expencesNotActiveCategories: (state) =>
      state.expencesCategories.filter((event) => event.active === false),
    expencesCategoriesActiveChildrenArray:
      (state, getters) => (isNeedToLimitMaxBranch) => {
        if (!state.isExpencesCategoriesBinded) return []

        const catsWithValueAndName = getters.expencesActiveCategories.map(
          (item) => {
            return {
              ...item,
              label: item.name,
              value: item.id,
            }
          }
        )

        const catsWithParentCategory = catsWithValueAndName.filter(
          (item) => item.parentCategory
        )
        const catsWithoutParentCategory = catsWithValueAndName.filter(
          (item) => !item.parentCategory
        )

        const categorizedCats = buildCategoryTree(
          [...catsWithParentCategory, ...PARENT_CATEGORIES_FOR_NESTED_ARRAY],
          undefined,
          isNeedToLimitMaxBranch
        )

        if (catsWithoutParentCategory.length) {
          const indexOfOtherCat = categorizedCats.findIndex(
            (item) => item.value === 'other'
          )

          let children = categorizedCats[indexOfOtherCat].children || []
          children = sortArrayByString(
            children.concat(catsWithoutParentCategory),
            'name'
          )

          categorizedCats[indexOfOtherCat].children = children
        }

        return categorizedCats
      },
    expencesCategoriesNotActiveChildrenArray: (state, getters) => {
      return buildCategoryTree(
        [
          {
            id: 'notActive',
            value: 'notActive',
            label: 'Не активні',
            name: 'Не активні',
            root: true,
          },
          ...JSON.parse(
            JSON.stringify(
              getters.expencesNotActiveCategories.map((category) => {
                return { ...category, parentCategory: 'notActive' }
              })
            )
          ),
        ],
        undefined
      )
    },
    expencesCategoryById: (state) => (id) => {
      return state.expencesCategories.find((expences) => expences.id === id)
    },
    expencesCategoryByName: (state) => (name) => {
      return state.expencesCategories.find((expences) => expences.name === name)
    },
  },
}
