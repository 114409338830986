<template>
  <div v-if="!isProductsLoading">
    <div
      v-for="(chosenProductItem, index) in chosenProducts"
      :key="index"
      class="product-form-item"
      :class="{ last: index + 1 === chosenProducts.length }"
    >
      <WarehouseFormItemProduct
        :chosenProductItem="chosenProductItem"
        :isGlobalMasterPercent="isGlobalMasterPercent"
        :globalMasterPercent="globalMasterPercent"
        :adminPercent="adminPercent"
        :index="index"
        :masterId="masterId"
        @sendProductData="onSendProductData"
        @removeProduct="onRemoveProduct"
      />
    </div>

    <el-form-item label="Продукт" class="adding-product" prop="product">
      <el-cascader
        v-model="productModel"
        :disabled="loading"
        :options="productsWithCategories"
        :props="{ emitPath: false }"
        :show-all-levels="false"
        v-show="addingProduct"
      />

      <el-button
        type="success"
        @click="showAddProductSelector"
        :disabled="loading"
        v-show="!addingProduct"
        icon="el-icon-plus"
      >
        Додати {{ chosenProducts.length > 0 ? 'ще один' : '' }} товар
      </el-button>

      <div v-show="addingProduct">
        <el-button
          type="success"
          icon="el-icon-check"
          class="icon-button"
          :disabled="loading || !productModel.id"
          @click="addProduct"
        />

        <el-button
          type="danger"
          icon="el-icon-close"
          class="icon-button"
          :disabled="loading"
          @click="cancelAddProduct"
        />
      </div>
    </el-form-item>
  </div>
</template>

<script>
  import 'firebase/firestore'
  import { mapGetters } from 'vuex'
  import { productBindMixin } from '@/components/Warehouse/product-bind-mixin'

  import { getQuantity, getItemsFromQuantities } from '@/utils/warehouse'

  import { MASTER_PERCENT_OPTIONS } from '@/components/Finances/FinancesFlow/FinancesIncome/finances-income-config'

  import WarehouseFormItemProduct from './WarehouseFormItemProduct.vue'

  export default {
    data() {
      return {
        chosenProductsIds: [],
        chosenProducts: [],
        productModel: {},
        addingProduct: false,
        MASTER_PERCENT_OPTIONS,
      }
    },
    mixins: [productBindMixin],
    components: {
      WarehouseFormItemProduct,
    },
    props: {
      isGlobalMasterPercent: Boolean,
      globalMasterPercent: Number,
      adminPercent: Number,
      masterId: String,
    },
    computed: {
      ...mapGetters([
        'loading',
        'user',
        'masterById',
        'isProductsLoading',
        'productsCategorized',
        'userSalon',
      ]),
      sellsMasterPart() {
        return this.userSalon.sellsMasterPart
      },
      currentUser() {
        return this.masterById(this.user.id)
      },
      productsWithCategories() {
        return this.productsCategorized({
          chosenProductsIds: this.chosenProductsIds,
        })
      },
    },
    methods: {
      onSendProductData({ productItem, index }) {
        this.chosenProducts[index] = JSON.parse(JSON.stringify(productItem))

        this.sendProductsData()
      },
      getQuantityLocal(productId, quantities) {
        return getQuantity(
          productId,
          quantities,
          this.$store.getters.userSalonId,
          this.currentUser.userId
        )
      },
      addProduct() {
        this.addingProduct = false

        this.chosenProducts.push({
          id: this.productModel.id,
          quantity: 1,
          price: this.productModel.price,
          masterPart: 0,
          bookedKeysAndValues: getItemsFromQuantities(this.productModel, 1),
        })

        this.chosenProductsIds.push(this.productModel.id)

        this.sendProductsData()
      },
      cancelAddProduct() {
        this.addingProduct = false
        this.productModel = {}
      },
      showAddProductSelector() {
        this.productModel = {}
        this.addingProduct = true
      },
      onRemoveProduct(index) {
        this.chosenProducts.splice(index, 1)
        this.chosenProductsIds.splice(index, 1)

        this.sendProductsData()
      },
      sendProductsData() {
        this.$emit('sendProductsData', this.chosenProducts)
      },
      clearForm() {
        this.chosenProductsIds = []
        this.chosenProducts = []
        this.productModel = {}
        this.addingProduct = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .product-form-item {
    margin-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid $border-color;

    &.last {
      border-bottom: 1px solid $border-color;
      padding-bottom: 15px;
    }

    &::v-deep .el-form-item .el-form-item__label {
      min-height: inherit;
    }
  }

  .product-form {
    @media screen and (min-width: 768px) {
      display: flex;
    }

    .product-form-inner-item {
      margin-right: 0;

      @media screen and (min-width: 768px) {
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .adding-product {
    &::v-deep {
      .el-form-item__content {
        @include to('sm') {
          display: flex;
        }

        .el-cascader {
          margin-bottom: 10px;

          @include to('sm') {
            margin-right: 10px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>
