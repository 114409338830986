import { db } from '@/plugins/firebase'
import router from '@/router/index'

export function onLogin(getters, dispatch) {
  db.collection('masters')
    .doc(getters.user.email)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const currentMaster = doc.data()

        dispatch('setSalonId', currentMaster.salonId)
        dispatch('setSalon', currentMaster.salonId)
      }
    })
    .then(() => dispatch('bindMasters'))
    .then(() => dispatch('bindProcedures'))
    .then(() => dispatch('bindProceduresCategories'))
    .then(() => dispatch('bindSalesCategories'))
    .then(() => dispatch('bindProceduresFieldTemplates'))
    .then(() => dispatch('setFirstDataIsLoaded', true))
    .then(() => dispatch('setLoading', false))
    .then(() => {
      dispatch(
        'setSuccess',
        'Ви успішно увійшли до облікового запису ' + getters.user.email
      )
    })
    .then(() => router.push('/calendar').catch(() => {}))
    .catch(() => {})
}
