<template>
  <div class="finances-transaction-add-expence">
    <el-button
      type="danger"
      icon="el-icon-plus"
      @click="
        isExpenceAdding = true
        addLookOnBackButtonEvent()
      "
      :size="size"
      >Витрати</el-button
    >

    <el-dialog
      :visible.sync="isExpenceAdding"
      title="Додати витрати"
      width="60%"
      append-to-body
      :before-close="onCloseAddingExpence"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <FinancesExpenceForm
        ref="financesExpenceForm"
        @closeAddingExpence="onCloseAddingExpence"
      />
    </el-dialog>
  </div>
</template>

<script>
  import FinancesExpenceForm from '@/components/Finances/FinancesFlow/FinancesExpence/FinancesExpenceForm'

  export default {
    data() {
      return {
        isExpenceAdding: false,
      }
    },
    props: {
      size: {
        type: String,
        default: '',
      },
    },
    computed: {},
    components: {
      FinancesExpenceForm,
    },
    methods: {
      onCloseAddingExpence() {
        if (
          this.$refs.financesExpenceForm &&
          this.$refs.financesExpenceForm.resetVars
        ) {
          this.$refs.financesExpenceForm.resetVars()
        }

        this.isExpenceAdding = false
      },
      addLookOnBackButtonEvent() {
        let self = this

        history.pushState(null, null, null)
        window.addEventListener('popstate', self.lookOnBackButton)
      },
      lookOnBackButton() {
        let self = this

        if (this.isExpenceAdding) {
          this.onCloseAddingExpence()
        }

        history.pushState(null, null, null)
        window.removeEventListener('popstate', self.lookOnBackButton)
      },
    },
    created() {},
    destroyed() {},
  }
</script>

<style lang="scss" scoped>
  .finances-transaction-add-expence {
    &::v-deep {
      .el-button {
        > span {
          display: none;

          @media screen and (min-width: 480px) {
            display: inline;
          }
        }
      }
    }
  }
</style>
