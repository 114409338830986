<template>
  <div class="header-wrapper">
    <div class="header">
      <div class="container flex flex--vertical-center">
        <img :src="logoPath" class="header-logo" />

        <el-button
          class="mobile-menu__toggler"
          circle
          @click="mobileMenu = !mobileMenu"
        >
          <Icons icon="bars" />
        </el-button>

        <el-drawer
          :visible.sync="mobileMenu"
          size="200px"
          direction="ltr"
          class="mobile-menu"
        >
          <VerticalMenu />
        </el-drawer>

        <div class="header-greeting" v-if="currentUser && currentUser.name">
          <div
            class="header-greeting__name"
            v-if="currentUser && currentUser.name"
          >
            {{ `Вітаємо, ${currentUser.name}!` }}
          </div>

          <div class="salon-sign" v-if="isUserHasPartnerSalons">
            <span class="show-up-sm margin-right-5">Ви у салоні </span>

            <strong>{{ userSalon.name }}</strong>
          </div>
        </div>
        <div class="header-profile" v-if="isUserLoggedIn">
          <div class="header-profile__search-finances">
            <div class="header-profile__client-search">
              <ClientSearch
                ref="clientSearch"
                :size="'mini'"
                @choosingClient="onChoosingClient"
              />

              <ClientViewDravler
                :clientId="clientId"
                :clientViewVisible="clientViewVisible"
                @closeClientView="onCloseClientView"
              />
            </div>

            <div class="header-profile__transactions-buttons">
              <FinancesTransactionsAddIncome size="mini" />
              <FinancesTransactionsAddExpence size="mini" />
            </div>
          </div>

          <!-- <div class="header-profile__help-button">
            <router-link to="/wizard">
              <Icons icon="question-circle" />
            </router-link>
          </div> -->

          <el-dropdown>
            <span class="el-dropdown-link">
              <Icons icon="user-circle" />
            </span>

            <el-dropdown-menu slot="dropdown" class="header-dropdown">
              <el-dropdown-item>
                <router-link to="/userPanel">Налаштування</router-link>
              </el-dropdown-item>

              <el-dropdown-item
                v-if="isUserHasPartnerSalons"
                class="salon-list"
              >
                <div class="salon-list-label">Салони:</div>

                <el-link
                  @click="() => changeSalon({ id, name: salonName })"
                  :underline="false"
                  v-for="({ salonName }, id) in userPartnerSalons"
                  :key="id"
                  class="salon-item"
                  :disabled="id === userSalon.id"
                  :title="id === userSalon.id ? 'Поточний салон' : salonName"
                >
                  {{ salonName }}
                </el-link>
              </el-dropdown-item>

              <el-dropdown-item divided>
                <a href="#" @click="onLogout">Вихід</a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { db } from '@/plugins/firebase'
  import { mapGetters } from 'vuex'
  import { EventBus } from '@/bus.js'

  import masterService from '@/services/master-service'

  import VerticalMenu from '@/components/Common/VerticalMenu'
  import Icons from '@/components/Common/Icons/Icons'
  import FinancesTransactionsAddIncome from '@/components/Finances/FinancesFlow/FinancesIncome/FinancesTransactionsAddIncome'
  import FinancesTransactionsAddExpence from '@/components/Finances/FinancesFlow/FinancesExpence/FinancesTransactionsAddExpence'
  import ClientSearch from '@/components/Clients/ClientSearch'
  import ClientViewDravler from '@/components/Clients/ClientViewDravler'

  export default {
    data() {
      return {
        mobileMenu: false,
        logoPath: require('@/../public/img/logo.png'),
        client: {},
        clientId: '',
        clientViewVisible: false,
      }
    },
    components: {
      VerticalMenu,
      Icons,
      FinancesTransactionsAddIncome,
      FinancesTransactionsAddExpence,
      ClientSearch,
      ClientViewDravler,
    },
    watch: {
      $route() {
        this.mobileMenu = false
      },
    },
    computed: {
      ...mapGetters(['user', 'userSalon', 'masterById', 'isUserLoggedIn']),
      currentUser() {
        return this.user ? this.masterById(this.user.id) : null
      },
      isUserHasPartnerSalons() {
        return this.currentUser?.partnerSalons
          ? Object.keys(this.currentUser.partnerSalons).length > 0
          : false
      },
      userPartnerSalons() {
        return this.currentUser?.partnerSalons
          ? Object.assign(
              JSON.parse(JSON.stringify(this.currentUser.partnerSalons)),
              {
                [this.currentUser.originSalonId]: {
                  salonId: this.currentUser.originSalonId,
                  salonName: this.currentUser.originSalonName,
                },
              }
            )
          : null
      },
    },
    methods: {
      changeSalon(salon) {
        this.$confirm(
          `Ви дійсно хочете перейти в ${salon.name}. Продовжити?`,
          'Увага',
          {
            confirmButtonText: 'Так',
            cancelButtonText: 'Скасувати',
            type: 'warning',
          }
        )
          .then(async () => {
            const currentUser = JSON.parse(JSON.stringify(this.currentUser))

            this.$store.dispatch('setLoading', true)

            const batch = db.batch()

            masterService.updateMaster({
              batch,
              masterId: currentUser.email,
              masterData: {
                salonId: salon.id,
              },
            })

            await batch
              .commit()
              .then(async () => {
                this.onLogout()
              })
              .catch((error) => {
                this.$store.dispatch('setError', error.message)
              })
          })
          .catch(() => {})
      },
      async onLogout() {
        await this.$router.push('/').catch(() => {})

        this.$store.dispatch('setLoading', true)

        await this.$store.dispatch('setBindDate', null)
        await this.$store.dispatch('unbindMasters')
        await this.$store.dispatch('unbindProcedures')
        await this.$store.dispatch('unbindProceduresCategories')

        await this.$store.dispatch('unbindSalesCategories')
        await this.$store.dispatch('unbindSettings')
        await this.$store.dispatch('unbindEvents')
        await this.$store.dispatch('unbindAbsences')
        await this.$store.dispatch('unbindSchedules')
        await this.$store.dispatch('unbindProceduresFieldTemplates')

        await this.$store.dispatch('unbindProducts')
        await this.$store.dispatch('unbindProductsCategories')
        this.$store.dispatch('setIsProductsLoading', false)
        this.$store.dispatch('setIsProductsBinded', false)

        await this.$store.dispatch('unbindExpencesCategories')
        this.$store.dispatch('setIsExpencesCategoriesBinded', false)

        await this.$store.dispatch('logoutUser')

        this.$store.dispatch('setLoading', false)
      },
      onMenuClick() {
        this.mobileMenu = false
      },
      onChoosingClient({ id }) {
        this.viewClient(id)
      },
      viewClient(clientId) {
        EventBus.$emit('viewClient', clientId)
      },
      onCloseClientView() {
        if (this.$refs.clientSearch.clearSearch) {
          this.$refs.clientSearch.clearSearch()
        }

        this.clientId = ''
        this.clientViewVisible = false
      },
    },
    mounted() {},
    created() {
      EventBus.$on('viewClient', (clientId) => {
        this.clientViewVisible = true
        this.clientId = clientId
      })
    },
    beforeDestroy() {
      EventBus.$off('viewClient')
    },
  }
</script>

<style lang="scss">
  .header {
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    position: relative;

    .container {
      border-bottom: 1px solid $border-color;
      padding-top: 5px;
      padding-bottom: 5px;
      justify-content: space-between;
    }

    &-logo {
      display: none;
      width: 40px;
      height: 40px;

      @media screen and (min-width: 980px) {
        display: block;
        margin-right: 10px;
      }
    }
  }

  .header-dropdown.el-dropdown-menu {
    .salon-list {
      .salon-list-label {
        padding: 5px 0 5px 10px;
        color: $gray;
        cursor: default;
      }

      &:hover {
        background: none;
        color: inherit;
      }

      .salon-item {
        padding-left: 15px;

        &:hover {
          background: $lighter-gray;
        }
      }
    }
  }

  .mobile-menu {
    &__toggler {
      width: 30px;
      height: 30px;
      line-height: 1;
      padding: 0;

      @media screen and (min-width: 980px) {
        width: 40px;
        height: 40px;
      }

      &.el-button.is-circle {
        margin-right: 10px;
        margin-bottom: 0;
        padding: 0;
        flex-shrink: 0;
        display: block;

        @media screen and (min-width: 980px) {
          display: none;
        }
      }
    }

    &.el-dialog__wrapper {
      padding: 0;

      .el-drawer__header {
        padding: 10px 0 0 5px;

        > span:first-child {
          display: none;
        }
      }

      .el-drawer__close-btn {
        padding: 0;
        background: $blue;
        color: $white;
        width: 30px;
        height: 30px;
        border-radius: 50%;

        @media screen and (min-width: 768px) {
          width: 40px;
          height: 40px;
        }
      }
    }

    .el-menu {
      border: none;

      .el-menu-item {
        padding: 0 10px !important;
      }
    }
  }

  .header-greeting {
    display: flex;
    justify-content: flex-end;
    flex: 1;

    .salon-sign {
      display: flex;
      align-items: center;
    }

    &__name {
      display: none;
      margin-right: 10px;

      @media screen and (min-width: 768px) {
        display: block;
      }
    }
  }

  .header-profile {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    padding-left: 20px;
    margin-left: auto;

    &__search-finances {
      margin-right: 10px;

      @media screen and (min-width: 480px) {
        display: flex;
        align-items: center;
      }
    }

    &__client-search {
      width: 150px;
      display: none;

      @media screen and (min-width: 768px) {
        width: 180px;
        display: flex;
        margin-right: 10px;
      }

      .el-button {
        margin-left: -1px;
        border-radius: 0 $border-radius-mini $border-radius-mini 0;
      }
    }

    &__transactions-buttons {
      display: flex;
      justify-content: flex-end;
    }

    &__money-data {
      width: 100%;
      justify-content: flex-end;
      margin-top: 5px;

      @media screen and (min-width: 768px) {
        width: auto;
        margin-top: 0;
      }
    }

    svg {
      color: $light-gray;
      font-size: 25px;
      cursor: pointer;

      @media screen and (min-width: 768px) {
        font-size: 30px;
      }
    }

    &__help-button,
    .finances-transaction-add-income {
      margin-right: 10px;
    }
  }
</style>
