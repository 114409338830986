import store from '@/store/index.js'

import { db } from '@/plugins/firebase'

import firebase from 'firebase/app'
import 'firebase/firestore'

const MASTERS_COLLECTION_KEY = 'masters'
const MASTERS_EVENTS_COLLECTION_KEY = 'masterEvents'
const USED_IN_SALONS_IDS_ARRAY_KEY = 'usedInSalonIds'

const masterService = {
  updateMaster: ({ batch, masterId, masterData }) => {
    const masterRef = db.collection(MASTERS_COLLECTION_KEY).doc(masterId)

    batch.set(masterRef, { ...masterData }, { merge: true })
  },

  addMasterEvent: ({
    batch,
    eventId,
    clientId,
    mastersProcedures,
    date,
    sortingValue,
  }) => {
    const masterById = store.getters.masterById

    Object.keys(mastersProcedures).forEach((masterKey) => {
      const {
        masterId,
        masterPrice,
        procedures,
        masterMasterPart,
        masterSalonPart,
      } = mastersProcedures[masterKey]
      const master = masterById(masterId)
      const { id: masterEmail } = master

      const masterRef = db
        .collection(MASTERS_COLLECTION_KEY)
        .doc(masterEmail)
        .collection(MASTERS_EVENTS_COLLECTION_KEY)
        .doc(eventId)

      batch.set(masterRef, {
        id: eventId,
        salonId: store.getters.userSalonId,
        date,
        masterPrice,
        masterPart: masterMasterPart,
        salonPart: masterSalonPart,
        clientId,
        sortingValue,
        procedures,
      })
    })
  },

  addPartnerMasterAsUsed: ({ batch, masterIds }) => {
    const masterById = store.getters.masterById
    const salonId = store.getters.userSalonId

    masterIds.forEach((masterId) => {
      const master = masterById(masterId)

      if (!master || !master.partner) return

      if (
        !master[USED_IN_SALONS_IDS_ARRAY_KEY] ||
        (master[USED_IN_SALONS_IDS_ARRAY_KEY] &&
          !master[USED_IN_SALONS_IDS_ARRAY_KEY].includes(salonId))
      ) {
        masterService.updateMaster({
          batch,
          masterId: master.id,
          masterData: {
            [USED_IN_SALONS_IDS_ARRAY_KEY]:
              firebase.firestore.FieldValue.arrayUnion(salonId),
          },
        })
      }
    })
  },
}

export default masterService
