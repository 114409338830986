export function firstNull(number) {
  return number < 10 ? '0' + number : number.toString()
}

export function compareDates(a, b) {
  return a.getTime() > b.getTime()
}

export function dateTimeStringToDateObj(
  date,
  time = '0:00',
  dateSeparator = '-',
  timeSeparator = ':'
) {
  const dateArray = date.split(dateSeparator)
  const timeArray = time.split(timeSeparator)

  return new Date(
    Number(dateArray[0]),
    Number(dateArray[1]) - 1,
    Number(dateArray[2]),
    Number(timeArray[0]),
    Number(timeArray[1]),
    0
  )
}

export const diffDays = (date, otherDate, wayOfRounding = 'bottom') => {
  const diff = (date - otherDate) / (1000 * 60 * 60 * 24)

  return wayOfRounding === 'bottom' ? Math.ceil(diff) : Math.round(diff)
}

export function diffMinutes(start, stop) {
  return (stop - start) / (1000 * 60)
}

export function getCustomDateObjFromDateObj(dateObj) {
  return {
    year: dateObj.getFullYear(),
    month: firstNull(dateObj.getMonth() + 1),
    date: firstNull(dateObj.getDate()),
    hours: firstNull(dateObj.getHours()),
    minutes: firstNull(dateObj.getMinutes()),
  }
}

export function toTens(value) {
  if (value === 10) return value

  return Math.round(+value / 10) * 10
}

export function toFifth(value) {
  if (value === 5) return value

  return Math.round(+value / 5) * 5
}

export function timeStringToFloat(time) {
  const hoursMinutes = time.split(/[.:]/)
  const hours = parseInt(hoursMinutes[0], 10)
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0

  return hours + minutes / 60
}

export function durationToHoursAndMinutesArray(duration) {
  const hours = parseInt(duration / 60)
  const minutes = toTens(duration - hours * 60)

  return [hours, minutes]
}

export function durationToHoursAndMinutesString(duration) {
  const [hours, minutes] = durationToHoursAndMinutesArray(duration)

  return `${hours}:${minutes}`
}

export function durationToTimeFloat(duration) {
  return timeStringToFloat(durationToHoursAndMinutesString(duration))
}

export function floatToMinutesDuration(duration) {
  const hours = Math.floor(duration)
  const minutes = (duration - hours) * 60

  return Math.floor(hours * 60 + minutes)
}

export function floatToTimeString(timeFloat) {
  const min = 1 / 60
  const hours = Math.floor(timeFloat)

  let decimalPart = timeFloat - hours
  decimalPart = min * Math.round(decimalPart / min)

  const minutes = Math.floor(decimalPart * 60)

  return `${hours}:${firstNull(minutes)}`
}

export function parseDateTimeToObj(date) {
  if (!date) return

  let dateAndTimeObj = {}

  if (typeof date === 'object') {
    const dateTimeArr = getCustomDateObjFromDateObj(date)

    dateAndTimeObj = {
      date: dateTimeArr.year + '-' + dateTimeArr.month + '-' + dateTimeArr.date,
      time: dateTimeArr.hours + ':' + dateTimeArr.minutes,
    }
  } else {
    const dateObj = new Date(date)

    dateAndTimeObj = {
      date:
        dateObj.getFullYear() +
        '-' +
        firstNull(dateObj.getMonth() + 1) +
        '-' +
        firstNull(dateObj.getDate()),
      time:
        firstNull(dateObj.getHours()) + ':' + firstNull(dateObj.getMinutes()),
    }
  }

  return dateAndTimeObj
}

export function dateObjToDateNum(dateObj) {
  return Number(
    dateObj.getFullYear() +
      firstNull(dateObj.getMonth() + 1) +
      firstNull(dateObj.getDate())
  )
}

export function stringDateToArray(date) {
  const dateArr = date.split('-')

  return dateArr
}

export function stringDateToDateObj(stringDate) {
  const dateArr = stringDateToArray(stringDate)
  return new Date(dateArr[0], dateArr[1] - 1, dateArr[2], 0, 0, 0, 0)
}

export function stringTimeToArray(time) {
  const timeArr = time.split(':')

  return timeArr
}

export function timeToString(string) {
  if (!string) return

  return string.replace(':', '')
}

export function dateToNum(number) {
  if (!number) return

  return parseInt(number.replace(/-/g, ''))
}

export function dateToHoursMinutesSecondsMillsecondsToNull(date) {
  const nullDate = new Date(date)
  nullDate.setHours(0)
  nullDate.setMinutes(0)
  nullDate.setSeconds(0)
  nullDate.setMilliseconds(0)

  return nullDate
}

export function getSortingValueNumberFromDate(
  date = new Date(),
  withTime = true
) {
  const time = withTime
    ? String(firstNull(date.getHours()) + firstNull(date.getMinutes()))
    : ''

  return Number(
    date.getFullYear() +
      firstNull(date.getMonth() + 1) +
      firstNull(date.getDate()) +
      time
  )
}

export function getSortingValueNumberFromDateWithoutTime(date = new Date()) {
  return getSortingValueNumberFromDate(date, false)
}

export function getSortingValueNumberFromDateWithoutTimeAndDay(
  date = new Date()
) {
  const sortingValue = String(getSortingValueNumberFromDateWithoutTime(date))

  return Number(sortingValue.slice(0, sortingValue.length - 2))
}

export function getDateNumberFromSortingValueNumber(sortingValueNumber) {
  return String(sortingValueNumber).substring(0, 8)
}

export function getYearMonthDayArrayFromSortingValueNumber(sortingValueNumber) {
  return [
    +String(sortingValueNumber).substring(0, 4),
    +String(sortingValueNumber).substring(4, 6),
    +String(sortingValueNumber).substring(6, 8),
  ]
}

export function getDateStringFromSortingValueNumber(
  dateNumber,
  order = 'asc',
  divider = '.'
) {
  dateNumber = String(dateNumber)

  return order === 'asc'
    ? dateNumber.substring(0, 4) +
        divider +
        dateNumber.substring(4, 6) +
        divider +
        dateNumber.substring(6, 8)
    : dateNumber.substring(6, 8) +
        divider +
        dateNumber.substring(4, 6) +
        divider +
        dateNumber.substring(0, 4)
}

export function getDateFromSortingValueNumber(dateNumber) {
  dateNumber = String(dateNumber)

  return new Date(
    +dateNumber.substring(0, 4),
    +dateNumber.substring(4, 6) - 1,
    +dateNumber.substring(6, 8),
    dateNumber.length > 8 ? +dateNumber.substring(8, 10) : 0,
    dateNumber.length > 8 ? +dateNumber.substring(10, 12) : 0
  )
}

export function getDatePeriodArray(date, period, withTime = true) {
  let startDate = new Date(date)
  let endDate = new Date(date)

  startDate.setHours('0')
  startDate.setMinutes('0')

  endDate.setDate(startDate.getDate() + (period - 1))
  endDate.setHours('23')
  endDate.setMinutes('59')

  const periodArray = withTime
    ? [
        getSortingValueNumberFromDate(startDate),
        getSortingValueNumberFromDate(endDate),
      ]
    : [
        getSortingValueNumberFromDateWithoutTime(startDate),
        getSortingValueNumberFromDateWithoutTime(endDate),
      ]

  return periodArray
}

export function getMondayDateFromCurrentDate(date) {
  const mondayDate = new Date(date)
  const weekDay = mondayDate.getDay()
  const offset = weekDay === 0 ? 6 : weekDay - 1

  mondayDate.setDate(mondayDate.getDate() - offset)

  return mondayDate
}

export function getSundayFromCurrentDate(date) {
  const sundayDate = new Date(date)
  sundayDate.setDate(sundayDate.getDate() + 7)
  const nextWeekMonday = getMondayDateFromCurrentDate(sundayDate)

  nextWeekMonday.setDate(nextWeekMonday.getDate() - 1)

  return nextWeekMonday
}

export function getDateThatWillBeAfterOffsetDays(daysToNextDay) {
  let nextDateObj = new Date()

  nextDateObj.setDate(nextDateObj.getDate() + daysToNextDay)

  return (
    nextDateObj.getFullYear() +
    firstNull(nextDateObj.getMonth() + 1) +
    firstNull(nextDateObj.getDate())
  )
}

export function dateTimeFormat(date = new Date(), format = 'Y-Mo-D H:Mi:S') {
  /* Date & Time Formats Hints
   ** Y - year with 4 numbers
   ** YY - year with 2 numbers
   ** Mo - month number with lead zero
   ** D - day number with lead zero
   ** H - hour with lead zero
   ** Mi - minute with lead zero
   ** S - second with lead zero
   */

  let formatedDateTimeString = format

  if (format.includes('YY')) {
    formatedDateTimeString = formatedDateTimeString.replace(
      'YY',
      firstNull(date.getFullYear()).slice(-2)
    )
  }

  if (format.includes('Y')) {
    formatedDateTimeString = formatedDateTimeString.replace(
      'Y',
      firstNull(date.getFullYear())
    )
  }

  if (format.includes('Mo')) {
    formatedDateTimeString = formatedDateTimeString.replace(
      'Mo',
      firstNull(date.getMonth() + 1)
    )
  }

  if (format.includes('D')) {
    formatedDateTimeString = formatedDateTimeString.replace(
      'D',
      firstNull(date.getDate())
    )
  }

  if (format.includes('H')) {
    formatedDateTimeString = formatedDateTimeString.replace(
      'H',
      firstNull(date.getHours())
    )
  }

  if (format.includes('Mi')) {
    formatedDateTimeString = formatedDateTimeString.replace(
      'Mi',
      firstNull(date.getMinutes())
    )
  }

  if (format.includes('S')) {
    formatedDateTimeString = formatedDateTimeString.replace(
      'S',
      firstNull(date.getSeconds())
    )
  }

  return formatedDateTimeString
}

export function sortArrayByNumber(array, prop, order = 'asc') {
  return array.sort(function (a, b) {
    if (a[prop] < b[prop]) {
      return order === 'asc' ? -1 : 1
    }
    if (a[prop] > b[prop]) {
      return order === 'asc' ? 1 : -1
    }
    return 0
  })
}

export function sortArrayByString(array, prop = undefined) {
  return array.sort((a, b) =>
    prop ? a[prop].localeCompare(b[prop]) : a.localeCompare(b)
  )
}

export function sortObjectByKey(obj) {
  const keys = Object.keys(obj)
  const len = keys.length

  let i
  let sortedObj = {}
  keys.sort()

  for (i = 0; i < len; i++) {
    const k = keys[i]
    sortedObj[k] = obj[k]
  }

  return sortedObj
}

export function generateRandomID() {
  return Math.random().toString(36).substr(2, 3)
}

export function cleanPhone(phone) {
  return phone
    ? phone
        .replace('+', '')
        .replace(/-/g, '')
        .replace(/ /g, '')
        .replace('(', '')
        .replace(')', '')
    : phone
}

export function getPlural(
  howMuchNumber,
  pluralArray = ['день', 'дні', 'днів']
) {
  return howMuchNumber % 10 === 1 && howMuchNumber % 100 !== 11
    ? pluralArray[0]
    : howMuchNumber % 10 >= 2 &&
      howMuchNumber % 10 <= 4 &&
      (howMuchNumber % 100 < 10 || howMuchNumber % 100 >= 20)
    ? pluralArray[1]
    : pluralArray[2]
}

export function getPluralWithNumber(
  howMuchNumber,
  pluralArray = ['день', 'дні', 'днів']
) {
  return `${howMuchNumber} ${getPlural(howMuchNumber, pluralArray)}`
}

export function searchInArrayWithChildren(array, id, nestingKey = 'children') {
  if (array.length === 0) return

  return (
    array.find(
      (item) => item.value === id || item.id === id || item.value.id === id
    ) ||
    searchInArrayWithChildren(
      array.flatMap((item) => item[nestingKey] || []),
      id,
      nestingKey
    ) ||
    null
  )
}

export function flattenArray(arr) {
  return arr.reduce((flatArray, element) => {
    if (element.children) {
      const cleanedElement = { ...element }
      delete cleanedElement.children
      return flatArray.concat(cleanedElement, flattenArray(element.children))
    }
    return flatArray.concat(element)
  }, [])
}

export function sanitizeString(string) {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '’': '&#x27;',
    '-': '&#8208;',
    '#': '',
  }

  const reg = /[&<>"']/gi

  return string.replace(reg, (match) => map[match])
}

export function parseTextarea(string) {
  return sanitizeString(string)
    .replace(/\r\n|\r|\n/g, '<br/>')
    .replace(/<br\/><br\/>/g, '<br/>')
}
