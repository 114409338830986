import { db } from '@/plugins/firebase'
import store from '@/store/index.js'

const FINANCES_COLLECTION_KEY = 'finances'

const financesService = {
  addFinance: ({ batch, type, financeData }) => {
    const financeRef = db.collection(FINANCES_COLLECTION_KEY).doc()
    const financeRefId = financeRef.id

    batch.set(financeRef, {
      id: financeRefId,
      salonId: store.getters.userSalonId,
      type,
      ...financeData,
    })
  },
}

export default financesService
