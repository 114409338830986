<template>
  <div class="sells-item">
    {{ date }} / <strong>{{ productName }}</strong> /
    <CurrencyView :value="quantity" sign="шт" /> за ціною
    <CurrencyView :value="price" /> за шт / всього
    <CurrencyView :value="result" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { getDateStringFromSortingValueNumber } from '@/utils/functions'

  import CurrencyView from '@/components/Common/CurrencyView'

  export default {
    data() {
      return {}
    },
    props: {
      sell: Object,
    },
    components: { CurrencyView },
    watch: {},
    computed: {
      ...mapGetters(['productById']),
      date() {
        return getDateStringFromSortingValueNumber(this.sell.sortingValue)
      },
      product() {
        return this.productById(this.sell.productId)
      },
      productName() {
        return this.product.name
      },
      price() {
        return this.sell.price
      },
      quantity() {
        return this.sell.quantity
      },
      result() {
        return this.price * this.quantity
      },
    },
    methods: {},
  }
</script>

<style lang="scss" scoped></style>
