var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{ref:"eventForm",attrs:{"rules":_vm.rules,"model":_vm.eventForm,"label-width":"140px"}},[_c('el-form-item',{attrs:{"label":"Дата візиту","required":""}},[_c('div',{staticClass:"flex--md flex--vertical-center"},[_c('el-form-item',{staticClass:"margin-bottom-0 margin-right-10",attrs:{"prop":"dateStart"}},[_c('el-date-picker',{attrs:{"type":"date","format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","firstDayOfWeek":"1","picker-options":_vm.datePickerOptions,"disabled":_vm.isClientAdding || _vm.loading,"clearable":false},model:{value:(_vm.eventForm.dateStart),callback:function ($$v) {_vm.$set(_vm.eventForm, "dateStart", $$v)},expression:"eventForm.dateStart"}})],1),_c('div',{staticClass:"margin-right-10 show-up-md"},[_vm._v("-")]),_c('el-form-item',[_c('el-checkbox',{attrs:{"disabled":_vm.isClientAdding || _vm.loading},model:{value:(_vm.eventForm.faster),callback:function ($$v) {_vm.$set(_vm.eventForm, "faster", $$v)},expression:"eventForm.faster"}},[_vm._v(" По можливості перенести раніше ")])],1)],1)]),_c('el-form-item',{attrs:{"label":"Час початку","prop":"eventTimeStart","required":""}},[_c('el-time-select',{attrs:{"picker-options":{
        start: _vm.TIMESTART,
        step: '00:10',
        end: _vm.TIMEEND,
      },"format":"HH:mm","placeholder":"Час початку","disabled":_vm.isClientAdding || _vm.loading},on:{"change":_vm.countStartsAndEndsForProcedures},model:{value:(_vm.eventForm.eventTimeStart),callback:function ($$v) {_vm.$set(_vm.eventForm, "eventTimeStart", $$v)},expression:"eventForm.eventTimeStart"}}),(_vm.isEventInTurnOffTime)?_c('InfoPanel',{staticClass:"margin-top-15",attrs:{"text":"Візит, або його частина під час відключень/можливих відключень енергії."}}):_vm._e(),(_vm.isEventStartInTurnOffTime && !this.eventForm.eventTimeEnd)?_c('InfoPanel',{staticClass:"margin-top-15",attrs:{"text":"Початок візиту під час відключень/можливих відключень енергії."}}):_vm._e()],1),(_vm.dataLoaded)?_c('ClientFormItem',{attrs:{"prop":"client","rules":{
      required: true,
      message: 'Виберіть клієнта',
      trigger: 'blur',
    },"client":_vm.eventForm.client,"isClientAdding":_vm.isClientAdding},on:{"changeClientAdding":_vm.onChangeClientAdding,"choosedClient":_vm.onChoosedClient}}):_vm._e(),(_vm.dataLoaded)?_c('MastersItem',{ref:"mastersProceduresItem",attrs:{"isNewEvent":_vm.isNewEvent,"isClientAdding":_vm.isClientAdding,"eventTimeStart":_vm.eventForm.eventTimeStart,"masterIds":_vm.eventForm.masterIds,"mastersProcedures":_vm.eventForm.mastersProcedures,"date":_vm.eventForm.dateStart,"eventId":_vm.eventId},on:{"addMaster":_vm.onAddMaster,"sendMastersProcedures":_vm.onSendMastersProcedures,"sendEndTime":_vm.onSendEndTime,"removeMaster":_vm.onRemoveMaster}}):_vm._e(),_c('el-form-item',{attrs:{"label":"Додаткова інформація про візит","prop":"note"}},[_c('el-input',{attrs:{"disabled":_vm.loading,"type":"textarea","rows":3},model:{value:(_vm.eventForm.note),callback:function ($$v) {_vm.$set(_vm.eventForm, "note", $$v)},expression:"eventForm.note"}})],1),(
      _vm.eventForm.eventDuration ||
      (_vm.eventForm.eventTimeStart && _vm.eventForm.eventTimeEnd) ||
      _vm.eventForm.eventPrice
    )?_c('el-form-item',{attrs:{"label":"Інформація про візит"}},[_c('div',{staticClass:"line-height-1-5"},[(_vm.eventForm.eventDuration)?_c('div',[_vm._v(" Тривалість: "),_c('strong',[_vm._v(_vm._s(_vm.getDuration(_vm.eventForm.eventDuration)))])]):_vm._e(),(_vm.eventForm.eventTimeStart && _vm.eventForm.eventTimeEnd)?_c('div',[_vm._v(" Час: "),_c('strong',[_vm._v(_vm._s(_vm.eventForm.eventTimeStart)+" - "+_vm._s(_vm.eventForm.eventTimeEnd))])]):_vm._e(),(_vm.eventForm.eventPrice)?_c('div',[_vm._v(" Загальна вартість: "),_c('CurrencyView',{attrs:{"value":_vm.eventForm.eventPrice}})],1):_vm._e()])]):_vm._e(),(
      _vm.originEvent && _vm.originEvent.status && _vm.isCanceledEvent(_vm.originEvent.status)
    )?_c('el-form-item',{attrs:{"label":"Видалений візит"}},[_c('el-switch',{attrs:{"disabled":_vm.loading,"active-text":"Повернути до запланованих","inactive-text":"Залишити як є","active-value":"1","inactive-value":"2","active-color":"#13ce66","inactive-color":"#ff4949"},model:{value:(_vm.eventForm.status),callback:function ($$v) {_vm.$set(_vm.eventForm, "status", $$v)},expression:"eventForm.status"}})],1):_vm._e(),(_vm.originEvent?.editCounts)?_c('el-form-item',{attrs:{"label":"Кількість редагувань"}},[_c('strong',[_vm._v(_vm._s(_vm.originEvent.editCounts))])]):_vm._e(),(_vm.isClientWithBot)?_c('el-form-item',{attrs:{"label":"Повідомлення клієнту в бот"}},[_c('el-checkbox',{model:{value:(_vm.isNeedToSendBotMessage),callback:function ($$v) {_vm.isNeedToSendBotMessage=$$v},expression:"isNeedToSendBotMessage"}},[_vm._v(" Після редагування ми "+_vm._s(_vm.isNeedToSendBotMessage ? '' : 'не')+" відправляємо повідомлення в бот клієнту ")])],1):_vm._e(),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","loading":_vm.loading,"disabled":_vm.loading || _vm.isClientAdding},on:{"click":_vm.saveEvent}},[_vm._v(" "+_vm._s(_vm.isNewEvent ? 'Додати' : 'Редагувати')+" ")]),_c('el-button',{attrs:{"type":"danger","loading":_vm.loading,"disabled":_vm.loading || _vm.isClientAdding},on:{"click":_vm.closeDialog}},[_vm._v(" Скасувати ")])],1),(
      _vm.eventForm &&
      _vm.eventForm.eventTimeStart &&
      _vm.eventForm.dateStart &&
      _vm.eventForm.client &&
      _vm.eventForm.client.name
    )?_c('EventNoteForClient',{ref:"eventNoteForClient",attrs:{"clientName":_vm.eventForm.client.name,"eventDate":_vm.eventForm.dateStart,"eventStartTime":_vm.eventForm.eventTimeStart}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }