<template>
  <div class="client-cashback" v-loading="loading">
    <div class="client-cashback__header">
      <div class="client-cashback__title">
        <h6>Бали</h6>

        <el-link
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="addCashbackDialog = true"
          v-if="isUserIsOwnerOrLower && isClientPartEnabled"
          >Додати</el-link
        >
      </div>
    </div>

    <div v-if="cashback.length" class="margin-bottom-10">
      Всього: <CurrencyView :value="total" sign="B" />
    </div>

    <div v-if="!cashback || cashback.length === 0"><i>Поки що порожньо</i></div>

    <div v-if="cashback && cashback.length > 0">
      <ClientCashbackList
        :cashback="cashback"
        @doubleCashback="onDoubleCashback"
      />
    </div>

    <el-dialog
      title="Додати балі"
      width="60%"
      :visible.sync="addCashbackDialog"
      append-to-body
      :show-close="false"
      :close-on-click-modal="false"
      :before-close="closeAddCashbackDialog"
      destroy-on-close
      class="on-left"
    >
      <el-form
        :model="addCashbackForm"
        :rules="rules"
        ref="addCashbackForm"
        label-position="top"
      >
        <el-form-item label="Кількість" prop="value">
          <NumberInput
            v-model="addCashbackForm.value"
            :min="10"
            :max="20"
            size="small"
            :disabled="addCashbackValueDisabled"
          />
        </el-form-item>

        <el-form-item label="Причина" prop="reason">
          <el-radio-group v-model="addCashbackForm.reason" size="small">
            <el-radio-button label="stories">Сторіз</el-radio-button>
            <el-radio-button label="review">Відгук</el-radio-button>
            <el-radio-button label="other">Інша</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="Деталі" prop="note">
          <el-input
            type="textarea"
            v-model="addCashbackForm.note"
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addCashback">Додати</el-button>
        <el-button @click="closeAddCashbackDialog">Скасувати</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { db } from '@/plugins/firebase'
  import { mapGetters } from 'vuex'
  import { isUserIsOwnerOrLower } from '@/utils/users'
  import { constants } from '@/utils/constants'

  import {
    getSortingValueNumberFromDateWithoutTime,
    getSortingValueNumberFromDate,
  } from '@/utils/functions'

  import { HISTORY_TYPES } from '@/utils/history'
  import { CASHBACK_REASONS_KEYS } from '@/utils/expences'

  import clientService from '@/services/client-service'
  import financesService from '@/services/finances-service'

  import NumberInput from '@/components/Common/Forms/NumberInput'
  import CurrencyView from '@/components/Common/CurrencyView'
  import ClientCashbackList from '@/components/Clients/ClientCashback/ClientCashbackList'

  export default {
    data() {
      return {
        cashback: [],
        today: new Date(),
        todayNumber: getSortingValueNumberFromDateWithoutTime(this.today),
        addCashbackDialog: false,
        addCashbackForm: {
          value: 0,
          reason: 'stories',
          note: '',
        },
        addCashbackValueDisabled: false,
        doublingSameCashback: false,
        rules: {
          value: [constants.requiredFieldMessageRuleObject],
          note: [constants.requiredFieldMessageRuleObject],
        },
      }
    },
    props: {
      clientId: String,
    },
    computed: {
      ...mapGetters(['user', 'userSalon', 'loading', 'masterById']),
      currentUser() {
        return this.masterById(this.user.id)
      },
      isUserIsOwnerOrLower() {
        return isUserIsOwnerOrLower(this.currentUser)
      },
      total() {
        const todaySortValue = getSortingValueNumberFromDateWithoutTime()

        return this.cashback.reduce((acc, item) => {
          acc +=
            item.validFrom < todaySortValue && item.validTill > todaySortValue
              ? item.value
              : 0
          return acc
        }, 0)
      },
      isClientPartEnabled() {
        return !!(this.userSalon.loyality && this.userSalon.loyalityDaysValid)
      },
    },
    components: {
      NumberInput,
      ClientCashbackList,
      CurrencyView,
    },
    watch: {
      clientId: {
        async handler(value) {
          if (!value) return

          await this.getCashback()
        },
      },
    },
    methods: {
      async getCashback() {
        this.$store.dispatch('setLoading', true)

        this.cashback = await clientService.getClientCashbacks({
          clientId: this.clientId,
        })

        this.cashback = this.cashback.map((item) => {
          const validByDateFrom = this.todayNumber >= item.validFrom
          const validByDateTill = this.todayNumber <= item.validTill
          const validByValue = item.value !== 0

          return {
            ...item,
            isValid: validByDateTill && validByDateFrom && validByValue,
            notValidYet: this.todayNumber < item.validFrom,
          }
        })

        this.$store.dispatch('setLoading', false)
      },
      onDoubleCashback(cashbackItem) {
        this.addCashbackForm.value = cashbackItem.value

        this.addCashbackValueDisabled = true
        this.doublingSameCashback = true // we adding same value cashback
        this.addCashbackDialog = true
      },
      closeAddCashbackDialog() {
        this.addCashbackForm = {
          value: 0,
          reason: 'stories',
          note: '',
        }

        this.addCashbackDialog = false
        this.addCashbackValueDisabled = false
        this.doublingSameCashback = false
      },
      addCashback() {
        this.$refs.addCashbackForm.validate(async (valid) => {
          if (valid) {
            const batch = db.batch()
            const now = new Date()

            await clientService.addClientCashback({
              batch,
              clientId: this.clientId,
              cashbackValue: Number(this.addCashbackForm.value),
              data: {
                isReward: true,
                reason: this.addCashbackForm.reason,
                reasonKey: this.doublingSameCashback
                  ? CASHBACK_REASONS_KEYS.DOUBLE_FOR_EVENT
                  : CASHBACK_REASONS_KEYS.MANUAL,
                note: this.addCashbackForm.note,
                createdBy: this.currentUser.userId,
              },
              historyData: {
                currentUser: this.currentUser,
                now,
                type: HISTORY_TYPES.ADD_CLIENT_CASHBACK_MANUAL,
                additionalData: {
                  clientId: this.clientId,
                  value: Number(this.addCashbackForm.value),
                  reason: this.addCashbackForm.reason,
                  note: this.addCashbackForm.note,
                },
              },
            })

            financesService.addFinance({
              batch,
              type: 'clientCashback',
              financeData: {
                clientId: this.clientId,
                createdBy: this.currentUser.userId,
                eventId: null,
                createdAt: Date(),
                isReward: true,
                sortingValue: getSortingValueNumberFromDate(now),
                value: Number(this.addCashbackForm.value),
                reason: this.addCashbackForm.reason,
                note: this.addCashbackForm.note,
              },
            })

            batch
              .commit()
              .then(() => {
                this.closeAddCashbackDialog()
                this.$message.success('Готово')
              })
              .catch((error) => this.$store.dispatch('setError', error.message))
          }
        })
      },
    },
    async mounted() {
      await this.getCashback()
    },
  }
</script>

<style lang="scss" scoped>
  .client-cashback {
    width: 100%;

    &__header {
      display: block;
      align-items: baseline;
      margin-bottom: 10px;

      @media screen and (min-width: 767px) {
        display: flex;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      width: 100%;

      h6 {
        margin-right: 10px;
        margin-bottom: 0;
      }

      .el-button {
        margin-left: auto;
      }
    }
  }
</style>
