<template>
  <div>
    <ExpencesCategory
      v-for="category in categorizedCategories"
      :category="category"
      :key="category.id"
      :isSomeCategoryEditing="isSomeCategoryEditing"
      :class="{ angle: !category.root }"
      :rawCategorizedCategories="rawCategorizedCategories"
    />
  </div>
</template>

<script>
  import ExpencesCategory from '@/components/Finances/FinancesFlow/FinancesExpence/ExpencesCategories/ExpencesCategory'

  export default {
    props: {
      categorizedCategories: Array,
      isSomeCategoryEditing: Boolean,
      rawCategorizedCategories: Array,
    },
    data() {
      return {}
    },
    components: {
      ExpencesCategory,
    },
    computed: {},
    methods: {},
    mounted() {},
  }
</script>

<style lang="scss" scoped></style>
