import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

export default {
  state: {
    absences: [],
  },
  mutations: {},
  actions: {
    bindAbsences: firestoreAction(
      (context, { sortingValue, endSortingValue }) => {
        context.unbindFirestoreRef('absences')

        return context.bindFirestoreRef(
          'absences',
          db
            .collection('absences')
            .where('salonId', '==', context.getters.userSalonId)
            .where('sortingValue', '>=', sortingValue)
            .where('sortingValue', '<=', endSortingValue)
            .orderBy('sortingValue', 'asc')
        )
      }
    ),

    unbindAbsences: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('absences')
    }),

    deleteAbsence: firestoreAction((context, payload) => {
      return db.collection('absences').doc(payload.id).delete()
    }),
  },
  getters: {
    absences: (state) => state.absences,
    absenceById: (state) => (id) =>
      state.absences.find((event) => event.id === id),
    absencesByMaster: (state) => (masterId) => {
      return state.absences.filter(
        (event) => event.raw.master.userId === masterId
      )
    },
    absencesByFilteredMastersIds: (state) => (mastersIds) => {
      let returningEvents = []

      if (mastersIds && Array.isArray(mastersIds) && mastersIds.length > 0) {
        mastersIds.forEach(function (id) {
          let activeEvents = state.absences.filter((event) => {
            const masterId = event.master?.userId || event.raw?.master?.userId

            return masterId === id
          })

          returningEvents.push(...activeEvents)
        })
      }

      return returningEvents
    },
    absencesByDate: (state) => (date) => {
      return state.absences.filter((event) => {
        const absenceDate = event.dateStart || event.raw?.date

        return absenceDate === date
      }) // On date
    },
    absencesByMasterAndDate: (state) => (masterId, date) => {
      return state.absences
        .filter((event) => {
          const absenceMasterId =
            event.master?.userId || event.raw?.master?.userId

          return absenceMasterId === masterId
        }) // By master
        .filter((event) => {
          const absenceDate = event.dateStart || event.raw?.date

          return absenceDate === date
        }) // On date
    },
  },
}
