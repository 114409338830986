<template>
  <div class="client-search-container">
    <el-input
      placeholder="Ім'я або номер"
      v-model="queryString"
      size="small"
      clearable
      @keyup.enter.native="search"
      @clear="onClearInput"
      slot="reference"
    />

    <el-popover
      placement="bottom-end"
      width="300"
      trigger="manual"
      v-model="isVisible"
      :popper-class="popperClass"
    >
      <div class="search-results" v-loading="localLoading">
        <div class="clients-list margin-bottom-5" v-if="results.length">
          <el-scrollbar
            wrap-style="max-height: 165px;"
            class="client-suggestions-list"
          >
            <div v-for="client in results" :key="client.id" class="client-item">
              <ClientView :client="client" class="margin-right-5" />
            </div>
          </el-scrollbar>
        </div>

        <div v-if="notEnough || !queryString" class="small margin-bottom-5">
          <span class="color-danger"
            >Введіть щонайменше {{ minimalCharsForSearch }}
            {{ minimalCharsForSearch === 2 ? 'символи' : 'символів'
            }}<br />(частину імені або телефону)</span
          >
        </div>

        <div class="search-results-footer">
          <div class="small">
            <div v-if="results.length">
              Знайдено: <strong>{{ results.length }}</strong>
            </div>

            <div v-if="notFound">
              <span class="color-danger">Нічого не знайдено</span>
            </div>
          </div>

          <el-link type="danger" class="close-link" @click="search"
            >Закрити</el-link
          >
        </div>
      </div>

      <el-button
        :icon="
          isVisible && queryString === searchedString
            ? 'el-icon-close'
            : 'el-icon-search'
        "
        :type="isVisible && queryString === searchedString ? 'danger' : ''"
        size="small"
        slot="reference"
        :loading="localLoading"
        @click="search"
      />
    </el-popover>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { searchClientsFromSQLNotFull } from '@/utils/clients'

  import { constants } from '@/utils/constants'

  import { isUserIsAdminOrLower } from '@/utils/users'

  import ClientView from '@/components/Common/Clients/ClientView'

  export default {
    data() {
      return {
        isVisible: false,
        queryString: '',
        searchedString: '',
        results: [],
        notFound: false,
        notEnough: true,
        localLoading: false,
      }
    },
    computed: {
      ...mapGetters(['user', 'masterById']),
      currentUser() {
        return this.masterById(this.user.id)
      },
      isUserIsAdminOrLower() {
        return isUserIsAdminOrLower(this.currentUser)
      },
      minimalCharsForSearch() {
        return this.isUserIsAdminOrLower
          ? constants.minimalCharsForSearch
          : constants.minimalCharsForMaster
      },
      popperClass() {
        return `client-search ${this.atBottom ? 'at-bottom' : ''}`
      },
    },
    props: {
      atBottom: {
        default: false,
        type: Boolean,
      },
    },
    components: {
      ClientView,
    },
    watch: {},
    methods: {
      async search() {
        let justOpened = false

        if (!this.isVisible) {
          this.isVisible = true
          justOpened = true
        }

        if (this.isVisible) {
          this.notEnough =
            !this.queryString ||
            this.queryString.toLowerCase().length < this.minimalCharsForSearch

          if (this.searchedString !== this.queryString) {
            if (!this.notEnough) {
              this.localLoading = true

              const results = await searchClientsFromSQLNotFull({
                queryString: this.queryString,
              }).catch((error) =>
                this.$store.dispatch('setError', error.message)
              )

              this.results = results

              this.localLoading = false

              this.searchedString = this.queryString
            } else {
              if (this.results.length) {
                this.results = []
              }
            }

            this.notFound =
              this.results && this.results.length === 0 && !this.notEnough
          } else {
            if (!justOpened) {
              this.isVisible = false
            }
          }
        }
      },
      onClearInput() {
        this.results = []
      },
    },
  }
</script>

<style lang="scss" scoped>
  .client-search-container {
    display: flex;

    &::v-deep {
      .el-input__inner {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .client-item {
    & + .client-item {
      margin-top: 5px;
    }
  }

  .search-results-footer {
    display: flex;
    align-items: flex-end;
  }

  .close-link {
    margin-left: auto;
  }
</style>
