import { sortArrayByNumber, timeStringToFloat } from './functions'

import { isEvent, isAbsense, isStudy, isCanceledEvent } from '@/utils/statuses'

// Solution from http://jsbin.com/nosefotipo/edit?html,js,output
let EventsById = {}

function isFreeSpace(ts, leftindex, eventid) {
  var tslength = ts.length
  var event

  for (var i = 0; i < tslength; ++i) {
    // get the event in this timeslot location
    event = EventsById[ts[i]]

    if (event.leftindex == leftindex) {
      if (event.id != eventid) {
        return false // left index taken
      } else {
        return true // this event is in this place
      }
    }
  }

  return true
}

function setUpEvents(events, STARTTIME, HEIGHTOFHOUR, type) {
  var numEvents = events.length
  var event, e, pos, stH, stM, etH, etM, height

  for (e = 0; e < numEvents; e++) {
    event = events[e]

    const ver2 =
      (event.ver2 || event.timeStart) && type === 'event' ? true : false // TODO remove after 01.03.2022

    let timeStart, timeEnd

    if (ver2) {
      // TODO remove after 01.03.2022
      timeStart = event.timeStart
      timeEnd = event.timeEnd
    } else {
      // TODO remove after 01.03.2022
      timeStart = event.timeStart || event.raw.timeStart
      timeEnd = event.timeEnd || event.raw.timeEnd
    }

    event.leftindex = -1
    event.numcolumns = 0

    pos = timeStart.indexOf(':')
    stH = parseInt(timeStart.substring(0, pos), 10)
    stM = parseInt(timeStart.substring(pos + 1), 10) / 60

    // need its positions top and bottom in minutes
    event.startNum = (stH - STARTTIME) * 60 + stM * 60
    event.topPos = (stH - STARTTIME) * HEIGHTOFHOUR + stM * HEIGHTOFHOUR

    pos = timeEnd.indexOf(':')
    etH = parseInt(timeEnd.substring(0, pos), 10)
    etM = parseInt(timeEnd.substring(pos + 1), 10) / 60

    // need its positions top and bottom in minutes
    event.stopNum = (etH - STARTTIME) * 60 + etM * 60

    height = (etH - stH) * HEIGHTOFHOUR
    height -= stM * HEIGHTOFHOUR
    height += etM * HEIGHTOFHOUR

    event.height = height

    EventsById[event.id] = event
  }
}

export const updateEvents = function (originEvents, timeArray, type) {
  const STARTTIME = timeStringToFloat(timeArray[0])
  const ENDTIME = timeStringToFloat(timeArray[timeArray.length - 1]) + 1

  const HEIGHTOFHOUR = 100 / timeArray.length
  const MINUTESINDAY = (ENDTIME - STARTTIME) * 60

  let events = [...originEvents]
  let m, e, ts, event
  let timeslots = []

  events = sortArrayByNumber(
    events,
    type === 'events' ? 'sortingValue' : 'timeStartNumber'
  )

  for (m = 0; m < MINUTESINDAY; m++) {
    timeslots.push([])
  }

  setUpEvents(events, STARTTIME, HEIGHTOFHOUR, type)

  var numEvents = events.length

  for (e = 0; e < numEvents; e++) {
    event = events[e]

    for (m = event.startNum; m < event.stopNum; m++) {
      timeslots[m].push(event.id)
    }
  }

  for (m = 0; m < MINUTESINDAY; m++) {
    ts = timeslots[m]

    for (e = 0; e < ts.length; e++) {
      event = EventsById[ts[e]]

      let max = ts.length

      ts.forEach(function (id) {
        var evt = EventsById[id]

        max = evt.numcolumns > max ? evt.numcolumns : max
      })

      if (event.numcolumns <= max) {
        event.numcolumns = max
      }

      if (event.leftindex == -1) {
        let leftindex = 0

        while (!isFreeSpace(ts, leftindex, event.id)) {
          leftindex++
        }

        event.leftindex = leftindex
      }
    }
  }

  for (m = 0; m < MINUTESINDAY; m++) {
    ts = timeslots[m]

    for (e = 0; e < ts.length; e++) {
      event = EventsById[ts[e]]

      var max = ts.length

      ts.forEach(function (id) {
        var evt = EventsById[id]
        max = evt.numcolumns > max ? evt.numcolumns : max
      })

      if (event.numcolumns <= max) {
        event.numcolumns = max
      }
    }
  }

  return events
}

export const getIdFromVer2Events = (id) => {
  return id.includes('_') ? id.split('_')[0] : id
}

export const transformNewEventsToOldView = (
  array,
  filteredMastersArray,
  showRemovedEvents
) => {
  let returningEvents = []

  array.forEach((event) => {
    let isNeededEvent = false
    const status = event.status || event.raw?.status

    if (isEvent(status)) {
      const ver2 = event.mastersProcedures ? true : false // TODO remove after 01.03.2022

      if (!ver2) {
        // Old events
        if (filteredMastersArray.includes(event.raw?.master?.userId)) {
          isNeededEvent = true

          if (!showRemovedEvents && isCanceledEvent(status)) {
            isNeededEvent = false
          }
        }
      } else {
        // New events. we need to transform them to different for each masters that in
        isNeededEvent = false

        Object.keys(event.mastersProcedures).forEach((masterKey) => {
          const [index, masterId] = masterKey.split('_')

          if (isEvent(status) && filteredMastersArray.includes(masterId)) {
            const masterProceduresItem = event.mastersProcedures[masterKey]

            if (
              (isCanceledEvent(status) && showRemovedEvents) ||
              !isCanceledEvent(status)
            ) {
              returningEvents.push({
                timeStart: masterProceduresItem.masterStartTime,
                timeEnd: masterProceduresItem.masterEndTime,
                masterId: masterProceduresItem.masterId,
                procedures: masterProceduresItem.procedures,
                price: masterProceduresItem.masterPrice,
                originPrice: masterProceduresItem.masterOriginPrice,
                masterKey,
                history: event.history,
                id: `${event.id}_${masterId}_${index}`,
                status: event.status,
                dateStart: event.dateStart,
                dateEnd: event.dateEnd,
                dateEndObj: event.dateEndObj,
                eventTimeEnd: event.eventTimeEnd,
                client: event.client,
                mastersIds: event.masterIds,
                confirmed: event.confirmed ? event.confirmed : false,
                note: event.note,
                faster: event.faster,
                ver2: true,
                ...(isCanceledEvent(status) && {
                  ...(event.cancelReason && {
                    cancelReason: event.cancelReason,
                  }),
                  ...(event.cancelNote && {
                    cancelNote: event.cancelNote,
                  }),
                }),
              })
            }
          }
        })
      }
    } else if (isAbsense(status)) {
      isNeededEvent = filteredMastersArray.includes(event.master?.userId)
    } else if (isStudy(status)) {
      isNeededEvent = true
    }

    if (isNeededEvent) {
      returningEvents.push(event)
    }
  })

  return returningEvents
}
