<template>
  <div class="client-event" :id="event.id">
    <div
      class="client-event__status"
      :style="{ backgroundColor: statusById(event.status).color }"
    />

    <div class="client-event__info">
      <div class="client-event__info-primary">
        <span>{{ date }}</span>

        <span class="title" v-show="!hideTitle">
          <span v-if="event.studyId && event.modelFromStudy">
            Модель на процедуру {{ event.studyProcedure }}
          </span>

          <span v-if="event.studyId && !event.modelFromStudy">
            Оплата за курс <strong>{{ event.studyName }}</strong>
          </span>

          <span v-if="!event.studyId">
            {{ event.title }}
          </span>
        </span>

        <span class="separator">/</span>

        <CurrencyView :value="event.price || event.eventPrice" />

        <span v-if="event.master || event.masterId">
          <span class="separator">/</span>

          {{ masterById(event.master || event.masterId).name }}
        </span>

        <div class="client-event__info-link" v-if="!event.studyId">
          <EventContainer :eventId="event.id" />
        </div>
      </div>

      <div class="client-event__info-time" v-if="!event.studyId">
        {{ event.timeStart }} - {{ event.timeEnd }}
      </div>

      <div v-if="event.note" class="client-event__info-note">
        {{ event.note }}
      </div>

      <div
        class="client-event__info-secondary"
        v-if="event.additionalFields"
        :class="{ 'one-line': Object.keys(event.additionalFields).length < 5 }"
      >
        <div
          v-for="(field, index) in getAdditionalFields(event.additionalFields)"
          :key="index"
          class="client-event__info-secondary-item"
        >
          {{ field.name }}:
          <span v-if="typeof field.value === 'object'">{{
            field.value.join(', ')
          }}</span>
          <span v-if="typeof field.value !== 'object'">{{ field.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CurrencyView from '@/components/Common/CurrencyView'
  import EventContainer from '@/components/Event/EventContainer'

  export default {
    data() {
      return {}
    },
    props: {
      event: Object,
      hideTitle: Boolean,
    },
    computed: {
      ...mapGetters(['procedureById', 'statusById', 'masterById']),
      date() {
        const dateArray = this.event.date.split('-')

        return dateArray[2] + '.' + dateArray[1] + '.' + dateArray[0]
      },
    },
    components: {
      CurrencyView,
      EventContainer,
    },
    methods: {
      getAdditionalFields(fields) {
        return Object.fromEntries(Object.entries(fields).sort())
      },
    },
  }
</script>

<style lang="scss" scoped>
  .client-event {
    display: flex;

    &__status {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-top: 5px;
      margin-right: 7px;
    }
  }

  .client-event__info {
    width: 100%;

    &-link {
      margin-left: auto;
      font-size: 85%;
      padding-left: 5px;
      flex: 0 0 auto;
    }

    &-time,
    &-note {
      margin-top: 3px;
      font-size: 85%;
    }

    &-time {
      color: $gray;
    }
  }

  .client-event__info-primary {
    align-items: baseline;

    @media screen and (min-width: 480px) {
      display: flex;
    }

    .title {
      margin: 0 0 0 5px;
      flex: 0 1 auto;
    }

    .separator {
      margin: 0 5px;
    }

    span {
      flex: 0 0 auto;
    }
  }

  .client-event__info-secondary {
    &:not(:empty) {
      padding: 5px 0 10px;
      border-bottom: 1px solid $border-color;
    }

    &-item {
      color: $gray;

      & + div {
        margin-top: 5px;
      }

      span {
        color: $text-color;
      }
    }

    &.one-line {
      display: flex;
      justify-content: stretch;

      .client-event__info-secondary-item {
        margin: 0;
        flex: 1;

        & + div {
          padding-left: 10px;
        }

        span {
          display: block;
        }
      }
    }
  }
</style>
