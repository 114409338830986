import Vue from 'vue'
import { firestorePlugin } from 'vuefire'

import fb from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/app-check'

let firebaseConfig =
  process.env.NODE_ENV === 'development' ||
  window.location.hostname === 'test.vytya.com'
    ? {
        apiKey: 'AIzaSyCKYg2x7XykbExmTS2185oVbFGS0Wtp_OU',
        authDomain: 'beautymanagerdev-826b0.firebaseapp.com',
        databaseURL: 'https://beautymanagerdev-826b0.firebaseio.com',
        projectId: 'beautymanagerdev-826b0',
        storageBucket: 'beautymanagerdev-826b0.appspot.com',
        messagingSenderId: '104660487423',
        appId: '1:104660487423:web:e1612e5c7dc53bf949d0ca',
      }
    : {
        apiKey: 'AIzaSyAnKuBBYKpezqwOMUHUQzds099UNNoXRDk',
        authDomain: 'bossy-86bee.firebaseapp.com',
        projectId: 'bossy-86bee',
        storageBucket: 'bossy-86bee.appspot.com',
        messagingSenderId: '190762160169',
        appId: '1:190762160169:web:c5e2b304c07b75a5ce4c38',
      }

// firebaseConfig = {
//   apiKey: 'AIzaSyAnKuBBYKpezqwOMUHUQzds099UNNoXRDk',
//   authDomain: 'bossy-86bee.firebaseapp.com',
//   projectId: 'bossy-86bee',
//   storageBucket: 'bossy-86bee.appspot.com',
//   messagingSenderId: '190762160169',
//   appId: '1:190762160169:web:c5e2b304c07b75a5ce4c38',
// }

// if (process.env.NODE_ENV === 'development') {
//   fb.firestore.setLogLevel('debug')
// }

Vue.use(firestorePlugin)

export const firebaseApp = fb.initializeApp(firebaseConfig)
export const db = firebaseApp.firestore()

const appCheck = fb.appCheck()
appCheck.activate(
  process.env.NODE_ENV === 'development'
    ? '6LcYN2QmAAAAAP6jRzCsDqjDszecsxfPdiqL0Gzm'
    : '6LfGMmQmAAAAAJm3mvnuyRVTgxJZoAEfLl_JEsXc',
  true
)

export async function getUserTokenId() {
  let tokenId = ''
  const user = fb.auth().currentUser

  if (user) {
    tokenId = await fb.auth().currentUser.getIdToken(/* forceRefresh */ true)
  }

  return tokenId
}
