export const MASTER_PERCENT_OPTIONS = [
  {
    id: 0,
    label: '0',
  },
  { id: 5, label: '5%' },
  { id: 10, label: '10%' },
  { id: 15, label: '15%' },
  { id: 20, label: '20%' },
  { id: 999, label: 'Інший відсоток' },
  { id: 9999, label: 'Фіксована сума' },
]
