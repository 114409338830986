var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form-item',{staticClass:"time-in-row",attrs:{"label":"Час","required":""}},[_c('div',{staticClass:"time-in-row__time"},[_c('el-form-item',{attrs:{"prop":"timeStart"}},[_c('el-time-select',{attrs:{"picker-options":{
          start: _vm.TIMESTART_FALLBACK,
          step: '00:30',
          end: _vm.TIMEEND_FALLBACK,
        },"format":"HH:mm","disabled":_vm.timeStartDisabled,"placeholder":"Час початку"},on:{"change":_vm.sendData},model:{value:(_vm.timeStart),callback:function ($$v) {_vm.timeStart=$$v},expression:"timeStart"}})],1),_c('div',{staticStyle:{"margin":"0 10px"}},[_vm._v("-")]),_c('el-form-item',{attrs:{"prop":"timeEnd"}},[_c('el-time-select',{attrs:{"picker-options":{
          start: _vm.timeStart ? _vm.timeStart : _vm.TIMESTART_FALLBACK,
          step: '00:30',
          end: _vm.TIMEEND_FALLBACK,
          minTime: _vm.timeStart ? _vm.timeStart : _vm.TIMESTART_FALLBACK,
        },"format":"HH:mm","disabled":_vm.timeEndDisabled,"placeholder":"Час закінчення"},on:{"change":_vm.sendData},model:{value:(_vm.timeEnd),callback:function ($$v) {_vm.timeEnd=$$v},expression:"timeEnd"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }