<template>
  <div>
    <el-form-item
      :label="`Майстер ${masterName}`"
      :prop="'mastersProcedures.' + masterKey + '.procedures'"
      :rules="{
        required: true,
        message: 'Виберіть процедури',
        trigger: 'change',
      }"
    >
      <ProceduresItem
        :isNewEvent="isNewEvent"
        :eventFormProcedures="localMasterProcedures.procedures"
        :eventTimeStart="eventTimeStart"
        :isClientAdding="isClientAdding"
        :masterId="localMasterProcedures.masterId"
        :masterKey="masterKey"
        @sendProcedureItem="onSendProcedureItem"
        @sendProcedures="onSendProcedures"
        @removeProcedureItem="onRemoveProcedureItem"
        @moveProcedure="onMoveProcedure"
        @removeMaster="onRemoveMaster"
      />
    </el-form-item>

    <el-form-item
      label="Інформація про процедури майстра"
      v-if="
        localMasterProcedures.procedures &&
        (localMasterProcedures.masterDuration ||
          (localMasterProcedures.masterStartTime &&
            localMasterProcedures.masterEndTime) ||
          localMasterProcedures.masterPrice)
      "
    >
      <div class="line-height-1-5">
        <div v-if="localMasterProcedures.masterDuration">
          Тривалість:
          <strong>{{
            getDuration(localMasterProcedures.masterDuration)
          }}</strong>
        </div>

        <div
          v-if="
            localMasterProcedures.masterStartTime &&
            localMasterProcedures.masterEndTime
          "
        >
          Час:
          <strong
            >{{ localMasterProcedures.masterStartTime }} -
            {{ localMasterProcedures.masterEndTime }}</strong
          >
        </div>

        <div v-if="localMasterProcedures.masterPrice">
          Загальна вартість:
          <CurrencyView :value="localMasterProcedures.masterPrice" />
        </div>
      </div>
    </el-form-item>

    <MasterItemEvents
      :masterId="mastersItem.masterId"
      :date="date"
      :eventId="correctEventId"
      :currentItemTimeStart="mastersItem.masterStartTime"
      :currentItemTimeEnd="mastersItem.masterEndTime"
      :newEvent="isNewEvent"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import {
    durationToHoursAndMinutesArray,
    sortObjectByKey,
  } from '@/utils/functions'

  import MasterItemEvents from '@/components/Calendar/EventsForms/Default/MasterItemEvents'
  import ProceduresItem from '@/components/Calendar/EventsForms/Default/ProceduresItem'
  import CurrencyView from '@/components/Common/CurrencyView'

  export default {
    data() {
      return {
        localMasterProcedures: {},
      }
    },
    props: {
      isNewEvent: Boolean,
      masterIds: Array,
      isClientAdding: Boolean,
      eventTimeStart: String,
      mastersItem: Object,
      masterKey: String,
      mastersCount: Number,
      date: String,
      eventId: String,
    },
    components: {
      MasterItemEvents,
      ProceduresItem,
      CurrencyView,
    },
    computed: {
      ...mapGetters(['loading', 'masterById']),
      masterName() {
        return this.masterById(this.mastersItem.masterId).name
      },
      correctEventId() {
        if (!this.eventId) return ''

        const [eventId] = this.eventId.split('_')

        return eventId
      },
    },
    watch: {
      mastersItem: {
        immediate: true,
        deep: false,
        handler(masterProcedures) {
          if (!masterProcedures) return

          this.localMasterProcedures = JSON.parse(
            JSON.stringify(masterProcedures)
          )
        },
      },
    },
    methods: {
      getDuration(duration) {
        const [hours, minutes] = durationToHoursAndMinutesArray(duration)

        return `${hours}г ${minutes}хв`
      },
      onSendProcedureItem({ procedureKey, procedureItem }) {
        Object.assign(
          this.localMasterProcedures.procedures[procedureKey],
          JSON.parse(JSON.stringify(procedureItem))
        )

        this.sendMasterProcedures()
      },
      onSendProcedures(procedureItems) {
        this.$set(
          this.localMasterProcedures,
          'procedures',
          JSON.parse(JSON.stringify(procedureItems))
        )

        this.sendMasterProcedures()
      },
      onRemoveProcedureItem({ procedureKey }) {
        const copyOfProcedures = { ...this.localMasterProcedures.procedures }

        delete copyOfProcedures[procedureKey]

        Object.keys(copyOfProcedures).forEach((procedureKey, index) => {
          const [, procedureId] = procedureKey.split('_')
          const newProcedureKey = `${index}_${procedureId}`
          const procedure = copyOfProcedures[procedureKey]

          delete copyOfProcedures[procedureKey]

          if (
            !Object.prototype.hasOwnProperty.call(
              copyOfProcedures,
              newProcedureKey
            )
          ) {
            copyOfProcedures[newProcedureKey] = procedure
          }
        })

        this.$set(
          this.localMasterProcedures,
          'procedures',
          JSON.parse(JSON.stringify(copyOfProcedures))
        )

        this.sendMasterProcedures()
      },
      onMoveProcedure({ newIndex, procedureKey }) {
        const copyOfProcedures = { ...this.localMasterProcedures.procedures }

        const [movedProcedureIndex, movedProcedureId] = procedureKey.split('_')
        const procedureToBeMoved = copyOfProcedures[procedureKey]

        delete copyOfProcedures[procedureKey]

        let foundReplacedProcedureKey

        for (let loopProcedureKey in copyOfProcedures) {
          if (loopProcedureKey.startsWith(`${newIndex}_`)) {
            foundReplacedProcedureKey = loopProcedureKey

            break
          }
        }

        const [replacedProcedureIndex, replacedProcedureId] =
          foundReplacedProcedureKey.split('_')
        const procedureToBeReplaced =
          copyOfProcedures[foundReplacedProcedureKey]

        delete copyOfProcedures[foundReplacedProcedureKey]

        const movedProcedureKey = `${replacedProcedureIndex}_${movedProcedureId}`
        const replacedProcedureKey = `${movedProcedureIndex}_${replacedProcedureId}`

        copyOfProcedures[movedProcedureKey] = procedureToBeMoved
        copyOfProcedures[replacedProcedureKey] = procedureToBeReplaced

        this.$set(
          this.localMasterProcedures,
          ['procedures'],
          sortObjectByKey(JSON.parse(JSON.stringify(copyOfProcedures)))
        )

        this.sendMasterProcedures()
      },
      onRemoveMaster(masterKey) {
        this.$emit('removeMaster', masterKey)
      },
      sendMasterProcedures() {
        this.$emit('sendMasterProcedures', {
          masterProcedures: JSON.parse(
            JSON.stringify(this.localMasterProcedures)
          ),
          masterKey: this.masterKey,
        })
      },
    },
    beforeDestroy() {},
  }
</script>

<style lang="scss" scoped></style>
