import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

export default {
  state: {
    productsCategories: [],
  },
  mutations: {},
  actions: {
    bindProductsCategories: firestoreAction((context) => {
      const salonId = context.getters.userSalonId

      return context.bindFirestoreRef(
        'productsCategories',
        db.collection('productsCategories').where('salonId', '==', salonId)
      )
    }),

    unbindProductsCategories: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('productsCategories')
    }),

    addProductsCategory: firestoreAction((context, payload) => {
      payload = Object.assign(
        {
          salonId: context.getters.userSalonId,
        },
        payload
      )

      return db
        .collection('productsCategories')
        .add(payload)
        .then(function (docRef) {
          db.collection('productsCategories')
            .doc(docRef.id)
            .set({ id: docRef.id }, { merge: true })
        })
    }),

    updateProductsCategory: firestoreAction((context, payload) => {
      return db
        .collection('productsCategories')
        .doc(payload.id)
        .set(payload, { merge: true })
    }),

    removeProductsCategory: firestoreAction((context, catId) => {
      return db
        .collection('productsCategories')
        .doc(catId)
        .set({ active: Boolean(false) }, { merge: true })
    }),
  },
  getters: {
    productsCategories: (state) => state.productsCategories,
    productsActiveCategories: (state) =>
      state.productsCategories.filter((cat) => cat.active === true),
    productsCategoryById: (state) => (id) => {
      return state.productsCategories.find((cat) => cat.id === id)
    },
    productsCategoryByName: (state) => (name) => {
      return state.productsCategories.find((cat) => cat.name === name)
    },
  },
}
