<template>
  <el-button
    @click="addModel(date)"
    :disabled="loading"
    size="small"
    type="primary"
  >
    Обрати
    {{
      eventForm.models[date]
        ? eventForm.models[date].length > 0
          ? 'іншу'
          : ''
        : ''
    }}
    модель на {{ date }}
  </el-button>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {}
    },
    props: {
      date: String,
      eventForm: Object,
    },
    watch: {},
    computed: {
      ...mapGetters(['loading']),
    },
    components: {},
    methods: {
      addModel(date) {
        this.$emit('addModel', date)
      },
    },
  }
</script>

<style lang="scss" scoped></style>
