import { db } from '@/plugins/firebase'
import store from '@/store/index.js'

const ABSENCES_COLLECTION_KEY = 'absences'

const absenceService = {
  getAbsence: async ({ absenceId }) => {
    await store.dispatch('setLoading', true)

    let event = {}

    await db
      .collection(ABSENCES_COLLECTION_KEY)
      .doc(absenceId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          event = doc.data()
        }
      })
      .catch((error) => store.dispatch('setError', error.message))
      .finally(() => store.dispatch('setLoading', false))

    return event
  },

  addAbsence: ({ batch, absenceData }) => {
    const absenceRef = db.collection(ABSENCES_COLLECTION_KEY).doc()
    const absenceRefId = absenceRef.id

    batch.set(absenceRef, {
      id: absenceRefId,
      salonId: store.getters.userSalonId,
      ...(absenceData && { ...absenceData }),
    })
  },

  updateAbsence: ({ batch, absenceId, absenceData }) => {
    const absenceRef = db.collection(ABSENCES_COLLECTION_KEY).doc(absenceId)

    batch.set(
      absenceRef,
      {
        salonId: store.getters.userSalonId,
        ...(absenceData && { ...absenceData }),
      },
      { merge: true }
    )
  },

  deleteAbsence: ({ batch, absenceId }) => {
    batch.delete(db.collection(ABSENCES_COLLECTION_KEY).doc(absenceId))
  },
}

export default absenceService
