<template>
  <div class="sales-categories">
    <h6>Добавить новую категорию</h6>

    <el-form
      ref="salesCategoryForm"
      label-position="top"
      :model="salesCategoryForm"
      :rules="rules"
      class="sales-category-form"
    >
      <el-form-item label="Название" prop="name" size="small">
        <el-input v-model="salesCategoryForm.name"></el-input>
      </el-form-item>

      <el-form-item size="small">
        <el-button type="primary" @click="submitForm()">Добавить</el-button>
        <el-button @click="closeDialog()">Закрыть</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { constants } from '@/utils/constants'

  export default {
    data() {
      return {
        salesCategoryForm: {
          name: '',
        },
        rules: {
          name: [
            constants.requiredFieldMessageRuleObject,
            {
              min: 3,
              max: 20,
              message: 'Длина от 3 и до 20 символов',
              trigger: 'blur',
            },
          ],
        },
      }
    },
    computed: {
      ...mapGetters(['userSalonId']),
    },
    methods: {
      submitForm() {
        this.$refs.salesCategoryForm.validate((valid) => {
          if (valid) {
            const self = this

            let category = {
              name: this.salesCategoryForm.name,
              active: true,
              salonId: this.userSalonId,
            }

            this.$store
              .dispatch('addSalesCategory', category)
              .then(function () {
                self.$message.success('Категория добавлена')
                self.closeDialog()
              })
          } else {
            return false
          }
        })
      },
      closeDialog() {
        this.$refs.salesCategoryForm.resetFields()
        this.$emit('closeIncomeCategoriesForm')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sales-categories {
    &-list {
      margin-bottom: 40px;
    }

    &-item {
      padding-top: 5px;
      padding-bottom: 5px;

      & + div {
        border-top: 1px solid $border-color;
      }

      &__children {
        padding-left: 10px;

        div {
          &::before {
            content: '-';
            margin-right: 5px;
          }
        }
      }
    }
  }

  .sales-categories-close {
    margin-top: 30px;
    margin-left: auto;
    margin-right: 0;
    display: block;
  }
</style>
