<template>
  <div class="margin-top-10 margin-bottom-15">
    <div class="category-editing margin-bottom-10 line-height-1-5">
      <div>
        <div>
          Батьківська категорія
          <el-tooltip
            :content="`Рівень вкладеності категорій не повинен перевищувати ${PARENT_CATEGORIES_MAX_LEVEL} рівнів`"
            placement="top"
          >
            <el-link type="text" :underline="false">
              &nbsp;<i class="el-icon-question color-danger"
            /></el-link>
          </el-tooltip>
        </div>

        <el-cascader
          v-model="localCategory.parentCategory"
          :options="rawCategorizedCategories"
          :props="{ checkStrictly: true, emitPath: false }"
          :show-all-levels="false"
          ref="parentCategory"
          @change="checkParentCategory"
        />
      </div>

      <div>
        <div>Назва</div>

        <el-input v-model="localCategory.name" placeholder="Назва" required />
      </div>
    </div>

    <div class="line-height-1-5">
      <el-checkbox
        v-model="isGoodsCategory"
        :disabled="isSameGoodsExpenceCategory"
      >
        Категорія для закупок товарів
      </el-checkbox>

      <span>&nbsp;</span>

      <el-tooltip
        v-if="isSameGoodsExpenceCategory"
        content="Категорія обрана, якщо ви хочете змінити, то виберіть іншу і збережіть"
      >
        <i class="el-icon-question color-danger" />
      </el-tooltip>
    </div>

    <div class="line-height-1-5">
      <el-checkbox
        v-model="isMaterialCategory"
        :disabled="isSameMaterialExpenceCategory"
      >
        Категорія для закупок матеріалів
      </el-checkbox>

      <span>&nbsp;</span>

      <el-tooltip
        v-if="isSameMaterialExpenceCategory"
        content="Категорія обрана, якщо ви хочете змінити, то виберіть іншу і збережіть"
      >
        <i class="el-icon-question color-danger" />
      </el-tooltip>
    </div>

    <div v-if="isSameParentCategoryChosen" class="color-danger small">
      Не можна вибрати ту ж категорію як батьківську
    </div>

    <div v-if="isTooNested" class="color-danger small">
      Можна вказати лише {{ PARENT_CATEGORIES_MAX_LEVEL }} рівень вкладеності
    </div>

    <div class="margin-top-10">
      <el-button
        @click="saveCategory"
        type="success"
        :disabled="
          isSameParentCategoryChosen ||
          isTooNested ||
          loading ||
          localCategory.name === ''
        "
        >Зберегти</el-button
      >

      <el-button @click="cancelEditing" :disabled="loading" type="danger"
        >Скасувати</el-button
      >
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { db } from '@/plugins/firebase'

  import { PARENT_CATEGORIES_MAX_LEVEL } from '@/utils/expences'

  import expenceService from '@/services/expence-service'
  import salonService from '@/services/salon-service'

  export default {
    props: {
      rawCategorizedCategories: Array,
      category: Object,
      isNew: Boolean,
    },
    data() {
      return {
        localCategory: {},
        isSameParentCategoryChosen: false,
        isTooNested: false,
        isGoodsCategory: false,
        isMaterialCategory: false,
        PARENT_CATEGORIES_MAX_LEVEL,
      }
    },
    watch: {
      category: {
        handler: function (value) {
          if (!value) return

          this.localCategory = JSON.parse(JSON.stringify(value))

          if (!value.parentCategory) {
            this.localCategory.parentCategory = 'other'
          }

          if (this.isSameMaterialExpenceCategory) {
            this.isMaterialCategory = true
          }

          if (this.isSameGoodsExpenceCategory) {
            this.isGoodsCategory = true
          }
        },
        immediate: true,
      },
    },
    computed: {
      ...mapGetters(['loading', 'userSalon']),
      materialExpenceCategory() {
        return this.userSalon.materialExpenceCategory
      },
      isSameMaterialExpenceCategory() {
        return this.localCategory.id === this.materialExpenceCategory
      },
      goodsExpenceCategory() {
        return this.userSalon.goodsExpenceCategory
      },
      isSameGoodsExpenceCategory() {
        return this.localCategory.id === this.goodsExpenceCategory
      },
    },
    methods: {
      checkParentCategory() {
        if (this.$refs.parentCategory) {
          const [{ level }] = this.$refs.parentCategory.getCheckedNodes()

          this.isTooNested = level === PARENT_CATEGORIES_MAX_LEVEL
        }

        this.isSameParentCategoryChosen =
          this.localCategory.parentCategory === this.localCategory.id
      },
      allFlatChildren(catsArray) {
        return catsArray.reduce((result, item) => {
          result.push(item)

          if (item.children) {
            result = result.concat(this.allFlatChildren(item.children))

            delete item.children
          }

          return result
        }, [])
      },
      flatChildrenCategories(children, batch, parentCategory) {
        this.allFlatChildren(JSON.parse(JSON.stringify(children))).forEach(
          (item) => {
            expenceService.updateCategory({
              categoryId: item.id,
              categoryData: {
                parentCategory,
              },
              batch,
            })
          }
        )
      },
      saveCategory() {
        if (!this.isSameParentCategoryChosen && !this.isTooNested) {
          const batch = db.batch()

          this.$store.dispatch('setLoading', true)

          const categoryToSave = {
            name: this.localCategory.name.trim(),
            parentCategory: this.localCategory.parentCategory,
          }

          if (
            this.localCategory.level >= PARENT_CATEGORIES_MAX_LEVEL &&
            this.localCategory.children?.length > 0
          ) {
            this.flatChildrenCategories(
              this.localCategory.children,
              batch,
              this.localCategory.parentCategory
            )
          }

          if (this.isGoodsCategory && !this.isSameGoodsExpenceCategory) {
            salonService.updateSalon({
              batch,
              salonId: this.userSalon.id,
              salonData: {
                goodsExpenceCategory: this.localCategory.id,
              },
            })
          }

          if (this.isMaterialCategory && !this.isSameMaterialExpenceCategory) {
            salonService.updateSalon({
              batch,
              salonId: this.userSalon.id,
              salonData: {
                materialExpenceCategory: this.localCategory.id,
              },
            })
          }

          if (this.isNew) {
            expenceService.addCategory({
              batch,
              categoryData: {
                ...categoryToSave,
                active: true,
              },
            })
          } else {
            expenceService.updateCategory({
              batch,
              categoryId: this.localCategory.id,
              categoryData: {
                ...categoryToSave,
              },
            })
          }

          batch
            .commit()
            .then(() => this.cancelEditing())
            .catch((error) => {
              this.$store.dispatch('setError', error.message)
            })
            .finally(() => {
              this.$store.dispatch('setLoading', false)
            })
        }
      },
      cancelEditing() {
        this.isSameParentCategoryChosen = false
        this.isTooNested = false

        this.$emit('categoryEditing', false)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .category-editing {
    align-items: flex-end;

    @include to('sm') {
      display: flex;

      > div,
      .el-button {
        margin-right: 10px;
        margin-left: 0;
      }
    }
  }
</style>
