import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

import { isEvent, isStudy, isPlannedEvent } from '@/utils/statuses'

import { transformNewEventsToOldView } from '@/utils/events'

import { getClientsFromSqlByIdsAndAddToStore } from '@/utils/clients'

export default {
  state: {
    events: [],
    filteredEventType: {},
    syncDate: {},
  },
  mutations: {
    setFilteredEventType(state, payload) {
      state.filteredEventType = payload
    },
    setSyncDate(state, payload) {
      state.syncDate = payload
    },
  },
  actions: {
    bindAllEvents: firestoreAction((context) => {
      context.dispatch('setLoading', true)

      context.unbindFirestoreRef('events')

      return context
        .bindFirestoreRef(
          'events',
          db
            .collection('events')
            .where('salonId', '==', context.getters.userSalonId)
            .orderBy('sortingValue', 'asc')
        )
        .then(function () {
          context.dispatch('setLoading', false)
        })
    }),

    bindEvents: firestoreAction(
      (context, { sortingValue, endSortingValue }) => {
        context.unbindFirestoreRef('events')

        return context
          .bindFirestoreRef(
            'events',
            db
              .collection('events')
              .where('salonId', '==', context.getters.userSalonId)
              .where('sortingValue', '>=', sortingValue)
              .where('sortingValue', '<=', endSortingValue)
              .orderBy('sortingValue', 'asc')
          )
          .then(async (data) => {
            const clientIds = data
              .filter((item) => item.client || item.raw?.client)
              .map((item) => {
                const clientId = item.client?.id || item.raw?.client?.id

                return clientId
              })

            await getClientsFromSqlByIdsAndAddToStore([...new Set(clientIds)])
          })
          .catch((error) => context.dispatch('setError', error.message))
      }
    ),

    unbindEvents: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('events')
    }),

    addEvent: firestoreAction((context, payload) => {
      payload = Object.assign(
        {
          salonId: context.getters.userSalonId,
        },
        payload
      )

      return db
        .collection('events')
        .add(payload)
        .then(function (docRef) {
          db.collection('events')
            .doc(docRef.id)
            .set({ id: docRef.id }, { merge: true })

          return docRef.id
        })
    }),

    updateEvent: firestoreAction((context, payload) => {
      return db
        .collection('events')
        .doc(payload.id)
        .set(payload, { merge: true })
    }),

    updateEventRaw: firestoreAction((context, payload) => {
      const eventId = payload.id

      delete payload.id

      return db.collection('events').doc(eventId).update(payload.raw)
    }),

    deleteEvent: firestoreAction((context, payload) => {
      context.dispatch('setLoading', true)

      return db
        .collection('events')
        .doc(payload.id)
        .delete()
        .then(function () {
          context.dispatch('setLoading', false)
        })
    }),

    setFilteredEventType({ commit }, payload) {
      commit('setFilteredEventType', payload)
    },

    setSyncDate({ commit }, payload) {
      commit('setSyncDate', payload)
    },
  },
  getters: {
    events: (state) => state.events,
    eventsActive: (state) => {
      return state.events
        .filter((event) => event.raw?.status !== 4) // Not Study events
        .filter((event) => event.raw?.statusActive === true)
    },
    eventActiveOnly: (state) => {
      return state.events.filter((event) => {
        return isEvent(event.raw?.status || event.status)
      })
    },
    eventsCanceled: (state) =>
      state.events.filter((event) => event.raw?.statusActive === false),
    eventById: (state) => (id) => state.events.find((event) => event.id === id),
    eventsByFilteredMastersIds:
      (state, getters) =>
      (mastersIds, showRemovedEvents, importedEvents = []) => {
        const returningEvents = transformNewEventsToOldView(
          getters.eventActiveOnly,
          mastersIds,
          showRemovedEvents
        )

        return [...returningEvents, ...importedEvents]
      },
    eventsByMasterAndDate: (state) => (masterId, date) => {
      return state.events
        .filter(
          (event) => (event.statusActive || event.raw?.statusActive) === true
        ) // Active // TODO remove after 01.03.2022
        .filter((event) => !isStudy(event.status || event.raw?.status)) // Not Study events // TODO remove after 01.03.2022
        .filter((event) =>
          event.raw // TODO remove after 01.03.2022
            ? event.raw?.master
              ? event.raw?.master.userId === masterId
              : false
            : event.masterIds.includes(masterId)
        ) // By master
        .filter((event) => (event.dateStart || event.raw?.date) === date) // On date // TODO remove after 01.03.2022
    },
    eventsByDate: (state) => (date) => {
      return state.events
        .filter((event) => !isStudy(event.status || event.raw?.status)) // Not Study events // TODO remove after 01.03.2022
        .filter((event) => (event.dateStart || event.raw?.date) === date) // On date // TODO remove after 01.03.2022
    },
    eventsPendingByDate: (state) => (date) => {
      return state.events
        .filter((event) => isPlannedEvent(event.status || event.raw?.status)) // Pending events // TODO remove after 01.03.2022
        .filter((event) => (event.date || event.raw?.date) === date) // On date // TODO remove after 01.03.2022
    },
    eventsStudy: (state) =>
      state.events.filter((event) =>
        isStudy(event.status || event.raw?.status)
      ), // TODO remove after 01.03.2022
    filteredEventType: (state) => state.filteredEventType,
    syncDate: (state) => state.syncDate,
  },
}
