import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  state: {
    income: [],
  },
  mutations: {},
  actions: {
    bindIncome: firestoreAction((context) => {
      context.dispatch('setLoading', true)

      return context
        .bindFirestoreRef(
          'income',
          db
            .collection('income')
            .where('salonId', '==', context.getters.userSalonId)
            .orderBy('sortingValue', 'desc')
        )
        .then(function () {
          context.dispatch('setLoading', false)
        })
    }),

    unbindIncome: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('income')
    }),

    updateIncome: firestoreAction((context, payload) => {
      return db
        .collection('income')
        .doc(payload.id)
        .set(payload, { merge: true })
    }),

    updateIncomeHistory: firestoreAction((context, payload) => {
      const history = {
        history: firebase.firestore.FieldValue.arrayUnion({
          changedBy: payload.changedBy,
          changedAt: payload.changedAt,
          ...(payload.value && { value: payload.value }),
          ...(payload.method && { method: payload.method }),
          ...(payload.combiCardValue && {
            combiCardValue: payload.combiCardValue,
          }),
          ...(payload.combiCashValue && {
            combiCashValue: payload.combiCashValue,
          }),

          ...(payload.masterIncome && { masterIncome: payload.masterIncome }),
          ...(payload.masterId && { masterId: payload.masterId }),
          ...(payload.masterPercents && {
            masterPercents: payload.masterPercents,
          }),
          ...(payload.comment && { comment: payload.comment }),
        }),
      }

      db.collection('income').doc(payload.id).update(history)
    }),

    removeIncome: firestoreAction((context, incomeId) => {
      db.collection('income').doc(incomeId).delete()
    }),
  },
  getters: {
    income: (state) => state.income,
    incomeById: (state) => (id) => {
      return state.income.find((income) => income.id === id)
    },
    incomeByClient: (state) => (clientId) => {
      return state.income.filter((income) => income.clientId === clientId)
    },
    incomeByMaster: (state) => (masterId) => {
      return state.income.filter((income) => income.masterId === masterId)
    },
    incomeByDateByMasterIdsAndByProcedureIds:
      (state, getters) => (datePeriod, mastersIds, proceduresIds) => {
        let returnIncomeArr = []
        let datePeriodArray = datePeriod.split('-')

        mastersIds.forEach(function (masterId) {
          returnIncomeArr.push(getters.incomeByMaster(masterId))
        })

        returnIncomeArr = returnIncomeArr.flat().filter((income) => {
          if (proceduresIds.includes(income.procedureId) || income.saleItem) {
            return (
              income.sortingValue >= Number(datePeriodArray[0]) &&
              income.sortingValue <= Number(datePeriodArray[1])
            )
          }
        })

        returnIncomeArr = returnIncomeArr.sort(function (a, b) {
          if (a.sortingValue < b.sortingValue) {
            return 1
          }
          if (a.sortingValue > b.sortingValue) {
            return -1
          }
          return 0
        })

        return returnIncomeArr
      },
  },
}
