<template>
  <span>&nbsp;</span>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { isSuperAdmin } from '@/utils/users'

  export default {
    data() {
      return {}
    },
    components: {},
    computed: {
      ...mapGetters(['loading', 'siteSettings', 'user', 'masterById']),
      currentUser() {
        return this.masterById(this.user.id)
      },
      maintenance() {
        return this.siteSettings.maintenance
      },
    },
    props: {},
    watch: {
      maintenance: {
        handler(maintenanceState) {
          if (!maintenanceState || isSuperAdmin(this.currentUser)) return

          this.openMessage()

          this.$store.dispatch('setLoading', false)
        },
        immediate: true,
      },
    },
    methods: {
      openMessage() {
        this.$alert(
          'Наразі ми працюємо над оновленням нашого сервісу. Будь ласка, зайдіть пізніше.',
          {
            confirmButtonText: 'Вийти',
            type: 'warning',
            showClose: false,
            callback: () => {
              this.$store.dispatch('logoutUser')
              this.$router.push('/').catch(() => {})
            },
          }
        )
      },
    },
  }
</script>
