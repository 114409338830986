<template>
  <div class="client-sells">
    <div class="client-sells__header">
      <div class="client-sells__title">
        <h6>ПОКУПКИ</h6>
      </div>
    </div>

    <div v-loading="loading">
      <div v-if="!sells || sells.length === 0">
        <i>Клієнт поки що нічого не купував</i>
      </div>

      <div v-if="sells && sells.length" class="client-sells-list">
        <ClientGoodsList :sells="sells" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import clientService from '@/services/client-service'

  import ClientGoodsList from '@/components/Clients/ClientGoods/ClientGoodsList'

  export default {
    data() {
      return {
        sells: [],
      }
    },
    props: {
      clientId: String,
    },
    components: {
      ClientGoodsList,
    },
    watch: {
      clientId: {
        immediate: true,
        deep: false,
        async handler(id) {
          if (!id) {
            return
          }

          this.$store.dispatch('setLoading', true)

          this.sells = await clientService.getClientSells({ clientId: id })

          this.$store.dispatch('setLoading', false)
        },
      },
    },
    computed: {
      ...mapGetters(['user', 'masterById', 'loading']),
      currentUser() {
        return this.masterById(this.user.id)
      },
    },
    methods: {},
    beforeDestroy() {
      this.sells = []
    },
  }
</script>

<style lang="scss" scoped>
  .client-sells {
    width: 100%;

    &__header {
      display: block;
      margin-bottom: 10px;
    }

    &__title {
      h6 {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
  }
</style>
