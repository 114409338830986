<template>
  <ul>
    <li v-for="(event, index) in events" :key="index">
      <ClientEventProcedureItem :event="event" />
    </li>
  </ul>
</template>

<script>
  import ClientEventProcedureItem from '@/components/Clients/ClientEvents/ClientEventProcedureItem'

  export default {
    data() {
      return {}
    },
    props: {
      events: Array,
    },
    components: {
      ClientEventProcedureItem,
    },
  }
</script>

<style lang="scss" scoped></style>
