import firebase from 'firebase/app'
import * as Sentry from '@sentry/browser'
import 'firebase/auth'
import { db } from '@/plugins/firebase'

import { onLogin } from '../../utils/login'

let salonListener

export default {
  state: {
    user: null,
    userSalonId: '',
    userSalon: {},
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload

      if (
        process.env.NODE_ENV !== 'development' &&
        Sentry &&
        payload &&
        payload.email
      ) {
        Sentry.setUser({ email: payload.email })
      }
    },
    setSalonId(state, payload) {
      state.userSalonId = payload

      if (process.env.NODE_ENV !== 'development' && Sentry && payload) {
        Sentry.setTag('salonId', payload)
      }
    },
    setSalon(state, payload) {
      state.userSalon = payload
    },
  },
  actions: {
    async registerUser({ commit, dispatch }, payload) {
      commit('clearError')
      commit('setLoading', true)

      try {
        const user = await firebase
          .auth()
          .createUserWithEmailAndPassword(payload.email, payload.password)

        await dispatch('addMaster', {
          userId: user.user.uid,
          email: user.user.email,
          role: payload.role,
          phone: payload.phone,
          salonId: payload.salonId,
          name: payload.name,
          eventLetter: payload.name ? payload.name.charAt(0).toUpperCase() : '',
          inviteId: payload.inviteId,
          active: true,
        })

        await dispatch('updateSalonMasters', {
          salonId: payload.salonId,
          master: {
            id: user.user.uid,
            name: payload.name,
            email: user.user.email,
          },
          role: payload.role,
        })

        commit('setSuccess', 'Профіль ' + user.user.email + ' успішно створено')
      } catch (error) {
        commit('setError', error.message)
        throw error
      } finally {
        commit('setLoading', false)
      }
    },
    async loginUser({ commit, getters, dispatch }, { email, password }) {
      commit('clearError')
      commit('setLoading', true)

      try {
        const user = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password)

        commit('setUser', { id: user.user.uid, email: user.user.email })

        onLogin(getters, dispatch)

        return user
      } catch (error) {
        commit('setError', error.message)
        throw error
      }
    },
    async forgotPassword({ commit }, { email }) {
      commit('clearError')
      commit('setLoading', true)

      try {
        await firebase.auth().sendPasswordResetEmail(email)

        commit(
          'setSuccess',
          'Посилання для скидання пароля відправлено на ваш email'
        )
      } catch (error) {
        commit('setError', error.message)
        throw error
      } finally {
        commit('setLoading', false)
      }
    },
    autoLoginUser({ commit, getters, dispatch }, payload) {
      commit('setUser', { id: payload.uid, email: payload.email })

      onLogin(getters, dispatch)
    },
    setSalonId({ commit }, payload) {
      commit('setSalonId', payload)
    },
    async setSalon({ commit, dispatch }, payload) {
      salonListener = await db
        .collection('salons')
        .doc(payload)
        .onSnapshot(
          (doc) => {
            const salon = doc.data()

            commit('setSalon', salon)

            if (salon?.partnerIds && salon?.partnerIds?.length) {
              dispatch('bindPartnerMasters')
              dispatch('bindUsedMasters')
            }
          },
          function (error) {
            commit('setError', error.message)
          }
        )
    },
    async logoutUser({ commit }) {
      try {
        if (salonListener) {
          await salonListener()
        }

        commit('setUser', null)
        commit('setSalonId', '')
        commit('setSalon', {})

        await firebase.auth().signOut()

        commit('setLoading', false)
      } catch (error) {
        commit('setError', error.message)
        commit('setLoading', false)
        throw error
      }
    },
  },
  getters: {
    user: (state) => state.user,
    isUserLoggedIn(state) {
      return state.user !== null
    },
    userSalonId: (state) => state.userSalonId,
    userSalon: (state) => state.userSalon,
  },
}
