import { flattenArray } from './functions'

export const EXPENCES_TYPES_KEYS = {
  CAPITAL: 'capital',
  OPERATIONAL: 'operational',
  OTHER: 'other',
}

export const EXPENCES_TYPES = {
  [EXPENCES_TYPES_KEYS.CAPITAL]: 'Капітальні витрати',
  [EXPENCES_TYPES_KEYS.OPERATIONAL]: 'Операційні витрати',
  [EXPENCES_TYPES_KEYS.OTHER]: 'Інші витрати',
}

export const EXPENCES_KEYS = {
  PURCHASES: 'PURCHASES',
  PURCHASES_GOODS: 'PURCHASES_GOODS',
  PURCHASES_MATERIALS: 'PURCHASES_MATERIALS',
  ROOM: 'ROOM',
  RENT: 'RENT',
  UTILITY_PAYMENTS: 'UTILITY_PAYMENTS',
  FINANCE: 'FINANCE',
  TAXES: 'TAXES',
  ACCOUNT_MAINTENANCE: 'ACCOUNT_MAINTENANCE',
  AQUIRING: 'AQUIRING',
  SALARY_ACCOUNTINGS: 'SALARY_ACCOUNTINGS',
  SALARY: 'SALARY',
  SALARY_ADMIN: 'SALARY_ADMIN',
  SALARY_MASTER: 'SALARY_MASTER',
  SALARY_OTHER: 'SALARY_OTHER',
  FOR_WORK: 'FOR_WORK',
  PRODUCTS: 'PRODUCTS',
  WATER: 'WATER',
  CHEMISTRY: 'CHEMISTRY',
  OFFICE_SUPPLIES: 'OFFICE_SUPPLIES',
  HOUSEHOLD: 'HOUSEHOLD',
  EXTRA: 'EXTRA',
  CLIENT_DAYS: 'CLIENT_DAYS',
  CORPORATE_COSTS: 'CORPORATE_COSTS',
  EQUIPMENT_REPAIR: 'EQUIPMENT_REPAIR',
  LICENSE_FEES: 'LICENSE_FEES',
  COMMUNICATION: 'COMMUNICATION',
  ADVERTISING: 'ADVERTISING',
  CLIENT: 'CLIENT',
}

export const EXPENSES_VALUES = {
  PURCHASES: 'purchases',
  PURCHASES_GOODS: 'purchases-goods',
  PURCHASES_MATERIALS: 'purchases-materials',
  ROOM: 'room',
  RENT: 'rent',
  UTILITY_PAYMENTS: 'utility-payments',
  FINANCE: 'finance',
  TAXES: 'taxes',
  ACCOUNT_MAINTENANCE: 'account-maintenance',
  AQUIRING: 'acquiring',
  SALARY_ACCOUNTINGS: 'salary-accountings',
  SALARY: 'salary',
  SALARY_ADMIN: 'salary-admin',
  SALARY_MASTER: 'salary-master',
  SALARY_OTHER: 'salary-other',
  FOR_WORK: 'for-work',
  PRODUCTS: 'products',
  WATER: 'water',
  OFFICE_SUPPLIES: 'office-supplies',
  HOUSEHOLD: 'household',
  EXTRA: 'extra',
  CHEMISTRY: 'chemistry',
  CLIENT_DAYS: 'client-days',
  CORPORATE_COSTS: 'corporate-costs',
  EQUIPMENT_REPAIR: 'equipment-repair',
  LICENSE_FEES: 'license-fees',
  COMMUNICATION: 'communication',
  ADVERTISING: 'advertising',
  CLIENT: 'client',
  OPERATIONAL: 'operational',
  CAPITAL: 'capital',
}

export const EXPENSES_LABELS = {
  PURCHASES: 'Закупівлі',
  PURCHASES_GOODS: 'Закупівля товару',
  PURCHASES_MATERIALS: 'Закупівля матеріалів',
  ROOM: 'Приміщення',
  RENT: 'Оренда',
  UTILITY_PAYMENTS: 'Комунальні платежі',
  FINANCE: 'Фінансові витрати',
  TAXES: 'Податки',
  ACCOUNT_MAINTENANCE: 'Обслуговування р/с',
  AQUIRING: 'Еквайринг',
  SALARY_ACCOUNTINGS: 'Бухгалтерські послуги',
  SALARY: 'Зарплата',
  SALARY_ADMIN: 'Адміністративні',
  SALARY_MASTER: 'Майстри',
  SALARY_OTHER: 'Інші зарплати',
  CHEMISTRY: 'Хімія',
  FOR_WORK: 'Для роботи',
  PRODUCTS: 'Продукти',
  WATER: 'Вода',
  OFFICE_SUPPLIES: 'Канцтовари',
  HOUSEHOLD: 'Господарські витрати',
  EXTRA: 'Додаткові витрати',
  CLIENT_DAYS: 'Клієнтські дні',
  CORPORATE_COSTS: 'Корпоративні витрати',
  EQUIPMENT_REPAIR: 'Ремонт обладнання',
  LICENSE_FEES: 'Оплата ліцензій',
  COMMUNICATION: "Зв'язок",
  ADVERTISING: 'Реклама',
  CLIENT: 'Витрати, створені клієнтом',
}
export const LEGACY_EXPENCES_CATEGORIES = [
  {
    value: EXPENSES_VALUES[EXPENCES_KEYS.PURCHASES],
    label: EXPENSES_LABELS[EXPENCES_KEYS.PURCHASES],
    type: EXPENCES_TYPES_KEYS.OPERATIONAL,
    children: [
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.PURCHASES_GOODS],
        label: EXPENSES_LABELS[EXPENCES_KEYS.PURCHASES_GOODS],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.PURCHASES_MATERIALS],
        label: EXPENSES_LABELS[EXPENCES_KEYS.PURCHASES_MATERIALS],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
    ],
  },
  {
    value: EXPENSES_VALUES[EXPENCES_KEYS.ROOM],
    label: EXPENSES_LABELS[EXPENCES_KEYS.ROOM],
    type: EXPENCES_TYPES_KEYS.OPERATIONAL,
    children: [
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.RENT],
        label: EXPENSES_LABELS[EXPENCES_KEYS.RENT],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.UTILITY_PAYMENTS],
        label: EXPENSES_LABELS[EXPENCES_KEYS.UTILITY_PAYMENTS],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
    ],
  },
  {
    value: EXPENSES_VALUES[EXPENCES_KEYS.FINANCE],
    label: EXPENSES_LABELS[EXPENCES_KEYS.FINANCE],
    type: EXPENCES_TYPES_KEYS.OPERATIONAL,
    children: [
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.TAXES],
        label: EXPENSES_LABELS[EXPENCES_KEYS.TAXES],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.ACCOUNT_MAINTENANCE],
        label: EXPENSES_LABELS[EXPENCES_KEYS.ACCOUNT_MAINTENANCE],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.AQUIRING],
        label: EXPENSES_LABELS[EXPENCES_KEYS.AQUIRING],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.SALARY_ACCOUNTINGS],
        label: EXPENSES_LABELS[EXPENCES_KEYS.SALARY_ACCOUNTINGS],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
    ],
  },
  {
    value: EXPENSES_VALUES[EXPENCES_KEYS.SALARY],
    label: EXPENSES_LABELS[EXPENCES_KEYS.SALARY],
    type: EXPENCES_TYPES_KEYS.OPERATIONAL,
    children: [
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.SALARY_ADMIN],
        label: EXPENSES_LABELS[EXPENCES_KEYS.SALARY_ADMIN],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.SALARY_MASTER],
        label: EXPENSES_LABELS[EXPENCES_KEYS.SALARY_MASTER],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.SALARY_OTHER],
        label: EXPENSES_LABELS[EXPENCES_KEYS.SALARY_OTHER],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
    ],
  },
  {
    value: EXPENSES_VALUES[EXPENCES_KEYS.HOUSEHOLD],
    label: EXPENSES_LABELS[EXPENCES_KEYS.HOUSEHOLD],
    type: EXPENCES_TYPES_KEYS.CAPITAL,
    children: [
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.PRODUCTS],
        label: EXPENSES_LABELS[EXPENCES_KEYS.PRODUCTS],
        type: EXPENCES_TYPES_KEYS.CAPITAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.WATER],
        label: EXPENSES_LABELS[EXPENCES_KEYS.WATER],
        type: EXPENCES_TYPES_KEYS.CAPITAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.OFFICE_SUPPLIES],
        label: EXPENSES_LABELS[EXPENCES_KEYS.OFFICE_SUPPLIES],
        type: EXPENCES_TYPES_KEYS.CAPITAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.CHEMISTRY],
        label: EXPENSES_LABELS[EXPENCES_KEYS.CHEMISTRY],
        type: EXPENCES_TYPES_KEYS.CAPITAL,
      },
    ],
  },
  {
    value: EXPENSES_VALUES[EXPENCES_KEYS.EXTRA],
    label: EXPENSES_LABELS[EXPENCES_KEYS.EXTRA],
    type: EXPENCES_TYPES_KEYS.OTHER,
    children: [
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.CLIENT_DAYS],
        label: EXPENSES_LABELS[EXPENCES_KEYS.CLIENT_DAYS],
        type: EXPENCES_TYPES_KEYS.CAPITAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.CORPORATE_COSTS],
        label: EXPENSES_LABELS[EXPENCES_KEYS.CORPORATE_COSTS],
        type: EXPENCES_TYPES_KEYS.CAPITAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.EQUIPMENT_REPAIR],
        label: EXPENSES_LABELS[EXPENCES_KEYS.EQUIPMENT_REPAIR],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.LICENSE_FEES],
        label: EXPENSES_LABELS[EXPENCES_KEYS.LICENSE_FEES],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.COMMUNICATION],
        label: EXPENSES_LABELS[EXPENCES_KEYS.COMMUNICATION],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
      {
        value: EXPENSES_VALUES[EXPENCES_KEYS.ADVERTISING],
        label: EXPENSES_LABELS[EXPENCES_KEYS.ADVERTISING],
        type: EXPENCES_TYPES_KEYS.OPERATIONAL,
      },
    ],
  },
]

export const LEGACY_EXPENCES_CATEGORIES_WITHOUT_CHILDREN = flattenArray(
  LEGACY_EXPENCES_CATEGORIES
)

export const CASHBACK_REASONS_KEYS = {
  EVENT: 'EVENT',
  DOUBLE_FOR_EVENT: 'DOUBLE_FOR_EVENT',
  MANUAL: 'MANUAL',
  REFERRAL_INVITED_CLIENT: 'REFERRAL_INVITED_CLIENT',
  REFERRAL_JOINED_CLIENT: 'REFERRAL_JOINED_CLIENT',
}

export const CASHBACK_REASONS = {
  [CASHBACK_REASONS_KEYS.EVENT]: 'Бали за визіт',
  [CASHBACK_REASONS_KEYS.DOUBLE_FOR_EVENT]: 'Подвоєні бали за визіт',
  [CASHBACK_REASONS_KEYS.MANUAL]: 'Бали додані вручну',
  [CASHBACK_REASONS_KEYS.REFERRAL_INVITED_CLIENT]: 'Бали клієнту, що запросив',
  [CASHBACK_REASONS_KEYS.REFERRAL_JOINED_CLIENT]:
    'Бали клієнту, якого запросили',
}

export const PARENT_CATEGORIES_MAX_LEVEL = 4

export const PARENT_CATEGORIES_FOR_NESTED_ARRAY = Object.keys(
  EXPENCES_TYPES_KEYS
).map((key) => {
  return {
    id: EXPENCES_TYPES_KEYS[key],
    value: EXPENCES_TYPES_KEYS[key],
    label: EXPENCES_TYPES[EXPENCES_TYPES_KEYS[key]],
    name: EXPENCES_TYPES[EXPENCES_TYPES_KEYS[key]],
    root: true,
  }
})

export const FINANCE_REPORT_DATA_STRUCTURE = {
  incomes: {
    events: {
      procedures: {
        // procedureId1: {
        //   value: 0,
        //   count: 0,
        // },
        // procedureId2: {
        //   value: 0,
        //   count: 0,
        // },
      },
      value: 0,
      count: 0,
      clients: {
        // Not sure that it's needed
        new: 0,
      },
      masters: {},
    },
    certificates: {
      value: 0,
      count: 0,
    },
    studies: {
      value: 0,
      count: 0,
      students: {
        value: 0,
        count: 0,
      },
      models: {
        value: 0,
        count: 0,
      },
    },
    sales: {
      products: {
        // productId1: {
        //   value: 0,
        //   count: 0,
        // },
      },
      other: {
        categories: {},
        value: 0,
        count: 0,
      },
      value: 0,
      count: 0,
      masters: {},
    },
    value: 0,
    count: 0,
  },
  // studies: {
  //   card: 0,
  //   total: 0,
  // },
  expences: {
    categories: {
      // categoryId1: {
      //   value: 0,
      //   count: 0,
      //   type: 'operation',
      // },
      // categoryId2: {
      //   value: 0,
      //   count: 0,
      //   type: 'operation',
      // },
    },
    value: 0,
    count: 0,
  },
  cashback: {
    given: 0,
    paid: 0,
  },
}
