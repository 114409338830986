<template>
  <div>
    <ClientEventProcedureItemV1
      :event="event"
      :hideTitle="hideTitle"
      v-if="!isVer2"
    />

    <ClientEventProcedureItemV2
      :event="event"
      :hideTitle="hideTitle"
      v-if="isVer2"
    />
  </div>
</template>

<script>
  import ClientEventProcedureItemV1 from '@/components/Clients/ClientEvents/ClientEventProcedureItemV1'
  import ClientEventProcedureItemV2 from '@/components/Clients/ClientEvents/ClientEventProcedureItemV2'

  export default {
    data() {
      return {}
    },
    props: {
      event: Object,
      hideTitle: Boolean,
    },
    computed: {
      isVer2() {
        return !!this.event.masterIds
      },
    },
    components: {
      ClientEventProcedureItemV1,
      ClientEventProcedureItemV2,
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .client-event {
    display: flex;

    &__status {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-top: 5px;
      margin-right: 7px;
    }
  }

  .client-event__info {
    width: 100%;

    &-link {
      margin-left: auto;
      font-size: 85%;
      padding-left: 5px;
      flex: 0 0 auto;
    }

    &-time,
    &-note {
      margin-top: 3px;
      font-size: 85%;
    }

    &-time {
      color: $gray;
    }
  }

  .client-event__info-primary {
    align-items: baseline;

    @media screen and (min-width: 480px) {
      display: flex;
    }

    .title {
      margin: 0 0 0 5px;
      flex: 0 1 auto;
    }

    .separator {
      margin: 0 5px;
    }

    span {
      flex: 0 0 auto;
    }
  }

  .client-event__info-secondary {
    &:not(:empty) {
      padding: 5px 0 10px;
      border-bottom: 1px solid $border-color;
    }

    &-item {
      color: $gray;

      & + div {
        margin-top: 5px;
      }

      span {
        color: $text-color;
      }
    }

    &.one-line {
      display: flex;
      justify-content: stretch;

      .client-event__info-secondary-item {
        margin: 0;
        flex: 1;

        & + div {
          padding-left: 10px;
        }

        span {
          display: block;
        }
      }
    }
  }
</style>
