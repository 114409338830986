import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

import { sortArrayByString } from '@/utils/functions'

export default {
  state: {
    procedures: [],
  },
  mutations: {},
  actions: {
    bindProcedures: firestoreAction((context) => {
      const salonId = context.getters.userSalonId

      return context.bindFirestoreRef(
        'procedures',
        db.collection('procedures').where('salonId', '==', salonId)
      )
    }),

    unbindProcedures: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('procedures')
    }),
  },
  getters: {
    procedures: (state) => state.procedures,
    proceduresActive: (state) => {
      const procedures = state.procedures.filter(
        (procedure) => procedure.active === true
      )

      return sortArrayByString(procedures, 'name')
    },
    proceduresByMasterId: (state) => (masterId) => {
      return state.procedures.filter(
        (procedure) => procedure.masterId === masterId
      )
    },
    proceduresActiveByMasterId: (state) => (masterId) => {
      return state.procedures.filter(
        (procedure) =>
          procedure.masterIds.includes(masterId) && procedure.active !== false
      )
    },
    procedureById: (state) => (id) => {
      return state.procedures.find((procedure) => procedure.id === id)
    },
    proceduresCategorized:
      (state, getters) =>
      ({ masterId = null, returnId = true } = {}) => {
        const procedures = masterId
          ? getters.proceduresActiveByMasterId(masterId)
          : getters.proceduresActive

        if (!procedures || !procedures.length) return []

        let categorizedProceduresArr = []
        let categorizedProceduresObj = {
          other: [],
        }

        procedures.map((item) => {
          if (item.categoryId) {
            if (
              !Object.prototype.hasOwnProperty.call(
                categorizedProceduresObj,
                item.categoryId
              )
            ) {
              categorizedProceduresObj[item.categoryId] = []
            }

            categorizedProceduresObj[item.categoryId].push({
              id: item.id,
              value: returnId
                ? item.id
                : {
                    ...item,
                    ...(masterId && { masterId }),
                  },
              label: item.name,
            })
          } else {
            if (item) {
              categorizedProceduresObj.other.push({
                id: item.id,
                value: returnId
                  ? item.id
                  : {
                      ...item,
                      ...(masterId && { masterId }),
                    },
                label: item.name,
              })
            }
          }
        })

        let tempCategorizedProceduresObj = JSON.parse(
          JSON.stringify(categorizedProceduresObj)
        )

        // Get rid from other key (it's for other was last array elem)
        delete tempCategorizedProceduresObj.other

        for (let categoryId in tempCategorizedProceduresObj) {
          categorizedProceduresArr.push({
            id: categoryId,
            value: categoryId,
            label: getters.procedureCategoryById(categoryId).name,
            children: sortArrayByString(
              categorizedProceduresObj[categoryId],
              'label'
            ),
          })
        }

        if (
          categorizedProceduresObj.other &&
          categorizedProceduresObj.other.length > 0
        ) {
          categorizedProceduresArr.push({
            value: 'other',
            id: 'other',
            label: 'Інші',
            children: sortArrayByString(
              categorizedProceduresObj.other,
              'label'
            ),
          })
        }

        return categorizedProceduresArr
      },
  },
}
