<template>
  <ul class="client-cashback-list">
    <ClientCashbackListItem
      v-for="cashbackItem in cashback"
      :key="cashbackItem.id"
      :id="cashbackItem.id"
      :cashbackItem="cashbackItem"
      class="client-cashback-item"
      :class="{
        valid: cashbackItem.isValid,
        'not-valid-yet': cashbackItem.notValidYet,
      }"
      @doubleCashback="doubleCashback"
    />
  </ul>
</template>

<script>
  import ClientCashbackListItem from './ClientCashbackListItem'

  export default {
    data() {
      return {}
    },
    props: {
      cashback: Array,
    },
    computed: {},
    components: {
      ClientCashbackListItem,
    },
    methods: {
      doubleCashback(cashItem) {
        this.$emit('doubleCashback', cashItem)
      },
    },
    mounted() {},
  }
</script>

<style lang="scss" scoped>
  .client-cashback-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .client-cashback-item {
    display: flex;
    color: $gray;

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      flex: 0 0 10px;
      display: block;
      margin-top: 5px;
      margin-right: 10px;
      background: $gray;
      border-radius: 50%;
    }

    &.valid {
      color: $text-color;

      &:before {
        background: $green;
      }
    }

    &.not-valid-yet {
      &:before {
        background: $yellow;
      }
    }
  }
</style>
