import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'

import user from './modules/user'
import masters from './modules/masters'
import common from './modules/common'
import statuses from './modules/statuses'
import clients from './modules/clients'
import procedures from './modules/procedures'
import proceduresCategories from './modules/proceduresCategories'
import procedureFieldsTemplates from './modules/procedureFieldsTemplates'
import events from './modules/events'
import absences from './modules/absences'
import schedules from './modules/schedules'
import income from './modules/income'
import salesCategories from './modules/salesCategories'
import products from './modules/products'
import productsCategories from './modules/productsCategories'
import expences from './modules/expences'
import expencesCategories from './modules/expencesCategories'
import notifications from './modules/notifications'
import marketings from './modules/marketings'
import statistics from './modules/statistics'
import salons from './modules/salons'
import settings from './modules/settings'
import reports from './modules/reports'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    masters,
    common,
    statuses,
    procedures,
    proceduresCategories,
    procedureFieldsTemplates,
    clients,
    events,
    absences,
    schedules,
    income,
    products,
    productsCategories,
    salesCategories,
    expences,
    expencesCategories,
    notifications,
    marketings,
    statistics,
    salons,
    settings,
    reports,
  },
  state: {},
  mutations: {
    ...vuexfireMutations,
  },
  actions: {},
})
