<template>
  <div class="verical-menu">
    <el-menu mode="vertical" :router="true" :default-active="$route.path">
      <el-menu-item
        v-for="(item, i) in navList"
        :key="i"
        :index="item.name"
        @click="onMenuClick"
        class="vertical-menu-item"
        :class="{ active: $route.path === item.name }"
      >
        <i :class="item.icon" />
        {{ item.navItem }}
      </el-menu-item>

      <el-menu-item
        v-if="isUserLoggedIn"
        class="vertical-menu-item"
        index="/logout"
        @click="onLogout"
      >
        <i class="el-icon-unlock" />
        Вихід
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import {
    isUserIsMasterOrLower,
    isUserIsAdminOrLower,
    isUserIsOwnerOrLower,
    isSuperAdmin,
  } from '@/utils/users'

  export default {
    data() {
      return {
        mobileMenu: false,
      }
    },
    computed: {
      ...mapGetters(['isUserLoggedIn', 'user', 'masterById']),
      navList() {
        if (this.isUserLoggedIn) {
          let menuItems = []

          if (this.masterById(this.user.id)) {
            let master = this.masterById(this.user.id)

            if (isUserIsMasterOrLower(master)) {
              menuItems.push(
                {
                  name: '/calendar',
                  navItem: 'Календар',
                  icon: 'el-icon-date',
                },
                {
                  name: '/finances',
                  navItem: 'Фінанси',
                  icon: 'el-icon-money',
                }
              )
            }

            if (isUserIsAdminOrLower(master)) {
              menuItems.push(
                { name: '/clients', navItem: 'Клієнти', icon: 'el-icon-user' },
                {
                  name: '/procedures',
                  navItem: 'Процедури',
                  icon: 'el-icon-scissors',
                },
                {
                  name: '/certificates',
                  navItem: 'Сертифікати',
                  icon: 'el-icon-present',
                },
                {
                  name: '/notifications',
                  navItem: 'Повідомлення',
                  icon: 'el-icon-alarm-clock',
                },
                {
                  name: '/cources',
                  navItem: 'Курси',
                  icon: 'el-icon-collection',
                },
                {
                  name: '/warehouse',
                  navItem: 'Товари',
                  icon: 'el-icon-shopping-cart-2',
                },
                {
                  name: '/marketing',
                  navItem: 'Маркетинг',
                  icon: 'el-icon-data-analysis',
                },
                {
                  name: '/masters',
                  navItem: 'Майстри',
                  icon: 'el-icon-suitcase',
                },
                {
                  name: '/calling',
                  navItem: 'Обзвон',
                  icon: 'el-icon-service',
                }
              )
            }

            if (isUserIsOwnerOrLower(master)) {
              menuItems.push({
                name: '/statistics',
                navItem: 'Статистика',
                icon: 'el-icon-alarm-clock',
              })
            }

            menuItems.push({
              name: '/userPanel',
              navItem: 'Налаштування',
              icon: 'el-icon-setting',
            })

            if (isSuperAdmin(master)) {
              menuItems.push(
                {
                  name: '/salons',
                  navItem: 'Салони',
                  icon: 'el-icon-office-building',
                },
                {
                  name: '/versions',
                  navItem: 'Версії',
                  icon: 'el-icon-files',
                },
                {
                  name: '/systemPanel',
                  navItem: 'Система',
                  icon: 'el-icon-magic-stick',
                } // TEMP
              )
            }
          }

          return menuItems
        } else {
          return [
            { name: '/', navItem: 'Вхід', icon: 'el-icon-lock' },
            { name: '/register', navItem: 'Реєстрація', icon: 'el-icon-edit' },
          ]
        }
      },
    },
    methods: {
      async onLogout() {
        this.$store.dispatch('setLoading', true)

        await this.$store.dispatch('unbindMasters')
        await this.$store.dispatch('unbindProcedures')
        await this.$store.dispatch('unbindProceduresCategories')
        await this.$store.dispatch('unbindSalesCategories')
        await this.$store.dispatch('unbindSettings')
        // await this.$store.dispatch('unbindClients')
        await this.$store.dispatch('unbindEvents')
        await this.$store.dispatch('unbindAbsences')
        await this.$store.dispatch('unbindSchedules')
        await this.$store.dispatch('unbindProceduresFieldTemplates')
        await this.$store.dispatch('logoutUser')

        await this.$router.push('/').catch(() => {})
        this.$store.dispatch('setLoading', false)
      },
      onMenuClick() {
        this.mobileMenu = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-menu {
    height: 100%;

    .el-menu-item.active {
      pointer-events: none;
    }
  }

  .verical-menu {
    height: 100%;
  }

  .vertical-menu-item {
    height: 45px;
    line-height: 45px;
    padding: 0 10px !important;
    display: flex;
    align-items: center;
  }
</style>
