/* eslint-disable no-console */
import { register } from 'register-service-worker'

const prefix = 'beauty-manager'

if (process.env.NODE_ENV === 'production') {
  register(`service-worker.js`, {
    registrationOptions: {
      scope: '.',
    },
    ready() {
      console.log('App is being served from cache by a service worker.')

      console.log(
        `%c ${window.appVersion} `,
        'background: #ddd; color: #0000ff'
      )

      if (caches) {
        caches.keys().then((arr) => {
          arr.forEach((key) => {
            if (key.indexOf(prefix) < -1) {
              caches
                .delete(key)
                .then(() =>
                  console.log(
                    `%c Cleared ${key}`,
                    'background: #333; color: #ff0000'
                  )
                )
            } else {
              caches.open(key).then((cache) => {
                cache.match('version').then((res) => {
                  if (!res) {
                    cache.put(
                      'version',
                      new Response(window.appVersion, {
                        status: 200,
                        statusText: window.appVersion,
                      })
                    )
                  } else if (res.statusText !== window.appVersion) {
                    caches
                      .delete(key)
                      .then(() =>
                        console.log(
                          `%c Cleared Cache ${window.appVersion}`,
                          'background: #333; color: #ff0000'
                        )
                      )
                  } else
                    console.log(
                      `%c Great you have the latest version ${window.appVersion}`,
                      'background: #333; color: #00ff00'
                    )
                })
              })
            }
          })
        })
      }
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      console.log('New content is ready for install.')
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      console.error('Error during service worker registration: ', error)
    },
  })
}
