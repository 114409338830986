<template>
  <el-collapse accordion>
    <el-collapse-item
      v-for="catIdArray in proceduresSortedArray"
      :key="catIdArray[0]"
      :title="`${catIdArray[1]} (${proceduresObj[catIdArray[0]].length})`"
      :name="catIdArray[0]"
    >
      <ul>
        <li v-for="event in proceduresObj[catIdArray[0]]" :key="event.id">
          <ClientEventProcedureItem :event="event" hideTitle />
        </li>
      </ul>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ClientEventProcedureItem from '@/components/Clients/ClientEvents/ClientEventProcedureItem'

  export default {
    data() {
      return {
        proceduresObj: {},
        proceduresSortedArray: [],
      }
    },
    props: {
      events: Array,
    },
    computed: {
      ...mapGetters(['procedureById', 'statusById', 'masterById']),
    },
    components: {
      ClientEventProcedureItem,
    },
    methods: {
      getProcedure(procedureId, event, sortableProcedures) {
        let procedure = procedureId ? this.procedureById(procedureId) : null

        if (procedure && procedure.id) {
          if (
            !Object.prototype.hasOwnProperty.call(
              this.proceduresObj,
              procedure.id
            )
          ) {
            this.proceduresObj[procedure.id] = []
            sortableProcedures.push([procedure.id, procedure.name])
          }

          this.proceduresObj[procedure.id].push(event)
        }
      },
      getProceduresFromEvents() {
        let sortableProcedures = []
        this.proceduresObj = {}

        this.events.map((event) => {
          if (event.procedures) {
            for (let procedureKey in event.procedures) {
              this.getProcedure(
                event.procedures[procedureKey].procedureId,
                event,
                sortableProcedures
              )
            }
          } else {
            this.getProcedure(event.procedure, event, sortableProcedures)
          }
        })

        sortableProcedures = sortableProcedures.sort(function (a, b) {
          return a[1].localeCompare(b[1])
        })

        this.proceduresSortedArray = sortableProcedures
      },
    },
    created() {
      this.getProceduresFromEvents()
    },
  }
</script>

<style lang="scss" scoped>
  .el-collapse {
    margin-top: 20px;

    &::v-deep {
      .el-collapse-item__content {
        font-size: inherit;
        color: inherit;
        line-height: inherit;
      }
    }
  }

  .client-events__category {
    & + .client-events__category {
      margin-top: 20px;
    }

    &-name {
      font-size: 16px;
    }
  }
</style>
