<template>
  <div class="current-events-container">
    <el-form-item label="Графік майстра на день">
      <div class="current-events">
        <div class="current-events-hours">
          <div
            v-for="(_, index) in timeEndHour - timeStartHour"
            :key="index"
            class="current-events-hour"
            :class="getTurnOffClass(index + timeStartHour)"
          >
            {{ index + timeStartHour }}
          </div>
        </div>

        <div class="current-events-list">
          <div
            v-for="event in events"
            :key="event.id"
            :style="
              getEventStyles(
                event.timeStart || event.eventTimeStart || event.raw?.timeStart,
                event.timeEnd || event.eventTimeEnd || event.raw?.timeEnd
              )
            "
            class="current-events-item"
            :class="{
              absence: event.status === 5,
            }"
          />

          <div
            class="current-events-current-item"
            :style="getEventStyles(currentItemTimeStart, currentItemTimeEnd)"
            v-if="currentItemTimeStart && currentItemTimeEnd"
          />
        </div>
      </div>
    </el-form-item>
  </div>
</template>

<script>
  import { db } from '@/plugins/firebase'

  import { mapGetters } from 'vuex'
  import { constants } from '@/utils/constants'
  import {
    timeStringToFloat,
    getSortingValueNumberFromDate,
  } from '@/utils/functions'

  export default {
    data() {
      return {
        timeStartHour: constants.timeStartHour,
        timeEndHour: constants.timeEndHour,
        events: [],
        firstRun: true,
        isSyncDate: true,
      }
    },
    computed: {
      ...mapGetters([
        'eventsByMasterAndDate',
        'absencesByMasterAndDate',
        'syncDate',
        'userSalon',
      ]),
      currentDate() {
        return new Date(this.date)
      },
      currentDay() {
        const day = this.currentDate.getDay()

        return day === 0 ? 7 : day
      },
    },
    components: {},
    props: {
      masterId: String,
      date: String,
      eventId: String,
      currentItemTimeStart: String,
      currentItemTimeEnd: String,
      newEvent: Boolean,
    },
    watch: {
      date: {
        handler(date) {
          if (
            !date ||
            !(this.syncDate && this.syncDate.start && this.syncDate.end)
          )
            return

          this.isSyncDate =
            getSortingValueNumberFromDate(this.currentDate, false) >=
              getSortingValueNumberFromDate(this.syncDate.start, false) &&
            getSortingValueNumberFromDate(this.currentDate, false) <
              getSortingValueNumberFromDate(this.syncDate.end, false)

          if (!this.firstRun) {
            this.getEvents()
          }
        },
        immediate: true,
      },
      masterId: {
        async handler(id) {
          if (!id) return

          if (!this.firstRun) {
            this.getEvents()
          }
        },
        immediate: true,
      },
    },
    methods: {
      async getEvents() {
        this.events = []

        if (this.isSyncDate) {
          const sharedMastersEvents = JSON.parse(
            JSON.stringify(this.eventsByMasterAndDate(this.masterId, this.date))
          )

          sharedMastersEvents.forEach((event) => {
            if (event.mastersProcedures) {
              for (let masterKey in event.mastersProcedures) {
                const [, masterId] = masterKey.split('_')

                if (masterId === this.masterId) {
                  const masterStartTime =
                    event.mastersProcedures[masterKey].masterStartTime
                  const masterEndTime =
                    event.mastersProcedures[masterKey].masterEndTime

                  event.eventTimeStart = masterStartTime
                  event.eventTimeEnd = masterEndTime
                }
              }
            }
          })

          this.events = [
            ...sharedMastersEvents,
            ...this.absencesByMasterAndDate(this.masterId, this.date),
          ]
        } else {
          this.$store.dispatch('setLoading', true)

          await db
            .collection('events')
            .where('salonId', '==', this.$store.getters.userSalonId)
            .where('date', '==', this.date)
            .where('master.userId', '==', this.masterId)
            .orderBy('sortingValue', 'asc')
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                this.events.push(doc.data())
              })
            })
            .catch((error) => this.$store.dispatch('setError', error.message))

          await db
            .collection('absences')
            .where('salonId', '==', this.$store.getters.userSalonId)
            .where('raw.date', '==', this.date)
            .where('master.userId', '==', this.masterId)
            .orderBy('sortingValue', 'asc')
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                this.events.push(doc.data())
              })
            })
            .catch((error) => this.$store.dispatch('setError', error.message))

          this.$store.dispatch('setLoading', false)
        }

        if (!this.newEvent) {
          const index = this.events.findIndex(
            (event) => event.id === this.eventId
          )

          this.events.splice(index, 1)
        }

        this.firstRun = false
      },
      getEventStyles(start, end) {
        const eventStartWithOffset = start
          ? timeStringToFloat(start) - this.timeStartHour
          : 0
        const eventEndWithOffset = end
          ? timeStringToFloat(end) - this.timeStartHour
          : start
          ? eventStartWithOffset
          : 0

        const leftPropertyPercent =
          (eventStartWithOffset * 100) / (this.timeEndHour - this.timeStartHour)
        const widthProperyPercent =
          (eventEndWithOffset * 100) / (this.timeEndHour - this.timeStartHour) -
          leftPropertyPercent

        return {
          left: leftPropertyPercent + '%',
          width: widthProperyPercent + '%',
        }
      },
      getTurnOffClass(time) {
        if (!this.userSalon.isTurnOff || !this.userSalon.turnOffGraph) {
          return ''
        }

        if (
          !this.userSalon.turnOffGraph[`day_${this.currentDay}`][`hour_${time}`]
        ) {
          return 'on'
        }

        return this.userSalon.turnOffGraph[`day_${this.currentDay}`][
          `hour_${time}`
        ]
      },
    },
    mounted() {
      if (this.firstRun) {
        this.getEvents()
      }
    },
  }
</script>

<style lang="scss" scoped>
  .current-events-container {
    margin-bottom: 15px;
  }

  .current-events {
    padding-top: 20px;
    position: relative;
  }

  .current-events-hours {
    display: flex;
    line-height: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  $topGap: 20px;

  .current-events-hour {
    flex: 1;
    padding: 0 0 2px 0;
    border-left: none;
    font-size: 10px;
    position: relative;
    z-index: 1;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: calc(100% - 20px);
      top: $topGap;
      background: $border-color;
      left: 0;
    }

    &:after {
      width: 100%;
      height: 100%;
      top: 0;
      z-index: -1;
      opacity: 0.2;
    }

    &.on {
      &:after {
        background: $green;
      }
    }

    &.off {
      &:after {
        background: $red;
      }
    }

    &.possible {
      &:after {
        background: $yellow;
      }
    }
  }

  .current-events-list {
    border: 1px solid $border-color;
    position: relative;
    height: 50px;
    z-index: 2;
  }

  .current-events-item,
  .current-events-current-item {
    position: absolute;
    top: 0;
    background: $yellow;
    height: 100%;
    background-blend-mode: multiply;
    mix-blend-mode: multiply;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='12' viewBox='0 0 40 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 6.172L6.172 0h5.656L0 11.828V6.172zm40 5.656L28.172 0h5.656L40 6.172v5.656zM6.172 12l12-12h3.656l12 12h-5.656L20 3.828 11.828 12H6.172zm12 0L20 10.172 21.828 12h-3.656z' fill='%23655436' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
  }

  .current-events-item {
    border: 1px solid #ba7f1f;

    &.absence {
      background-color: $gray;
      border-color: $dark-gray;
    }
  }

  .current-events-current-item {
    background-color: $red;
    border: 2px dashed $blue;
  }
</style>
