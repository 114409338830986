import { mapGetters } from 'vuex'

export const expencesCategoriesBindMixin = {
  computed: {
    ...mapGetters([
      'isExpencesCategoriesBinded',
      'isExpencesCategoriesLoading',
    ]),
  },
  async mounted() {
    if (!this.isExpencesCategoriesBinded && !this.isExpencesCategoriesLoading) {
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('setIsExpencesCategoriesLoading', true)

      await this.$store.dispatch('bindExpencesCategories')

      this.$store.dispatch('setLoading', false)
      this.$store.dispatch('setIsExpencesCategoriesLoading', false)

      this.$store.dispatch('setIsExpencesCategoriesBinded', true)
    }
  },
}
