<template>
  <el-dialog
    :title="`Вибір процедур для майстра ${masterName}`"
    width="50%"
    :visible.sync="localDialogVisibility"
    append-to-body
    :show-close="false"
    :close-on-click-modal="false"
    :before-close="closeChoosingProceduresDialog"
    destroy-on-close
  >
    <div class="margin-bottom-15">
      <el-cascader-panel
        class="width-100 choose-procedure"
        popper-class="choose-procedure"
        v-model="choosedProcedures"
        collapse-tags
        :disabled="loading || masterProcedures.length === 0"
        :options="masterProcedures"
        :props="{ emitPath: false, multiple: true }"
      />
    </div>

    <div>
      <el-button
        @click="approveChoosingProcedures"
        :disabled="loading || choosedProcedures.length === 0"
        type="primary"
      >
        Підтвердити
      </el-button>

      <el-button
        @click="closeChoosingProceduresDialog"
        :disabled="loading"
        type="danger"
      >
        Скасувати
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { searchInArrayWithChildren } from '@/utils/functions'

  export default {
    data() {
      return {
        localDialogVisibility: false,
        choosedProcedures: [],
        originProceduresIds: [],
      }
    },
    components: {},
    props: {
      dialogVisibility: Boolean,
      masterProcedures: Array,
      eventFormProcedures: Object,
      masterName: String,
    },
    watch: {
      dialogVisibility: {
        immediate: true,
        deep: false,
        handler(visibility) {
          this.localDialogVisibility = visibility
        },
      },
    },
    computed: {
      ...mapGetters(['loading']),
    },
    methods: {
      closeChoosingProceduresDialog() {
        this.$emit('closeDialog')

        this.$nextTick(() => {
          this.clearData()
        })
      },
      approveChoosingProcedures() {
        const newProceduresIds = this.choosedProcedures.map((item) => item.id)
        const tempArrayOfNewProcedures = JSON.parse(
          JSON.stringify(this.choosedProcedures)
        )
        const resultArray = []

        this.originProceduresIds.forEach((oldId) => {
          // Trying to find old procedures in new list
          if (
            newProceduresIds.includes(oldId) &&
            !this.originProceduresIds.includes(oldId)
          ) {
            // Remove old procedures from new list
            tempArrayOfNewProcedures.splice(newProceduresIds.indexOf(oldId), 1)

            // Add old procedures to result list first
            resultArray.push(
              this.eventFormProcedures.find(
                ({ procedureId }) => procedureId === oldId
              )
            )
          }
        })

        // Concat all lists
        this.$emit(
          'chooseProcedures',
          JSON.parse(
            JSON.stringify([...resultArray, ...tempArrayOfNewProcedures])
          )
        )

        this.clearData()
      },
      clearData() {
        this.choosedProcedures = []
        this.originProceduresIds = []
      },
    },
    mounted() {
      this.clearData()

      if (
        this.localDialogVisibility &&
        this.eventFormProcedures &&
        Object.keys(this.eventFormProcedures).length > 0
      ) {
        Object.keys(this.eventFormProcedures).forEach((procedureKey) => {
          const [, procedureId] = procedureKey.split('_')

          const { value } = searchInArrayWithChildren(
            this.masterProcedures,
            procedureId
          )

          if (!this.originProceduresIds.includes(value.id)) {
            this.choosedProcedures.push(JSON.parse(JSON.stringify(value)))
            this.originProceduresIds.push(procedureId)
          }
        })
      }
    },
  }
</script>

<style lang="scss">
  .el-cascader-panel {
    &.choose-procedure {
      flex-direction: column;

      @include to('md') {
        flex-direction: row;
      }

      .el-cascader-menu {
        & + .el-cascader-menu {
          border-top: 1px solid $border-color;

          @include to('md') {
            border-top: none;
          }
        }
      }
    }
  }
</style>
