<template>
  <el-form
    ref="eventForm"
    :rules="rules"
    :model="eventForm"
    label-width="140px"
  >
    <div class="event-add-cource">
      <CourceCoursesSelect
        :isClientAdding="isClientAdding"
        :newEvent="newEvent"
        :eventForm="eventForm"
        @selectCource="onSelectCource"
      />

      <el-form-item
        :label="isNotOneDayEvent ? 'Перший день' : 'Дата навчання'"
        required
      >
        <div class="flex flex--wrap">
          <el-form-item
            prop="dateStart"
            class="margin-right-10 margin-bottom-0"
          >
            <el-date-picker
              v-model="eventForm.dateStart"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              firstDayOfWeek="1"
              :clearable="false"
              :picker-options="datePickerOptions"
              :disabled="
                isClientAdding ||
                loading ||
                Object.keys(eventForm.cource).length === 0 ||
                isEventStartedYesterday
              "
              @change="getDaysFromDates"
            />
          </el-form-item>

          <el-checkbox
            v-model="isNotOneDayEvent"
            @change="changeDayEvent(isNotOneDayEvent)"
            :disabled="
              isClientAdding ||
              loading ||
              Object.keys(eventForm.cource).length === 0 ||
              isEventStartedYesterday
            "
          >
            Кілька днів
          </el-checkbox>
        </div>
      </el-form-item>

      <el-form-item label="Останній день" v-if="isNotOneDayEvent" required>
        <el-form-item prop="dateEnd">
          <el-date-picker
            v-model="eventForm.dateEnd"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            firstDayOfWeek="1"
            @change="getDaysFromDates"
            :clearable="false"
            :picker-options="{
              firstDayOfWeek: 1,
              disabledDate(date) {
                if (!eventForm.dateStart) return

                const firstDate = new Date(eventForm.dateStart.split('-'))

                return date < firstDate
              },
            }"
            :disabled="
              isClientAdding ||
              loading ||
              Object.keys(eventForm.cource).length === 0
            "
          />
        </el-form-item>
      </el-form-item>

      <TimeInRowFormItem
        :timeStartValue="eventForm.timeStart"
        :timeEndValue="eventForm.timeEnd"
        :timeStartDisabled="timeInputDisabled"
        :timeEndDisabled="!eventForm.timeStart || timeInputDisabled"
        @sendData="onSendTimeData"
      />

      <CourceStudents
        :isClientAdding="isClientAdding"
        :newEvent="newEvent"
        :eventForm="eventForm"
        @sendStudentsData="onSendStudentsData"
        @isClientAdding="onIsClientAdding"
      />

      <CourceModels
        :isClientAdding="isClientAdding"
        :eventForm="eventForm"
        v-if="eventForm.timeStart && eventForm.timeEnd"
      />

      <div />
    </div>

    <el-form-item label="Додаткова інформація" prop="note">
      <el-input v-model="eventForm.note" type="textarea" :rows="3" />
    </el-form-item>

    <el-form-item v-if="!newEvent">
      <InfoPanel
        text="Майте на увазі, що якщо ви змінюєте моделей (міняєте час, видаляєте, додаєте), то після змін обов'язково збережіть усю форму навчання."
      />
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        @click="saveCource"
        :loading="loading"
        :disabled="loading"
      >
        Зберегти
      </el-button>

      <el-button
        type="danger"
        @click="$emit('closeDialog')"
        :loading="loading"
        :disabled="loading"
      >
        Скасувати
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { db } from '@/plugins/firebase'
  import { mapGetters } from 'vuex'

  import { HISTORY_TYPES } from '@/utils/history'
  import { constants } from '@/utils/constants'
  import {
    diffDays,
    dateTimeFormat,
    dateToNum,
    timeToString,
    sanitizeString,
    dateTimeStringToDateObj,
    stringTimeToArray,
    diffMinutes,
  } from '@/utils/functions'
  import { validateString, validateObject } from '@/utils/customValidations'
  import { statuses } from '@/utils/statuses'

  import historyService from '@/services/history-service'
  import eventService from '@/services/event-service'
  import studiesService from '@/services/studies-service'

  import CourceStudents from '@/components/Calendar/EventsForms/Cource/CourceStudents'
  import CourceModels from '@/components/Calendar/EventsForms/Cource/CourceModels'
  import CourceCoursesSelect from '@/components/Calendar/EventsForms/Cource/CourceCoursesSelect'
  import InfoPanel from '@/components/Common/InfoPanel'
  import TimeInRowFormItem from '@/components/Common/Forms/TimeInRowFormItem'

  export default {
    data() {
      return {
        isClientAdding: false,
        eventForm: {
          dateStart: '',
          dateArray: [],
          dateEnd: '',
          timeStart: '',
          timeEnd: '',
          cource: {},
          students: [],
          models: {},
          note: '',
        },
        studyId: '',
        event: {},
        now: new Date(),
        choosedClientPhone: '',
        isNotOneDayEvent: false,
        isEventStartedYesterday: false,
        DAYTIMESTART: constants.timeStartString,
        datePickerOptions:
          constants.datePickerOptionsWithDisabledPastAndAfterOneYear,
        rules: {
          cource: [
            { validator: validateObject, required: true, trigger: 'change' },
          ],
          dateStart: [{ validator: validateString, trigger: 'change' }],
          dateEnd: [{ validator: validateString, trigger: 'change' }],
          timeStart: [{ validator: validateString, trigger: 'change' }],
          timeEnd: [{ validator: validateString, trigger: 'change' }],
        },
      }
    },
    props: {
      newEvent: Boolean,
      eventData: Object,
      eventId: String,
    },
    components: {
      CourceStudents,
      CourceModels,
      CourceCoursesSelect,
      InfoPanel,
      TimeInRowFormItem,
    },
    computed: {
      ...mapGetters(['loading', 'user', 'masterById', 'eventById']),
      currentUser() {
        return this.masterById(this.user.id)
      },
      timeInputDisabled() {
        return (
          this.isClientAdding ||
          this.loading ||
          Object.keys(this.eventForm.cource).length === 0
        )
      },
    },
    watch: {
      eventId: {
        async handler(id) {
          if (!id) return

          const event =
            this.eventById(id) || (await eventService.getEvent({ eventId: id }))

          if (event && event.studyId) {
            this.studyId = event.studyId
            this.event = await studiesService.getStudy({
              studyId: event.studyId,
            })

            if (
              this.event.dateStart &&
              this.event.dateEnd &&
              this.event.dateStart !== this.event.dateEnd
            ) {
              this.isNotOneDayEvent = true
            }

            const activeDate = Object.keys(this.event.eventIdsWithDate).find(
              (date) => {
                return this.event.eventIdsWithDate[date] === id
              }
            )

            this.eventForm = {
              dateStart: this.event.dateStart,
              dateEnd: this.event.dateEnd || '',
              timeStart: this.event.timeStart,
              timeEnd: this.event.timeEnd,
              cource: this.event.cource,
              students: this.event.students,
              models: JSON.parse(JSON.stringify(this.event.models)) || {},
              note: this.event.note || '',
              activeDate,
            }

            this.getDaysFromDates()
          }
        },
        immediate: true,
      },
      eventData: {
        handler(data) {
          if (!data && !this.newEvent) return

          this.eventForm.dateStart = data.dateStart
          this.eventForm.timeStart = data.timeStart
            ? data.timeStart
            : this.DAYTIMESTART
        },
        immediate: true,
      },
    },
    methods: {
      onIsClientAdding(isAdding) {
        this.isClientAdding = isAdding
      },
      onSendStudentsData(data) {
        this.eventForm.students = JSON.parse(JSON.stringify(data))
      },
      onSelectCource(cource) {
        this.eventForm.cource = cource

        this.isNotOneDayEvent = cource.duration > 1

        this.changeDayEvent(cource.duration > 1)
      },
      onSendTimeData(data) {
        this.eventForm.timeStart = data.timeStart
        this.eventForm.timeEnd = data.timeEnd
      },
      changeDayEvent(isMultipleDaysEvent) {
        const dateObj = new Date(this.eventForm.dateStart.split('-'))

        dateObj.setDate(
          dateObj.getDate() +
            (this.eventForm.cource && this.eventForm.cource.duration
              ? this.eventForm.cource.duration - 1
              : 0)
        )

        this.eventForm.dateEnd = isMultipleDaysEvent
          ? dateTimeFormat(dateObj, 'Y-Mo-D')
          : ''

        this.getDaysFromDates()
      },
      getDaysFromDates() {
        const dateStart = new Date(this.eventForm.dateStart)
        const dateEnd = this.eventForm.dateEnd
          ? new Date(this.eventForm.dateEnd)
          : null

        this.isEventStartedYesterday = diffDays(dateStart, new Date()) < 0

        if (dateEnd && diffDays(dateEnd, dateStart) < 0) {
          dateEnd.setDate(
            dateStart.getDate() +
              (this.eventForm.cource && this.eventForm.cource.duration
                ? this.eventForm.cource.duration - 1
                : 0)
          )

          this.eventForm.dateEnd = dateTimeFormat(dateEnd, 'Y-Mo-D')
        }

        const diffs =
          dateEnd && diffDays(dateEnd, dateStart) > 0
            ? diffDays(dateEnd, dateStart)
            : 0

        this.eventForm.dateArray = []

        for (let i = 0; i <= diffs; i++) {
          const resultDate = new Date(dateStart)
          resultDate.setDate(dateStart.getDate() + i)

          this.eventForm.dateArray.push(dateTimeFormat(resultDate, 'Y-Mo-D'))
        }

        // If we change dates, then check if on old days are models events
        if (Object.keys(this.eventForm.models).length > 0) {
          Object.keys(this.eventForm.models).map((modelsDate) => {
            if (
              !this.eventForm.dateArray.includes(modelsDate) &&
              this.eventForm.models[modelsDate]
            ) {
              const modelsIds = this.eventForm.models[modelsDate]
                ? [
                    ...new Set(
                      this.eventForm.models[modelsDate].map((item) => item.id)
                    ),
                  ]
                : []

              const cleanedModels = modelsIds.length
                ? modelsIds.map((modelId) => {
                    const modelItem = this.eventForm.models[modelsDate].find(
                      (item) => item.id === modelId
                    )

                    return { ...modelItem, date: modelsDate }
                  })
                : []

              if (
                !Object.prototype.hasOwnProperty.call(
                  this.eventForm.models,
                  this.eventForm.dateArray[0]
                )
              ) {
                this.eventForm.models[this.eventForm.dateArray[0]] = []
              }

              this.eventForm.models[this.eventForm.dateArray[0]] = cleanedModels

              delete this.eventForm.models[modelsDate]
            }
          })
        }
      },
      saveCource() {
        this.$refs.eventForm.validate(async (valid) => {
          if (valid) {
            const batch = db.batch()

            this.$store.dispatch('setLoading', true)

            let studyId = this.newEvent ? '' : this.studyId
            let eventIds = []
            let eventIdsWithDate = {}

            const now = Date()

            const startTimeArray = this.eventForm.timeStart
              ? stringTimeToArray(this.eventForm.timeStart)
              : []
            const endTimeArray = this.eventForm.timeEnd
              ? stringTimeToArray(this.eventForm.timeEnd)
              : []
            const sortingValue = Number(
              String(dateToNum(this.eventForm.dateStart)) +
                String(timeToString(this.eventForm.timeStart))
            )

            const studyData = {
              cource: {
                id: this.eventForm.cource.id,
                name: this.eventForm.cource.name,
                ...(this.eventForm.cource.masterId && {
                  masterId: this.eventForm.cource.masterId,
                  masterName: this.masterById(this.eventForm.cource.masterId)
                    .name,
                }),
                externalMaster: this.eventForm.cource.externalMasterName
                  ? true
                  : false,
                ...(this.eventForm.cource.externalMasterName && {
                  masterName: this.eventForm.cource.externalMasterName,
                }),
                price: this.eventForm.cource.price,
                duration: this.eventForm.cource.duration,
                procedures: this.eventForm.cource.procedures,
              },
              dateArray: this.eventForm.dateArray,
              dateStart: this.eventForm.dateStart,
              ...(this.eventForm.dateEnd && {
                dateEnd: this.eventForm.dateEnd,
              }),
              timeStart: startTimeArray[0] + ':' + startTimeArray[1],
              timeEnd: endTimeArray[0] + ':' + endTimeArray[1],
              students: this.eventForm.students,
              ...(this.eventForm.note && {
                note: sanitizeString(this.eventForm.note),
              }),
              models:
                Object.keys(this.eventForm.models).length > 0
                  ? this.eventForm.models
                  : null,
              sortingValue,
            }

            if (this.newEvent) {
              studyId = studiesService.addStudy({
                batch,
                studyData,
              })
            } else {
              studiesService.updateStudy({
                batch,
                studyId,
                studyData,
              })
            }

            if (!this.newEvent) {
              for (const oldEventIdItem of this.event.eventIds) {
                eventService.deleteEvent({ batch, eventId: oldEventIdItem })
              }
            }

            for (const dateItem of this.eventForm.dateArray) {
              const sortingValueEvent = Number(
                String(dateToNum(dateItem)) +
                  String(timeToString(this.eventForm.timeStart))
              )
              const startDateObj = dateTimeStringToDateObj(
                dateItem,
                this.eventForm.timeStart
              )
              const endDateObj = dateTimeStringToDateObj(
                dateItem,
                this.eventForm.timeEnd
              )

              const evendId = eventService.addEvent({
                batch,
                eventData: {
                  start: startDateObj.toUTCString(),
                  end: endDateObj.toUTCString(),
                  title: this.eventForm.cource.name,
                  dateStart: dateItem,
                  ...(this.eventForm.dateEnd && {
                    dateEnd: dateItem,
                  }),
                  dateArray: this.eventForm.dateArray,
                  timeStart: startTimeArray[0] + ':' + startTimeArray[1],
                  timeEnd: endTimeArray[0] + ':' + endTimeArray[1],
                  duration: diffMinutes(startDateObj, endDateObj),
                  creator: {
                    userId: this.currentUser.userId,
                    name: this.currentUser.name,
                  },
                  students: this.eventForm.students,
                  ...(this.eventForm.models &&
                    this.eventForm.models[dateItem]?.length && {
                      models: this.eventForm.models[dateItem],
                    }),
                  cource: {
                    id: this.eventForm.cource.id,
                    name: this.eventForm.cource.name,
                    ...(this.eventForm.cource.masterId && {
                      masterId: this.eventForm.cource.masterId,
                      masterName: this.masterById(
                        this.eventForm.cource.masterId
                      ).name,
                    }),
                    externalMaster: this.eventForm.cource.externalMasterName
                      ? true
                      : false,
                    ...(this.eventForm.cource.externalMasterName && {
                      masterName: this.eventForm.cource.externalMasterName,
                    }),
                  },
                  ...(this.eventForm.note && {
                    note: sanitizeString(this.eventForm.note),
                  }),
                  status: statuses.study,
                  createdAt: now,
                  statusActive: Boolean(true),
                  studyId,
                },
                currentUser: this.currentUser,
                sortingValue: sortingValueEvent,
                now,
              })

              eventIds.push(evendId)
              eventIdsWithDate[dateItem] = evendId
            }

            studiesService.updateStudy({
              batch,
              studyId,
              studyData: {
                eventIds,
                eventIdsWithDate,
              },
            })

            historyService.addHistory({
              batch,
              currentUser: this.currentUser,
              now: new Date(),
              type: this.newEvent
                ? HISTORY_TYPES.ADD_STUDY
                : HISTORY_TYPES.UPDATE_STUDY,
              additionalData: {
                eventIds,
                eventIdsWithDate,
                studyId,
              },
            })

            batch
              .commit()
              .then(() => {
                this.$emit('closeDialog')
                this.$message.success('Курс збережено')
              })
              .catch((error) => this.$store.dispatch('setError', error.message))
              .finally(() => this.$store.dispatch('setLoading', false))
          }
        })
      },
    },
    beforeDestroy() {
      this.eventForm = {}
    },
  }
</script>
