export const prepareReportData = (reportData, currentFinanceReport) => {
  const copyOfCurrentReport = {
    ...JSON.parse(JSON.stringify(currentFinanceReport)),
  }

  const { value, type, isEdit } = reportData

  if (['event', 'sale', 'saleProduct', 'cert', 'study'].includes(type)) {
    const incomesDbValue = copyOfCurrentReport.incomes.value || 0

    copyOfCurrentReport.incomes.value = incomesDbValue + value
  }

  if (type === 'event') {
    const {
      event: { procedures, isNewClient },
    } = reportData

    const eventsDbValue = copyOfCurrentReport.incomes.events.value || 0
    const eventsDbCount = copyOfCurrentReport.incomes.events.count || 0

    copyOfCurrentReport.incomes.events.value = eventsDbValue + value
    copyOfCurrentReport.incomes.events.count = eventsDbCount + 1

    if (isNewClient) {
      const newClientsDbCount =
        copyOfCurrentReport.incomes.events.clients.new || 0

      copyOfCurrentReport.incomes.events.clients.new = newClientsDbCount + 1
    }

    procedures.forEach((procedure) => {
      const {
        procedureId,
        value: procedureValue,
        masterId,
        masterPart = 0,
      } = procedure

      if (masterId) {
        if (!copyOfCurrentReport.incomes.events.masters) {
          copyOfCurrentReport.incomes.events.masters = {}
        }

        if (!copyOfCurrentReport.incomes.events.masters.total) {
          copyOfCurrentReport.incomes.events.masters.total = {
            value: 0,
            count: 0,
          }
        }

        copyOfCurrentReport.incomes.events.masters.total.value += masterPart
        copyOfCurrentReport.incomes.events.masters.total.count += isEdit ? 0 : 1

        if (!copyOfCurrentReport.incomes.events.masters[masterId]) {
          copyOfCurrentReport.incomes.events.masters[masterId] = {
            value: 0,
            count: 0,
            masterPart: 0,
          }
        }

        copyOfCurrentReport.incomes.events.masters[masterId].value +=
          procedureValue
        copyOfCurrentReport.incomes.events.masters[masterId].masterPart +=
          masterPart
        copyOfCurrentReport.incomes.events.masters[masterId].count += isEdit
          ? 0
          : 1
      }

      if (!copyOfCurrentReport.incomes.events.procedures[procedureId]) {
        copyOfCurrentReport.incomes.events.procedures[procedureId] = {}
      }

      const dbValue =
        copyOfCurrentReport.incomes.events.procedures[procedureId].value || 0
      const dbCount =
        copyOfCurrentReport.incomes.events.procedures[procedureId].count || 0

      copyOfCurrentReport.incomes.events.procedures[procedureId].value =
        dbValue + procedureValue

      copyOfCurrentReport.incomes.events.procedures[procedureId].count =
        dbCount + 1
    })
  }

  if (type === 'cert') {
    const certDbValue = copyOfCurrentReport.incomes.certificates.value || 0
    const certDbCount = copyOfCurrentReport.incomes.certificates.count || 0

    copyOfCurrentReport.incomes.certificates.value = certDbValue + value
    copyOfCurrentReport.incomes.certificates.count = certDbCount + 1
  }

  if (type === 'study') {
    const studyDbValue = copyOfCurrentReport.incomes.studies.value || 0
    const studyDbCount = copyOfCurrentReport.incomes.studies.count || 0

    copyOfCurrentReport.incomes.studies.value = studyDbValue + value
    copyOfCurrentReport.incomes.studies.count = studyDbCount + 1

    const {
      study: { student, model },
    } = reportData

    if (student) {
      if (!copyOfCurrentReport.incomes.studies.students) {
        copyOfCurrentReport.incomes.studies.students = {
          value: 0,
          count: 0,
        }
      }

      copyOfCurrentReport.incomes.studies.students.value += value
      copyOfCurrentReport.incomes.studies.students.count += isEdit ? 0 : 1
    }

    if (model) {
      if (!copyOfCurrentReport.incomes.studies.models) {
        copyOfCurrentReport.incomes.studies.models = {
          value: 0,
          count: 0,
        }
      }

      copyOfCurrentReport.incomes.studies.models.value += value
      copyOfCurrentReport.incomes.studies.models.count += isEdit ? 0 : 1
    }
  }

  if (
    (type === 'event' && reportData.sells) ||
    ['sale', 'saleProduct'].includes(type)
  ) {
    const mainReportData = reportData.sells || reportData

    const salesDbValue = copyOfCurrentReport.incomes.sales.value || 0
    const salesDbCount = copyOfCurrentReport.incomes.sales.count || 0

    const { value: sellsValue, masterId, masterPart = 0 } = mainReportData

    copyOfCurrentReport.incomes.sales.value =
      salesDbValue + (reportData.sells ? reportData.sells.value : sellsValue)
    copyOfCurrentReport.incomes.sales.count = salesDbCount + 1

    if (masterId) {
      if (!copyOfCurrentReport.incomes.sales.masters) {
        copyOfCurrentReport.incomes.sales.masters = {}
      }

      if (!copyOfCurrentReport.incomes.sales.masters.total) {
        copyOfCurrentReport.incomes.sales.masters.total = {
          value: 0,
          count: 0,
        }
      }

      copyOfCurrentReport.incomes.sales.masters.total.value += masterPart
      copyOfCurrentReport.incomes.sales.masters.total.count += isEdit ? 0 : 1

      if (!copyOfCurrentReport.incomes.sales.masters[masterId]) {
        copyOfCurrentReport.incomes.sales.masters[masterId] = {
          value: 0,
          count: 0,
          masterPart: 0,
        }
      }

      copyOfCurrentReport.incomes.sales.masters[masterId].value += sellsValue
      copyOfCurrentReport.incomes.sales.masters[masterId].masterPart +=
        masterPart
      copyOfCurrentReport.incomes.sales.masters[masterId].count += isEdit
        ? 0
        : 1
    }
  }

  if ((type === 'event' && reportData.sells) || type === 'saleProduct') {
    const mainReportData = reportData.sells || reportData

    const {
      saleProduct: { products },
    } = mainReportData

    products.forEach((product) => {
      const { id, quantity, price } = product

      if (!copyOfCurrentReport.incomes.sales.products[id]) {
        copyOfCurrentReport.incomes.sales.products[id] = {
          value: 0,
          count: 0,
          quantity: 0,
        }
      }

      copyOfCurrentReport.incomes.sales.products[id].value += price * quantity
      copyOfCurrentReport.incomes.sales.products[id].quantity += quantity
      copyOfCurrentReport.incomes.sales.products[id].count += isEdit ? 0 : 1
    })
  }

  if (type === 'sale') {
    const {
      sale: { categoryId },
    } = reportData

    const salesOtherDbValue = copyOfCurrentReport.incomes.sales.other.value || 0
    const salesOtherDbCount = copyOfCurrentReport.incomes.sales.other.count || 0

    copyOfCurrentReport.incomes.sales.other.value = salesOtherDbValue + value
    copyOfCurrentReport.incomes.sales.other.count = salesOtherDbCount + 1

    if (!copyOfCurrentReport.incomes.sales.other.categories[categoryId]) {
      copyOfCurrentReport.incomes.sales.other.categories[categoryId] = {}
    }

    const dbValue =
      copyOfCurrentReport.incomes.sales.other.categories[categoryId].value || 0
    const dbCount =
      copyOfCurrentReport.incomes.sales.other.categories[categoryId].count || 0

    copyOfCurrentReport.incomes.sales.other.categories[categoryId].value =
      dbValue + value
    copyOfCurrentReport.incomes.sales.other.categories[categoryId].count =
      dbCount + 1
  }

  if (reportData.cashback) {
    if (reportData.cashback.paid) {
      const currentCashbackValue = copyOfCurrentReport.cashback.paid || 0

      copyOfCurrentReport.cashback.paid =
        currentCashbackValue + reportData.cashback.paid
    }

    if (reportData.cashback.given) {
      const currentCashbackValue = copyOfCurrentReport.cashback.given || 0

      copyOfCurrentReport.cashback.given =
        currentCashbackValue + reportData.cashback.given
    }
  }

  if (type === 'expence') {
    const { expence, expenceCategories } = reportData

    if (expenceCategories) {
      expenceCategories.forEach((expenceItem) => {
        copyOfCurrentReport.expences.value += expenceItem.value
        copyOfCurrentReport.expences.count += 1

        if (!copyOfCurrentReport.expences.categories[expenceItem.categoryId]) {
          copyOfCurrentReport.expences.categories[expenceItem.categoryId] = {
            value: 0,
            count: 0,
          }
        }

        copyOfCurrentReport.expences.categories[expenceItem.categoryId].value +=
          expenceItem.value

        copyOfCurrentReport.expences.categories[
          expenceItem.categoryId
        ].count += 1
      })
    } else {
      const categoryId = expence?.categoryId || null

      const expenceDbValue = copyOfCurrentReport.expences.value || 0
      const expenceDbCount = copyOfCurrentReport.expences.count || 0

      copyOfCurrentReport.expences.value = expenceDbValue + value
      copyOfCurrentReport.expences.count = expenceDbCount + 1

      if (!copyOfCurrentReport.expences.categories[categoryId]) {
        copyOfCurrentReport.expences.categories[categoryId] = {}
      }

      const dbValue =
        copyOfCurrentReport.expences.categories[categoryId].value || 0
      const dbCount =
        copyOfCurrentReport.expences.categories[categoryId].count || 0

      copyOfCurrentReport.expences.categories[categoryId].value =
        dbValue + value
      copyOfCurrentReport.expences.categories[categoryId].count = dbCount + 1
    }
  }

  return copyOfCurrentReport
}
