import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  state: {
    expences: [],
  },
  mutations: {},
  actions: {
    bindExpences: firestoreAction((context) => {
      context.dispatch('setLoading', true)

      return context
        .bindFirestoreRef(
          'expences',
          db
            .collection('expences')
            .where('salonId', '==', context.getters.userSalonId)
            .orderBy('sortingValue', 'desc')
        )
        .then(function () {
          context.dispatch('setLoading', false)
        })
    }),

    unbindExpences: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('expences')
    }),

    addExpences: firestoreAction((context, payload) => {
      payload = Object.assign(
        {
          salonId: context.getters.userSalonId,
        },
        payload
      )

      return db
        .collection('expences')
        .add(payload)
        .then(function (docRef) {
          db.collection('expences')
            .doc(docRef.id)
            .set({ id: docRef.id }, { merge: true })
        })
    }),

    updateExpences: firestoreAction((context, payload) => {
      return db
        .collection('expences')
        .doc(payload.id)
        .set(payload, { merge: true })
    }),

    updateExpencesHistory: firestoreAction((context, payload) => {
      const history = {
        history: firebase.firestore.FieldValue.arrayUnion({
          changedBy: payload.changedBy,
          changedAt: payload.changedAt,
          ...(payload.value && { value: payload.value }),
          ...(payload.moneySource && { moneySource: payload.moneySource }),
          ...(payload.comment && { comment: payload.comment }),
        }),
      }

      db.collection('expences').doc(payload.id).update(history)
    }),

    removeExpences: firestoreAction((context, expencesId) => {
      db.collection('expences').doc(expencesId).delete()
    }),
  },
  getters: {
    expences: (state) => state.expences,
    expencesById: (state) => (id) => {
      return state.expences.find((expences) => expences.id === id)
    },
    expencesByUserId: (state) => (userId) => {
      return state.expences.filter((expences) => expences.userId === userId)
    },
  },
}
