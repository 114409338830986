import { db } from '@/plugins/firebase'
import store from '@/store/index.js'
import firebase from 'firebase/app'
import 'firebase/firestore'

import { getSortingValueNumberFromDate } from '@/utils/functions'

import warehouseService from '@/services/warehouse-service'
import clientService from '@/services/client-service'
import incomeService from '@/services/income-service'
import historyService from '@/services/history-service'

import { HISTORY_TYPES } from '@/utils/history'

const PRODUCTS_COLLECTION_KEY = 'products'
const OPERATION_SUBCOLLECTION_KEY = 'operations'

const productService = {
  sellOperation: ({ batch, productId, operationData }) => {
    const operationsRef = db
      .collection(PRODUCTS_COLLECTION_KEY)
      .doc(productId)
      .collection(OPERATION_SUBCOLLECTION_KEY)
      .doc()
    const operationsRefId = operationsRef.id

    batch.set(operationsRef, {
      id: operationsRefId,
      salonId: store.getters.userSalonId,
      productId,
      operation: 'sell',
      ...(operationData && { ...operationData }),
    })

    return operationsRefId
  },

  addProduct: ({ batch, productData }) => {
    const productRef = db.collection(PRODUCTS_COLLECTION_KEY).doc()
    const productRefId = productRef.id

    batch.set(productRef, {
      salonId: store.getters.userSalonId,
      id: productRefId,
      ...productData,
    })
  },

  updateProduct: ({ batch, productId, productData }) => {
    const productRef = db.collection(PRODUCTS_COLLECTION_KEY).doc(productId)

    batch.update(
      productRef,
      {
        salonId: store.getters.userSalonId,
        ...productData,
      },
      { merge: true }
    )
  },

  addSell: async ({
    batch,
    products,
    paymentMethod,
    paymentParts,
    currentUser,
    masterId,
    clientId,
    note,
    eventId,
    adminId,
    finalPrice,
    adminPart,
    salonPart,
    masterPart,
    isNeedToReport = true,
  }) => {
    const sortingValue = getSortingValueNumberFromDate()
    const now = Date()

    let sells = {}

    for (let productItem of products) {
      const productObject = store.getters.productById(productItem.id)

      if (!Object.keys(sells).includes(productItem.id)) {
        sells[productItem.id] = {
          productId: productItem.id,
        }
      }

      // Update product quantities
      for (let key in productItem.bookedKeysAndValues) {
        const originValue = productObject.quantity[key].quantity
        const removedValue =
          originValue - productItem.bookedKeysAndValues[key].value

        if (removedValue === 0) {
          productService.updateProduct({
            batch,
            productId: productItem.id,
            productData: {
              sellIncome: firebase.firestore.FieldValue.increment(
                Number(productItem.price) * Number(productItem.quantity)
              ),
              [`quantity.${key}`]: firebase.firestore.FieldValue.delete(),
            },
          })
        } else {
          productService.updateProduct({
            batch,
            productId: productItem.id,
            productData: {
              sellIncome: firebase.firestore.FieldValue.increment(
                Number(productItem.price) * Number(productItem.quantity)
              ),
              quantity: {
                [key]: {
                  quantity: removedValue,
                },
              },
            },
          })
        }
      }

      // Add warehouse history entry
      const warehouseHistoryId = warehouseService.addHistory({
        batch,
        historyData: {
          productId: productItem.id,
          operation: 'sell',
          price: Number(productItem.price),
          moneySource: paymentMethod,
          quantity: Number(productItem.quantity),
          createdBy: currentUser.userId,
          createdAt: now,
          sortingValue,
        },
      })

      // Add product's operation
      const operationId = productService.sellOperation({
        batch,
        productId: productItem.id,
        operationData: {
          quantity: Number(productItem.quantity),
          price: Number(productItem.price),
          moneySource: paymentMethod,
          createdBy: currentUser.userId,
          createdAt: now,
          sellerId: masterId,
          sortingValue,
        },
      })

      let clientSellId

      if (clientId) {
        // Add client's sell
        clientSellId = clientService.addClientSell({
          batch,
          clientId,
          sellData: {
            createdAt: now,
            createdBy: currentUser.userId,
            productId: productItem.id,
            quantity: Number(productItem.quantity),
            value: Number(productItem.price) * Number(productItem.quantity),
            price: Number(productItem.price),
            sortingValue,
            paymentMethod,
          },
        })

        // Update client earnings
        clientService.updateClient({
          batch,
          clientId,
          clientData: {
            earnings: firebase.firestore.FieldValue.increment(
              Number(productItem.price) * Number(productItem.quantity)
            ),
          },
        })
      }

      sells[productItem.id] = {
        ...sells[productItem.id],
        operationId,
        warehouseHistoryId,
        ...(clientSellId && { clientSellId }),
      }
    }

    // Add income entry
    await incomeService.addIncome({
      batch,
      now,
      currentUser,
      incomeData: {
        type: 'saleProduct',
        sortingValue,
        value: finalPrice,
        paymentMethod,
        paymentParts,
        ...(clientId && {
          clientId,
        }),
        moneyParts: {
          masterPart,
          salonPart,
          adminPart,
          clientPart: 0, // No client part for product sell
        },
        masterId,
        ...(note && {
          note,
        }),
        data: {
          ...(adminPart &&
            adminId && {
              adminId,
            }),

          ...(eventId && {
            event: {
              id: eventId,
            },
          }),

          sells,
          products,
        },
      },
      ...(isNeedToReport && {
        reportData: {
          type: 'saleProduct',
          value: finalPrice,
          doMonthReport: true,
          saleProduct: {
            products,
          },
          ...(masterId && {
            masterId,
            masterPart,
          }),
          ...(clientId && {
            clientId,
          }),
        },
      }),
    })

    // Add history
    historyService.addHistory({
      batch,
      currentUser,
      now: new Date(),
      type: HISTORY_TYPES.SELL_PRODUCT,
    })
  },
}

export default productService
