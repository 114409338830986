import { getUserTokenId } from '@/plugins/firebase'
import { backendConfig } from './backend'
import store from '@/store/index.js'

export const PRE_CLIENTS_FLOW_TYPES = {
  REGISTERED: 'registered',
  CANCELED: 'canceled',
}

export const PRE_CLIENTS_FLOW_TYPES_TG_MESSAGES = {
  [PRE_CLIENTS_FLOW_TYPES.REGISTERED]:
    'Вітаємо, ви зареєстровані в нашої базі. Тепер вам доступні всі команди!',
  [PRE_CLIENTS_FLOW_TYPES.CANCELED]:
    "Щось пішло не по плану. Адміністратор зв'яжеться з вами.",
}

export const SENDPULSE_TAGS = {
  IS_PRE_CLIENT: 'isPreClient',
  IS_CLIENT: 'isClient',
}

export const sendMessage = async ({ message, contactId, schedule = null }) => {
  const tokenId = await getUserTokenId()

  const url = new URL(`${backendConfig.sendpulseApiUrl}/sendMessage`)

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      tokenId,
      message: JSON.stringify(message),
      contactId,
      ...(schedule && {
        schedule: JSON.stringify(schedule),
      }),
    }),
  }

  try {
    const response = await fetch(url, requestOptions)
    const result = await response.json()

    return result
  } catch (error) {
    store.dispatch('setError', error)
  }
}

export const runFlow = async ({
  flowType,
  flowId,
  contactId,
  salonId,
  data,
}) => {
  const tokenId = await getUserTokenId()

  const url = new URL(`${backendConfig.sendpulseApiUrl}/runFlow`)

  try {
    const response = await fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tokenId,
        contactId,
        salonId,
        flowType: flowType || '',
        flowId: flowId || '',
        data,
      }),
    })

    const result = await response.json()

    return result
  } catch (error) {
    store.dispatch('setError', error)
  }
}

export const setTag = async ({ tags, contactId }) => {
  const tokenId = await getUserTokenId()

  const url = new URL(`${backendConfig.sendpulseApiUrl}/setTag`)

  const params = {
    tokenId,
    tags,
    contactId,
  }

  url.search = new URLSearchParams(params).toString()

  try {
    const response = await fetch(url)
    const { result } = await response.json()

    return { result }
  } catch (error) {
    store.dispatch('setError', error)
  }
}

export const deleteTag = async ({ tag, contactId }) => {
  const tokenId = await getUserTokenId()

  const url = new URL(`${backendConfig.sendpulseApiUrl}/deleteTag`)

  const params = {
    tokenId,
    tag,
    contactId,
  }

  url.search = new URLSearchParams(params).toString()

  try {
    const response = await fetch(url)
    const { result } = await response.json()

    return { result }
  } catch (error) {
    store.dispatch('setError', error)
  }
}
