<template>
  <span>
    {{ phone }}
  </span>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { isUserIsAdminOrLower } from '@/utils/users'
  export default {
    data() {
      return {}
    },
    computed: {
      ...mapGetters(['user', 'masterById', 'userSalon']),
      currentUser() {
        return this.masterById(this.user.id)
      },
      isUserAdminOrLower() {
        return isUserIsAdminOrLower(this.currentUser)
      },
      hidePhonesForMasters() {
        return this.userSalon.hidePhonesForMasters
      },
      phone() {
        if (!this.clientPhone) return ''

        const startOfPhone = this.clientPhone.slice(0, 4)
        const endOfPhone = this.clientPhone.slice(
          this.clientPhone.length - 5,
          this.clientPhone.length
        )

        return this.hidePhonesForMasters && !this.isUserAdminOrLower
          ? `${startOfPhone} *** ${endOfPhone}`
          : this.clientPhone
      },
    },
    props: {
      clientPhone: String,
    },
    methods: {},
  }
</script>

<style lang="scss" scoped></style>
