<template>
  <el-dialog
    :title="newEvent ? 'Нова запис' : 'Редагувати запис'"
    :close-on-click-modal="true"
    :visible.sync="localDialogVisible"
    :before-close="closeDialog"
    destroy-on-close
    append-to-body
    width="80%"
    class="header-label-padding-left-140"
  >
    <el-form label-width="140px" class="margin-bottom-15" v-if="newEvent">
      <el-form-item label="Тип">
        <el-radio-group v-model="type" size="small" :disabled="loading">
          <el-radio-button label="default"> Звичайний </el-radio-button>

          <el-radio-button label="absence"> Відсутність </el-radio-button>

          <el-radio-button label="cource"> Навчання </el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <DefaultForm
      v-if="type === 'default' && localDialogVisible"
      :isNewEvent="newEvent"
      :eventId="eventId"
      :eventData="eventData"
      :localDialogVisible="localDialogVisible"
      @closeDialog="closeDialog"
    />

    <AbsenceForm
      v-if="type === 'absence' && localDialogVisible"
      :eventId="eventId"
      :newEvent="newEvent"
      :eventData="eventData"
      @closeDialog="closeDialog"
    />

    <CourceForm
      v-if="type === 'cource' && localDialogVisible"
      :eventId="eventId"
      :newEvent="newEvent"
      :eventData="eventData"
      @closeDialog="closeDialog"
    />
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  import DefaultForm from '@/components/Calendar/EventsForms/Default/DefaultForm'
  import AbsenceForm from '@/components/Calendar/EventsForms/Absence/AbsenceForm'
  import CourceForm from '@/components/Calendar/EventsForms/Cource/CourceForm'

  export default {
    data() {
      return {
        localDialogVisible: false,
        type: 'default',
      }
    },
    components: {
      DefaultForm,
      AbsenceForm,
      CourceForm,
    },
    props: {
      dialogVisible: Boolean,
      newEvent: Boolean,
      eventId: String,
      eventType: String,
      eventData: Object,
    },
    computed: {
      ...mapGetters(['loading']),
    },
    watch: {
      dialogVisible: {
        handler(visibility) {
          this.localDialogVisible = visibility

          if (this.eventType) {
            this.type = this.eventType
          }
        },
        immediate: true,
      },
    },
    methods: {
      closeDialog(eventData) {
        this.$nextTick(() => {
          this.$emit('closeEventDialog', eventData)
        })
      },
    },
  }
</script>

<style lang="scss"></style>
