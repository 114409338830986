import { db, getUserTokenId } from '@/plugins/firebase'
import { cleanPhone } from '@/utils/functions'
import { backendConfig } from './backend'
import store from '@/store/index.js'

export async function getClientFromFirebaseById(clientId) {
  if (!clientId) return

  const localClient = store.getters.localClientById(clientId)

  if (localClient && localClient.isFull) {
    return localClient
  }

  const clientRef = await db.collection('clients').doc(clientId).get()

  const client = clientRef.exists ? clientRef.data() : null

  store.dispatch('addToLocalClients', { ...client, isFull: true })

  return client
}

export async function getClientFromSqlById(
  clientId,
  salonId = store.getters.userSalonId
) {
  if (!clientId) return

  const localClient = store.getters.localClientById(clientId)

  if (!localClient) {
    const tokenId = await getUserTokenId()

    const url = new URL(`${backendConfig.clientApiUrl}/client/${clientId}`)

    const params = {
      tokenId,
      salonId,
    }
    url.search = new URLSearchParams(params).toString()

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const {
        result: { data },
      } = await response.json()

      if (data) {
        store.dispatch('addToLocalClients', data)
      }

      return data
    } catch (error) {
      // store.dispatch('setError', error)

      let result = await getClientFromFirebaseById(clientId)

      store.dispatch('addToLocalClients', result)

      return result
    }
  } else {
    return localClient
  }
}

export async function getClientsFromSqlByIdsAndAddToStore(
  clientIds,
  salonId = store.getters.userSalonId
) {
  if (!clientIds || clientIds.length === 0) return

  const notFetchedClients = []

  for await (let clientId of clientIds) {
    const localClient = await store.getters.localClientById(clientId)

    if (!localClient) {
      notFetchedClients.push(clientId)
    }
  }

  if (notFetchedClients.length > 0) {
    const tokenId = await getUserTokenId()
    const url = new URL(`${backendConfig.clientApiUrl}/clients`)

    const params = {
      tokenId,
      salonId,
      notFetchedClients,
    }

    url.search = new URLSearchParams(params).toString()

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const { result } = await response.json()

      if (result && result.length > 0) {
        for (let client of result) {
          store.dispatch('addToLocalClients', client)
        }
      }
    } catch (error) {
      store.dispatch('setError', error)
    }
  }
}

export async function getClientFromFirebaseByPhone(phone) {
  if (!phone) return

  const clientRef = await db
    .collection('clients')
    .where('rawPhone', '==', cleanPhone(phone))
    .where('salonId', '==', store.getters.userSalonId)
    .get()

  let client = null

  if (clientRef.empty) {
    return null
  }

  clientRef.forEach((doc) => {
    client = doc.data()
  })

  return client
}

export async function searchClientsFromSQLNotFull({ queryString }) {
  if (!queryString) return

  const tokenId = await getUserTokenId()

  const url = new URL(`${backendConfig.clientApiUrl}/search`)
  const params = {
    query: cleanPhone(queryString.toLowerCase().replace(/'/g, `\\'`)),
    full: false,
    tokenId,
  }

  url.search = new URLSearchParams(params).toString()

  try {
    const response = await fetch(url)
    const { results: resultsFromDb } = await response.json()

    return resultsFromDb
  } catch (error) {
    store.dispatch('setError', error)
  }
}

export async function searchClientsFromSQLFull({
  searchTerm,
  procedureCategories,
  masterIds,
  eventStatus,
  datePeriod,
  dateCashbackPeriod,
  page,
  isFirstQuery,
  sort,
}) {
  const tokenId = await getUserTokenId()

  const url = new URL(`${backendConfig.clientApiUrl}/search`)

  const params = {
    query: searchTerm.replace(/'/g, `\\'`),
    procedureCategories: procedureCategories || '',
    masterIds: masterIds || '',
    datePeriod: datePeriod || '',
    dateCashbackPeriod: dateCashbackPeriod || '',
    eventStatus: eventStatus || 3,
    full: true,
    tokenId,
    isFirstQuery,
    page,
    sortBy: sort.field,
    sortOrder: sort.order,
  }

  url.search = new URLSearchParams(params).toString()

  try {
    const response = await fetch(url)
    const { results, totalRows } = await response.json()

    return { results, totalRows }
  } catch (error) {
    store.dispatch('setError', error)
  }
}

export const clientOrigins = [
  {
    slug: 'phone',
    name: 'Телефон',
    link: false,
    icon: 'fas fa-phone',
  },
  {
    slug: 'facebook',
    name: 'Facebook',
    link: true,
    icon: 'fab fa-facebook',
  },
  {
    slug: 'viber',
    name: 'Viber',
    link: false,
    icon: 'fab fa-viber',
  },
  {
    slug: 'instagram',
    name: 'Instagram',
    link: true,
    icon: 'fab fa-instagram',
  },
  {
    slug: 'telegram-bot',
    name: 'Telegram Bot',
    link: false,
    icon: 'fab fa-telegram',
  },
  {
    slug: 'other',
    name: 'Інше',
    link: false,
    icon: 'fab fa-question',
  },
]

export const REFERRAL_TYPES = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  ADV: 'adv',
  YOUTUBE: 'youtube',
  CLIENT: 'client',
  MASTER: 'master',
  OTHER: 'other',
}

export const clientReferrals = [
  {
    slug: REFERRAL_TYPES.FACEBOOK,
    name: 'Facebook',
    icon: 'fab fa-facebook',
  },
  {
    slug: REFERRAL_TYPES.INSTAGRAM,
    name: 'Instagram',
    icon: 'fab fa-instagram',
  },
  {
    slug: REFERRAL_TYPES.ADV,
    name: 'Реклама',
    icon: 'fas fa-ad',
  },
  {
    slug: REFERRAL_TYPES.YOUTUBE,
    name: 'Youtube',
    icon: 'fab fa-youtube',
  },
  {
    slug: REFERRAL_TYPES.CLIENT,
    name: 'Від іншого клієнта',
    icon: 'fas fa-user-friends',
  },
  {
    slug: REFERRAL_TYPES.MASTER,
    name: 'Від майстра',
    icon: 'fas fa-user-tie',
  },
  {
    slug: REFERRAL_TYPES.OTHER,
    name: 'Інше',
    icon: 'fas fa-bookmark',
  },
]

export const advType = [
  {
    slug: 'facebookInstagram',
    name: 'Facebook/Instagram',
  },
  {
    slug: 'google',
    name: 'Google',
  },
  {
    slug: 'other',
    name: 'Інше',
  },
]
