import { db } from '@/plugins/firebase'

import firebase from 'firebase/app'
import 'firebase/firestore'

import store from '@/store/index'

import {
  getSortingValueNumberFromDate,
  diffDays,
  getDateFromSortingValueNumber,
  sortObjectByKey,
} from '@/utils/functions'

const now = new Date()

export const OPERATION_TYPE = {
  SELL: 'sell',
  ADD: 'add',
}

export async function removeExpiredQuantity(
  productId,
  quantityKey,
  quantityKeyValue,
  fromSortingValue,
  salonId,
  userId
) {
  const batch = db.batch()

  const productRef = db.collection('products').doc(productId)

  batch.set(
    productRef,
    {
      quantity: {
        [quantityKey]: firebase.firestore.FieldValue.delete(),
      },
    },
    { merge: true }
  )

  const warehouseHistoryRef = db.collection('warehouseHistory').doc()
  const warehouseHistoryRefId = warehouseHistoryRef.id

  batch.set(warehouseHistoryRef, {
    id: warehouseHistoryRefId,
    productId: productId,
    operation: 'removeExpired',
    quantity: quantityKeyValue.quantity,
    dateOfProductCreation: fromSortingValue,
    salonId: salonId,
    createdBy: userId,
    createdAt: Date(),
    sortingValue: getSortingValueNumberFromDate(new Date()),
    ...(quantityKeyValue.price && {
      price: quantityKeyValue.price,
    }),
  })

  batch.commit().catch((error) => {
    store.dispatch('setError', error.message)
  })
}

export function getQuantity(productId, quantities, salonId, userId) {
  let countingQuantity = 0

  for (let quantity in quantities) {
    const splitedDates = quantity.split('-')

    if (diffDays(getDateFromSortingValueNumber(splitedDates[1]), now) < 0) {
      removeExpiredQuantity(
        productId,
        quantity,
        quantities[quantity],
        splitedDates[0],
        salonId,
        userId
      )
    }

    countingQuantity += quantities[quantity].quantity
  }

  return countingQuantity
}

// export async function syncQuantity(productId, quantity) {
//   const operationsObj = {
//     [OPERATION_TYPE.ADD]: [],
//     [`${OPERATION_TYPE.ADD}_quantity`]: 0,
//     [OPERATION_TYPE.SELL]: [],
//     [`${OPERATION_TYPE.SELL}_quantity`]: 0,
//   }

//   await db.collection('products')
//     .doc(productId)
//     .collection('operations')
//     .orderBy('sortingValue', 'desc')
//     .get()
//       .then((querySnapshot) => {
//         querySnapshot.forEach(function(doc) {
//           const operationObj = doc.data()

//           // console.log(operationObj)
//           if (operationObj.operation === OPERATION_TYPE.ADD) {
//             operationsObj[OPERATION_TYPE.ADD].push({
//               quantity: operationObj.quantity,
//               sortingValue: operationObj.sortingValue,
//               expiration: operationObj.expiration
//             })

//             operationsObj[`${OPERATION_TYPE.ADD}_quantity`] += operationObj.quantity
//           }

//           if (operationObj.operation === OPERATION_TYPE.SELL) {
//             operationsObj[OPERATION_TYPE.SELL].push({
//               quantity: operationObj.quantity,
//               sortingValue: operationObj.sortingValue
//             })

//             operationsObj[`${OPERATION_TYPE.SELL}_quantity`] += operationObj.quantity
//           }
//         })

//         const differenceBetweenAddAndSell = operationsObj[`${OPERATION_TYPE.ADD}_quantity`] - operationsObj[`${OPERATION_TYPE.SELL}_quantity`]

//         if (differenceBetweenAddAndSell > 0 && differenceBetweenAddAndSell !== quantity) {
//           let needToRemove = differenceBetweenAddAndSell

//           for (let i = 0; i <= operationsObj[OPERATION_TYPE.ADD].length; i++) {
//             if (needToRemove === 0) break

//             // const { quantity, expiration, sortingValue } = operationsObj[OPERATION_TYPE.ADD]

//           }
//           console.log(operationsObj, quantity)
//         }

//       })
//       .catch((error) => {
//         store.dispatch('setError', error.message)
//       })
// }

export function getItemsFromQuantities(product, currentQuantity) {
  const quantities = sortObjectByKey(product.quantity)
  let bookedKeysAndValues = {}
  let leftToBook = currentQuantity

  for (const quantityKey in quantities) {
    const quantityKeyObj = quantities[quantityKey]
    const quantityKeyValue = quantityKeyObj.quantity

    if (leftToBook) {
      if (
        !Object.prototype.hasOwnProperty.call(bookedKeysAndValues, quantityKey)
      ) {
        bookedKeysAndValues[quantityKey] = {}
      }

      if (quantityKeyValue <= leftToBook) {
        // If needed quantity more or equal to current key value then we just take all values from key
        Object.assign(bookedKeysAndValues[quantityKey], {
          key: quantityKey,
          value: quantityKeyValue,
          buyPrice: quantityKeyObj.buyPrice ? quantityKeyObj.buyPrice : 0,
        })

        leftToBook = leftToBook - quantityKeyValue
      } else if (quantityKeyValue > leftToBook) {
        // If needed quantity is less then we just take needed value
        Object.assign(bookedKeysAndValues[quantityKey], {
          key: quantityKey,
          value: leftToBook,
          buyPrice: quantityKeyObj.buyPrice ? quantityKeyObj.buyPrice : 0,
        })

        leftToBook = 0
      }
    }
  }

  return bookedKeysAndValues
}
