<template>
  <div class="student-item">
    <div class="student-item--header">
      <ClientView :client="localStudent" />

      <el-button
        @click="removeItem(localStudent)"
        :disabled="isClientAdding || loading"
        class="student-item--remove"
        type="danger"
        icon="el-icon-delete"
        circle
      />
    </div>

    <div class="student-item--content">
      <div>Базова вартість курсу для {{ localStudent.name }}:</div>

      <div v-if="!isPriceEditing">
        <CurrencyView :value="localStudent.price" class="margin-right-5" />

        <CurrencyView
          :value="courcePrice"
          v-if="courcePrice !== localStudent.price"
          class="margin-right-5 small text-crossline"
        />

        <el-link
          type="primary"
          icon="el-icon-edit"
          @click="editPrice"
          class="margin-right-5"
        >
          змінити ціну
        </el-link>

        <el-link
          type="danger"
          icon="el-icon-close"
          @click="resetEditedPrice"
          v-if="courcePrice !== localStudent.price"
        >
          повернути оригінальну ціну
        </el-link>
      </div>

      <div v-if="isPriceEditing">
        <NumberInput
          v-model="editingPrice"
          :disabled="isClientAdding || loading"
          :min="10"
          suffixDefault="money"
          class="margin-right-10"
        />

        <el-button type="primary" @click="savePrice" plain>
          Зберегти
        </el-button>

        <el-button type="danger" @click="cancelEditingPrice" plain>
          Скасувати
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ClientView from '@/components/Common/Clients/ClientView'
  import NumberInput from '@/components/Common/Forms/NumberInput'
  import CurrencyView from '@/components/Common/CurrencyView'

  export default {
    data() {
      return {
        localStudent: {},
        isPriceEditing: false,
        editingPrice: 0,
      }
    },
    computed: {
      ...mapGetters(['loading']),
    },
    props: {
      student: Object,
      isClientAdding: Boolean,
      courcePrice: Number,
    },
    watch: {
      student: {
        async handler(studentData) {
          if (!studentData) return

          this.localStudent = JSON.parse(JSON.stringify(studentData))
          this.editingPrice = this.localStudent.price
        },
        immediate: true,
      },
    },
    components: {
      ClientView,
      NumberInput,
      CurrencyView,
    },
    methods: {
      removeItem(student) {
        this.$emit('removeStudent', student)
      },
      editPrice() {
        this.isPriceEditing = true
      },
      savePrice() {
        this.localStudent.price = this.editingPrice

        this.$emit('changePrice', this.localStudent)

        this.isPriceEditing = false
      },
      resetEditedPrice() {
        this.editingPrice = this.courcePrice

        this.localStudent.price = this.courcePrice

        this.$emit('changePrice', this.localStudent)
      },
      cancelEditingPrice() {
        this.editingPrice = this.localStudent.price

        this.isPriceEditing = false

        this.$emit('changePrice', this.localStudent)
      },
    },
    mounted() {},
  }
</script>

<style lang="scss" scoped>
  .student-item {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $border-color;
  }

  .student-item--header {
    display: flex;
  }

  .student-item--content {
    line-height: 2;
  }

  .student-item--remove {
    height: 40px;
    margin-left: 10px;
    flex: 0 0 40px;
  }
</style>
