import { db } from '@/plugins/firebase'
import store from '@/store/index.js'

import warningService from './warning-service'

import {
  getSortingValueNumberFromDateWithoutTimeAndDay,
  getSortingValueNumberFromDate,
} from '@/utils/functions'

import { FINANCE_REPORT_DATA_STRUCTURE } from '@/utils/expences'
import { prepareReportData } from '@/utils/reportData'

const FINANCES_REPORTS_COLLECTION_KEY = 'financeReports'
const MONTH_REPORTS_COLLECTION_KEY = 'monthReports'

const MASTER_INIT_OBJ = {
  value: 0,
  proceduresCount: 0,
  newClientsCount: 0,
  eventCount: 0,
  rewrittenClientsCount: 0,
  sellsCount: 0,
  clientIds: [],
}

const CALLS_INIT_OBJ = {
  calls: 0,
  npsCalls: 0,
  npsEventCreated: 0,
}

const prepareReportDataForMonthReport = (
  reportData,
  sortingValue,
  now = new Date()
) => {
  const { value, type, isEdit } = reportData

  if (!['event', 'saleProduct', 'calling'].includes(type)) return null

  const today = now.getDate()

  const copyOfLastMonthReport = store.getters.lastMonthReport
    ? JSON.parse(JSON.stringify(store.getters.lastMonthReport))
    : {
        data: {},
        salonId: store.getters.userSalonId,
        sortingValue,
      }

  if (!copyOfLastMonthReport.data) {
    copyOfLastMonthReport.data = {}
  }

  if (!copyOfLastMonthReport.data[today]) {
    const todayWeekDay = now.getDay()

    copyOfLastMonthReport.data[today] = {
      weekDay: todayWeekDay,
      plannedIncome: 0,
      factIncome: 0,
      eventsCount: 0,
      proceduresCount: 0,
      newClientsCount: 0,
      rewrittenClientsCount: 0,
      value: 0,
      createdEvents: 0,
      clientIds: [],
      eventsFinalizedBy: {},
      eventsCreatedBy: {},
      masters: {},
      callings: {},
      sellsCount: 0,
      sellsValue: 0,
      plannedCalls: 0,
    }
  }

  if (value) {
    copyOfLastMonthReport.data[today].factIncome += value
  }

  if (type === 'calling') {
    const { masterId, plannedCalls, isNpsCalling, isEventCreated } =
      reportData.calling

    if (typeof plannedCalls !== 'undefined') {
      copyOfLastMonthReport.data[today].plannedCalls = plannedCalls
    }

    if (masterId) {
      if (!copyOfLastMonthReport.data[today].callings) {
        copyOfLastMonthReport.data[today].callings = {}
      }

      if (!copyOfLastMonthReport.data[today].callings[masterId]) {
        copyOfLastMonthReport.data[today].callings[masterId] = {
          ...JSON.parse(JSON.stringify(CALLS_INIT_OBJ)),
        }
      }

      copyOfLastMonthReport.data[today].callings[masterId].calls += 1

      if (isNpsCalling) {
        copyOfLastMonthReport.data[today].callings[masterId].npsCalls += 1
      }

      if (isEventCreated) {
        copyOfLastMonthReport.data[today].callings[
          masterId
        ].npsEventCreated += 1
      }
    }
  }

  if (type === 'event') {
    const {
      procedures,
      isNewClient,
      clientId,
      masterIds,
      finalizedBy,
      createdBy,
      isRewrittenClientsCount,
      plannedIncome,
      createdEvents,
      lastEventMasterIds,
    } = reportData.event

    if (isNewClient) {
      copyOfLastMonthReport.data[today].newClientsCount += 1
    }

    if (isRewrittenClientsCount) {
      copyOfLastMonthReport.data[today].rewrittenClientsCount += 1
    }

    if (finalizedBy) {
      if (!copyOfLastMonthReport.data[today].eventsFinalizedBy) {
        copyOfLastMonthReport.data[today].eventsFinalizedBy = {}
      }

      if (!copyOfLastMonthReport.data[today].eventsFinalizedBy[finalizedBy]) {
        copyOfLastMonthReport.data[today].eventsFinalizedBy[finalizedBy] = {
          value: 0,
          count: 0,
        }
      }

      copyOfLastMonthReport.data[today].eventsFinalizedBy[finalizedBy].value +=
        value
      copyOfLastMonthReport.data[today].eventsFinalizedBy[
        finalizedBy
      ].count += 1
    }

    if (createdBy) {
      if (!copyOfLastMonthReport.data[today].eventsCreatedBy) {
        copyOfLastMonthReport.data[today].eventsCreatedBy = {}
      }

      if (!copyOfLastMonthReport.data[today].eventsCreatedBy[createdBy]) {
        copyOfLastMonthReport.data[today].eventsCreatedBy[createdBy] = {
          value: 0,
          count: 0,
        }
      }

      copyOfLastMonthReport.data[today].eventsCreatedBy[createdBy].value +=
        value
      copyOfLastMonthReport.data[today].eventsCreatedBy[createdBy].count += 1
    }

    if (
      clientId &&
      !copyOfLastMonthReport.data[today].clientIds.includes(clientId)
    ) {
      copyOfLastMonthReport.data[today].clientIds.push(clientId)
    }

    if (typeof plannedIncome !== 'undefined') {
      copyOfLastMonthReport.data[today].plannedIncome = plannedIncome
    }

    if (typeof createdEvents !== 'undefined') {
      copyOfLastMonthReport.data[today].createdEvents = createdEvents
    }

    if (masterIds?.length) {
      copyOfLastMonthReport.data[today].eventsCount += 1

      masterIds.forEach((masterId) => {
        if (!copyOfLastMonthReport.data[today].masters[masterId]) {
          copyOfLastMonthReport.data[today].masters[masterId] = {
            ...JSON.parse(JSON.stringify(MASTER_INIT_OBJ)),
          }
        }

        if (!copyOfLastMonthReport.data[today].masters[masterId].clientIds) {
          copyOfLastMonthReport.data[today].masters[masterId].clientIds = []
        }

        if (
          clientId &&
          !copyOfLastMonthReport.data[today].masters[
            masterId
          ].clientIds.includes(clientId)
        ) {
          copyOfLastMonthReport.data[today].masters[masterId].clientIds.push(
            clientId
          )
        }

        copyOfLastMonthReport.data[today].masters[masterId].eventCount += 1

        if (isNewClient) {
          copyOfLastMonthReport.data[today].masters[
            masterId
          ].newClientsCount += 1
        }

        if (
          isRewrittenClientsCount &&
          lastEventMasterIds.length &&
          lastEventMasterIds.includes(masterId)
        ) {
          if (
            !copyOfLastMonthReport.data[today].masters[masterId]
              .rewrittenClientsCount
          ) {
            copyOfLastMonthReport.data[today].masters[
              masterId
            ].rewrittenClientsCount = 0
          }

          copyOfLastMonthReport.data[today].masters[
            masterId
          ].rewrittenClientsCount += 1
        }
      })
    }

    if (procedures?.length) {
      procedures.forEach((procedure) => {
        const { masterId, value } = procedure

        copyOfLastMonthReport.data[today].masters[masterId].value += value
        copyOfLastMonthReport.data[today].value += value
        copyOfLastMonthReport.data[today].masters[masterId].proceduresCount +=
          isEdit ? 0 : 1
        copyOfLastMonthReport.data[today].proceduresCount += isEdit ? 0 : 1
      })
    }
  }

  if (reportData.events?.sells || reportData.saleProduct) {
    const mainSellReportData = reportData.events?.sells || reportData

    if (mainSellReportData && mainSellReportData.value) {
      copyOfLastMonthReport.data[today].sellsCount += 1
      copyOfLastMonthReport.data[today].value += mainSellReportData.value
      copyOfLastMonthReport.data[today].sellsValue += mainSellReportData.value
    }
  }

  return copyOfLastMonthReport
}

const financesReportService = {
  getFinanceReports: async (from, to) => {
    await store.dispatch('setLoading', true)

    const monthTo = Number(String(to).slice(4, to.length))

    let reports = new Array(monthTo).fill({})

    await db
      .collection(FINANCES_REPORTS_COLLECTION_KEY)
      .where('sortingValue', '>=', from)
      .where('sortingValue', '<=', to)
      .where('salonId', '==', store.getters.userSalonId)
      .orderBy('sortingValue', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const item = doc.data()

          const docMonth = Number(
            String(item.sortingValue).slice(4, item.sortingValue.length)
          )

          reports[docMonth - 1] = {
            ...item,
            itemMonth: docMonth,
          }
        })
      })
      .catch((error) => store.dispatch('setError', error.message))
      .finally(() => {
        store.dispatch('setLoading', false)
      })

    return reports
  },

  getFinanceReport: async (sortingValue) => {
    let reports = []

    await store.dispatch('setLoading', true)

    await db
      .collection(FINANCES_REPORTS_COLLECTION_KEY)
      .where('salonId', '==', store.getters.userSalonId)
      .where('sortingValue', '==', sortingValue)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          reports.push(doc.data())
        })
      })
      .catch((error) => store.dispatch('setError', error.message))

    if (reports.length > 1) {
      await warningService.addWarning({
        warningData: {
          date: Date(),
          type: 'TOO_MUCH_REPORTS',
          sortingValue: getSortingValueNumberFromDate(),
          salonId: store.getters.userSalonId,
          reportIds: reports.map((item) => item.id),
          solved: false,
        },
      })
    }

    await store.dispatch('setLoading', false)

    return {
      report: {
        ...JSON.parse(JSON.stringify(FINANCE_REPORT_DATA_STRUCTURE)),
        sortingValue,
        ...(reports[0] ?? {}),
      },
      isFound: !!reports.length,
      ...(reports.length > 1 && {
        reportIds: reports.map((item) => item.id),
      }),
    }
  },

  getMonthReport: async (sortingValue) => {
    await store.dispatch('setLoading', true)

    let reports = []

    await db
      .collection(MONTH_REPORTS_COLLECTION_KEY)
      .where('salonId', '==', store.getters.userSalonId)
      .where('sortingValue', '==', sortingValue)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          reports.push(doc.data())
        })
      })
      .catch((error) => store.dispatch('setError', error.message))
      .finally(() => {
        store.dispatch('setLoading', false)
      })

    if (reports.length > 1) {
      await warningService.addWarning({
        warningData: {
          date: Date(),
          type: 'TOO_MUCH_MONTH_REPORTS',
          salonId: store.getters.userSalonId,
          sortingValue: getSortingValueNumberFromDate(),
          reportIds: reports.map((item) => item.id),
          solved: false,
        },
      })
    }

    return reports[0] ?? {}
  },

  updateMonthReport: async ({
    batch,
    reportData,
    nowSortingValue,
    now = new Date(),
  }) => {
    if (!store.getters.isLastMonthReportLoaded) {
      await store.dispatch('bindLastMonthReport')
    }

    const monthReportData = prepareReportDataForMonthReport(
      reportData,
      nowSortingValue,
      now
    )

    if (monthReportData) {
      const isFound = !!store.getters.lastMonthReport?.id

      const monthReportRef = isFound
        ? db
            .collection(MONTH_REPORTS_COLLECTION_KEY)
            .doc(store.getters.lastMonthReport.id)
        : db.collection(MONTH_REPORTS_COLLECTION_KEY).doc()

      const monthReportRefId = monthReportRef.id

      batch.set(
        monthReportRef,
        {
          id: monthReportRefId,
          salonId: store.getters.userSalonId,
          ...monthReportData,
        },
        { merge: true }
      )
    }
  },

  addFinanceReport: async ({
    batch,
    financeReportDate = new Date(),
    salonId = store.getters.userSalonId,
    reportData,
    removeIfExist,
  }) => {
    const sortingValue =
      getSortingValueNumberFromDateWithoutTimeAndDay(financeReportDate)

    const { report, isFound, reportIds } =
      await financesReportService.getFinanceReport(sortingValue)

    if (isFound && removeIfExist) {
      if (reportIds?.length) {
        reportIds.forEach((id) => {
          batch.delete(db.collection(FINANCES_REPORTS_COLLECTION_KEY).doc(id))
        })
      } else {
        batch.delete(
          db.collection(FINANCES_REPORTS_COLLECTION_KEY).doc(report.id)
        )
      }
    }

    const financeReportRef = db
      .collection(FINANCES_REPORTS_COLLECTION_KEY)
      .doc()

    const financeReportRefId = financeReportRef.id

    batch.set(financeReportRef, {
      salonId,
      id: financeReportRefId,
      sortingValue,
      ...reportData,
    })
  },

  updateFinanceReport: async ({
    batch,
    reportData,
    doMonthReport = false,
    sortingValue,
  }) => {
    if (!store.getters.isLastMonthReportLoaded) {
      await store.dispatch('bindLastMonthReport')
    }

    const nowSortingValue =
      sortingValue || getSortingValueNumberFromDateWithoutTimeAndDay()

    const { report, isFound } = await financesReportService.getFinanceReport(
      nowSortingValue
    )

    const financeReportRef = isFound
      ? db.collection(FINANCES_REPORTS_COLLECTION_KEY).doc(report.id)
      : db.collection(FINANCES_REPORTS_COLLECTION_KEY).doc()

    const financeReportRefId = financeReportRef.id

    if (doMonthReport) {
      financesReportService.updateMonthReport({
        batch,
        reportData,
        nowSortingValue,
      })
    }

    const preparedReport = prepareReportData(reportData, report)

    batch.set(
      financeReportRef,
      {
        id: financeReportRefId,
        salonId: store.getters.userSalonId,
        ...preparedReport,
      },
      { merge: true }
    )
  },
}

export default financesReportService
