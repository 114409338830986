import { db } from '@/plugins/firebase'
import store from '@/store/index.js'

import financesReportsService from '@/services/finances-reports-service'

const EXPENCES_COLLECTION_KEY = 'expences'
const EXPENCES_CATEGORIES_COLLECTION_KEY = 'expencesCategories'

const expenceService = {
  addExpence: async ({ batch, expenceData, type, reportData }) => {
    const expenceRef = db.collection(EXPENCES_COLLECTION_KEY).doc()
    const expenceRefId = expenceRef.id

    if (reportData) {
      await financesReportsService.updateFinanceReport({
        batch,
        reportData,
      })
    }

    batch.set(expenceRef, {
      id: expenceRefId,
      salonId: store.getters.userSalonId,
      type: type || 'expence',
      ...(expenceData && { ...expenceData }),
    })
  },

  getExpences: async (date) => {
    let expences = []

    await db
      .collection(EXPENCES_COLLECTION_KEY)
      .where('sortingValue', '>=', date[0])
      .where('sortingValue', '<', date[1])
      .where('salonId', '==', store.getters.userSalonId)
      .orderBy('sortingValue', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const item = doc.data()

          expences.push(item)
        })
      })
      .catch((error) => {
        this.$store.dispatch('setError', error.message)
        this.$store.dispatch('setLoading', false)
      })

    return expences
  },

  addCategory: ({ batch, categoryData }) => {
    const expenceCategoryRef = db
      .collection(EXPENCES_CATEGORIES_COLLECTION_KEY)
      .doc()
    const expenceCategoryRefId = expenceCategoryRef.id

    batch.set(expenceCategoryRef, {
      id: expenceCategoryRefId,
      salonId: store.getters.userSalonId,
      ...categoryData,
    })

    return expenceCategoryRefId
  },

  updateCategory: ({ batch, categoryId, categoryData }) => {
    const expenceCategoryRef = db
      .collection(EXPENCES_CATEGORIES_COLLECTION_KEY)
      .doc(categoryId)

    batch.set(expenceCategoryRef, categoryData, { merge: true })
  },
}

export default expenceService
