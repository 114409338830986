import firebase from 'firebase/app'
import 'firebase/firestore'
import store from '@/store/index.js'

import { db } from '@/plugins/firebase'

const EVENTS_COLLECTION_KEY = 'events'

const eventService = {
  getEvent: async ({ eventId }) => {
    await store.dispatch('setLoading', true)

    let event = {}

    await db
      .collection(EVENTS_COLLECTION_KEY)
      .doc(eventId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          event = doc.data()
        }
      })
      .catch((error) => store.dispatch('setError', error.message))
      .finally(() => {
        store.dispatch('setLoading', false)
      })

    return event
  },

  getEvents: async ({ from, to, sorting = 'asc' }) => {
    await store.dispatch('setLoading', true)

    let events = []

    await db
      .collection(EVENTS_COLLECTION_KEY)
      .where('salonId', '==', store.getters.userSalonId)
      .where('sortingValue', '>=', from)
      .where('sortingValue', '<=', to)
      .orderBy('sortingValue', sorting)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          events.push(doc.data())
        })
      })
      .catch((error) => store.dispatch('setError', error.message))
      .finally(() => {
        store.dispatch('setLoading', false)
      })

    return events
  },

  addEvent: ({ batch, eventData, currentUser, sortingValue, now }) => {
    const eventRef = db.collection(EVENTS_COLLECTION_KEY).doc()
    const eventRefId = eventRef.id

    const eventObj = {
      id: eventRefId,
      salonId: store.getters.userSalonId,
      history: [
        {
          type: 'created',
          userId: currentUser.userId,
          name: currentUser.name,
          date: now,
        },
      ],
      sortingValue,
      ...eventData,
    }

    batch.set(eventRef, eventObj)

    return eventRefId
  },

  removeEventMasterProceduresBeforeEditing: ({ batch, eventId }) => {
    const eventRef = db.collection(EVENTS_COLLECTION_KEY).doc(eventId)

    batch.set(
      eventRef,
      {
        mastersProcedures: firebase.firestore.FieldValue.delete(),
      },
      { merge: true }
    )
  },

  editEvent: ({
    batch,
    eventId,
    currentUser,
    eventUpdateObj,
    editType,
    now,
  }) => {
    const eventRef = db.collection(EVENTS_COLLECTION_KEY).doc(eventId)

    const eventObj = {
      ...eventUpdateObj,
      history: firebase.firestore.FieldValue.arrayUnion({
        type: editType,
        userId: currentUser.userId,
        name: currentUser.name,
        date: now,
      }),
      salonId: store.getters.userSalonId,
    }

    batch.set(eventRef, eventObj, { merge: true })
  },

  addAdditionalEventData: ({
    batch,
    eventId,
    masterKey,
    procedureKey,
    additionalValuesObj,
    isVer2,
  }) => {
    const eventRef = db.collection(EVENTS_COLLECTION_KEY).doc(eventId)
    const eventKey = isVer2
      ? `mastersProcedures.${masterKey}.procedures.${procedureKey}.additionalFields`
      : 'raw.additionalFields'

    batch.update(
      eventRef,
      {
        [eventKey]: additionalValuesObj,
      },
      { merge: true }
    )
  },

  cancelEvent: ({
    batch,
    eventId,
    currentUser,
    now,
    editType,
    isVer2,
    cancelData = null,
  }) => {
    const eventData = isVer2
      ? {
          status: 2,
          statusActive: Boolean(false),
          canceledAt: Date(),
          history: firebase.firestore.FieldValue.arrayUnion({
            type: editType,
            userId: currentUser.userId,
            name: currentUser.name,
            date: now,
          }),
          ...(cancelData && {
            ...cancelData,
          }),
        }
      : {
          raw: {
            status: 2,
            statusActive: Boolean(false),
            canceledAt: Date(),
            canceler: {
              userId: currentUser.userId,
              name: currentUser.name,
            },
          },
        }

    const eventRef = db.collection(EVENTS_COLLECTION_KEY).doc(eventId)

    batch.set(eventRef, eventData, { merge: true })
  },

  deleteEvent: ({ batch, eventId }) => {
    const eventRef = db.collection(EVENTS_COLLECTION_KEY).doc(eventId)

    batch.delete(eventRef)
  },
}

export default eventService
