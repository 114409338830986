import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'
import { getSortingValueNumberFromDateWithoutTimeAndDay } from '@/utils/functions'

export default {
  state: {
    lastMonthReport: [],
    isLastMonthReportLoaded: false,
  },
  mutations: {
    setIsLastMonthReportLoaded(state, payload) {
      state.isLastMonthReportLoaded = payload
    },
  },
  actions: {
    setIsLastMonthReportLoaded({ commit }, payload) {
      commit('setIsLastMonthReportLoaded', payload)
    },

    bindLastMonthReport: firestoreAction((context) => {
      context.dispatch('setLoading', true)

      const nowSortingValue = getSortingValueNumberFromDateWithoutTimeAndDay()

      return context
        .bindFirestoreRef(
          'lastMonthReport',
          db
            .collection('monthReports')
            .where('salonId', '==', context.getters.userSalonId)
            .where('sortingValue', '==', nowSortingValue)
        )
        .then(() => {
          context.dispatch('setIsLastMonthReportLoaded', true)
          context.dispatch('setLoading', false)
        })
    }),

    unbindLastMonthReport: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('lastMonthReport')
    }),
  },
  getters: {
    lastMonthReport: (state) => state.lastMonthReport[0],
    isLastMonthReportLoaded: (state) => state.isLastMonthReportLoaded,
  },
}
