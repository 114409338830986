import { db } from '@/plugins/firebase'
import { firestoreAction } from 'vuexfire'

export default {
  state: {
    marketings: [],
  },
  mutations: {},
  actions: {
    bindMarketings: firestoreAction((context) => {
      context.dispatch('setLoading', true)

      return context
        .bindFirestoreRef(
          'marketings',
          db.collection('marketings').orderBy('sortingValue', 'desc')
        )
        .then(function () {
          context.dispatch('setLoading', false)
        })
    }),

    unbindMarketings: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('marketings')
    }),

    addMarketings: firestoreAction((context, payload) => {
      payload = Object.assign(
        {
          salonId: context.getters.userSalonId,
        },
        payload
      )

      return db
        .collection('marketings')
        .add(payload)
        .then(function (docRef) {
          db.collection('marketings')
            .doc(docRef.id)
            .set({ id: docRef.id }, { merge: true })
        })
    }),

    updateMarketings: firestoreAction((context, payload) => {
      return db
        .collection('marketings')
        .doc(payload.id)
        .set(payload, { merge: true })
    }),

    removeMarketings: firestoreAction((context, marketingsId) => {
      db.collection('marketings').doc(marketingsId).delete()
    }),
  },
  getters: {
    marketings: (state) => state.marketings,
    marketingsById: (state) => (id) => {
      return state.marketings.find((marketings) => marketings.id === id)
    },
  },
}
