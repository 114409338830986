<template>
  <div>
    <el-form-item :label="'Продукт ' + (index + 1)">
      <div class="product-header">
        <div class="line-height-1-5">
          <strong>{{ productObj.name }}</strong>

          <div class="small">
            Базова вартість за шт.

            <CurrencyView :value="productObj.price" /><br />

            Доступно

            <strong>{{ productQuantity }}</strong>
            шт.<br />
          </div>
        </div>

        <el-button
          type="danger"
          @click.prevent="removeProduct(index)"
          :disabled="loading"
          icon="el-icon-minus"
          size="small"
          class="product-remove show-up-sm"
        >
          Видалити
        </el-button>
      </div>

      <div class="product-form">
        <div class="product-form-inner-item">
          <div class="product-form-label">Ціна за шт:</div>

          <NumberInput
            suffixDefault="money"
            v-model="localProductItem.price"
            :min="0"
            @change="onChangePrice"
          />
        </div>

        <div class="product-form-inner-item">
          <div class="product-form-label">Кількість:</div>

          <NumberInput
            v-model="localProductItem.quantity"
            :min="1"
            :max="productQuantity"
            @change="getItemsFromQuantities(localProductItem.quantity)"
            suffix="шт"
            controls
          />
        </div>
      </div>
    </el-form-item>

    <el-form-item
      label="Майстру"
      size="small"
      prop="masterPercent"
      v-if="
        sellsMasterPart &&
        !isGlobalMasterPercent &&
        masterId &&
        localProductItem.price
      "
    >
      <FinancesIncomeWarehouseFormPercents
        :finalPrice="finalPrice"
        @change="onPercentsChange"
      />
    </el-form-item>

    <el-form-item class="margin-bottom-0">
      <div class="line-height-1-5">
        Разом за товар:
        <CurrencyView :value="finalPrice" />

        <span class="product-parts" v-if="salonPart || masterPart || adminPart">
          (<span v-if="salonPart"
            >салону: <CurrencyView :value="salonPart" />
          </span>

          <span v-if="masterPart"
            >майстру: <CurrencyView :value="masterPart" />
          </span>

          <span v-if="adminPart"
            >адміністратору: <CurrencyView :value="adminPart" /> </span
          >)
        </span>
      </div>
    </el-form-item>

    <el-form-item>
      <el-button
        type="danger"
        @click.prevent="removeProduct(index)"
        :disabled="loading"
        icon="el-icon-minus"
        size="small"
        class="product-remove show-down-sm margin-top-15"
      >
        Видалити
      </el-button>
    </el-form-item>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { getQuantity, getItemsFromQuantities } from '@/utils/warehouse'

  import FinancesIncomeWarehouseFormPercents from '@/components/Finances/FinancesFlow/FinancesIncome/FinancesIncomeWarehouseFormPercents'
  import NumberInput from '@/components/Common/Forms/NumberInput'
  import CurrencyView from '@/components/Common/CurrencyView'

  export default {
    data() {
      return {
        masterPercentValue: 0,
        masterPercentCustomValue: 0,
        masterCustomValue: 0,
        productObj: {},
        localProductItem: {
          id: '',
          quantity: 1,
          price: 0,
          masterPart: 0,
          bookedKeysAndValues: {},
        },
      }
    },
    watch: {
      chosenProductItem: {
        immediate: true,
        deep: false,
        handler(productItem) {
          if (!productItem) return

          this.productObj = this.productById(productItem.id)

          this.localProductItem = JSON.parse(JSON.stringify(productItem))
        },
      },
      globalMasterPercent: {
        immediate: true,
        deep: false,
        handler() {
          this.masterPercentValue = 0

          this.sendProductData()
        },
      },
      adminPercent: {
        immediate: true,
        deep: false,
        handler() {
          this.sendProductData()
        },
      },
    },
    components: {
      FinancesIncomeWarehouseFormPercents,
      NumberInput,
      CurrencyView,
    },
    props: {
      chosenProductItem: Object,
      isGlobalMasterPercent: Boolean,
      globalMasterPercent: Number,
      adminPercent: Number,
      index: Number,
      masterId: String,
    },
    computed: {
      ...mapGetters([
        'loading',
        'user',
        'masterById',
        'productById',
        'userSalon',
      ]),
      currentUser() {
        return this.masterById(this.user.id)
      },
      sellsMasterPart() {
        return this.userSalon.sellsMasterPart
      },
      finalPrice() {
        return this.localProductItem.quantity * this.localProductItem.price
      },
      adminPart() {
        if (!this.adminPercent) return 0

        return Number(((this.finalPrice * this.adminPercent) / 100).toFixed(0))
      },
      finalPriceWithAdminPart() {
        if (!this.adminPart) return this.finalPrice

        return this.finalPrice - this.adminPart
      },
      isNeedToUseLocalMasterPart() {
        return !this.isGlobalMasterPercent
      },
      isMasterPartInPercents() {
        return this.masterPercentValue <= 100
      },
      isMasterPartInCustomPercents() {
        return this.masterPercentValue === 999
      },
      isMasterPartInCustomValue() {
        return this.masterPercentValue === 9999
      },
      masterPercent() {
        if (!this.sellsMasterPart) return 0

        let value = 0

        if (this.isNeedToUseLocalMasterPart) {
          if (this.isMasterPartInPercents) {
            value = this.masterPercentValue
          } else if (this.isMasterPartInCustomPercents) {
            value = this.masterPercentCustomValue
          }
        } else {
          value = this.globalMasterPercent
        }

        return value
      },
      masterPart() {
        if (!this.sellsMasterPart) return 0

        return this.isMasterPartInCustomValue
          ? Number(this.masterCustomValue)
          : Number(
              (
                (this.finalPriceWithAdminPart * this.masterPercent) /
                100
              ).toFixed(0)
            )
      },
      salonPart() {
        return this.finalPriceWithAdminPart - this.masterPart
      },
      productQuantity() {
        return this.getQuantityLocal(
          this.productObj.id,
          this.productObj.quantity
        )
      },
    },
    methods: {
      onPercentsChange({ percentValue, percentCustomValue, customValue }) {
        this.masterPercentValue = percentValue
        this.masterPercentCustomValue = percentCustomValue
        this.masterCustomValue = customValue

        this.sendProductData()
      },
      getQuantityLocal(productId, quantities) {
        return getQuantity(
          productId,
          quantities,
          this.$store.getters.userSalonId,
          this.currentUser.userId
        )
      },
      getItemsFromQuantities(quantity) {
        this.localProductItem.bookedKeysAndValues = {}

        this.localProductItem.bookedKeysAndValues = getItemsFromQuantities(
          this.productObj,
          quantity
        )

        this.sendProductData()
      },
      onChangePrice() {
        this.sendProductData()
      },
      removeProduct(index) {
        this.$emit('removeProduct', index)
      },
      sendProductData() {
        this.$emit('sendProductData', {
          productItem: {
            ...this.localProductItem,
            masterPart: this.masterPart,
            salonPart: this.salonPart,
            adminPart: this.adminPart,
          },
          index: this.index,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .product-header {
    @media screen and (min-width: 481px) {
      display: flex;
      align-items: flex-start;
    }
  }

  .product-remove {
    @include to('sm') {
      margin-top: 10px;
      margin-left: auto;
    }
  }

  .product-form {
    @media screen and (min-width: 768px) {
      display: flex;
    }

    .product-form-inner-item {
      margin-right: 0;

      @media screen and (min-width: 768px) {
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .product-parts {
    > span {
      &:not(:last-child) {
        &:after {
          content: ' / ';
        }
      }
    }
  }
</style>
