<template>
  <el-dialog
    :title="`Додати модель на ${date}`"
    :close-on-click-modal="true"
    :visible.sync="localDialogVisibility"
    :show-close="true"
    destroy-on-close
    width="50%"
    append-to-body
    :before-close="closeDialog"
  >
    <el-form
      :model="courceModelForm"
      ref="courceModelForm"
      :rules="rules"
      label-width="140px"
    >
      <ClientFormItem
        :clientPhone="choosedClientPhone"
        :isClientAdding="isClientAdding"
        @changeClientAdding="onChangeClientAdding"
        @choosedClient="onClientsChoose"
        prop="clientId"
        title="Модель"
        :rules="REQUIRED_FIELD_MESSAGE_RULE_OBJECT"
      />

      <TimeInRowFormItem
        :timeStartValue="courceModelForm.timeStart"
        :timeEndValue="courceModelForm.timeEnd"
        :timeStartDisabled="timeInputDisabled"
        :timeEndDisabled="!courceModelForm.timeStart || timeInputDisabled"
        @sendData="onSendTimeData"
      />

      <el-form-item
        label="Процедура"
        prop="procedure"
        :rules="REQUIRED_FIELD_MESSAGE_RULE_OBJECT"
        v-if="
          eventForm.cource.procedures && eventForm.cource.procedures.length > 0
        "
      >
        <el-select
          v-model="courceModelForm.procedure"
          :disabled="isClientAdding || loading || courceModelForm.finished"
          placeholder="Обрати"
        >
          <el-option
            v-for="(item, index) in procedures"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="Запланована вартість процедури"
        prop="price"
        v-if="
          eventForm.cource.procedures && eventForm.cource.procedures.length > 0
        "
      >
        <NumberInput
          v-model="courceModelForm.price"
          :disabled="isClientAdding || loading || courceModelForm.finished"
          :min="10"
          suffixDefault="money"
        />
      </el-form-item>

      <el-form-item label="Коментар" prop="note">
        <el-input v-model="courceModelForm.note" type="textarea" :rows="2" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="sendModelData" v-if="!editingModel"
          >Додати модель</el-button
        >

        <el-button type="primary" @click="editModel" v-if="editingModel"
          >Редагувати модель</el-button
        >

        <el-button @click="closeDialog">Скасувати</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { constants } from '@/utils/constants'

  import { generateRandomID } from '@/utils/functions'
  import { validateStringAndIfItNotEqualOtherString } from '@/utils/customValidations'

  import ClientFormItem from '@/components/Common/Forms/ClientFormItem'
  import NumberInput from '@/components/Common/Forms/NumberInput'
  import TimeInRowFormItem from '@/components/Common/Forms/TimeInRowFormItem'

  export default {
    data() {
      return {
        localDialogVisibility: false,
        choosedClientPhone: '',
        courceModelForm: {
          id: '',
          clientId: '',
          name: '',
          phone: '',
          note: '',
          timeStartNumber: 0,
          timeEndNumber: 0,
          timeStart: '',
          timeEnd: '',
          procedure: '',
          price: 500,
          span: 1,
          finished: false,
          isConfirmed: false,
        },
        isClientAdding: false,
        REQUIRED_FIELD_MESSAGE_RULE_OBJECT:
          constants.requiredFieldMessageRuleObject,
        editingModel: false,
        rules: {
          price: [constants.requiredFieldMessageRuleObject],
          timeStart: [
            {
              validator: (rule, value, callback) =>
                validateStringAndIfItNotEqualOtherString(
                  rule,
                  value,
                  callback,
                  this.courceModelForm.timeEnd,
                  'Час не може бути однаковим'
                ),
              trigger: 'change',
            },
          ],
          timeEnd: [
            {
              validator: (rule, value, callback) =>
                validateStringAndIfItNotEqualOtherString(
                  rule,
                  value,
                  callback,
                  this.courceModelForm.timeStart,
                  'Час не може бути однаковим'
                ),
              trigger: 'change',
            },
          ],
        },
      }
    },
    props: {
      dateArray: Array,
      eventForm: Object,
      date: String,
      courceModelDialogVisible: Boolean,
      model: Object,
    },
    computed: {
      ...mapGetters(['loading']),
      procedures() {
        return this.eventForm.cource.procedures.split(',')
      },
      timeInputDisabled() {
        return (
          this.isClientAdding || this.loading || this.courceModelForm.finished
        )
      },
    },
    watch: {
      courceModelDialogVisible: {
        immediate: true,
        handler(visibility) {
          this.localDialogVisibility = visibility
        },
      },
      model: {
        immediate: true,
        deep: false,
        handler(model) {
          if (!model?.id) return

          this.courceModelForm = JSON.parse(JSON.stringify(model))
          this.choosedClientPhone = model.phone
          this.editingModel = true
        },
      },
    },
    components: {
      ClientFormItem,
      NumberInput,
      TimeInRowFormItem,
    },
    methods: {
      onSendTimeData(data) {
        this.courceModelForm.timeStart = data.timeStart
        this.courceModelForm.timeEnd = data.timeEnd

        if (data.timeStart) {
          this.generateTimeNumber(
            this.courceModelForm.timeStart,
            'timeStartNumber'
          )
        }

        if (data.timeEnd) {
          this.generateTimeNumber(this.courceModelForm.timeEnd, 'timeEndNumber')
        }
      },
      generateId(date) {
        let id = generateRandomID()
        let checkId = false

        if (
          this.eventForm.models[date] &&
          this.eventForm.models[date].length > 0
        ) {
          checkId = this.eventForm.models[date].some((model) => model.id === id)
        }

        if (!checkId) {
          return id
        } else {
          this.generateId(date)

          return null
        }
      },
      onClientsChoose(client) {
        if (client && client.id) {
          const id = this.courceModelForm.id
            ? this.courceModelForm.id
            : this.generateId(this.date)

          this.courceModelForm = {
            ...this.courceModelForm,
            id,
            clientId: client.id,
            name: client.name,
            phone: client.phone,
          }
        }
      },
      generateTimeNumber(value, type) {
        const numberValue = value?.split(':') || 0

        if (numberValue) {
          this.courceModelForm[type] = +`${numberValue[0]}${numberValue[1]}`
        }
      },
      getTimeSpan(start, end) {
        if (start === '' && end === '') return 0

        const timeStart = start.split(':')
        const timeEnd = end.split(':')

        const hoursDiff = +timeEnd[0] - +timeStart[0]
        const minutesDiff = +timeEnd[1] - +timeStart[1]

        const hoursSpan =
          minutesDiff < 0 ? hoursDiff / 0.5 - 1 : hoursDiff / 0.5
        const minutesSpan = minutesDiff <= 0 ? 0 : 1

        return hoursSpan + minutesSpan > 0 ? hoursSpan + minutesSpan : 0
      },
      sendModelData() {
        if (!this.courceModelForm.timeStartNumber) {
          this.generateTimeNumber(
            this.courceModelForm.timeStart,
            'timeStartNumber'
          )
        }

        if (!this.courceModelForm.timeEndNumber) {
          this.generateTimeNumber(this.courceModelForm.timeEnd, 'timeEndNumber')
        }

        this.$refs.courceModelForm.validate((valid) => {
          if (valid) {
            this.$emit('sendModelData', {
              model: {
                ...this.courceModelForm,
                span: this.getTimeSpan(
                  this.courceModelForm.timeStart,
                  this.courceModelForm.timeEnd
                ),
                date: this.date,
              },
            })

            this.closeDialog()
          }
        })
      },
      editModel() {
        if (!this.courceModelForm.timeStartNumber) {
          this.generateTimeNumber(
            this.courceModelForm.timeStart,
            'timeStartNumber'
          )
        }

        if (!this.courceModelForm.timeEndNumber) {
          this.generateTimeNumber(this.courceModelForm.timeEnd, 'timeEndNumber')
        }

        this.$refs.courceModelForm.validate((valid) => {
          if (valid) {
            this.$emit('sendModelData', {
              model: {
                ...this.courceModelForm,
                span: this.getTimeSpan(
                  this.courceModelForm.timeStart,
                  this.courceModelForm.timeEnd
                ),
                date: this.date,
              },
              edit: true,
            })

            this.closeDialog()
          }
        })
      },
      onChangeClientAdding(event) {
        this.isClientAdding = event
      },
      closeDialog() {
        this.courceModelForm = {
          id: '',
          clientId: '',
          name: '',
          phone: '',
          note: '',
          timeStartNumber: 0,
          timeEndNumber: 0,
          timeStart: '',
          timeEnd: '',
          procedure: '',
          price: 500,
          finished: false,
          isConfirmed: false,
        }

        this.choosedClientPhone = ''
        this.editingModel = false

        this.$emit('closeDialog')
      },
    },
  }
</script>
