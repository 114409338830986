<template>
  <div class="check-activity">
    <Icons icon="stopwatch" /> {{ timeOut / 1000 - timer }}
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Icons from '@/components/Common/Icons/Icons'

  export default {
    data() {
      return {
        t: 0,
        i: 0,
        timer: 0,
        timeOut: 18000000, // 5 hours
      }
    },
    components: {
      Icons,
    },
    computed: {
      ...mapGetters(['loading', 'user', 'masterById']),
      currentUser() {
        return this.masterById(this.user.id)
      },
    },
    methods: {
      initEvents() {
        if (!this.currentUser || !this.currentUser.forceExit) return

        window.onload = this.resetTimer
        window.onmousemove = this.resetTimer
        window.onmousedown = this.resetTimer // catches touchscreen presses as well
        window.ontouchstart = this.resetTimer // catches touchscreen swipes as well
        window.onclick = this.resetTimer // catches touchpad clicks as well
        window.onkeypress = this.resetTimer
        window.addEventListener('scroll', this.resetTimer, true) // improved; see comments
      },
      logOutUser() {
        this.$store.dispatch('logoutUser')
        this.$router.push('/').catch(() => {})
      },
      addOneToTimer() {
        this.timer++
      },
      clearTimer() {
        clearTimeout(this.t)
        clearInterval(this.i)
      },
      resetTimer() {
        this.timer = 0

        clearTimeout(this.t)
        clearInterval(this.i)

        this.t = setTimeout(this.logOutUser, this.timeOut)
        this.i = setInterval(this.addOneToTimer, 1000)
      },
    },
    mounted() {
      this.initEvents()
    },
    beforeDestroy() {
      clearTimeout(this.t)
      clearInterval(this.i)
    },
  }
</script>

<style lang="scss" scoped>
  .check-activity {
    font-size: 12px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 3px;
    }
  }
</style>
