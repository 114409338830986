import { db } from '@/plugins/firebase'
import store from '@/store/index.js'

import { getSortingValueNumberFromDate } from '@/utils/functions'

const HISTORY_COLLECTION_KEY = 'history'

const historyService = {
  addHistory: ({ batch, currentUser, type, now, additionalData }) => {
    const historyRef = db.collection(HISTORY_COLLECTION_KEY).doc()
    const historyRefId = historyRef.id

    batch.set(historyRef, {
      id: historyRefId,
      salonId: store.getters.userSalonId,
      userId: currentUser.userId,
      type,
      sortingValue: getSortingValueNumberFromDate(now),
      ...(additionalData && { ...additionalData }),
    })
  },
}

export default historyService
