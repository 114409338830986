export const USER_ROLES = {
  0: {
    slug: 'root',
    name: 'Рут',
    hidden: true,
    salaryType: 'salaryAdminExpenceCategory',
    id: 0,
  },
  1: {
    slug: 'super-admin',
    name: 'Керівник',
    salaryType: 'salaryAdminExpenceCategory',
    id: 1,
  },
  2: {
    slug: 'admin',
    name: 'Адміністратор',
    salaryType: 'salaryAdminExpenceCategory',
    id: 2,
  },
  3: {
    slug: 'master',
    name: 'Майстер',
    salaryType: 'salaryMasterExpenceCategory',
    id: 3,
  },
  4: {
    slug: 'reader',
    name: 'Читач',
    id: 4,
  },
  5: {
    slug: 'removed',
    name: 'Видалений',
    id: 5,
  },
}

export function isUserRemoved(user) {
  if (!user) return true

  return (
    (user.role !== undefined && +user.role === 5) ||
    (user.usedMaster !== undefined && user.usedMaster)
  )
}

export function isUserIsMasterOrLower(user) {
  if (!user) return false

  return user.role !== undefined && (+user.role <= 3 || user.role === 0)
}

export function isUserIsMaster(user) {
  if (!user) return false

  return user.role !== undefined && +user.role === 3
}

export function isUserIsAdminOrLower(user) {
  if (!user) return false

  return user.role !== undefined && (+user.role <= 2 || user.role === 0)
}

export function isUserIsAdmin(user) {
  if (!user) return false

  return user.role !== undefined && +user.role === 2
}

export function isUserIsOwnerOrLower(user) {
  if (!user) return false

  return user.role !== undefined && (+user.role <= 1 || user.role === 0)
}

export function isSuperAdmin(user) {
  if (!user) return false

  return user.role !== undefined && user.role === 0 ? true : false
}

export function getUserRoleById(roleId) {
  return USER_ROLES[+roleId]
}
