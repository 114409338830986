import { db } from '@/plugins/firebase'
import store from '@/store/index.js'

const SALONS_COLLECTION_KEY = 'salons'

const salonService = {
  getSalons: async () => {
    const salons = []

    await db
      .collection(SALONS_COLLECTION_KEY)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const item = doc.data()

          salons.push(item)
        })
      })
      .catch((error) => store.dispatch('setError', error.message))
      .finally(() => {
        store.dispatch('setLoading', false)
      })

    return salons
  },

  getSalon: async ({ salonId }) => {
    let salon = null

    await db
      .collection(SALONS_COLLECTION_KEY)
      .doc(salonId)
      .get()
      .then((doc) => {
        salon = doc.data()
      })
      .catch((error) => store.dispatch('setError', error.message))
      .finally(() => {
        store.dispatch('setLoading', false)
      })

    return salon
  },

  createSalonItem: () => {
    const salonRef = db.collection(SALONS_COLLECTION_KEY).doc()
    const salonRefId = salonRef.id

    return { salonRef, salonRefId }
  },

  addSalon: ({ batch, salonData, salonRef, salonRefId }) => {
    let localSalonRef, localSalonRefId

    if (!salonRef && !salonRefId) {
      const data = salonService.createSalonItem()

      localSalonRef = data.salonRef
      localSalonRefId = data.salonRefId
    }

    batch.set(salonRef || localSalonRef, {
      id: salonRefId || localSalonRefId,
      ...salonData,
    })

    return salonRefId
  },

  updateSalon: ({
    batch,
    salonId = store.getters.userSalonId,
    salonData,
    isNeedToUpdate = false,
  }) => {
    const salonRef = db.collection(SALONS_COLLECTION_KEY).doc(salonId)

    if (isNeedToUpdate) {
      batch.update(salonRef, {
        salonId,
        ...salonData,
      })
    } else {
      batch.set(
        salonRef,
        {
          salonId,
          ...salonData,
        },
        { merge: true }
      )
    }
  },
}

export default salonService
