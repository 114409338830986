<template>
  <div class="footer-buttons">
    <div class="footer-buttons-expanded-area">
      <div
        class="footer-buttons-masters"
        :class="{ active: expandedMenu === 'masters' }"
      >
        <el-checkbox-group
          v-model="filteredCalendarMasters"
          @change="changeMasters"
        >
          <el-checkbox
            v-for="master in mastersForCalendar"
            :label="master.id"
            :key="master.id"
          >
            {{
              master.eventLetter
                ? master.eventLetter
                : master.name.substring(0, 1)
            }}
          </el-checkbox>
        </el-checkbox-group>
      </div>

      <div
        class="footer-buttons-settings"
        :class="{ active: expandedMenu === 'settings' }"
      >
        <el-checkbox
          key="canceled"
          v-model="eventType.canceledEvents"
          @change="changeEventType"
          class="canceled"
        >
          <i class="el-icon-close" />
        </el-checkbox>

        <el-checkbox
          key="absence"
          v-model="eventType.absenceEvents"
          @change="changeEventType"
          class="absence"
        >
          <i class="el-icon-cold-drink" />
        </el-checkbox>

        <el-checkbox
          key="study"
          v-model="eventType.studyEvents"
          @change="changeEventType"
          class="study"
        >
          <i class="el-icon-notebook-1" />
        </el-checkbox>

        <div
          class="sms"
          v-if="currentUser && [0, 1, 2].includes(currentUser.role)"
        >
          <el-button
            type="primary"
            @click="openSmsDialog"
            size="mini"
            v-if="userSalonId === 'beautiq'"
          >
            SMS
          </el-button>
        </div>
      </div>

      <div
        class="footer-buttons-clients"
        :class="{ active: expandedMenu === 'clients' }"
      >
        <ClientSearch ref="clientSearch" :atBottom="true" />
      </div>
    </div>

    <div class="footer-button footer-button-1">
      <el-link icon="el-icon-arrow-left" :underline="false" @click="goToPre" />
    </div>

    <div
      class="footer-button footer-button-2"
      :class="{ active: expandedMenu === 'masters' }"
    >
      <el-link
        icon="el-icon-user"
        :underline="false"
        @click="expandFooterTrigger('masters')"
      />
    </div>

    <div class="footer-button footer-button-3">
      <el-link icon="el-icon-plus" :underline="false" @click="addNew" />
    </div>

    <div
      class="footer-button footer-button-4"
      :class="{ active: expandedMenu === 'settings' }"
    >
      <el-link
        icon="el-icon-more-outline"
        :underline="false"
        @click="expandFooterTrigger('settings')"
      />
    </div>

    <div
      class="footer-button footer-button-5"
      :class="{ active: expandedMenu === 'clients' }"
    >
      <el-link
        icon="el-icon-search"
        :underline="false"
        @click="expandFooterTrigger('clients')"
      />
    </div>

    <div class="footer-button footer-button-6">
      <el-link
        icon="el-icon-arrow-right"
        :underline="false"
        @click="goToNext"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { EventBus } from '@/bus.js'
  import { dateTimeFormat } from '@/utils/functions'

  import ClientSearch from '@/components/Clients/ClientSearch'

  export default {
    name: 'calendar-buttons',
    data() {
      return {
        expandedMenu: '',
        filteredCalendarMasters: [],
        eventType: {
          canceledEvents: true,
          absenceEvents: true,
          studyEvents: true,
        },
      }
    },
    computed: {
      ...mapGetters([
        'user',
        'masterById',
        'userSalonId',
        'mastersForCalendar',
        'filteredMasters',
        'filteredEventType',
      ]),
      currentUser() {
        return this.masterById(this.user.id)
      },
    },
    components: {
      ClientSearch,
    },
    props: {
      expandFooter: Boolean,
    },
    watch: {
      filteredMasters: {
        immediate: true,
        deep: false,
        handler(masters) {
          if (!masters) return

          this.filteredCalendarMasters = masters
        },
      },
      filteredEventType: {
        immediate: true,
        deep: false,
        handler(eventType) {
          if (!eventType) return

          this.eventType = eventType
        },
      },
    },
    methods: {
      goToNext() {
        EventBus.$emit('goToNext')
      },
      goToPre() {
        EventBus.$emit('goToPre')
      },
      addNew() {
        EventBus.$emit('addEvent', {
          date: dateTimeFormat(new Date(), 'Y-Mo-D'),
          timeStart: '',
        })
      },
      openSmsDialog() {
        EventBus.$emit('openSmsDialog')
      },
      changeMasters() {
        EventBus.$emit('filterMasters', this.filteredCalendarMasters)
      },
      changeEventType() {
        EventBus.$emit('filterType', this.eventType)
      },
      expandFooterTrigger(menu) {
        let footerState = this.expandFooter

        if (menu === this.expandedMenu && footerState) {
          this.expandedMenu = ''
          footerState = false
        } else if (
          (menu !== this.expandedMenu && footerState) ||
          !footerState
        ) {
          this.expandedMenu = menu
          footerState = true
        }

        this.$emit('expandFooter', footerState)
      },
    },
    mounted() {
      this.expandedMenu = ''
    },
  }
</script>

<style lang="scss" scoped>
  .footer-buttons-expanded-area {
    &::v-deep {
      .el-checkbox-group {
        overflow-x: auto;
        overflow-y: hidden;
        height: 100%;
        margin: 0;
      }

      .el-checkbox {
        vertical-align: middle;
        margin: 0 5px 0 0;
        height: 100%;
        min-width: $footer-height-mobile-expand-part - 10 - 1;

        &:last-child {
          margin-right: 0;
        }

        .el-checkbox__input {
          display: none;

          &.is-checked + .el-checkbox__label {
            color: $white;
            background: $dark-gray;
            border-color: $dark-gray;
          }
        }

        .el-checkbox__label {
          width: 100%;
          height: 100%;
          padding: 2px;
          color: rgba($gray, 0.7);
          background: rgba($gray, 0.1);
          border: 1px solid rgba($gray, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .el-checkbox.canceled {
        .el-checkbox__input {
          &.is-checked + .el-checkbox__label {
            color: $white;
            background: $red;
            border-color: $red;
          }
        }
      }

      .el-checkbox.absence {
        .el-checkbox__input {
          &.is-checked + .el-checkbox__label {
            color: $dark-gray;
            background: $gray;
            border: $gray;
          }
        }
      }

      .el-checkbox.study {
        .el-checkbox__input {
          &.is-checked + .el-checkbox__label {
            color: $white;
            background: $purple;
            border: $purple;
          }
        }
      }
    }
  }

  .footer-buttons-settings {
    display: flex;
    align-items: center;

    .sms {
      margin-left: auto;
    }
  }
</style>
