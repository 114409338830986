<template>
  <div class="client-events">
    <div class="client-events__header">
      <div class="client-events__title">
        <h6>ВІЗИТИ</h6>

        <div v-if="events && events.length > 0" class="client-events__info">
          Всього: {{ events.length }} / заплановані: {{ plannedEventsLength }} /
          видалені: {{ deletedEventsLength }}
        </div>
      </div>

      <div class="client-events__filter" v-if="events && events.length > 0">
        <div class="client-events__filter--type">
          Показувати за

          <el-radio-group v-model="eventsDisplayType" size="mini">
            <el-radio-button label="date">списком</el-radio-button>
            <el-radio-button label="category">категоріями</el-radio-button>
            <el-radio-button label="procedure">процедурами</el-radio-button>
          </el-radio-group>
        </div>

        <div class="client-events__filter--status">
          Статус

          <el-checkbox-group
            v-model="eventsStatus"
            @change="filterEventsByStatuses"
            size="small"
            class="event-statuses"
          >
            <el-checkbox-button :label="3"
              ><span class="event-status finished" title="Успішні"></span
            ></el-checkbox-button>

            <el-checkbox-button :label="1"
              ><span class="event-status pending" title="Заплановані"></span
            ></el-checkbox-button>

            <el-checkbox-button :label="2"
              ><span class="event-status canceled" title="Видалені"></span
            ></el-checkbox-button>
          </el-checkbox-group>
        </div>
      </div>
    </div>

    <div v-loading="loading">
      <div v-if="!events || events.length === 0">
        <i>Клієнт поки не записувався на процедури</i>
      </div>

      <div v-if="events && events.length > 0" class="client-events-list">
        <ClientEventsByDate
          :events="filteredEvents"
          :key="filteredEvents.length"
          v-if="eventsDisplayType === 'date'"
        />

        <ClientEventsByProcedureCategory
          :events="filteredEvents"
          :key="filteredEvents.length"
          v-if="eventsDisplayType === 'category'"
        />

        <ClientEventsByProcedure
          :events="filteredEvents"
          :key="filteredEvents.length"
          v-if="eventsDisplayType === 'procedure'"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { db } from '@/plugins/firebase'
  import { mapGetters } from 'vuex'

  import { STATUSES_KEYS, statuses } from '@/utils/statuses'

  import ClientEventsByDate from '@/components/Clients/ClientEvents/ClientEventsByDate'
  import ClientEventsByProcedureCategory from '@/components/Clients/ClientEvents/ClientEventsByProcedureCategory'
  import ClientEventsByProcedure from '@/components/Clients/ClientEvents/ClientEventsByProcedure'

  export default {
    data() {
      return {
        events: [],
        eventsDisplayType: 'date',
        filteredEvents: [],
        eventsStatus: [1, 2, 3],
      }
    },
    props: {
      clientId: String,
    },
    watch: {
      clientId: {
        immediate: true,
        deep: false,
        handler(id) {
          if (!id) {
            return
          }

          this.$store.dispatch('setLoading', true)

          this.events = []

          db.collection('clients')
            .doc(id)
            .collection('clientEvents')
            .orderBy('sortingValue', 'desc')
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const event = doc.data()

                // Fix of incorrect implementation where we stored not status number, but string
                this.events.push({
                  ...event,
                  status:
                    event.status === STATUSES_KEYS.CANCELED
                      ? statuses[STATUSES_KEYS.CANCELED]
                      : event.status,
                })
              })
            })
            .then(() => {
              this.filterEventsByStatuses()

              this.$store.dispatch('setLoading', false)
            })
        },
      },
    },
    computed: {
      ...mapGetters(['user', 'masterById', 'loading']),
      currentUser() {
        return this.masterById(this.user.id)
      },
      plannedEventsLength() {
        return this.events.filter((event) => event.status === 1).length
      },
      deletedEventsLength() {
        return this.events.filter((event) => event.status === 2).length
      },
    },
    components: {
      ClientEventsByDate,
      ClientEventsByProcedureCategory,
      ClientEventsByProcedure,
    },
    methods: {
      filterEventsByStatuses() {
        this.filteredEvents = this.events.filter((event) =>
          this.eventsStatus.includes(event.status)
        )
      },
    },
    beforeDestroy() {
      this.events = []
    },
  }
</script>

<style lang="scss" scoped>
  .client-events {
    width: 100%;

    &__header {
      display: block;
      margin-bottom: 10px;
    }

    &__title {
      margin-right: auto;

      h6 {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }

    &__info {
      font-size: 85%;
    }

    &__filter {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media screen and (min-width: 767px) {
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
      }

      > div {
        &:not(:first-child) {
          margin-top: 10px;

          @media screen and (min-width: 767px) {
            margin-top: 0;
            margin-left: auto;
          }
        }
      }

      &--type {
        margin-top: 10px;

        @media screen and (min-width: 360px) {
          display: flex;
          align-items: center;
        }

        @media screen and (min-width: 767px) {
          margin-top: 0;
        }

        .el-radio-group {
          margin-top: 10px;

          @media screen and (min-width: 360px) {
            margin-left: 5px;
            margin-top: 0;
          }
        }
      }

      &--status {
        margin-top: 10px;

        @media screen and (min-width: 360px) {
          display: flex;
          align-items: center;
        }

        @media screen and (min-width: 767px) {
          margin-top: 0;
        }
      }
    }

    &::v-deep {
      .event-statuses {
        margin-left: 5px;

        .el-checkbox-button {
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }

          .el-checkbox-button__inner {
            padding: 0;
            background: none;
            border: none;
          }

          &.is-checked {
            .event-status {
              opacity: 1;
            }
          }

          .event-status {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            display: block;
            opacity: 0.2;

            &.pending {
              background: $yellow;
            }

            &.canceled {
              background: $red;
            }

            &.finished {
              background: $green;
            }
          }
        }
      }

      .client-events-list {
        margin: 0;

        ul {
          list-style: none;
          margin: 10px 0 0;
          padding: 0;
          overflow-x: hidden;
          overflow-y: auto;

          li + li {
            margin-top: 15px;
          }
        }

        .small {
          font-size: $smallFontSize;
          color: $gray;
        }
      }
    }
  }
</style>
