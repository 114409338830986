var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"client-cashback-item"},[_c('div',{staticClass:"client-cashback-item__description"},[(_vm.cashbackItem.reasonKey && _vm.reason)?_c('span',[_vm._v(_vm._s(_vm.reason))]):(_vm.cashbackItem.reason)?_c('span',[_vm._v(" + бали за "+_vm._s(_vm.cashbackItem.reason)+" ")]):_vm._e(),(_vm.cashbackItem.eventName)?_c('span',[(_vm.cashbackItem.referralEarn)?_c('span',[_vm._v(" Бали за запрошення клієнта / процедура ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.cashbackItem.eventName)+" ")]):_vm._e(),(_vm.cashbackItem.note)?_c('span',{staticClass:"italic"},[_vm._v(" / "+_vm._s(_vm.cashbackItem.note))]):_vm._e(),_c('span',[_vm._v(" / "+_vm._s(_vm.validFrom)+" - "+_vm._s(_vm.validTill)+" ")])]),(_vm.cashbackItem.eventId)?_c('span',[_vm._v("  / "),_c('EventContainer',{attrs:{"eventId":_vm.cashbackItem.eventId}})],1):_vm._e(),_c('div',{staticClass:"client-cashback-item__value"},[_c('CurrencyView',{attrs:{"value":_vm.cashbackItem.value,"sign":"B"}}),(
        _vm.cashbackItem.originValue !== _vm.cashbackItem.value &&
        _vm.cashbackItem.value !== 0
      )?_c('span',[_vm._v(" / "),_c('CurrencyView',{attrs:{"value":_vm.cashbackItem.originValue,"sign":"B"}})],1):_vm._e(),(
        _vm.cashbackItem.notValidYet &&
        _vm.isUserIsAdminOrLower &&
        _vm.loyalityDoubleIsEnabled &&
        (_vm.isDayIsNotMoreThanFiveDaysAgo || _vm.isUserIsOwnerOrLower) &&
        _vm.cashbackItem.value !== 0
      )?_c('div',{staticClass:"cashback-double"},[_c('el-button',{attrs:{"size":"extra-mini","type":"primary","title":"Увеличить баллы в 2 раза","disabled":_vm.cashbackItem.isReward || _vm.cashbackItem.referralEarn},on:{"click":function($event){return _vm.doubleCashback(_vm.cashbackItem)}}},[_c('small',[_vm._v("x")]),_vm._v("2 ")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }