const requiredMessage = "Поле обов'язково"

export const constants = {
  minimalCharsForSearch: 2,
  minimalCharsForMaster: 4,
  maximalCharsForSearch: 25,
  timeStartHour: 9,
  timeStartNumber: 900,
  timeStartString: '09:00',
  timeEndHour: 22,
  timeEndNumber: 2200,
  timeEndString: '22:00',
  hourHeight: 50,
  adminsPercentPartFromSales: 0.01,
  halfYearInDays: 182,
  quarterYearInDays: 90,
  courcesTimeStep: '00:30',
  daynames: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  daynamesFull: [
    'неділя',
    'понеділок',
    'вівторок',
    'середа',
    'четвер',
    "п'ятниця",
    'субота',
  ],
  monthNames: [
    'Січень',
    'Лютий',
    'Березень',
    'Квітень',
    'Травень',
    'Червень',
    'Липень',
    'Серпень',
    'Вересень',
    'Жовтень',
    'Листопад',
    'Грудень',
  ],
  monthNamesMini: [
    'Січ',
    'Лют',
    'Бер',
    'Кві',
    'Тра',
    'Чер',
    'Лип',
    'Сер',
    'Вер',
    'Жов',
    'Лис',
    'Гру',
  ],
  monthQuarterPeriods: {
    28: [7, 7, 7, 7],
    29: [8, 7, 7, 7],
    30: [8, 7, 8, 7],
    31: [8, 8, 8, 7],
  },
  maxDaysInCourceModelView: 5,
  calendarViewTypes: ['day', 'week', 'month', 'year'],
  unexpiratedFutureDate: 30000101,
  moneySigns: ['₴', '$', '€', 'zł'],
  requiredFieldMessage: requiredMessage,
  requiredFieldMessageRuleObject: {
    required: true,
    message: requiredMessage,
    trigger: 'blur',
  },
  datePickerOptionsWithDisabledPastAndAfterOneYear: {
    firstDayOfWeek: 1,
    disabledDate(date) {
      const today = new Date()
      today.setDate(today.getDate() - 1)
      const todayAfterYear = new Date(today)
      todayAfterYear.setFullYear(todayAfterYear.getFullYear() + 1)

      return date < today || date > todayAfterYear
    },
  },
  phoneFormats: {
    ua: {
      label: 'Український',
      mask: '+380 (##) ###-##-##',
      format: '+380 (00) 000-00-00',
      code: '+380',
      flag: require('@/../public/img/uk.svg'),
      minNumbersWithSymbols: 19,
      maxNumbersWithSymbols: 19,
      countNumbers: 12,
    },
    pl: {
      label: 'Польський',
      mask: '+48 ## ### ## ##',
      format: '+48 00 000 00 00',
      code: '+48',
      flag: require('@/../public/img/pl.svg'),
      minNumbersWithSymbols: 16,
      maxNumbersWithSymbols: 16,
      countNumbers: 11,
    },
    int: {
      label: 'Міжнародний',
      mask: '+###############',
      format: '+000000000000000',
      flag: require('@/../public/img/globe.svg'),
      code: '+',
      minNumbersWithSymbols: 11,
      maxNumbersWithSymbols: 16,
      countNumbers: 16,
    },
  },
  yesNo: [
    {
      label: true,
      title: 'Так',
    },
    {
      label: false,
      title: 'Ні',
    },
  ],
  twoWeeksInDays: 14,
}

const getDaysInFebruary = () => {
  const firstOfMarch = new Date()
  firstOfMarch.setDate(1)
  firstOfMarch.setMonth(2)

  firstOfMarch.setDate(firstOfMarch.getDate() - 1)

  return firstOfMarch.getDate()
}

export const timeConstants = {
  daysInMonth: {
    1: 31,
    2: getDaysInFebruary(),
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  },
  monthQuarterPeriods: {
    28: [1, 8, 15, 22],
    29: [1, 9, 16, 23],
    30: [1, 9, 16, 24],
    31: [1, 9, 17, 25],
  },
}

export const notificationOptions = [
  { value: 0, name: 'Не нагадувати' },
  { value: 7, name: 'Через тиждень' },
  { value: 14, name: 'Через 2 тижні' },
  { value: 21, name: 'Через 3 тижні' },
  { value: 30, name: 'Через місяць' },
  { value: 45, name: 'Через 1,5 місяці' },
  { value: 60, name: 'Через 2 місяці' },
  { value: 75, name: 'Через 2,5 місяці' },
  { value: 90, name: 'Через 3 місяці' },
  { value: 120, name: 'Через 4 місяці' },
  { value: 182, name: 'Через 6 місяці' },
  { value: 364, name: 'Через рік' },
]

export const notificationOptionsWithCustom = [
  ...notificationOptions,
  { value: 999, name: 'Особливий' },
]
