const apiUrl =
  process.env.NODE_ENV === 'development'
    ? 'https://devapi.bossy.pro'
    : 'https://api.bossy.pro'
// const apiUrl = 'http://localhost:3000' // In case if local server is started
// const apiUrl = 'https://api.bossy.pro'

export const backendConfig = {
  clientApiUrl: `${apiUrl}/clients`,
  cashbacksApiUrl: `${apiUrl}/cashbacks`,
  sendpulseApiUrl: `${apiUrl}/sendpulse`,
}
