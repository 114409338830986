<template>
  <font-awesome-icon :icon="icon" />
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: {
      icon: String,
    },
  }
</script>

<style lang="scss" scoped></style>
