function eventDispatcher() {
  const listeners = {}

  return {
    off(eventName) {
      document.removeEventListener(eventName, listeners[eventName])

      delete listeners[eventName]
    },
    on(eventName, eventFunc) {
      if (!listeners[eventName]) {
        listeners[eventName] = (data) => eventFunc(data.detail)
      }

      document.addEventListener(eventName, listeners[eventName])
    },
    trigger(eventName, eventData) {
      if (!eventName) return

      const event = new CustomEvent(eventName, { detail: eventData })

      document.dispatchEvent(event)
    },
  }
}

export const customEventBus = eventDispatcher()
