import store from '@/store/index.js'

import { db } from '@/plugins/firebase'
import { statuses } from '@/utils/statuses'
import {
  sanitizeString,
  sortObjectByKey,
  getDateThatWillBeAfterOffsetDays,
  getSortingValueNumberFromDateWithoutTime,
} from '@/utils/functions'

import { CASHBACK_REASONS_KEYS } from '@/utils/expences'

import { constants } from '@/utils/constants'

import financesService from '@/services/finances-service'
import historyService from '@/services/history-service'
import financesReportsService from '@/services/finances-reports-service'

const CLIENTS_COLLECTION_KEY = 'clients'
const CLIENTS_EVENTS_COLLECTION_KEY = 'clientEvents'
const CLIENTS_CASHBACK_COLLECTION_KEY = 'cashback'
const CLIENTS_SELLS_COLLECTION_KEY = 'sells'
const ONEDAY = 1

const clientService = {
  addClient: ({ batch, clientData }) => {
    const clientRef = db.collection(CLIENTS_COLLECTION_KEY).doc()
    const clientRefId = clientRef.id

    batch.set(clientRef, {
      id: clientRefId,
      ...clientData,
    })

    return clientRefId
  },

  updateClient: ({ batch, clientId, clientData }) => {
    const clientEventRef = db.collection(CLIENTS_COLLECTION_KEY).doc(clientId)

    batch.set(
      clientEventRef,
      {
        salonId: store.getters.userSalonId,
        ...clientData,
      },
      { merge: true }
    )
  },

  getClientCashbackByEventId: async ({ clientId, eventId }) => {
    let cashback = null

    await db
      .collection(CLIENTS_COLLECTION_KEY)
      .doc(clientId)
      .collection(CLIENTS_CASHBACK_COLLECTION_KEY)
      .where('eventId', '==', eventId)
      .get()
      .then((data) => {
        data.forEach((snap) => {
          if (!snap.data()) return

          cashback = snap.data()
        })
      })

    return cashback
  },

  getClientCashbacks: async ({ clientId }) => {
    const monthAgo = new Date()
    monthAgo.setMonth(monthAgo.getMonth() - 1)

    let cashback = []

    await db
      .collection(CLIENTS_COLLECTION_KEY)
      .doc(clientId)
      .collection(CLIENTS_CASHBACK_COLLECTION_KEY)
      .where(
        'validTill',
        '>=',
        getSortingValueNumberFromDateWithoutTime(monthAgo)
      )
      .orderBy('validTill', 'desc')
      .get()
      .then((data) => {
        data.forEach((snap) => {
          if (!snap.data()) return

          cashback.push(snap.data())
        })
      })

    return cashback
  },

  getClientEvents: async ({
    clientId,
    status,
    sorting = 'desc',
    sortingValue = '',
  }) => {
    let events = []

    let query = db
      .collection(CLIENTS_COLLECTION_KEY)
      .doc(clientId)
      .collection(CLIENTS_EVENTS_COLLECTION_KEY)
      .orderBy('sortingValue', sorting)

    if (status) {
      query.where('status', '==', status)
    }

    if (sortingValue) {
      query = query.where('sortingValue', '>=', sortingValue)
    }

    await query.get().then((data) => {
      data.forEach((snap) => {
        if (!snap.data()) return

        events.push(snap.data())
      })
    })

    return events
  },

  getClientLastFinishedEvent: async ({ clientId }) => {
    let event = null

    await db
      .collection(CLIENTS_COLLECTION_KEY)
      .doc(clientId)
      .collection(CLIENTS_EVENTS_COLLECTION_KEY)
      .where('status', 'in', [statuses.finished])
      .orderBy('sortingValue', 'desc')
      .limit(1)
      .get()
      .then((data) => {
        data.forEach((snap) => {
          if (!snap.data()) return

          event = snap.data()
        })
      })

    return event
  },

  addClientEvent: ({ batch, clientId, eventId, sortingValue, eventData }) => {
    const clientEventRef = db
      .collection(CLIENTS_COLLECTION_KEY)
      .doc(clientId)
      .collection(CLIENTS_EVENTS_COLLECTION_KEY)
      .doc(eventId)

    batch.set(clientEventRef, {
      id: eventId,
      salonId: store.getters.userSalonId,
      sortingValue,
      ...eventData,
    })
  },

  addClientEventWithMastersProcedures: ({
    batch,
    clientId,
    eventId,
    sortingValue,
    eventForm,
  }) => {
    const {
      mastersProcedures,
      masterIds,
      dateStart,
      eventPrice,
      eventTimeEnd,
      eventTimeStart,
    } = eventForm

    const cleanedProcedures = {}
    let procedureIds = []

    Object.keys(mastersProcedures).forEach((masterKey) => {
      const [masterIndex, masterId] = masterKey.split('_')
      const { procedures } = mastersProcedures[masterKey]

      Object.keys(procedures).forEach((procedureKey) => {
        const [procedureIndex] = procedureKey.split('_')
        const {
          additionalFields,
          correction,
          timeStart,
          timeEnd,
          procedureName,
          procedureId,
          price,
        } = procedures[procedureKey]
        const procedureClientKey = `${masterKey}_${procedureKey}`

        if (
          !Object.prototype.hasOwnProperty.call(
            cleanedProcedures,
            procedureClientKey
          )
        ) {
          procedureIds.push(procedureId)

          cleanedProcedures[procedureClientKey] = {
            index: Number(`${masterIndex}.${procedureIndex}`),
            masterId,
            procedureId,
            procedureName,
            correction,
            timeStart,
            timeEnd,
            price,
            ...(additionalFields && { additionalFields }),
          }
        }
      })
    })

    procedureIds = [...new Set(procedureIds)]

    clientService.addClientEvent({
      batch,
      sortingValue,
      clientId,
      eventId,
      eventData: {
        masterIds,
        date: dateStart,
        eventPrice,
        eventTimeEnd,
        eventTimeStart,
        status: statuses.planned,
        procedures: sortObjectByKey(cleanedProcedures),
        procedureIds,
        ...(eventForm.note && { note: sanitizeString(eventForm.note) }),
      },
    })
  },

  updateClientEvent: ({ batch, eventId, clientId, eventData }) => {
    const clientEventRef = db
      .collection(CLIENTS_COLLECTION_KEY)
      .doc(clientId)
      .collection(CLIENTS_EVENTS_COLLECTION_KEY)
      .doc(eventId)

    batch.set(clientEventRef, eventData, { merge: true })
  },

  removeClientEvent: ({ batch, clientId, eventId }) => {
    const clientRef = db
      .collection(CLIENTS_COLLECTION_KEY)
      .doc(clientId)
      .collection(CLIENTS_EVENTS_COLLECTION_KEY)
      .doc(eventId)

    batch.delete(clientRef)
  },

  getClientSells: async ({ clientId }) => {
    let sells = []

    await db
      .collection(CLIENTS_COLLECTION_KEY)
      .doc(clientId)
      .collection(CLIENTS_SELLS_COLLECTION_KEY)
      .where('salonId', '==', store.getters.userSalonId)
      .orderBy('sortingValue', 'desc')
      .get()
      .then((data) => {
        data.forEach((snap) => {
          if (!snap.data()) return

          sells.push(snap.data())
        })
      })

    return sells
  },

  addClientSell: ({ batch, clientId, sellData }) => {
    const clientSellRef = db
      .collection(CLIENTS_COLLECTION_KEY)
      .doc(clientId)
      .collection(CLIENTS_SELLS_COLLECTION_KEY)
      .doc()

    const clientSellRefId = clientSellRef.id

    batch.set(clientSellRef, {
      id: clientSellRefId,
      salonId: store.getters.userSalonId,
      ...sellData,
    })

    return clientSellRefId
  },

  addAdditionalEventData: ({
    batch,
    eventId,
    clientId,
    masterKey,
    procedureKey,
    additionalValuesObj,
    isVer2,
  }) => {
    const clientRef = db
      .collection(CLIENTS_COLLECTION_KEY)
      .doc(clientId)
      .collection(CLIENTS_EVENTS_COLLECTION_KEY)
      .doc(eventId)

    const clientKey = isVer2
      ? `procedures.${masterKey}_${procedureKey}.additionalFields`
      : 'additionalFields'

    batch.update(
      clientRef,
      {
        [clientKey]: additionalValuesObj,
      },
      { merge: true }
    )
  },

  addClientCashback: async ({
    batch,
    clientId,
    cashbackValue,
    data,
    historyData,
    whenStartActive,
    isNeedToReport,
  }) => {
    if (
      !store.getters.userSalon.loyalityDaysValid ||
      store.getters.userSalon.loyalityDaysValid === 0
    ) {
      return
    }

    const CASHBACK_STARTS_VALID = whenStartActive
      ? whenStartActive
      : store.getters.userSalon.loyalityValidAfterDays ||
        constants.twoWeeksInDays

    const CASHBACKVALID = store.getters.userSalon.loyalityDaysValid || 0

    const cashbackRef = db
      .collection(CLIENTS_COLLECTION_KEY)
      .doc(clientId)
      .collection(CLIENTS_CASHBACK_COLLECTION_KEY)
      .doc()
    const cashbackRefId = cashbackRef.id

    const clientCashbackdData = {
      ...data,
      id: cashbackRefId,
      clientId,
      salonId: store.getters.userSalonId,
      validFrom: Number(
        getDateThatWillBeAfterOffsetDays(CASHBACK_STARTS_VALID)
      ),
      validTill: Number(
        getDateThatWillBeAfterOffsetDays(CASHBACKVALID + CASHBACK_STARTS_VALID)
      ),
      value: Number(cashbackValue),
      originValue: Number(cashbackValue),
    }

    batch.set(cashbackRef, clientCashbackdData)

    if (isNeedToReport) {
      financesReportsService.updateFinanceReport({
        batch,
        reportData: {
          cashback: {
            given: cashbackValue,
          },
        },
      })
    }

    if (historyData) {
      historyService.addHistory({
        batch,
        ...historyData,
      })
    }
  },

  updateClientCashback: async ({
    batch,
    clientId,
    cashbackDiff,
    cashbackId,
    updateData,
    isNeedToReport,
  }) => {
    const cashbackRef = db
      .collection(CLIENTS_COLLECTION_KEY)
      .doc(clientId)
      .collection(CLIENTS_CASHBACK_COLLECTION_KEY)
      .doc(cashbackId)

    batch.set(cashbackRef, { ...updateData }, { merge: true })

    if (isNeedToReport) {
      financesReportsService.updateFinanceReport({
        batch,
        reportData: {
          cashback: {
            given: cashbackDiff,
          },
        },
      })
    }
  },

  removeClientCashback: ({
    clientId,
    cashbackValueToRemove,
    cashbackArray,
    batch,
  }) => {
    let restOfCashback = cashbackValueToRemove

    cashbackArray.map((item, index) => {
      if (restOfCashback !== 0 && item.value !== 0) {
        let involvedItem = {}

        if (item.value >= restOfCashback) {
          const cashbackValue = cashbackArray[index].value - restOfCashback

          involvedItem = {
            id: item.id,
            value: cashbackValue,
          }

          cashbackArray[index].value = cashbackValue

          restOfCashback = 0
        } else {
          restOfCashback = restOfCashback - cashbackArray[index].value

          cashbackArray[index].value = 0

          involvedItem = {
            id: item.id,
            value: 0,
          }
        }

        const clientCashbackRef = db
          .collection(CLIENTS_COLLECTION_KEY)
          .doc(clientId)
          .collection(CLIENTS_CASHBACK_COLLECTION_KEY)
          .doc(involvedItem.id)

        batch.set(
          clientCashbackRef,
          {
            value: involvedItem.value,
          },
          { merge: true }
        )
      }
    })
  },

  updateFinalizedClientEvent: async ({
    batch,
    client,
    eventId,
    changedParts,
    currentUser,
    isNeedToReport,
    finishSortingvalue,
    eventDate,
  }) => {
    if (!changedParts.moneyParts?.clientPart && !changedParts.value) {
      return
    }

    const { id, earnings } = client

    if (changedParts.value) {
      const valueDiff = changedParts.value[1] - changedParts.value[0]

      const clientData = {
        id,
        earnings: parseInt(earnings) + valueDiff,
      }

      const clientRef = db.collection('clients').doc(id)

      batch.set(clientRef, clientData, { merge: true })

      clientService.updateClientEvent({
        batch,
        clientId: id,
        eventId,
        eventData: {
          status: statuses.finished,
          eventPrice: changedParts.value[1],
          finalized: Date(),
          finalizedBy: currentUser.userId,
        },
      })
    }

    if (changedParts.moneyParts?.clientPart) {
      const valueDiff =
        changedParts.moneyParts.clientPart[1] -
        changedParts.moneyParts.clientPart[0]

      const cashback = await clientService.getClientCashbackByEventId({
        clientId: id,
        eventId,
      })

      if (cashback?.id) {
        await clientService.updateClientCashback({
          batch,
          clientId: id,
          cashbackDiff: valueDiff,
          cashbackId: cashback.id,
          updateData: {
            value: changedParts.moneyParts.clientPart[1],
            originValue: changedParts.moneyParts.clientPart[1],
          },
          isNeedToReport,
        })
      } else {
        await clientService.addClientCashback({
          batch,
          clientId: id,
          cashbackValue: changedParts.moneyParts.clientPart[1],
          data: {
            eventId,
            eventDate,
            sortingValue: finishSortingvalue,
            referralEarn: false,
            reasonKey: CASHBACK_REASONS_KEYS.EVENT,
          },
          isNeedToReport,
        })
      }
    }
  },

  finalizeClientEvents: async ({
    batch,
    client,
    finishSortingvalue,
    eventId,
    eventPrice,
    eventDate,
    clientPart,
    now,
    isClientHasReffererAndWeDidntPayToReffererYet,
    isMasterActiveAndItsHasReffererAndWeDidntPayToMasterYet,
    isAdvClientAndCreatorNeedToPay,
    creatorId,
    loyalityReferralMasterValue,
    loyality,
    currentUser,
    cashbackPayedValue,
    cashbackArray,
    isNeedToReport = true,
  }) => {
    const { id, earnings } = client

    const clientData = {
      id,
      // Sum earnings to 'clients' DB to each client
      earnings: earnings ? parseInt(earnings) + eventPrice : eventPrice,
      latestFinishedDate: finishSortingvalue,
      ...((isClientHasReffererAndWeDidntPayToReffererYet ||
        isMasterActiveAndItsHasReffererAndWeDidntPayToMasterYet) && {
        // We will add refferer client's cashback, we should check that refferal client is earn own part
        isReferralClientReceivedCashback: true,
      }),
    }

    const clientRef = db.collection('clients').doc(id)

    batch.set(clientRef, clientData, { merge: true })

    clientService.updateClientEvent({
      batch,
      clientId: id,
      eventId,
      eventData: {
        status: statuses.finished,
        eventPrice,
        finalized: Date(),
        finalizedBy: currentUser.userId,
      },
    })

    // Add cashback
    if (loyality && clientPart) {
      await clientService.addClientCashback({
        batch,
        clientId: id,
        cashbackValue: clientPart,
        data: {
          eventId,
          eventDate,
          sortingValue: finishSortingvalue,
          referralEarn: false,
          reasonKey: CASHBACK_REASONS_KEYS.EVENT,
        },
        isNeedToReport,
      })
    }

    if (
      loyality &&
      cashbackPayedValue &&
      cashbackArray &&
      cashbackArray.length > 0
    ) {
      clientService.removeClientCashback({
        clientId: id,
        cashbackValueToRemove: cashbackPayedValue,
        cashbackArray,
        batch,
      })
    }

    // Add referral earns
    if (isClientHasReffererAndWeDidntPayToReffererYet) {
      const { referralClient } = client

      const value = store.getters.userSalon.loyalityReferralValue

      // For whom who joined
      await clientService.addClientCashback({
        batch,
        whenStartActive: ONEDAY,
        clientId: id,
        cashbackValue: value,
        data: {
          eventId,
          eventDate,
          sortingValue: finishSortingvalue,
          referralEarn: false,
          reasonKey: CASHBACK_REASONS_KEYS.REFERRAL_JOINED_CLIENT,
        },
        isNeedToReport,
      })

      financesService.addFinance({
        batch,
        type: 'clientBonus',
        financeData: {
          clientId: id,
          createdBy: currentUser.userId,
          eventId,
          createdAt: now,
          sortingValue: finishSortingvalue,
          value,
        },
      })

      // For whom who invited
      await clientService.addClientCashback({
        batch,
        whenStartActive: ONEDAY,
        clientId: referralClient,
        cashbackValue: value,
        data: {
          eventId,
          eventDate,
          sortingValue: finishSortingvalue,
          referralEarn: true,
          reasonKey: CASHBACK_REASONS_KEYS.REFERRAL_INVITED_CLIENT,
        },
        isNeedToReport,
      })

      financesService.addFinance({
        batch,
        type: 'clientRefBonus',
        financeData: {
          clientId: referralClient,
          createdBy: currentUser.userId,
          eventId,
          createdAt: now,
          sortingValue: finishSortingvalue,
          value,
        },
      })
    }

    // Add bonus to master
    if (isMasterActiveAndItsHasReffererAndWeDidntPayToMasterYet) {
      financesService.addFinance({
        batch,
        type: 'masterBonus',
        financeData: {
          clientId: id,
          masterId: client.referralMaster,
          createdBy: currentUser.userId,
          eventId,
          createdAt: now,
          sortingValue: finishSortingvalue,
          value: loyalityReferralMasterValue,
        },
      })
    }

    if (isAdvClientAndCreatorNeedToPay) {
      financesService.addFinance({
        batch,
        type: 'adminBonusForAdvClient',
        financeData: {
          clientId: id,
          masterId: creatorId,
          createdBy: currentUser.userId,
          eventId,
          createdAt: now,
          sortingValue: finishSortingvalue,
          value: store.getters.userSalon.adminBonusForAdvClients,
        },
      })
    }
  },
}

export default clientService
