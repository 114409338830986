<template>
  <el-form-item label="Моделі" prop="models" class="margin-bottom-15">
    <CourceModelsDays
      :eventForm="eventForm"
      :dateArray="
        eventForm.dateStart && !eventForm.dateEnd
          ? [eventForm.dateStart]
          : eventForm.dateArray
      "
    />
  </el-form-item>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CourceModelsDays from '@/components/Calendar/EventsForms/Cource/CourceModelsDays'

  export default {
    data() {
      return {}
    },
    computed: {
      ...mapGetters([]),
    },
    props: {
      eventForm: Object,
    },
    watch: {},
    components: {
      CourceModelsDays,
    },
    methods: {},
    mounted() {},
  }
</script>

<style lang="scss" scoped></style>
